import React, { useState, useRef } from 'react';
import { Helmet } from 'react-helmet-async';
import { Accordion, AccordionTab } from 'primereact/accordion';
import { Toast } from 'primereact/toast';
import axiosInstance from '../../axiosConfig';
import './ContactSales.css';
import TecnetLogo from '../../images/frontpage/tecnet_logo.jpg';

// List of countries
const countries = [
  "Afghanistan", "Albania", "Algeria", "Andorra", "Angola", "Antigua and Barbuda", "Argentina", "Armenia", "Australia", "Austria", "Azerbaijan",
  "Bahamas", "Bahrain", "Bangladesh", "Barbados", "Belarus", "Belgium", "Belize", "Benin", "Bhutan", "Bolivia", "Bosnia and Herzegovina",
  "Botswana", "Brazil", "Brunei", "Bulgaria", "Burkina Faso", "Burundi", "Cabo Verde", "Cambodia", "Cameroon", "Canada", "Central African Republic",
  "Chad", "Chile", "China", "Colombia", "Comoros", "Congo", "Costa Rica", "Croatia", "Cuba", "Cyprus", "Czech Republic", "Denmark", "Djibouti",
  "Dominica", "Dominican Republic", "Ecuador", "Egypt", "El Salvador", "Equatorial Guinea", "Eritrea", "Estonia", "Eswatini", "Ethiopia", "Fiji",
  "Finland", "France", "Gabon", "Gambia", "Georgia", "Germany", "Ghana", "Greece", "Grenada", "Guatemala", "Guinea", "Guinea-Bissau", "Guyana",
  "Haiti", "Honduras", "Hungary", "Iceland", "India", "Indonesia", "Iran", "Iraq", "Ireland", "Israel", "Italy", "Jamaica", "Japan", "Jordan",
  "Kazakhstan", "Kenya", "Kiribati", "Korea, North", "Korea, South", "Kosovo", "Kuwait", "Kyrgyzstan", "Laos", "Latvia", "Lebanon", "Lesotho",
  "Liberia", "Libya", "Liechtenstein", "Lithuania", "Luxembourg", "Madagascar", "Malawi", "Malaysia", "Maldives", "Mali", "Malta", "Marshall Islands",
  "Mauritania", "Mauritius", "Mexico", "Micronesia", "Moldova", "Monaco", "Mongolia", "Montenegro", "Morocco", "Mozambique", "Myanmar", "Namibia",
  "Nauru", "Nepal", "Netherlands", "New Zealand", "Nicaragua", "Niger", "Nigeria", "North Macedonia", "Norway", "Oman", "Pakistan", "Palau",
  "Palestine", "Panama", "Papua New Guinea", "Paraguay", "Peru", "Philippines", "Poland", "Portugal", "Qatar", "Romania", "Russia", "Rwanda",
  "Saint Kitts and Nevis", "Saint Lucia", "Saint Vincent and the Grenadines", "Samoa", "San Marino", "Sao Tome and Principe", "Saudi Arabia",
  "Senegal", "Serbia", "Seychelles", "Sierra Leone", "Singapore", "Slovakia", "Slovenia", "Solomon Islands", "Somalia", "South Africa",
  "South Sudan", "Spain", "Sri Lanka", "Sudan", "Suriname", "Sweden", "Switzerland", "Syria", "Taiwan", "Tajikistan", "Tanzania", "Thailand",
  "Timor-Leste", "Togo", "Tonga", "Trinidad and Tobago", "Tunisia", "Turkey", "Turkmenistan", "Tuvalu", "Uganda", "Ukraine",
  "United Arab Emirates", "United Kingdom", "United States", "Uruguay", "Uzbekistan", "Vanuatu", "Vatican City", "Venezuela", "Vietnam",
  "Yemen", "Zambia", "Zimbabwe"
];

const ContactSalesHeader = () => {
  return (
    <section className="contact-sales-header">
      <div className="contact-sales-container">
        <div className="contact-sales-header-content">
          <h1>Talk to an Expert</h1>
          <p>Want to see a custom demo or get help finding the right plan?<br />We'd love to chat.</p>
        </div>
      </div>
    </section>
  );
};

const CustomerStories = () => {
  return (
    <div className="customer-story">
      <img src={TecnetLogo} alt="Tecnet Logo" className="customer-logo" />
      <p className="customer-quote">
        "CodeDD's AI-powered code auditing platform has transformed our due diligence process. What used to take weeks of manual code review is now completed in hours, with even more comprehensive insights."
      </p>
      <p className="customer-author">
        - Peter Buchinger, Senior Investment Manager, Tecnet
      </p>
    </div>
  );
};

const ContactForm = () => {
  const toast = useRef(null);
  const [loading, setLoading] = useState(false);
  const [formData, setFormData] = useState({
    firstName: '',
    lastName: '',
    jobTitle: '',
    companyName: '',
    workEmail: '',
    country: '',
    helpText: ''
  });
  const [errors, setErrors] = useState({});

  // Get last submit time from localStorage on component mount
  const getLastSubmitTime = () => {
    const storedTime = localStorage.getItem('lastContactFormSubmit');
    return storedTime ? parseInt(storedTime, 10) : 0;
  };

  // Field length constraints matching backend
  const maxLengths = {
    firstName: 50,
    lastName: 50,
    jobTitle: 100,
    companyName: 100,
    workEmail: 100,
    country: 100,
    helpText: 1000
  };

  const validateEmail = (email) => {
    const re = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    return re.test(email);
  };

  const validateForm = () => {
    const newErrors = {};
    const requiredFields = ['firstName', 'lastName', 'jobTitle', 'workEmail', 'country', 'companyName', 'helpText'];
    
    requiredFields.forEach(field => {
      if (!formData[field]) {
        newErrors[field] = 'This field is required';
      }
    });

    // Length validations
    if (formData.firstName && formData.firstName.length < 2) {
      newErrors.firstName = 'First name must be at least 2 characters';
    }
    if (formData.lastName && formData.lastName.length < 2) {
      newErrors.lastName = 'Last name must be at least 2 characters';
    }
    if (formData.jobTitle && formData.jobTitle.length < 3) {
      newErrors.jobTitle = 'Job title must be at least 3 characters';
    }
    if (formData.companyName && formData.companyName.length < 2) {
      newErrors.companyName = 'Company name must be at least 2 characters';
    }
    if (formData.helpText && formData.helpText.length < 10) {
      newErrors.helpText = 'Please provide more details (minimum 10 characters)';
    }

    if (formData.workEmail && !validateEmail(formData.workEmail)) {
      newErrors.workEmail = 'Please enter a valid email address';
    }

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    if (value.length <= maxLengths[name]) {
      setFormData(prev => ({
        ...prev,
        [name]: value
      }));
      // Clear error when user starts typing
      if (errors[name]) {
        setErrors(prev => ({
          ...prev,
          [name]: ''
        }));
      }
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    // Rate limiting check using localStorage
    const lastSubmitTime = getLastSubmitTime();
    const now = Date.now();
    const timeSinceLastSubmit = now - lastSubmitTime;
    const cooldownPeriod = 180000; // 3 minutes in milliseconds

    if (timeSinceLastSubmit < cooldownPeriod) {
      const remainingTime = Math.ceil((cooldownPeriod - timeSinceLastSubmit) / 1000);
      toast.current.show({
        severity: 'warn',
        summary: 'Please wait',
        detail: `Please wait ${remainingTime} seconds before submitting another request`,
        life: 3000
      });
      return;
    }

    if (!validateForm()) {
      toast.current.show({
        severity: 'error',
        summary: 'Validation Error',
        detail: 'Please check the required fields',
        life: 3000
      });
      return;
    }

    setLoading(true);
    try {
      await axiosInstance.post('/contact-sales/', formData);
      // Store submission time in localStorage
      localStorage.setItem('lastContactFormSubmit', now.toString());
      
      toast.current.show({
        severity: 'success',
        summary: 'Success',
        detail: 'Your message has been sent successfully',
        life: 3000
      });
      setFormData({
        firstName: '',
        lastName: '',
        jobTitle: '',
        companyName: '',
        workEmail: '',
        country: '',
        helpText: ''
      });
    } catch (error) {
      toast.current.show({
        severity: 'error',
        summary: 'Error',
        detail: 'Failed to send message. Please try again later.',
        life: 3000
      });
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="contact-form-container">
      <Toast ref={toast} />
      <div className="contact-form-content">
        <p className="form-instruction">Please complete all fields.</p>
        <form className="contact-form" onSubmit={handleSubmit}>
          <div className="form-field">
            <label htmlFor="firstName">First name</label>
            <input
              type="text"
              id="firstName"
              name="firstName"
              value={formData.firstName}
              onChange={handleInputChange}
              maxLength={maxLengths.firstName}
              className={errors.firstName ? 'error' : ''}
            />
            <div className="field-info">
              {errors.firstName ? (
                <span className="error-message">{errors.firstName}</span>
              ) : (
                <span className="char-count">{formData.firstName.length}/{maxLengths.firstName}</span>
              )}
            </div>
          </div>

          <div className="form-field">
            <label htmlFor="lastName">Last name</label>
            <input
              type="text"
              id="lastName"
              name="lastName"
              value={formData.lastName}
              onChange={handleInputChange}
              maxLength={maxLengths.lastName}
              className={errors.lastName ? 'error' : ''}
            />
            <div className="field-info">
              {errors.lastName ? (
                <span className="error-message">{errors.lastName}</span>
              ) : (
                <span className="char-count">{formData.lastName.length}/{maxLengths.lastName}</span>
              )}
            </div>
          </div>

          <div className="form-field">
            <label htmlFor="jobTitle">Job title</label>
            <input
              type="text"
              id="jobTitle"
              name="jobTitle"
              value={formData.jobTitle}
              onChange={handleInputChange}
              maxLength={maxLengths.jobTitle}
              className={errors.jobTitle ? 'error' : ''}
            />
            <div className="field-info">
              {errors.jobTitle ? (
                <span className="error-message">{errors.jobTitle}</span>
              ) : (
                <span className="char-count">{formData.jobTitle.length}/{maxLengths.jobTitle}</span>
              )}
            </div>
          </div>

          <div className="form-field">
            <label htmlFor="companyName">Company name</label>
            <input
              type="text"
              id="companyName"
              name="companyName"
              value={formData.companyName}
              onChange={handleInputChange}
              maxLength={maxLengths.companyName}
              className={errors.companyName ? 'error' : ''}
            />
            <div className="field-info">
              {errors.companyName ? (
                <span className="error-message">{errors.companyName}</span>
              ) : (
                <span className="char-count">{formData.companyName.length}/{maxLengths.companyName}</span>
              )}
            </div>
          </div>

          <div className="form-field">
            <label htmlFor="workEmail">Work email address</label>
            <input
              type="email"
              id="workEmail"
              name="workEmail"
              value={formData.workEmail}
              onChange={handleInputChange}
              maxLength={maxLengths.workEmail}
              className={errors.workEmail ? 'error' : ''}
            />
            <div className="field-info">
              {errors.workEmail ? (
                <span className="error-message">{errors.workEmail}</span>
              ) : (
                <span className="char-count">{formData.workEmail.length}/{maxLengths.workEmail}</span>
              )}
            </div>
          </div>

          <div className="form-field">
            <label htmlFor="country">Country</label>
            <select
              id="country"
              name="country"
              value={formData.country}
              onChange={handleInputChange}
              className={errors.country ? 'error' : ''}
            >
              <option value="">Select...</option>
              {countries.map((country, index) => (
                <option key={index} value={country}>{country}</option>
              ))}
            </select>
            {errors.country && <span className="error-message">{errors.country}</span>}
          </div>

          <div className="form-field">
            <label htmlFor="helpText">How can we help you?</label>
            <textarea
              id="helpText"
              name="helpText"
              rows="4"
              value={formData.helpText}
              onChange={handleInputChange}
              maxLength={maxLengths.helpText}
              className={errors.helpText ? 'error' : ''}
            />
            <div className="field-info">
              {errors.helpText ? (
                <span className="error-message">{errors.helpText}</span>
              ) : (
                <span className="char-count">{formData.helpText.length}/{maxLengths.helpText}</span>
              )}
            </div>
          </div>

          <p className="privacy-notice">
            By providing your contact information, you agree that CodeDD may contact you via email and telephone about its products, services and events. You may opt-out at any time by unsubscribing in emails.
          </p>

          <button type="submit" className="submit-button" disabled={loading}>
            {loading ? (
              <i className="pi pi-spinner pi-spin"></i>
            ) : (
              <>
                <i className="pi pi-send"></i>
                <span>Get in touch</span>
              </>
            )}
          </button>
        </form>
      </div>
    </div>
  );
};

const FAQSection = () => {
  const [expandAll, setExpandAll] = useState(false);
  const [licenseExpanded, setLicenseExpanded] = useState([]);
  const [pricingExpanded, setPricingExpanded] = useState([]);

  const licenseQuestions = [
    {
      header: "I already have an account, how do I upgrade?",
      content: "You can upgrade your account by visiting your account settings and selecting the 'Upgrade' option. Choose the plan that best fits your needs and follow the prompts to complete the upgrade process."
    },
    {
      header: "I have an organization - how do I create one?",
      content: "To create an organization, log in to your account and navigate to the Organizations section. Click 'Create New Organization' and follow the setup wizard to configure your organization settings, add team members, and set permissions."
    },
    {
      header: "Can I add more users to my organization?",
      content: "Yes, you can add more users to your organization at any time. Go to your organization settings, select 'Members', and click 'Invite Users'. Additional users will be billed according to your subscription plan."
    },
    {
      header: "What happens when my subscription is about to expire or has expired?",
      content: "We'll send you email notifications 30, 15, and 5 days before your subscription expires. If your subscription expires, you'll maintain read-only access to your audits for 30 days, after which access will be restricted until renewal."
    },
    {
      header: "What happens if I decide not to renew my subscription?",
      content: "If you choose not to renew, you'll have 30 days of read-only access to your data after expiration. After this period, your data will be archived and can be restored if you decide to reactivate your subscription within 90 days."
    },
    {
      header: "Can I just buy an audit without subscription?",
      content: "Yes, we offer one-time audit purchases for those who don't need a continuous subscription. Contact our sales team for pricing and details on single audit options."
    }
  ];

  const pricingQuestions = [
    {
      header: "What is an audit?",
      content: "An audit is a comprehensive analysis of your codebase using our AI-powered platform. It includes code quality assessment, security vulnerability scanning, dependency analysis, and detailed recommendations for improvement."
    },
    {
      header: "What are Lines of Code?",
      content: "Lines of Code (LoC) refers to the total number of lines in your codebase, excluding comments and blank lines. This metric is used to measure the size of your project and calculate audit pricing."
    },
    {
      header: "What is a LoC Budget / Quota?",
      content: "A LoC Budget/Quota is the total number of code lines you can audit within your subscription period. Different subscription tiers come with different quotas, and you can purchase additional capacity if needed."
    },
    {
      header: "How are audit prices calculated?",
      content: "Audit prices are calculated based on your codebase size (LoC), the complexity of the analysis required, and your subscription tier. Enterprise customers receive custom pricing based on their specific needs."
    },
    {
      header: "Is the listed pricing all inclusive?",
      content: "Yes, our listed pricing includes all features within your chosen tier, including AI analysis, security scanning, and basic support. Enterprise customers may have additional costs for custom features or premium support."
    }
  ];

  const handleShowAll = () => {
    const isAnyExpanded = licenseExpanded.length > 0 || pricingExpanded.length > 0;
    if (isAnyExpanded) {
      // Close all
      setLicenseExpanded([]);
      setPricingExpanded([]);
      setExpandAll(false);
    } else {
      // Open all
      setLicenseExpanded([...Array(licenseQuestions.length).keys()]);
      setPricingExpanded([...Array(pricingQuestions.length).keys()]);
      setExpandAll(true);
    }
  };

  const handleLicenseChange = (indexes) => {
    setLicenseExpanded(indexes);
    setExpandAll(indexes.length === licenseQuestions.length && pricingExpanded.length === pricingQuestions.length);
  };

  const handlePricingChange = (indexes) => {
    setPricingExpanded(indexes);
    setExpandAll(licenseExpanded.length === licenseQuestions.length && indexes.length === pricingQuestions.length);
  };

  return (
    <section className="faq-section">
      <div className="contact-sales-container">
        <h2 className="faq-title">FAQ</h2>
        
        <div className="faq-category">
          <div className="faq-header">
            <h3>License and Subscription</h3>
            <button className="show-all-button" onClick={handleShowAll}>
              {licenseExpanded.length > 0 || pricingExpanded.length > 0 ? 'Close All' : 'Show All'}
            </button>
          </div>
          <Accordion 
            multiple 
            activeIndex={licenseExpanded}
            onTabChange={(e) => handleLicenseChange(e.index)}
          >
            {licenseQuestions.map((q, index) => (
              <AccordionTab key={index} header={q.header}>
                <p>{q.content}</p>
              </AccordionTab>
            ))}
          </Accordion>
        </div>

        <div className="faq-category">
          <div className="faq-header">
            <h3>Payments and Pricing</h3>
            <button className="show-all-button" onClick={handleShowAll}>
              {licenseExpanded.length > 0 || pricingExpanded.length > 0 ? 'Close All' : 'Show All'}
            </button>
          </div>
          <Accordion 
            multiple 
            activeIndex={pricingExpanded}
            onTabChange={(e) => handlePricingChange(e.index)}
          >
            {pricingQuestions.map((q, index) => (
              <AccordionTab key={index} header={q.header}>
                <p>{q.content}</p>
              </AccordionTab>
            ))}
          </Accordion>
        </div>
      </div>
    </section>
  );
};

const ContactSales = () => {
  return (
    <div className="contact-sales-page">
      <Helmet>
        <title>Contact Sales | CodeDD</title>
        <meta name="description" content="Get in touch with our sales team to learn more about CodeDD's AI-powered code auditing platform and find the right plan for your needs." />
      </Helmet>

      <ContactSalesHeader />

      <section className="contact-sales-content">
        <div className="contact-sales-container">
          <div className="content-grid">
            <CustomerStories />
            <ContactForm />
          </div>
        </div>
      </section>

      <FAQSection />
    </div>
  );
};

export default ContactSales;
