import React, { useEffect, useRef } from 'react';
import { Card } from 'primereact/card';
import { Button } from 'primereact/button';
import { Dialog } from 'primereact/dialog';
import { ProgressSpinner } from 'primereact/progressspinner';
import { ProgressBar } from 'primereact/progressbar';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { selectAuth } from '../features/user/userSlice';
import { checkAuditStatus } from '../utils/Actions';
import { TieredMenu } from 'primereact/tieredmenu';
import axiosInstance from '../axiosConfig';
import AuditScoreBar from '../user_dashboard/components/AuditScoreBar';
import '../user_dashboard/components/AuditScoreBar.css';

import './RepositoryCard.css'; 

const RepositoryCard = ({ repositories, userRole, fetchRepositories, groupAuditUuid, auditStarted, auditFinished, isLoading, error, isPaid }) => {
    const [deleteDialogVisible, setDeleteDialogVisible] = useState(false);
    const [deleting, setDeleting] = useState(false);
    const [selectedAuditUuid, setSelectedAuditUuid] = useState('');
    const navigate = useNavigate();
    const userAuth = useSelector(selectAuth);
    const dispatch = useDispatch();
    const menuRefs = useRef({});
    const [auditStatuses, setAuditStatuses] = useState({});

    // Keep the original progress mapping
    const auditStatusToProgress = {
        "File information imported": 0,
        "Audit started": 10,
        "Scores calculated": 50,
        "Contextualization completed": 80,
        "Audit completed": 100,
    };
    const [showProgressBar, setShowProgressBar] = useState({});

    const getProgressMessage = (status) => {
        if (!status) return 'Processing repository...';

        // Map backend statuses to user-friendly messages
        const messageMap = {
            'Opening': 'Preparing repository...',
            'Audit session created': 'Creating audit session...',
            'Repository structure imported': 'Repository structure imported',
            'File information imported': 'Ready to start audit',
            'Audit started': 'Analysis in progress...',
            'Scores calculated': 'Calculating quality scores...',
            'Contextualization completed': 'Finalizing analysis...',
            'Audit completed': 'Audit completed!'
        };
        return messageMap[status] || status || 'Processing repository...';
    };

    const getStatusStyle = (status) => {
        // Get the actual displayed message
        const displayMessage = getProgressMessage(status);
        
        // Define styles for different status states
        if (status === 'Audit completed') {
            return { color: '#2CB392', fontWeight: '600' };
        } else if (displayMessage === 'Ready to start audit') {
            return { color: '#2CB392', fontWeight: '500' };
        } else if (status?.includes('started') || status?.includes('Scores') || status?.includes('Contextualization')) {
            return { color: '#32AFC3', fontWeight: '500' };
        }
        return { color: '#8D8D8D' };
    };

    useEffect(() => {
        const newRefs = {};
        repositories.forEach(repo => {
            if (!menuRefs.current[repo.auditUuid]) {
                newRefs[repo.auditUuid] = React.createRef();
            }
        });
        menuRefs.current = { ...menuRefs.current, ...newRefs };
    }, [repositories]);

    useEffect(() => {
        // Initialize the audit statuses only once when the audit starts
        if (!auditStarted) {
            const initialStatuses = repositories.reduce((acc, repo) => {
                acc[repo.auditUuid] = repo.auditStatus || 'Pending'; // 'Pending' as a fallback status
                return acc;
            }, {});
            setAuditStatuses(initialStatuses);
        }
    }, [repositories, auditStarted]);

    useEffect(() => {
        if (auditStarted) {
            const fetchAllAuditStatuses = async () => {
                const statuses = {...auditStatuses}; // Start with the current statuses
                const progressBarDisplay = {};
                            
                // Fetch status for each repository
                for (const repo of repositories) {
                    try {
                        const response = await axiosInstance.get(`audit_status/`, {
                            params: { audit_uuid: repo.auditUuid },
                        });
                        statuses[repo.auditUuid] = response.data.audit_status;
                        
                        // Show progress bar for any status after "File information imported"
                        const showProgress = response.data.audit_status !== "File information imported";
                        progressBarDisplay[repo.auditUuid] = showProgress;

                    } catch (error) {
                        console.error("Error getting audit status for UUID:", repo.auditUuid, error);
                    }
                }
                
                setAuditStatuses(statuses);
                setShowProgressBar(progressBarDisplay);
            };

            // Call once initially and then set an interval
            fetchAllAuditStatuses();
            let intervalId;
            if (!auditFinished) {
                intervalId = setInterval(fetchAllAuditStatuses, 10000);
            }

            return () => {
                if (intervalId) {
                    clearInterval(intervalId);
                }
            };
        }
    }, [auditStarted, auditFinished, repositories]);

    const getDomainLogo = (url) => {
        const domainLogos = {
            "github.com": '/images/GitHub.png',
            "gitlab.com": '/images/GitLab.png',
            "bitbucket.org": "/images/Bitbucket.png",
            "SourceForge.net": "/images/SourceForge.png",
            "code.google.com": "/images/GoogleCode.png",
            "codeplex.com": "/images/CodePlex.png",
            "launchpad.net": "/images/Launchpad.png",
            "savannah.gnu.org": "/images/Savannah.png",
            "freecode.com": "/images/Freecode.png",
            "gitkraken.com": "/images/GitKraken.png",
            "beanstalkapp.com": "/images/Beanstalk.png",
            "assembla.com": "/images/Assembla.png",
            "phabricator.com": "/images/Phabricator.png",
            "gogs.io": "/images/Gogs.png",
            "gitea.com": "/images/Gitea.png",
            "gitbucket.com": "/images/GitBucket.png",
            "codeberg.org": "/images/Codeberg.png",
            // ... other domains if necessary
        };
        const domain = new URL(url).hostname;
        return domainLogos[domain] || '/images/default-logo.png'; // Path to a default logo
    };

    // Function to handle button click
    const handleButtonClick = async (repo) => {
        const accountUuid = userAuth.accountUuid;
        
        if (!accountUuid) {
            console.error('No account UUID found');
            navigate('/login');
            return;
        }

        try {
            // Check access and wait for the result
            const accessResult = await dispatch(checkAuditStatus(repo.auditUuid, accountUuid));

            if (!accessResult.hasAccess) {
                console.error('No access to this audit');
                navigate('/dashboard');
                return;
            }

            // Determine the target path based on audit status
            const targetStatuses = ["Opening", "Audit session created", "Repository structure imported", "File information imported"];
            const isTargetStatus = targetStatuses.includes(accessResult.auditStatus);
            
            // No need to pass state since we can get audit status and access info from Redux
            // and user info from auth context in the target components
            const path = isTargetStatus 
                ? `/${repo.auditUuid}/audit-scope-selection` 
                : `/${repo.auditUuid}/audit-summary`;
                        
            navigate(path, { replace: true });
        } catch (error) {
            console.error('Error during access check:', error);
            navigate('/error');
        }
    };

    const calculateProgress = (auditUuid) => {
        const status = auditStatuses[auditUuid]; // Fetch status from the centralized state
        if (status === "Audit started" && !showProgressBar[auditUuid]) {
            // Initially set progress to 0% when status changes to "Audit started"
            return 0;
        }
        return auditStatusToProgress[status] || 0;
    };

    const getMenuItems = (repo, auditStarted) => {
        const status = auditStatuses[repo.auditUuid];
        const targetStatuses = ["Opening", "Audit session created", "Repository structure imported", "File information imported"];
        const isTargetStatus = targetStatuses.includes(status);
        const buttonText = isTargetStatus ? "Adapt Scope" : "See Results";
        
        // Base menu items
        const menuItems = [];
        
        // Add See Results/Adapt Scope option
        const viewButtonItem = {
            label: buttonText,
            icon: 'pi pi-file',
            command: () => handleButtonClick(repo),
            disabled: (auditStarted && isTargetStatus) || (isPaid && isTargetStatus) // Disable for paid audits that haven't started
        };
        
        // Only add the button if it should be shown (always show See Results, even for paid)
        if (!isPaid || !isTargetStatus || isRepositoryInProgress(status)) {
            menuItems.push(viewButtonItem);
        }
        
        // Add delete option only if not paid and in target status
        if (isTargetStatus && !isPaid) {
            menuItems.push({
                label: 'Delete',
                icon: 'pi pi-trash',
                className: 'p-error',
                command: () => showDeleteDialog(repo.auditUuid),
                disabled: auditStarted
            });
        }
        
        return menuItems;
    };

    const [auditDetails, setAuditDetails] = useState({});

    const fetchAuditDetails = async (auditUuid) => {
        try {
            const response = await axiosInstance.get(`audit_details/${auditUuid}/`);
            return {
                file_count: response.data.file_count,
                overall_quality_score: response.data.overall_quality_score,
                overall_functionality_score: response.data.overall_functionality_score,
                overall_security_score: response.data.overall_security_score,
                overall_architecture_score: response.data.overall_architecture_score,
                overall_documentation_score: response.data.overall_documentation_score,
                overall_standards_score: response.data.overall_standards_score
            };
        } catch (error) {
            console.error('Error fetching audit details:', error);
            return {
                file_count: 0,
                overall_quality_score: 0,
                overall_functionality_score: 0,
                overall_security_score: 0,
                overall_architecture_score: 0,
                overall_documentation_score: 0,
                overall_standards_score: 0
            };
        }
    };

    useEffect(() => {
        const fetchDetailsForCompletedAudits = async () => {
            const newAuditDetails = { ...auditDetails };
            let detailsChanged = false;

            for (const repo of repositories) {
                if (auditStatuses[repo.auditUuid] === "Audit completed" && !auditDetails[repo.auditUuid]) {
                    const details = await fetchAuditDetails(repo.auditUuid);
                    newAuditDetails[repo.auditUuid] = details;
                    detailsChanged = true;
                }
            }

            if (detailsChanged) {
                setAuditDetails(newAuditDetails);
            }
        };

        fetchDetailsForCompletedAudits();
    }, [repositories, auditStatuses]);

    // Add this at the top of the component, after the state declarations
    const isRepositoryInProgress = (status) => {
        const inProgressStatuses = [
            "Audit started",
            "Scores calculated",
            "Contextualization completed",
            "Audit completed"
        ];
        return inProgressStatuses.includes(status);
    };

    const getButtonState = (repo) => {
        const status = auditStatuses[repo.auditUuid];
        const targetStatuses = ["Opening", "Audit session created", "Repository structure imported", "File information imported"];
        const isTargetStatus = targetStatuses.includes(status);
        
        // If audit is paid, disable or hide based on completed status
        if (isPaid) {
            if (isRepositoryInProgress(status)) {
                return {
                    text: "See Results",
                    disabled: false,
                    hide: false // Show See Results for completed audits even if paid
                };
            }
            return {
                text: "Adapt Scope",
                disabled: true,
                hide: true // Hide button for not-yet-started audits when paid
            };
        }
        
        // If audit has started or is completed, always show "See Results"
        if (isRepositoryInProgress(status)) {
            return {
                text: "See Results",
                disabled: false,
                hide: false
            };
        }
        
        // If audit is started but repository is in initial state, disable "Adapt Scope"
        if (auditStarted && isTargetStatus) {
            return {
                text: "Adapt Scope",
                disabled: true,
                hide: false
            };
        }
        
        return {
            text: isTargetStatus ? "Adapt Scope" : "See Results",
            disabled: auditStarted && isTargetStatus,
            hide: false
        };
    };

    if (isLoading) {
        return <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center' }}><ProgressSpinner style={{marginTop: '3rem', with: '50px', height: '50px'}}/></div>;
    }

    if (error) {
        return <div style={{marginLeft: '50px'}}>Error loading repositories: {error}</div>;
    }

    const renderRepositories = () => {
        const sortedRepositories = repositories.sort((a, b) => new Date(b.aiSynthesis) - new Date(a.aiSynthesis));
    
        return sortedRepositories.map((repo, index) => {
            const buttonState = getButtonState(repo);
            const status = auditStatuses[repo.auditUuid];
            const menuRef = menuRefs.current[repo.auditUuid];
            const details = auditDetails[repo.auditUuid] || {};
            
            const scoreMetrics = [
                { key: 'overall_quality_score', score: details.overall_quality_score },
                { key: 'overall_functionality_score', score: details.overall_functionality_score },
                { key: 'overall_security_score', score: details.overall_security_score },
                { key: 'overall_architecture_score', score: details.overall_architecture_score },
                { key: 'overall_documentation_score', score: details.overall_documentation_score },
                { key: 'overall_standards_score', score: details.overall_standards_score }
            ];

            // Determine if delete button should be shown (not if paid or in progress)
            const showDeleteButton = !isRepositoryInProgress(status) && 
                                    status !== "Audit completed" && 
                                    !isPaid; // Hide delete button if paid
            
            return (
                <div key={index} className={`repo-container ${index !== sortedRepositories.length - 1 ? 'repo-border' : ''}`}>
                    <div style={{ width: '100%', display: 'flex', flexDirection: 'column' }}>
                        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', width: '100%' }}>
                            <div style={{ display: 'flex', alignItems: 'center', flexGrow: 1 }}>
                                <img src={getDomainLogo(repo.url)} alt="Repo Logo" className="repo-logo" />
                                <div className="repo-info">
                                    <h2 className="repo-name">{repo.repoName.replace(/-|_/g, " ")}</h2>
                                    <a href={repo.url} target="_blank" rel="noopener noreferrer" className="repo-url-in-repo-card" style={{ color: '#8D8D8D' }}>{repo.url}</a>
                                    <p style={{color: "#8D8D8D", fontSize: "16px"}}>{`${repo.numberFiles ? repo.numberFiles.toLocaleString('de-DE') : '0'} files with ${repo.linesOfCode ? repo.linesOfCode.toLocaleString('de-DE') : '0'} lines of code`}</p>
                                    <div className="status-message">
                                        <div style={getStatusStyle(status)}>
                                            Status: {getProgressMessage(status)}
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="repo-actions">
                                <div className="desktop-actions">
                                    {!buttonState.hide && (
                                        <Button 
                                            label={buttonState.text}
                                            className="p-button-text" 
                                            onClick={() => handleButtonClick(repo)} 
                                            style={{ minWidth: '120px', height: '40px'}}
                                            disabled={buttonState.disabled}
                                        />
                                    )}
                                    {showDeleteButton && (
                                        <Button 
                                            icon="pi pi-trash" 
                                            style={{
                                                border: "1px solid #E83636",
                                                color: "#E83636",
                                                width: "40px"
                                            }}
                                            className="p-button-outlined p-button-danger" 
                                            onClick={() => showDeleteDialog(repo.auditUuid)} 
                                            disabled={auditStarted}
                                        />
                                    )}
                                </div>
                                <div className="mobile-actions">
                                    <TieredMenu 
                                        model={getMenuItems(repo, auditStarted)}
                                        popup 
                                        ref={menuRef}
                                    />
                                    <Button 
                                        icon="pi pi-bars" 
                                        className="p-button-text mobile-menu-button" 
                                        onClick={(e) => menuRef?.current?.toggle(e)} 
                                        disabled={auditStarted && isRepositoryInProgress(status) || isPaid}
                                    />
                                </div>
                            </div>
                        </div>
                        {/* Add score visualization if audit is completed */}
                        {auditStatuses[repo.auditUuid] === "Audit completed" && auditDetails[repo.auditUuid] && (
                            <div className="audit-scores-grid" style={{ marginTop: '1rem' }}>
                                {scoreMetrics.map(({ key, score }) => (
                                    <AuditScoreBar
                                        key={`${repo.auditUuid}-${key}`}
                                        title={key}
                                        score={score}
                                        auditId={repo.auditUuid}
                                    />
                                ))}
                            </div>
                        )}
                        
                        {isRepositoryInProgress(status) && (
                            <ProgressBar 
                                className={`repo-card-progress-bar ${status === "Audit completed" ? 'complete' : ''}`}
                                style={{height: '10px', marginTop: '1rem'}} 
                                value={calculateProgress(repo.auditUuid)} 
                                displayValueTemplate={(value) => `${value}% Complete`} 
                            />
                        )}
                    </div>
                </div>
            );
        });
    };
    
    const deleteRepo = async () => {
        setDeleting(true);
        try {
            await axiosInstance.post(`delete_repo_from_scope/`, { audit_uuid: selectedAuditUuid });
            // Call fetchRepositories from the parent component to refresh the list
            fetchRepositories();
        } catch (error) {
            console.error("Error deleting repository:", error);
            // Handle error

        } finally {
            setDeleting(false);
            setDeleteDialogVisible(false); // Close the dialog
        }
    };

    const showDeleteDialog = (auditUuid) => {
        setSelectedAuditUuid(auditUuid);
        setDeleteDialogVisible(true);
    };

    const deleteConfirmationDialog = (
        <Dialog className="delete-repo-from-list" header="Confirm Delete" visible={deleteDialogVisible} style={{ minWidth: '350px', width:'30%' }} modal onHide={() => setDeleteDialogVisible(false)} footer={
            <div style={{ display: 'flex', justifyContent: 'space-between', marginBottom: '20px'}}>
                <Button label="Cancel" onClick={() => setDeleteDialogVisible(false)} className="p-button-text" disabled={deleting} style={{ marginLeft: '20px'}} />
                <Button label="Delete" onClick={deleteRepo} className="delete-audit-button" disabled={deleting}/>
            </div>
        }>
            {deleting ? <ProgressSpinner /> : <p style={{paddingLeft: '20px', paddingTop: '10px', paddingBottom: '20px'}}>Are you sure you want to delete this repository from auditing?</p>}
        </Dialog>
    );

    return (
        <Card className="card-container">
            <h2 className="audit-list-title">Audit List</h2>
            <p className="repositories-count"><strong style={{color: "#8D8D8D"}}>{repositories.length}</strong> repositories added for auditing</p>
            {repositories.length === 0 && (
                <>
                    <p className="audit-list-empty">Audit list is empty.</p>
                    <p className="audit-list-action-required">
                        {userRole === 'inviter' ? "The repository owner needs to add repositories for auditing." : "You need to add repositories for auditing."}
                    </p>
                </> 
            )}
            {renderRepositories()}
            {deleteConfirmationDialog}
        </Card>
    );
};

export default RepositoryCard;
