import React, { useEffect, useState, useRef, useCallback } from 'react';
import { Sidebar } from 'primereact/sidebar';
import { Button } from 'primereact/button';
import { Toast } from 'primereact/toast';
import axiosInstance from '../../axiosConfig';
import './AuditInvitationSummaryTab.css';
import CheckoutModal from '../../user_dashboard/checkout/CheckoutModal';

// Helper function to get account UUID from local storage
const getAccountUuidFromLocalStorage = () => {
    try {
        const persistRoot = localStorage.getItem('persist:root');
        if (!persistRoot) return null;
        
        const parsedRoot = JSON.parse(persistRoot);
        if (!parsedRoot?.user) return null;
        
        const user = JSON.parse(parsedRoot.user);
        return user?.accountUuid || null;
    } catch (error) {
        console.error('Error retrieving account UUID from localStorage:', error);
        return null;
    }
};

const AuditInvitationSummaryTab = ({ 
    visible, 
    onHide, 
    userRole, 
    repositoriesData, 
    groupAuditUuid, 
    userName, 
    fetchRepositories, 
    setAuditStarted, 
    auditStarted, 
    setAuditFinished, 
    auditFinished, 
    setIsPaidForParent,
    readyForAudit,
    setReadyForAudit,
    // New props for handling Stripe redirect
    shouldOpenCheckoutModal,
    stripeSessionId,
    stripeSuccess,
    stripeCancelled,
    resetStripeParams
}) => {
    const [isLoading, setIsLoading] = useState(false);
    const [locBudget, setLocBudget] = useState(0);
    const [isAuditInitiating, setIsAuditInitiating] = useState(false);
    const toast = useRef(null);
    const [accountUuid, setAccountUuid] = useState("");
    const intervalIdRef = useRef(null);
    const [isCollapsed, setIsCollapsed] = useState(false);
    const [locBudgetLocked, setLocBudgetLocked] = useState(false);
    
    // Payment related state
    const [isPaid, setIsPaid] = useState(false);
    const [checkoutModalVisible, setCheckoutModalVisible] = useState(false);
    const [orderDetails, setOrderDetails] = useState(null);
    
    // New state to track if we've processed the stripe redirect
    const [stripeRedirectProcessed, setStripeRedirectProcessed] = useState(false);
    
    // Internal state to track Stripe URL parameters
    const [internalStripeParams, setInternalStripeParams] = useState({
        sessionId: null,
        success: false,
        cancelled: false
    });

    // State to store accountUuid from localStorage if props is empty
    const [accountUuidFromStorage, setAccountUuidFromStorage] = useState(null);
    
    // State to track if we're returning from Stripe
    const [isReturnFromStripe, setIsReturnFromStripe] = useState(false);
    
    // State to track the initial active index in CheckoutModal
    const [initialActiveIndex, setInitialActiveIndex] = useState(0);

    // State to track if we've shown the contact sales
    const handleContactSales = () => {
        window.location.href = 'mailto:info@codedd.ai';
    };
    
    // Effect to detect Stripe redirect parameters and set appropriate initial states
    useEffect(() => {
        const effectiveSessionId = stripeSessionId || internalStripeParams.sessionId;
        const effectiveSuccess = stripeSuccess || internalStripeParams.success;
        
        if (effectiveSessionId) {
            setIsReturnFromStripe(true);
            setInitialActiveIndex(1); // Set to payment tab (index 1)
            
            // If success parameter is true, automatically set isPaid for immediate UI update
            if (effectiveSuccess) {
                console.log('Setting immediate isPaid=true for faster UI update when returning from Stripe');
                setIsPaid(true);
                
                // Also propagate to parent if callback exists
                if (setIsPaidForParent) {
                    setIsPaidForParent(true);
                }
            }
        }
    }, [stripeSessionId, internalStripeParams.sessionId, stripeSuccess, internalStripeParams.success, setIsPaidForParent]);

    // Effect to check URL parameters on mount
    useEffect(() => {
        // Check if we have URL parameters for Stripe redirect
        const urlParams = new URLSearchParams(window.location.search);
        const sessionId = urlParams.get('session_id');
        const success = urlParams.get('success') === 'true';
        const cancelled = urlParams.get('cancelled') === 'true';
        
        // If we have any Stripe parameters, store them
        if (sessionId || success || cancelled) {
            console.log('Found Stripe redirect parameters in URL:', { sessionId, success, cancelled });
            
            setInternalStripeParams({
                sessionId,
                success,
                cancelled
            });
            
            // Clean URL to prevent re-processing on refresh
            if (window.history.replaceState) {
                const cleanUrl = window.location.pathname;
                window.history.replaceState({}, document.title, cleanUrl);
            }
        }
    }, []);
    
    useEffect(() => {
        // Use the helper function to get accountUuid
        const fetchedAccountUuid = getAccountUuidFromLocalStorage() || "";
    
        const fetchLocBudget = async (accountUuid) => {
            if (!accountUuid) {
                console.error('Account UUID not found in local storage');
                return;
            }
        
            try {
                const response = await axiosInstance.get(`api/get_loc_buget/?account_uuid=${accountUuid}`);
                setLocBudget(response.data.loc_budget);
            } catch (error) {
                console.error('Error fetching LOC budget:', error);
            }
        };
    
        if (fetchedAccountUuid) {
            setAccountUuid(fetchedAccountUuid);
            fetchLocBudget(fetchedAccountUuid);
        } else {
            console.warn('No account UUID found in localStorage, some features may not work correctly');
        }
    
        // Only start polling if we're not in the middle of importing a repository
        if (!isLoading) {
            startAuditReadyStatusPolling();
        }
        
        return () => {
            if (intervalIdRef.current) {
                clearInterval(intervalIdRef.current);
                intervalIdRef.current = null;
            }
        };
    }, [isLoading]); // Add isLoading as a dependency

    // Handle Stripe redirects with a separate useEffect for clarity and determinism
    useEffect(() => {
        // Extract Stripe parameters from URL or props
        const queryParams = new URLSearchParams(window.location.search);
        const sessionIdFromUrl = queryParams.get('session_id');
        const successFromUrl = queryParams.get('success') === 'true';
        const cancelledFromUrl = queryParams.get('cancelled') === 'true';
        
        // Consolidated parameters for cleaner logic
        const effectiveSessionId = sessionIdFromUrl || stripeSessionId || internalStripeParams.sessionId;
        const isSuccess = successFromUrl || stripeSuccess || internalStripeParams.success;
        const isCancelled = cancelledFromUrl || stripeCancelled || internalStripeParams.cancelled;
        
        // Only process if we have Stripe-related parameters and haven't processed this redirect yet
        if ((effectiveSessionId || isSuccess || isCancelled) && !stripeRedirectProcessed) {
            console.log('Detected return from Stripe:', {
                sessionId: effectiveSessionId,
                success: isSuccess,
                cancelled: isCancelled,
                alreadyProcessed: stripeRedirectProcessed
            });
            
            if (isPaid) {
                console.log('Payment already recorded in the system, no need to open checkout modal');
                // Mark as processed to avoid further processing
                setStripeRedirectProcessed(true);
                
                // Reset Stripe parameters
                if (resetStripeParams) {
                    resetStripeParams();
                }
                
                // Clear internal parameters
                setInternalStripeParams({
                    sessionId: null,
                    success: false,
                    cancelled: false
                });
                
                return;
            }
            
            // Prepare minimal order details
            const minimalOrderDetails = {
                linesOfCode: repositoriesData.total_lines_of_code || 0,
                payableLines: Math.max(0, (repositoriesData.total_lines_of_code || 0) - (locBudget || 0)),
                audit_uuid: groupAuditUuid,
                account_uuid: accountUuid,
                isGroupAudit: true
            };
            
            // Set order details and open checkout modal
            setOrderDetails(minimalOrderDetails);
            
            // Ensure we set the return from Stripe flag to true
            setIsReturnFromStripe(true);
            
            // Set initial active index to payment tab (1)
            setInitialActiveIndex(1);
            
            // Log debug info
            console.log('Opening checkout modal after Stripe redirect:', {
                isReturnFromStripe: true,
                initialActiveIndex: 1,
                effectiveSessionId,
                orderDetails: minimalOrderDetails
            });
            
            // Show the checkout modal with the processed payment
            setCheckoutModalVisible(true);
        }
    }, [
        stripeSessionId, 
        stripeSuccess, 
        stripeCancelled, 
        internalStripeParams, 
        stripeRedirectProcessed, 
        resetStripeParams, 
        groupAuditUuid, 
        accountUuid, 
        isPaid,
        repositoriesData.total_lines_of_code,
        locBudget
    ]);

    useEffect(() => {
        const handleResize = () => {
            setIsCollapsed(window.innerWidth < 1075);
        };

        // Set initial state
        handleResize();

        // Add event listener
        window.addEventListener('resize', handleResize);

        // Cleanup
        return () => window.removeEventListener('resize', handleResize);
    }, []);
    
    const startAuditReadyStatusPolling = () => {
        // This can just refresh payment status now - audit ready comes from props
        const checkPaymentStatus = async () => {
            try {
                const response = await axiosInstance.get(`get_audit_ready_status/?groupAuditUuid=${encodeURIComponent(groupAuditUuid)}`);
                
                // Check payment status from the response
                if (response.data.is_paid) {
                    setIsPaid(response.data.is_paid);
                    
                    // Also propagate to parent if callback exists
                    if (typeof setIsPaidForParent === 'function') {
                        setIsPaidForParent(true);
                    }
                }
            } catch (error) {
                console.error('Error checking payment status:', error);
            }
        };
        
        // Initial check
        checkPaymentStatus();
        
        // Clear any existing interval
        if (intervalIdRef.current) {
            clearInterval(intervalIdRef.current);
        }
        
        // Start a new interval
        const intervalId = setInterval(checkPaymentStatus, 30000); // Poll every 30 seconds
        intervalIdRef.current = intervalId; // Store interval ID
        
        return intervalId;
    };
    
    const handleSetReadyForAudit = async () => {
        setIsLoading(true);
        try {
            await axiosInstance.post(`audit_invitation_ready/`, { groupAuditUuid: groupAuditUuid }
            );

            toast.current.show({ severity: 'success', summary: 'Audit marked as ready', detail: 'Emails have been sent', life: 3000 });
            setReadyForAudit(true);
            
            // Also update parent component state if the callback is provided
            if (typeof setReadyForAudit === 'function') {
                setReadyForAudit(true);
            }
        } catch (error) {
            console.error('Error in setting audit ready:', error);
            toast.current.show({ severity: 'error', summary: 'Error', detail: 'Failed to set audit ready', life: 3000 });
        } finally {
            setIsLoading(false);
        }
    };

    const onStartAuditClick = async () => {
        if (!accountUuid) {
            console.error('Account UUID not found in local storage');
            return;
        }

        setIsLoading(true);
        setIsAuditInitiating(true);
        
        try {
            
            // First check if audit can be started with proper params
            const canStartAuditResponse = await axiosInstance.get(`api/can_start_audit/`, {
                params: {
                    accountUuid: accountUuid,
                    groupAuditUuid: groupAuditUuid
                }
            });
            
            // Handle the response
            if (canStartAuditResponse.status !== 200) {
                throw new Error(canStartAuditResponse.data.error || 'Failed to validate audit start conditions');
            }
                        
            // Update isPaid based on the response
            if (canStartAuditResponse.data.is_paid) {
                setIsPaid(true);
            }
            
            // Send group audit confirmation email
            await axiosInstance.post(`send_group_audit_confirmation_email/`, {
                groupAuditUuid: groupAuditUuid
            });
            
            // Trigger the main audit process and wait for it to complete
            await axiosInstance.post(`api/trigger_main_process/`, {
                groupAuditUuid: groupAuditUuid,
                accountUuid: accountUuid
            });

            console.log('Main process triggered, updating states...');
            
            // Set states after successful trigger
            setAuditStarted(true);
            setLocBudgetLocked(true);

            // Immediately execute functions after triggering the main process
            await fetchRepositories();
            await startAuditReadyStatusPolling();       

        } catch (error) {
            console.error('Error in audit process or sending email:', error);
            setIsAuditInitiating(false);
            setAuditStarted(false);
            
            toast.current?.show({
                severity: 'error',
                summary: 'Error',
                detail: error.response?.data?.error || error.message || 'Failed to start the audit',
                life: 3000
            });
        } finally {
            setIsLoading(false);
            setIsAuditInitiating(false);
        }

        // Only update LOC budget if it's not a paid audit AND we have lines to use from budget
        if (!isPaid && locBudget > 0) {
            try {
                // Calculate how many lines can be used from the budget
                const linesUsedFromBudget = Math.min(locBudget, repositoriesData.total_lines_of_code);
                
                if (linesUsedFromBudget > 0) {
                    console.log(`Updating LOC budget, using ${linesUsedFromBudget} lines from budget`);
                    
                    await axiosInstance.post(`api/update_loc_budget/`, {
                        accountUuid: accountUuid,
                        linesOfCodeUsed: linesUsedFromBudget
                    });
                    
                    console.log('LoC budget updated successfully');
                }
            } catch (error) {
                console.error('Error updating locBudget:', error);
            }
        }
    };

    const toggleSidebar = () => {
        setIsCollapsed(!isCollapsed);
    };
    
    // Function to show checkout modal
    const showCheckoutModal = () => {
        // Ensure we have account_uuid
        if (!accountUuid) {
            console.error('Account UUID not found when showing checkout modal');
            toast.current?.show({
                severity: 'error',
                summary: 'Error',
                detail: 'Unable to proceed with checkout. Please try again or contact support.',
                life: 3000
            });
            return;
        }
        
        // Log checkout attempt
        console.log('Showing checkout modal with details:', {
            accountUuid,
            groupAuditUuid,
            locBudget,
            totalLinesOfCode: repositoriesData.total_lines_of_code || 0
        });
        
        // Prepare order details for the checkout
        const payableLines = Math.max(0, (repositoriesData.total_lines_of_code || 0) - (locBudget || 0));
        
        setOrderDetails({
            linesOfCode: repositoriesData.total_lines_of_code || 0,
            payableLines: payableLines,
            freeLines: Math.min(locBudget || 0, repositoriesData.total_lines_of_code || 0),
            audit_uuid: groupAuditUuid,
            account_uuid: accountUuid,
            is_group_audit: true // Flag that this is a group audit payment
        });
        
        setCheckoutModalVisible(true);
    };

    // Function to handle successful payment
    const handlePaymentSuccess = async (paymentDetails) => {
        try {
            setIsLoading(true);
            console.log('Payment successful in AuditInvitationSummaryTab:', paymentDetails);
            
            // Set paid state for immediate UI feedback
            setIsPaid(true);
            
            // Propagate isPaid state to parent component
            if (setIsPaidForParent) {
                setIsPaidForParent(true);
            }
            
            // Try up to 3 times to confirm payment in the backend
            let paymentConfirmed = false;
            let retryCount = 0;
            const maxRetries = 3;
            const retryDelay = 2000; // 2 seconds between retries
            
            while (!paymentConfirmed && retryCount < maxRetries) {
                try {
                    // Wait before checking (except first time)
                    if (retryCount > 0) {
                        await new Promise(resolve => setTimeout(resolve, retryDelay));
                    }
                    
                    // Check if payment is recorded in backend for the group audit using the audit status endpoint
                    const refreshResponse = await axiosInstance.get(`get_audit_ready_status/?groupAuditUuid=${encodeURIComponent(groupAuditUuid)}`);
                    
                    if (refreshResponse.data.is_paid) {
                        paymentConfirmed = true;
                        break;
                    } else {
                        // Check payment status directly
                        const verifyResponse = await axiosInstance.get(`api/verify_payment_status/${paymentDetails.transactionId}/?account_uuid=${accountUuid}&audit_uuid=${groupAuditUuid}`).catch(e => {
                            console.warn('Error checking payment status:', e);
                            return { data: { is_paid: false, audit_already_paid: false } };
                        });
                                                
                        // If either Stripe or TypeDB confirms payment is successful, proceed
                        if (verifyResponse.data.is_paid || 
                            verifyResponse.data.audit_already_paid || 
                            verifyResponse.data.status === 'succeeded') {
                            paymentConfirmed = true;
                            break;
                        }
                    retryCount++;
                    }
                } catch (refreshError) {
                    console.error('Error refreshing payment status:', refreshError);
                    retryCount++;
                }
            }
            
            // If still not confirmed after retries, try manual recording as last resort
            if (!paymentConfirmed) {
                try {
                    
                    // Ensure we have required fields
                    if (!accountUuid || !groupAuditUuid) {
                        console.error('Missing required fields for manual payment recording', {
                            accountUuid,
                            groupAuditUuid
                        });
                        return;
                    }
                    
                    // Extract account_uuid from session metadata if available
                    const sessionAccountUuid = paymentDetails.sessionMetadata?.account_uuid || accountUuid;
                    const sessionAuditUuid = paymentDetails.sessionMetadata?.audit_uuid || groupAuditUuid;
                    
                    // Prepare payment data with only essential fields
                    const paymentData = {
                        payment_intent_id: paymentDetails.transactionId,
                        account_uuid: sessionAccountUuid,
                        audit_uuid: sessionAuditUuid,
                        group_audit_uuid: groupAuditUuid,
                        is_group_audit: true,
                        send_receipt: true,
                        amount: paymentDetails.amount || 250,
                        currency: paymentDetails.currency || 'eur'
                    };
                    
                    // Only add these optional fields if available
                    if (paymentDetails.billing_details?.email) {
                        paymentData.customer_email = paymentDetails.billing_details.email;
                    }
                    
                    if (paymentDetails.billing_details?.address?.country) {
                        paymentData.country_code = paymentDetails.billing_details.address.country;
                    }
                    
                    
                    const manualResponse = await axiosInstance.post('/api/manual_payment_success/', paymentData);
                                        
                    if (manualResponse.data.success) {
                        paymentConfirmed = true;
                    } else if (manualResponse.data.already_existed) {
                        paymentConfirmed = true;
                    } else {
                        console.warn('Manual payment recording failed:', manualResponse.data.error);
                    }
                } catch (manualError) {
                    console.error('Error during manual payment recording:', manualError);
                }
            }
            
            // Show appropriate message based on confirmation status
            if (paymentConfirmed) {
                toast.current?.show({
                    severity: 'success',
                    summary: 'Payment Confirmed',
                    detail: `Transaction ID: ${paymentDetails.transactionId}`,
                    life: 3000
                });
            } else {
                toast.current?.show({
                    severity: 'info',
                    summary: 'Payment Processing',
                    detail: 'Your payment was successful but is still being processed. You can proceed with the audit.',
                    life: 5000
                });
            }
            
        } catch (error) {
            console.error('Error processing payment:', error);
            toast.current?.show({
                severity: 'error',
                summary: 'Payment Error',
                detail: error.message || 'An error occurred during payment processing',
                life: 3000
            });
        } finally {
            setIsLoading(false);
        }
    };

    // Check if the audit is paid
    useEffect(() => {
        const checkPaymentStatus = async () => {
            if (!groupAuditUuid) return;
            
            try {
                const response = await axiosInstance.get(`get_audit_ready_status/?groupAuditUuid=${encodeURIComponent(groupAuditUuid)}`);
                if (response.data && response.data.is_paid === true) {
                    setIsPaid(true);
                    
                    // Also propagate to parent component
                    if (setIsPaidForParent) {
                        setIsPaidForParent(true);
                    }
                }
            } catch (error) {
                console.error('Error checking payment status:', error);
            }
        };
        
        checkPaymentStatus();
    }, [groupAuditUuid, setIsPaidForParent]);

    // Add a cleanup effect to hide the checkout modal when unmounting
    useEffect(() => {
        return () => {
            // Reset checkout modal on unmount
            setCheckoutModalVisible(false);
        };
    }, []);

    // Function to handle manual recording in case the parent component set isPaid to true
    // but the payment wasn't properly recorded in our backend
    const recordManualPayment = useCallback(async (paymentIntentId) => {
        if (!paymentIntentId || !groupAuditUuid || !accountUuid) {
            console.log('Missing required data for manual payment recording');
            return;
        }
        
        try {           
            const paymentData = {
                payment_intent_id: paymentIntentId,
                account_uuid: accountUuid,
                audit_uuid: groupAuditUuid,
                group_audit_uuid: groupAuditUuid,
                is_group_audit: true,
                send_receipt: true,
                amount: 250, // Default amount
                currency: 'eur' // Default currency
            };
            
            const manualResponse = await axiosInstance.post('/api/manual_payment_success/', paymentData);
            
            if (manualResponse.data.success) {
                console.log('Backup manual payment recording successful');
            } else if (manualResponse.data.already_existed) {
                console.log('Payment record already exists');
            } else {
                console.warn('Backup manual payment recording failed:', manualResponse.data.error);
            }
        } catch (error) {
            console.error('Error in backup manual payment recording:', error);
        }
    }, [groupAuditUuid, accountUuid]);
    
    // Check if we have a Stripe session ID but parent component already set isPaid
    // This helps in case the payment was verified by the parent but not recorded in our backend
    useEffect(() => {
        const effectiveSessionId = stripeSessionId || internalStripeParams.sessionId;
        
        // Only run if we have a session ID, isPaid is true, and we haven't processed this redirect yet
        if (effectiveSessionId && isPaid && !stripeRedirectProcessed) {
            
            // First fetch the checkout session data
            axiosInstance.get(`/api/checkout-session/${effectiveSessionId}/`)
                .then(sessionResponse => {
                    const paymentIntentId = sessionResponse.data?.payment_intent;
                    
                    if (paymentIntentId) {
                        // Now check if this payment is already recorded in our system
                        return axiosInstance.get(`api/verify_payment_status/${paymentIntentId}/?account_uuid=${accountUuid}&audit_uuid=${groupAuditUuid}`)
                            .then(verifyResponse => {
                                // If not properly recorded in our system, trigger manual recording
                                if (!verifyResponse.data.is_paid && !verifyResponse.data.audit_already_paid && 
                                    (verifyResponse.data.stripe_status === 'succeeded' || 
                                     sessionResponse.data.payment_status === 'paid')) {
                                    
                                    // Perform manual recording
                                    recordManualPayment(paymentIntentId);
                                }
                                
                                // Mark as processed regardless
                                setStripeRedirectProcessed(true);
                                
                                // Clear params
                                if (resetStripeParams) {
                                    resetStripeParams();
                                }
                                
                                setInternalStripeParams({
                                    sessionId: null,
                                    success: false,
                                    cancelled: false
                                });
                            });
                    }
                })
                .catch(error => {
                    console.error('Error while checking if manual recording is needed:', error);
                    
                    // Mark as processed to avoid infinite retries
                    setStripeRedirectProcessed(true);
                });
        }
    }, [
        isPaid, 
        stripeSessionId, 
        internalStripeParams, 
        stripeRedirectProcessed, 
        accountUuid, 
        groupAuditUuid, 
        resetStripeParams, 
        recordManualPayment
    ]);

    return (
        <div className={`sidebar-container ${isCollapsed ? 'collapsed' : ''}`}>
            <Sidebar visible={visible} modal={false} position="right" className={`responsive-sidebar ${isCollapsed ? 'collapsed' : ''}`} onHide={() => {}}>
            <Button 
                icon={`pi ${isCollapsed ? 'pi-angle-left' : 'pi-angle-right'}`}
                className="p-button-rounded p-button-text toggle-button"
                onClick={toggleSidebar}
            />
                {!isCollapsed && (
                    <>
                        <Toast ref={toast} />
                        <div className="audit-summary">
                            <h2>Audit scope</h2>
                            <h4>The following content will be audited:</h4>
                            <div className="repositories-to-audit">
                                <span><strong>{(repositoriesData.number_of_repos || 0).toLocaleString('de-DE')}</strong></span>
                                <label> repositories</label>
                            </div>
                            <div className="files-to-audit">
                                <span><strong>{(repositoriesData.total_number_files || 0).toLocaleString('de-DE')}</strong></span>
                                <label> files</label>
                            </div>
                            <div className="lines-of-code">
                                <span><strong>{(repositoriesData.total_lines_of_code || 0).toLocaleString('de-DE')}</strong></span>
                                <label> total lines of code</label>
                            </div>

                            {userRole === 'inviter' && repositoriesData.number_of_repos === 0 && !readyForAudit && (
                                <div className="confirmation-message">
                                    <h4>It is the turn of the repository owner to add repositories.</h4>
                                </div>
                            )}

                            {userRole === 'invitee' && repositoriesData.number_of_repos > 0 && (
                                <div className="confirmation-message">
                                    <h4>Mark the audit list as ready to notify {userName} to start the audit.</h4>
                                </div>
                            )}

                            {userRole === 'invitee' && repositoriesData.number_of_repos > 0 && (
                                <div>
                                <Button 
                                    label={<span style={{ color: 'white', fontSize: '1rem' }}>Set group audit ready</span>}
                                    onClick={handleSetReadyForAudit}
                                    icon={isLoading ? 'pi pi-spin pi-spinner' : 'pi pi-check'}
                                    className={`p-button-sm set-group-audit-ready ${readyForAudit || isLoading || auditStarted || auditFinished ? 'disabled' : ''}`}
                                    disabled={readyForAudit || isLoading || auditStarted || auditFinished}
                                />
                                </div>
                            )}
                            {userRole === 'invitee' && readyForAudit && (
                                <div className="confirmation-message">
                                    <h4>🚀 Thank you for setting the audit scope 🎉</h4>
                                    <h4>You will receive an email as soon as the audit started.</h4>
                                </div>
                            )}
                            {userRole === 'inviter' && readyForAudit && (
                                <div className="confirmation-message">
                                    <h4>🚀 Your audit is set and ready 🎉</h4>
                                    <h4>You can start the audit process now or adapt the scope, if needed.</h4>
                                </div>
                            )}
                        </div>

                        {/* Checkout section - Show when ready for audit OR paid */}
                        {userRole === 'inviter' && repositoriesData.total_lines_of_code > 0 && (readyForAudit || isPaid) && (
                            <div className="audit-order">
                                <h2>Checkout</h2>
                                
                                <div className="audit-info-row">
                                    <span>Free LoC:</span>
                                    <span className="audit-value"><b>{(locBudget || 0).toLocaleString('de-DE', {maximumFractionDigits: 0})}</b></span>
                                </div>
                                
                                <div className="audit-info-row-small">
                                    <span style={{fontStyle: "italic", fontSize: "14px" }}>After this audit:</span>
                                    <span className="audit-value" style={{fontStyle: "italic", fontSize: "12px" }}>
                                        <i style={{fontStyle: "italic", fontSize: "14px" }}>
                                            {Math.max(0, (locBudget || 0) - (repositoriesData.total_lines_of_code || 0))
                                                .toLocaleString('de-DE', {maximumFractionDigits: 0})}
                                        </i>
                                    </span>
                                </div>
                                
                                <div className="audit-info-row">
                                    <span>From selection:</span>
                                    <span className="audit-value">
                                        <b>{(repositoriesData.total_lines_of_code || 0).toLocaleString('de-DE', {maximumFractionDigits: 0})}</b>
                                    </span>
                                </div>
                                
                                <hr className="audit-divider"/>
                                
                                <div className="audit-info-row">
                                    <span>Payable LoC:</span>
                                    <span className="audit-value">
                                        <b>{Math.max(0, (repositoriesData.total_lines_of_code || 0) - (locBudget || 0))
                                            .toLocaleString('de-DE', {maximumFractionDigits: 0})}</b>
                                    </span>
                                </div>
                                
                                {/* Display "Paid" message when the audit is already paid for */}
                                {isPaid && (
                                    <div className="payment-status-paid" style={{ color: '#2CB392', margin: '10px 0', fontWeight: 'bold', textAlign: 'center' }}>
                                        <i className="pi pi-check-circle" style={{ marginRight: '8px' }}></i>
                                        <span>Payment Completed</span>
                                    </div>
                                )}
                                
                                {/* New Checkout Button - Only show if not paid and payment is needed, removed readyForAudit requirement */}
                                { readyForAudit && !auditFinished && !auditStarted && !isPaid && (locBudget || 0) < (repositoriesData.total_lines_of_code || 0) && (
                                    <Button 
                                        label="Proceed to Checkout"
                                        icon="pi pi-credit-card"
                                        className="checkout-button"
                                        onClick={showCheckoutModal}
                                        disabled={isLoading || isAuditInitiating}
                                    />
                                )}
                                
                                {/* Start Audit Button */}
                                {!auditFinished && !auditStarted && (
                                    // Only show Start Audit button if user has sufficient LOC budget or the audit is paid for
                                    ((locBudget || 0) >= (repositoriesData.total_lines_of_code || 0) || isPaid) && readyForAudit && (
                                        <Button 
                                            label="Start Audit"
                                            className={`start-audit-button ${isLoading ? 'p-button-text p-button-plain' : ''}`}
                                            onClick={onStartAuditClick}
                                            icon={isLoading ? 'pi pi-spin pi-spinner' : 'pi pi-bolt'}
                                            disabled={isLoading || auditFinished || auditStarted || isAuditInitiating}
                                            style={{
                                                backgroundColor: '#32AFC3',
                                                color: 'white',
                                                minWidth: '95%',
                                                marginTop: '1rem'
                                            }}
                                        />
                                    )
                                )}                                
                                {(locBudget || 0) < (repositoriesData.total_lines_of_code || 0) && !auditFinished && !auditStarted && !isPaid && (
                                    <>
                                        <div className="audit-warning" style={{fontSize: '0.875rem', marginBottom: '2rem'}}>Insufficient free LOCs</div>
                                        <Button 
                                            label="Talk to sales"
                                            icon="pi pi-arrow-right"
                                            className="talk-to-sales-button"
                                            onClick={handleContactSales}
                                            style={{
                                                backgroundColor: '#323232',
                                                color: 'white',
                                                border: '2px solid #323232',
                                                minWidth: '95%',
                                                marginTop: '1rem',
                                                fontWeight: '600'
                                            }}
                                        />
                                    </>
                                )}
                            </div>
                        )}
                        
                        {/* Checkout Modal with required props */}
                        <CheckoutModal 
                            visible={checkoutModalVisible}
                            onHide={() => setCheckoutModalVisible(false)}
                            account_uuid={accountUuid}
                            audit_uuid={groupAuditUuid}
                            orderDetails={orderDetails}
                            successHandler={handlePaymentSuccess}
                            isGroupAudit={true}
                            groupAuditUuid={groupAuditUuid}
                            initialSessionId={stripeSessionId || internalStripeParams.sessionId}
                            initialPaymentSuccess={stripeSuccess || internalStripeParams.success}
                            initialPaymentCancelled={stripeCancelled || internalStripeParams.cancelled}
                            isReturnFromStripe={isReturnFromStripe}
                            activeIndex={initialActiveIndex}
                        />
                    </>
                )}
            </Sidebar>
        </div>
    );
};

export default AuditInvitationSummaryTab;