import React, { useState, useEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import { Card } from 'primereact/card';
import { InputNumber } from 'primereact/inputnumber';
import { Button } from 'primereact/button';
import { Slider } from 'primereact/slider';
import * as d3 from 'd3';
import './Pricing.css';

// Pricing tiers configuration - copied from pricingUtils but modified for component use
const PRICING_TIERS = [
  { name: 'Base', maxLines: 50000, price: 250, pricePerLine: 0, color: '#32AFC3' },
  { name: 'Start-up', maxLines: 1000000, pricePerLine: 0.0035, color: '#7B96E6' },
  { name: 'Growth', maxLines: 3000000, pricePerLine: 0.00275, color: '#9F5FE9' },
  { name: 'Enterprise', maxLines: 10000000, pricePerLine: 0.00225, color: '#F8A1D1' }
];

// Helper functions from pricingUtils
const formatNumber = (num) => {
  return num.toLocaleString('de-DE');
};

const formatLinesForDisplay = (lines) => {
  if (window.innerWidth <= 650) {
    if (lines >= 1000000) {
      return `< ${(lines / 1000000).toFixed(0)}M lines`;
    } else if (lines >= 1000) {
      return `< ${(lines / 1000).toFixed(0)}k lines`;
    }
  }
  return `Up to ${formatNumber(lines)} lines`;
};

const formatPrice = (price, decimals = 2) => {
  // Format with European number formatting (dots as thousand separators, comma as decimal separator)
  return price.toLocaleString('de-DE', {
    minimumFractionDigits: decimals,
    maximumFractionDigits: decimals
  });
};

const TierRow = ({ tier, icon }) => (
  <div className="tier-row">
    <div className="tier-name">
      <span className="tier-dot" style={{ backgroundColor: tier.color }}></span>
      <span>{tier.name}</span>
    </div>
    <div className="tier-range" dangerouslySetInnerHTML={{ __html: formatLinesForDisplay(tier.maxLines) }}></div>
    <div className="tier-price">
      {tier.name === 'Base' 
        ? `€${formatPrice(tier.price)} flat` 
        : `€${tier.pricePerLine.toLocaleString('de-DE', { minimumFractionDigits: 5, maximumFractionDigits: 5 })}/line`}
    </div>
  </div>
);

const EnterpriseCard = () => {
  const navigate = useNavigate();

  const handleButtonClick = () => {
    navigate('/contact-sales');
  };

  return (
    <Card className="enterprise-card">
      <div className="enterprise-card-content">
        <h2>Custom pricing</h2>
        <p>For enterprises looking to scale technical due diligence with custom solutions.</p>
        
        <ul className="enterprise-features">
          <li>
            <i className="pi pi-check-circle" style={{ color: '#4CAF50', marginRight: '8px' }}></i>
            Custom pricing model
          </li>
          <li>
            <i className="pi pi-check-circle" style={{ color: '#4CAF50', marginRight: '8px' }}></i>
            Advanced security compliance
          </li>
          <li>
            <i className="pi pi-check-circle" style={{ color: '#4CAF50', marginRight: '8px' }}></i>
            Priority support
          </li>
          <li>
            <i className="pi pi-check-circle" style={{ color: '#4CAF50', marginRight: '8px' }}></i>
            Dedicated account manager
          </li>
          <li>
            <i className="pi pi-check-circle" style={{ color: '#4CAF50', marginRight: '8px' }}></i>
            Organization-wide security reporting
          </li>
        </ul>
        
        <Button 
          label="Contact Sales" 
          className="talk-to-sales-button"
          onClick={handleButtonClick}
          style={{
            backgroundColor: '#323232',
            color: 'white',
            border: '2px solid #323232',
            minWidth: '95%',
            marginTop: '1rem',
            fontWeight: '600'
          }}
        />
      </div>
    </Card>
  );
};

const PerAuditCard = () => {
  return (
    <Card className="per-audit-card">
      <div className="per-audit-card-content">
        <div className="per-audit-header">
          <h2>Per Audit</h2>
          <div className="per-audit-badge">Pay as you go</div>
        </div>
        <p>
          Perfect for starting your technical due diligence journey. Pay per audit based on 
          lines of code analyzed, giving you complete cost control.
        </p>
        
        <div className="tier-list">
          <div className="tier-header">
            <div>Tier</div>
            <div>Volume</div>
            <div>Price</div>
          </div>
          
          {PRICING_TIERS.map((tier, index) => (
            <TierRow key={index} tier={tier} />
          ))}
        </div>
        
        <div className="per-audit-features">
          <div className="feature-item">
            <i className="pi pi-check-circle" style={{ color: '#4CAF50', marginRight: '8px' }}></i>
            <span>Pay only for what you use</span>
          </div>
          <div className="feature-item">
            <i className="pi pi-check-circle" style={{ color: '#4CAF50', marginRight: '8px' }}></i>
            <span>Code analysis up to 10M lines</span>
          </div>
          <div className="feature-item">
            <i className="pi pi-check-circle" style={{ color: '#4CAF50', marginRight: '8px' }}></i>
            <span>Full audit report</span>
          </div>
          <div className="feature-item">
            <i className="pi pi-check-circle" style={{ color: '#4CAF50', marginRight: '8px' }}></i>
            <span>Visibility and access management</span>
          </div>
        </div>
      </div>
    </Card>
  );
};

const PricingCalculator = () => {
  const [linesOfCode, setLinesOfCode] = useState(100000);
  const [sliderValue, setSliderValue] = useState(100000);
  const [calculatedPrice, setCalculatedPrice] = useState(null);
  const chartRef = useRef(null);
  const timeoutRef = useRef(null);
  
  // Calculate the price based on lines of code
  const calculatePrice = (lines) => {
    // Ensure lines is a valid number
    const payableLines = Math.max(0, parseInt(lines) || 0);
    
    let basePrice = 0;
    let additionalPrice = 0;
    let breakdown = [];
    let selectedTier = 'Base';
    
    // Skip calculation if no lines
    if (payableLines === 0) {
      return {
        basePrice: 0,
        tierPrice: 0,
        total: 0,
        currency: 'EUR',
        selectedTier: 'Base',
        breakdown: []
      };
    }
    
    // Determine which tier applies
    let selectedTierIndex = 0;
    for (let i = 0; i < PRICING_TIERS.length; i++) {
      const tierInfo = PRICING_TIERS[i];
      if (payableLines <= tierInfo.maxLines) {
        selectedTierIndex = i;
        selectedTier = tierInfo.name;
        break;
      }
      if (i === PRICING_TIERS.length - 1) {
        selectedTierIndex = i;
        selectedTier = tierInfo.name;
      }
    }
    
    // Base price is always charged
    basePrice = PRICING_TIERS[0].price;
    breakdown.push({
      tier: PRICING_TIERS[0].name,
      description: `Base fee (First ${formatNumber(PRICING_TIERS[0].maxLines)} lines)`,
      lines: Math.min(payableLines, PRICING_TIERS[0].maxLines),
      rate: PRICING_TIERS[0].pricePerLine,
      amount: basePrice,
      color: PRICING_TIERS[0].color
    });
    
    // Calculate additional costs for higher tiers if applicable
    if (payableLines > PRICING_TIERS[0].maxLines) {
      for (let i = 1; i <= selectedTierIndex; i++) {
        const previousTierMax = PRICING_TIERS[i-1].maxLines;
        const currentTierMax = PRICING_TIERS[i].maxLines;
        const linesInThisTier = Math.min(payableLines, currentTierMax) - previousTierMax;
        
        if (linesInThisTier > 0) {
          const tierCost = linesInThisTier * PRICING_TIERS[i].pricePerLine;
          additionalPrice += tierCost;
          
          breakdown.push({
            tier: PRICING_TIERS[i].name,
            description: `${PRICING_TIERS[i].name} tier (Lines ${formatNumber(previousTierMax + 1)} to ${formatNumber(Math.min(payableLines, currentTierMax))})`,
            lines: linesInThisTier,
            rate: PRICING_TIERS[i].pricePerLine,
            amount: tierCost,
            color: PRICING_TIERS[i].color
          });
        }
      }
    }
    
    const total = basePrice + additionalPrice;
    
    return {
      basePrice,
      tierPrice: additionalPrice,
      total,
      currency: 'EUR',
      selectedTier,
      breakdown
    };
  };

  // Debounce function to make slider more efficient
  const handleSliderChange = (value) => {
    setSliderValue(value);
    
    // Clear previous timeout
    if (timeoutRef.current) {
      clearTimeout(timeoutRef.current);
    }
    
    // Set new timeout
    timeoutRef.current = setTimeout(() => {
      setLinesOfCode(value);
    }, 100); // 100ms delay for better performance
  };

  // Handle direct input change without debounce
  const handleInputChange = (value) => {
    setSliderValue(value);
    setLinesOfCode(value);
  };

  // Update calculated price when lines of code changes
  useEffect(() => {
    const price = calculatePrice(linesOfCode);
    setCalculatedPrice(price);
  }, [linesOfCode]);

  // Clear timeout on component unmount
  useEffect(() => {
    return () => {
      if (timeoutRef.current) {
        clearTimeout(timeoutRef.current);
      }
    };
  }, []);

  // Create D3 visualization
  useEffect(() => {
    if (calculatedPrice && chartRef.current) {
      createPriceBreakdownChart();
    }
  }, [calculatedPrice]);

  // Generate step markers at 100,000 intervals after 1000 lines
  const generateStepMarkers = () => {
    const markers = [];
    const maxValue = 3000000; // Maximum value on slider
    const step = 10000; // Step size
    
    for (let i = step; i <= maxValue; i += step) {
      markers.push(i);
    }
    
    return markers;
  };

  const createPriceBreakdownChart = () => {
    // Get the container width and set a maximum width
    const containerWidth = chartRef.current.clientWidth;
    const maxWidth = Math.min(containerWidth, 600); // Set a maximum width for larger screens
    const width = maxWidth;
    const height = 300;
    const margin = { top: 40, right: 20, bottom: 30, left: 60 };
    
    // Clear previous SVG
    d3.select(chartRef.current).selectAll("*").remove();
    
    // Create SVG
    const svg = d3.select(chartRef.current)
      .append("svg")
      .attr("width", "100%")
      .attr("height", height)
      .attr("viewBox", `0 0 ${width} ${height}`)
      .attr("preserveAspectRatio", "xMidYMid meet")
      .append("g")
      .attr("transform", `translate(${margin.left},${margin.top})`);
    
    const innerWidth = width - margin.left - margin.right;
    const innerHeight = height - margin.top - margin.bottom;
    
    // Prepare data
    const data = calculatedPrice.breakdown;
    
    // Create scales
    const x = d3.scaleBand()
      .domain(data.map(d => d.tier))
      .range([0, innerWidth])
      .padding(0.3);
    
    const y = d3.scaleLinear()
      .domain([0, d3.max(data, d => d.amount) * 1.1])
      .range([innerHeight, 0]);
    
    // Create and style the bars
    svg.selectAll(".bar")
      .data(data)
      .enter()
      .append("rect")
      .attr("class", "bar")
      .attr("x", d => x(d.tier))
      .attr("y", d => y(d.amount))
      .attr("width", x.bandwidth())
      .attr("height", d => innerHeight - y(d.amount))
      .attr("fill", d => d.color)
      .attr("rx", 5)
      .attr("ry", 5);
    
    // Add text labels on bars
    svg.selectAll(".label")
      .data(data)
      .enter()
      .append("text")
      .attr("class", "label")
      .attr("x", d => x(d.tier) + x.bandwidth() / 2)
      .attr("y", d => y(d.amount) - 10)
      .attr("text-anchor", "middle")
      .text(d => `€${formatPrice(d.amount)}`);
    
    // Add axis - but without labels
    svg.append("g")
      .attr("transform", `translate(0,${innerHeight})`)
      .call(d3.axisBottom(x).tickSize(0))
      .call(g => g.select(".domain").remove());
    
    svg.append("g")
      .call(d3.axisLeft(y).ticks(5).tickFormat(d => `€${d}`))
      .call(g => g.select(".domain").remove());
  };

  // Add resize observer to update chart on container size changes
  useEffect(() => {
    if (calculatedPrice && chartRef.current) {
      const resizeObserver = new ResizeObserver(() => {
        createPriceBreakdownChart();
      });
      
      resizeObserver.observe(chartRef.current);
      
      return () => {
        resizeObserver.disconnect();
      };
    }
  }, [calculatedPrice]);

  return (
    <div className="pricing-calculator-section">
      <h2>Calculate Your Audit Cost</h2>
      <p className="calculator-description">
        Use the slider below to estimate the cost of your code audit based on lines of code.
      </p>
      
      <Card className="pricing-calculator-card">
        <div className="calculator-content">
          <div className="calculator-input">
            <label htmlFor="lines-of-code">Lines of Code:</label>
            <div className="lines-input-wrapper">
              <Slider 
                value={sliderValue} 
                onChange={(e) => handleSliderChange(e.value)} 
                min={0} 
                max={4000000} 
                step={10000}
                className="p-mb-2"
                stepMarkers={generateStepMarkers()}
              />
              <InputNumber
                id="lines-of-code"
                value={sliderValue}
                onValueChange={(e) => handleInputChange(e.value)}
                min={0}
                max={4000000}
                step={10000}
                showButtons
                buttonLayout="horizontal"
                decrementButtonClassName="p-button-secondary"
                incrementButtonClassName="p-button-secondary"
                incrementButtonIcon="pi pi-plus"
                decrementButtonIcon="pi pi-minus"
                mode="decimal"
                useGrouping={true}
              />
            </div>
          </div>
          
          {calculatedPrice && (
            <div className="calculator-results-container">
              <div className="calculator-results">
                <div className="result-item">
                  <span className="result-label">Base Price:</span>
                  <span className="result-value">€{formatPrice(calculatedPrice.basePrice)}</span>
                </div>
                <div className="result-item">
                  <span className="result-label">Additional Tier Costs:</span>
                  <span className="result-value">€{formatPrice(calculatedPrice.tierPrice)}</span>
                </div>
                <div className="result-item total">
                  <span className="result-label">Total Price:</span>
                  <span className="result-value">€{formatPrice(calculatedPrice.total)}</span>
                </div>
                <div className="result-item no-border">
                  <span className="result-label">Your Pricing Tier:</span>
                  <span className="result-value tier-badge">{calculatedPrice.selectedTier}</span>
                </div>
              </div>
              
              <div className="price-breakdown">
                <h3>Price Breakdown</h3>
                <div className="breakdown-chart" ref={chartRef}></div>
                
                <div className="breakdown-table">
                  {calculatedPrice.breakdown.map((item, index) => (
                    <div key={index} className="breakdown-row">
                      <div className="breakdown-tier">
                        <span className="tier-dot" style={{ backgroundColor: item.color }}></span>
                        {item.tier}
                      </div>
                      <div className="breakdown-lines">{formatNumber(item.lines)} lines</div>
                      <div className="breakdown-rate">
                        {item.rate > 0 ? `€${item.rate.toLocaleString('de-DE', { minimumFractionDigits: 5, maximumFractionDigits: 5 })}/line` : 'Flat fee'}
                      </div>
                      <div className="breakdown-amount">€{formatPrice(item.amount)}</div>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          )}
        </div>
      </Card>
    </div>
  );
};

const Pricing = () => {
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return (
    <div className="pricing-page">
      <div className="pricing-header">
        <div className="pricing-header-content">
          <h1>Transparent Volume-Based Pricing</h1>
          <p>
            Our pricing is based on the volume of code analyzed, measured in lines of code. 
            Pay only for what you need with our flexible pricing tiers designed for projects of all sizes.
          </p>
        </div>
      </div>

      <div className="pricing-cards-section">
        <div className="pricing-cards-container">
          <div className="per-audit-container">
            <PerAuditCard />
          </div>
          <div className="enterprise-container">
            <EnterpriseCard />
          </div>
        </div>
      </div>
      
      <div className="pricing-calculator-container">
        <PricingCalculator />
      </div>
      
      <div className="pricing-faq">
        <div className="pricing-faq-content">
          <h2>Frequently Asked Questions</h2>
          <div className="faq-grid">
            <div className="faq-item">
              <h3>How is the number of lines calculated?</h3>
              <p>We count all non-empty lines of code including comments. Generated code, binary files, and assets are excluded from the count.<strong>You are in full control of the scope of the audit and can exclude any files you want.</strong></p>
            </div>
            <div className="faq-item">
              <h3>How does volume-based pricing work?</h3>
              <p>Our pricing tiers are based on the total lines of code analyzed. As your volume increases, the per-line rate decreases, providing better value for larger projects.</p>
            </div>
            <div className="faq-item">
              <h3>Do unused lines of code count?</h3>
              <p>Yes, all lines of code in the analyzed codebase are counted, including unused code. We recommend focusing audits on active code areas for best value.</p>
            </div>
            <div className="faq-item">
              <h3>Can I get a custom quote for large projects?</h3>
              <p>Absolutely! For large projects or special requirements, please contact our sales team for a custom pricing model tailored to your needs.</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Pricing;
