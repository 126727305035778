// App.js
import './axiosConfig';
import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Routes, Route, Navigate, Outlet} from 'react-router-dom';
import { HelmetProvider } from 'react-helmet-async';
import ScrollToTop from './utils/ScrollToTop';

import { PrimeReactProvider } from 'primereact/api';
import { useSelector, useDispatch } from 'react-redux';
import { selectAuth, logout } from './features/user/userSlice';
import { AuditAccessProvider } from './utils/AuditAccessContext';
import { CsrfTokenProvider } from './utils/CsrfTokenContext';
import AxiosConfigInitializer from './utils/AxiosConfigInitializer';
import { PrivateRoute } from './RouteConfig';
import AuditRoute from './utils/AuditRoute';

// Import components
import Start from './Start';
import UserDashboard from './user_dashboard/UserDashboard';
import AuditInvitation from './audit/AuditInvitation';
import AuditSummary from './audit/AuditSummary';
import FlagsView from './audit/Flags';
import DependenciesView from './audit/Dependencies';
import DevelopmentView from './audit/Development';
import FilesView from './audit/Files';
import GlobalSidebar from './components/GlobalSidebar';
import AuditScopeSelection from './user_dashboard/AuditScopeSelection';
import Login from './landingpage/login/Login';
import ForgotPassword from './landingpage/login/ForgotPassword';
import Register from './landingpage/registration/Registration';
import RegistrationComplete from './landingpage/registration/RegistrationComplete';
import Account from './user_dashboard/account/Account';
import Terms from './landingpage/legals/Terms';
import Privacy from './landingpage/legals/Privacy';
import DataProcessingAddendum from './landingpage/legals/DataProcessingAddendum';
import Error from './landingpage/error/Error';
import Navigation from './components/landingpage/Navigation';
import Footer from './components/landingpage/Footer';
import ResetPassword from './landingpage/login/ResetPassword';
import TryDemo from './landingpage/demo/TryDemo';
import Platform from './landingpage/platform/Platform';
import ContactSales from './landingpage/contact-sales/ContactSales';
import WhyCodedd from './landingpage/whycodedd/WhyCodedd';
import Security from './landingpage/security/Security';
import Impressum from './landingpage/legals/Impressum';
import DiscoverAudits from './landingpage/discover/Discover';
import Pricing from './landingpage/pricing/Pricing';
import Logs from './audit/logs/Logs';

// Import blog components
import BlogList from './landingpage/blog/BlogList';
import BlogDetail from './landingpage/blog/BlogDetail';
// Import admin components
import AdminPanel from './admin/AdminDashboard';
import AdminAudits from './admin/AdminAudits';
import AdminUsers from './admin/AdminUsers';

// Import styles
import './App.css';
import 'primeicons/primeicons.css';
import 'primereact/resources/primereact.min.css';
import 'primereact/resources/themes/saga-blue/theme.css';
import './index.css';

const PublicLayout = () => (
  <div className="public-layout">
    <Navigation />
    <main className="public-content">
      <Outlet />
    </main>
    <Footer />
  </div>
);

const PrivateLayout = () => {
  const [sidebarLocked, setSidebarLocked] = useState(false);
  const handleSidebarToggle = (isLocked) => setSidebarLocked(isLocked);

  return (
    <>
      <GlobalSidebar onLockToggle={handleSidebarToggle} />
      <Outlet />
    </>
  );
};

const AppContent = () => {
  const { isLoggedIn } = useSelector(selectAuth);
  const dispatch = useDispatch();

  useEffect(() => {
    // Get current path
    const currentPath = window.location.pathname;
    
    // List of public paths that don't require auth
    const publicPaths = [
      '/login',
      '/register',
      '/forgot-password',
      '/password/reset/',
      '/terms',
      '/privacy',
      '/why-codedd',
      '/platform',
      '/demo',
      '/contact-sales',
      '/security',
      '/impressum',
      '/discover',
      '/pricing',
      '/blog'
    ];

    // Check if current path is public
    const isPublicPath = publicPaths.some(path => currentPath.startsWith(path));

    // Only check auth state if not on a public path
    if (!isPublicPath) {
      const accessToken = localStorage.getItem('accessToken');
      const refreshToken = localStorage.getItem('refreshToken');
      
      if (!accessToken && !refreshToken) {
        dispatch(logout());
      }
    }
  }, [dispatch]);

  return (
    <Routes>
      {/* Public routes */}
      <Route element={<PublicLayout />}>
        <Route path="/" element={<Start />} />
        <Route path="/login" element={isLoggedIn ? <Navigate to="/dashboard" replace /> : <Login />} />
        <Route path="/register" element={<Register />} />
        <Route path="/register/:email" element={<Register />} />
        <Route path="/complete-registration/:registrationUrlKey" element={<RegistrationComplete />} />
        <Route path="/forgot-password" element={<ForgotPassword />} />
        <Route path="/pricing" element={<Pricing />} />
        <Route path="/error" element={<Error />} />
        <Route path="/terms" element={<Terms />} />
        <Route path="/privacy" element={<Privacy />} />
        <Route path="/data-processing-addendum" element={<DataProcessingAddendum />} />
        <Route path="/why-codedd" element={<WhyCodedd />} />
        <Route path="/platform" element={<Platform />} />
        <Route path="/password/reset" element={<ResetPassword />} />
        <Route path="/demo" element={<TryDemo />} />
        <Route path="/contact-sales" element={<ContactSales />} />
        <Route path="/security" element={<Security />} />
        <Route path="/impressum" element={<Impressum />} />
        <Route path="/discover" element={<DiscoverAudits />} />

        {/* Blog routes */}
        <Route path="/blog" element={<BlogList />} />
        <Route path="/blog/:slug" element={<BlogDetail />} />
      </Route>

      {/* Audit routes with their own access control */}
      <Route element={<AuditRoute />}>
        <Route path="/:auditUuid/audit-summary" element={<AuditSummary />} />
        <Route path="/:auditUuid/flags" element={<FlagsView />} />
        <Route path="/:auditUuid/development" element={<DevelopmentView />} />
        <Route path="/:auditUuid/dependencies" element={<DependenciesView />} />
        <Route path="/:auditUuid/files" element={<FilesView />} />
        <Route path="/:auditUuid/logs" element={<Logs />} />

      </Route>

      {/* Protected routes that always require authentication */}
      <Route element={<PrivateRoute />}>
        <Route element={<PrivateLayout />}>
          <Route path="/dashboard" element={<UserDashboard />} />
          <Route path="/account" element={<Account />} />
          <Route path="/admin/dashboard" element={<AdminPanel />} />
          <Route path="/admin/audits" element={<AdminAudits />} />
          <Route path="/admin/users" element={<AdminUsers />} />
          <Route path="/:groupAuditUuid/audit-invitation" element={<AuditInvitation />} />
          <Route path="/:auditUuid/audit-scope-selection" element={<AuditScopeSelection />} />
        </Route>
      </Route>

      {/* Catch-all route */}
      <Route path="*" element={<Navigate to="/error" replace />} />
    </Routes>
  );
};

const App = () => {
  return (
    <CsrfTokenProvider>
      <PrimeReactProvider value={{ ripple: true }}>
        <HelmetProvider>
          <Router>
            <ScrollToTop />
            <AxiosConfigInitializer />
            <AuditAccessProvider>
              <AppContent />
            </AuditAccessProvider>
          </Router>
        </HelmetProvider>
      </PrimeReactProvider>
    </CsrfTokenProvider>
  );
};

export default App;