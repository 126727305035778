import React, { useEffect, useRef, useState } from 'react';
import * as d3 from 'd3';
import { Tooltip } from 'primereact/tooltip';
import './TimeToFixGraph.css';

const TotalToFix = ({ data }) => {
    const ref = useRef(null);
    const [hoveredCategory, setHoveredCategory] = useState(null);

    useEffect(() => {
        if (!data) return;

        // Clear any existing SVG content
        d3.select(ref.current).selectAll("*").remove();

        // Set up dimensions
        const margin = { top: 10, right: 30, bottom: 30, left: 20 };
        const width = 500;
        const height = 140;
        const innerWidth = width - margin.left - margin.right;
        const innerHeight = height - margin.top - margin.bottom;

        // Create SVG
        const svg = d3.select(ref.current)
            .attr("width", width)
            .attr("height", height)
            .attr("viewBox", [0, 0, width, height])
            .attr("style", "max-width: 100%; height: auto;");

        // Define flag categories and order
        const categories = ["Red", "Orange"];
        
        // Process the data
        const flagData = categories.map(category => ({
            name: category,
            value: data[category] || 0,
            color: category === "Red" ? "#E83636" : "#DE7539"
        }));

        // Create x scale
        const x = d3.scaleBand()
            .domain(categories)
            .range([margin.left, width - margin.right])
            .padding(0.4);

        // Create y scale
        const totalHours = d3.sum(flagData, d => d.value);
        const y = d3.scaleLinear()
            .domain([0, totalHours * 1.1]) // Add 10% padding
            .range([height - margin.bottom, margin.top]);

        // Create x axis (only keeping this one)
        svg.append("g")
            .attr("transform", `translate(0,${height - margin.bottom})`)
            .call(d3.axisBottom(x))
            .call(g => g.select(".domain").remove())
            .call(g => g.selectAll(".tick text")
                .attr("font-size", "12px")
                .attr("fill", "#6B7280")
                .attr("font-weight", "500"));

        // Create bars
        const barGroups = svg.selectAll(".bar-group")
            .data(flagData)
            .join("g")
            .attr("class", "bar-group")
            .attr("data-category", d => d.name);

        barGroups.append("rect")
            .attr("class", "time-fix-bar")
            .attr("x", d => x(d.name))
            .attr("y", d => y(d.value))
            .attr("width", x.bandwidth())
            .attr("height", d => y(0) - y(d.value))
            .attr("fill", d => d.color)
            .attr("rx", 4)
            .on("mouseover", function(event, d) {
                setHoveredCategory({
                    name: d.name,
                    hours: d.value,
                    position: {
                        x: event.clientX,
                        y: event.clientY
                    }
                });
                
                d3.select(this)
                    .transition()
                    .duration(200)
                    .attr("opacity", 0.8)
                    .attr("stroke", "white")
                    .attr("stroke-width", 2);
            })
            .on("mouseout", function() {
                setHoveredCategory(null);
                
                d3.select(this)
                    .transition()
                    .duration(200)
                    .attr("opacity", 1)
                    .attr("stroke", "none");
            });

        // Add hour labels at the top of each bar
        barGroups.append("text")
            .attr("class", "bar-value")
            .attr("x", d => x(d.name) + x.bandwidth() / 2)
            .attr("y", d => y(d.value) - 8)
            .attr("text-anchor", "middle")
            .attr("font-size", "14px")
            .attr("font-weight", "600")
            .attr("fill", d => d.name === "Red" ? "#E83636" : "#DE7539")
            .text(d => d.value > 0 ? `${Math.round(d.value)}h` : "");

    }, [data]);

    return (
        <div className="time-fix-graph-container">
            <svg 
                ref={ref} 
                className="time-fix-graph"
            />
            {hoveredCategory && (
                <div 
                    id="fix-time-tooltip" 
                    className="fix-time-tooltip" 
                    style={{ 
                        left: `${hoveredCategory.position.x}px`, 
                        top: `${hoveredCategory.position.y}px`,
                        visibility: 'hidden' 
                    }}
                    data-pr-tooltip={`${hoveredCategory.name} flags: ${Math.round(hoveredCategory.hours)} hours to fix`}
                />
            )}
            <Tooltip target="#fix-time-tooltip" position="top" />
        </div>
    );
};

export default TotalToFix;