import React, { useEffect, useState, useRef } from 'react';
import { Button } from 'primereact/button';
import { InputText } from 'primereact/inputtext';
import { Dropdown } from 'primereact/dropdown';
import { Card } from 'primereact/card';
import { Message } from 'primereact/message';
import { ProgressSpinner } from 'primereact/progressspinner';
import axiosInstance from '../../axiosConfig';
import { calculatePrice, formatNumber } from '../../utils/pricingUtils';
import PriceBreakdown from './PriceBreakdown';
import './CheckoutStepper.css';

/**
 * CheckoutStepper component for handling organization selection/creation and payment
 */
const CheckoutStepper = ({ 
    account_uuid, 
    audit_uuid, 
    orderDetails: initialOrderDetails,
    pricingDetails = { total: 0, totalWithTax: 0, subtotal: 0, vatRate: 0, currency: 'EUR', breakdown: [] },
    setPricingDetails,
    successHandler,
    onCancel,
    paymentStatus: externalPaymentStatus,
    setPaymentStatus: setExternalPaymentStatus,
    initialSessionId,
    initialPaymentSuccess,
    initialPaymentCancelled,
    isReturnFromStripe = false,
    activeIndex,
    setActiveIndex,
    onClose,
    isGroupAudit = false,
    groupAuditUuid,
    processedPaymentIntents: externalProcessedPaymentIntents,
    setProcessedPaymentIntents: setExternalProcessedPaymentIntents,
    onPaymentSuccess  // Add this prop to expose the handlePaymentSuccess function
}) => {
    
    // Create a safe version of orderDetails that's never null
    const orderDetails = initialOrderDetails || {
        linesOfCode: 0,
        payableLines: 0,
        freeLines: 0,
        audit_uuid: audit_uuid,
        account_uuid: account_uuid,
        isGroupAudit: isGroupAudit
    };
    
    const stepperRef = useRef(null);
    
    // State for organizations
    const [organizations, setOrganizations] = useState([]);
    const [isLoadingOrgs, setIsLoadingOrgs] = useState(false);
    const [selectedOrganization, setSelectedOrganization] = useState(null);
    const [showNewOrgForm, setShowNewOrgForm] = useState(false);
    
    // State for price breakdown display
    const [showPriceBreakdown, setShowPriceBreakdown] = useState(false);
    
    // State for organization form
    const [newOrg, setNewOrg] = useState({
        name: '',
        email: '',
        country: '',
        domain: '',
        address_line1: '',
        address_line2: '',
        post_code: '',
        tax_num: '',
        phone: ''
    });
    const [formErrors, setFormErrors] = useState({});
    const [isSavingOrg, setIsSavingOrg] = useState(false);
    const [orgCreationError, setOrgCreationError] = useState(null);
    
    // State for payment processing
    const [paymentError, setPaymentError] = useState(null);
    const [clientSecret, setClientSecret] = useState(null);
    const [initialSessionProcessed, setInitialSessionProcessed] = useState(false);
    const [isLoadingIntent, setIsLoadingIntent] = useState(false);
    const [verificationData, setVerificationData] = useState(null);
    
    // Use external payment status if provided, otherwise use local state
    const [internalPaymentStatus, setInternalPaymentStatus] = useState('initial'); // initial, processing, succeeded, failed
    const paymentStatus = externalPaymentStatus || internalPaymentStatus;
    
    // Track processed payment intents
    const [processedPaymentIntents, setProcessedPaymentIntents] = useState(() => {
        // Initialize with external intents if provided
        return externalProcessedPaymentIntents ? new Set(externalProcessedPaymentIntents) : new Set();
    });
    
    // Use a ref to track if we're currently syncing to avoid loops
    const isSyncingRef = useRef(false);
    
    // Sync processed payment intents with parent component
    useEffect(() => {
        // Skip if we're currently processing a sync or no setter provided
        if (isSyncingRef.current || !setExternalProcessedPaymentIntents) {
            return;
        }
        
        const localIntents = Array.from(processedPaymentIntents);
        const externalIntents = externalProcessedPaymentIntents ? Array.from(externalProcessedPaymentIntents) : [];
        
        // Check if parent has intents we don't have locally
        const hasNewExternalIntents = externalIntents.some(id => !processedPaymentIntents.has(id));
        
        if (hasNewExternalIntents) {            
            // Mark that we're syncing to prevent loops
            isSyncingRef.current = true;
            
            // Add all external intents to our local set
            setProcessedPaymentIntents(prev => {
                const newSet = new Set(prev);
                externalIntents.forEach(id => newSet.add(id));
                return newSet;
            });
            
            // Reset sync flag after a short delay
            setTimeout(() => {
                isSyncingRef.current = false;
            }, 100);
        }
    }, [externalProcessedPaymentIntents, setExternalProcessedPaymentIntents]);
    
    // Function to update payment status (updates both internal and external if available)
    const setPaymentStatus = (status) => {
        setInternalPaymentStatus(status);
        if (setExternalPaymentStatus) {
            setExternalPaymentStatus(status);
        }
    };
    
    const [customerCountry, setCustomerCountry] = useState(null);
    
    // Only initialize activeIndex state if not provided as prop
    const [internalActiveIndex, setInternalActiveIndex] = useState(0);
    
    // Use provided activeIndex/setActiveIndex or fallback to internal state
    const currentActiveIndex = activeIndex !== undefined ? activeIndex : internalActiveIndex;
    const updateActiveIndex = setActiveIndex || setInternalActiveIndex;
    
    // Countries for dropdown
    const countries = [
        { label: 'Germany', value: 'DE' },
        { label: 'United States', value: 'US' },
        { label: 'United Kingdom', value: 'GB' },
        { label: 'France', value: 'FR' },
        { label: 'Spain', value: 'ES' },
        { label: 'Italy', value: 'IT' },
        { label: 'Netherlands', value: 'NL' },
        { label: 'Switzerland', value: 'CH' },
        { label: 'Austria', value: 'AT' },
        { label: 'Belgium', value: 'BE' },
        { label: 'Portugal', value: 'PT' },
        { label: 'Sweden', value: 'SE' },
        { label: 'Norway', value: 'NO' },
        { label: 'Denmark', value: 'DK' },
        { label: 'Finland', value: 'FI' },
        { label: 'Greece', value: 'GR' },
        { label: 'Poland', value: 'PL' },
        { label: 'Romania', value: 'RO' },
        { label: 'Bulgaria', value: 'BG' },
        { label: 'Hungary', value: 'HU' },
        { label: 'Czech Republic', value: 'CZ' },
        { label: 'Slovakia', value: 'SK' },
        { label: 'Croatia', value: 'HR' },
        { label: 'Slovenia', value: 'SI' },
        { label: 'Lithuania', value: 'LT' },
        { label: 'Latvia', value: 'LV' },
        { label: 'Estonia', value: 'EE' },
        { label: 'Luxembourg', value: 'LU' },
        { label: 'Cyprus', value: 'CY' },
        { label: 'Malta', value: 'MT' },
        { label: 'Albania', value: 'AL' },
        { label: 'Montenegro', value: 'ME' },
        { label: 'North Macedonia', value: 'MK' },
        { label: 'Serbia', value: 'RS' },
        { label: 'Kosovo', value: 'XK' },
        { label: 'Bosnia and Herzegovina', value: 'BA' },
        { label: 'Montserrat', value: 'MS' },
        { label: 'New Caledonia', value: 'NC' },
        { label: 'New Zealand', value: 'NZ' },
        { label: 'Norfolk Island', value: 'NF' },
        { label: 'Puerto Rico', value: 'PR' },
        { label: 'Turks and Caicos Islands', value: 'TC' },
        { label: 'U.S. Virgin Islands', value: 'VI' },
        { label: 'Wallis and Futuna', value: 'WF' },
        { label: 'Cook Islands', value: 'CK' },
        { label: 'Fiji', value: 'FJ' },
        { label: 'French Polynesia', value: 'PF' },
        { label: 'Guam', value: 'GU' },
        { label: 'Guernsey', value: 'GG' },
        { label: 'Holy See', value: 'VA' },
        { label: 'Isle of Man', value: 'IM' },
        { label: 'Jersey', value: 'JE' },
        { label: 'Liechtenstein', value: 'LI' },
        { label: 'Monaco', value: 'MC' },
        { label: 'San Marino', value: 'SM' },
        { label: 'Sint Maarten', value: 'SX' },
        { label: 'Tunisia', value: 'TN' },
        { label: 'Turkey', value: 'TR' },
        { label: 'Ukraine', value: 'UA' },
        { label: 'United Arab Emirates', value: 'AE' },
        { label: 'United States Minor Outlying Islands', value: 'UM' },
        { label: 'Uruguay', value: 'UY' },
        { label: 'Vanuatu', value: 'VU' },
        { label: 'Vatican City', value: 'VA' },
        { label: 'Western Sahara', value: 'EH' },
        { label: 'Yemen', value: 'YE' },
        { label: 'Zambia', value: 'ZM' },
        { label: 'Zimbabwe', value: 'ZW' },
        { label: 'Aland Islands', value: 'AX' },
        { label: 'Afghanistan', value: 'AF' },
        { label: 'Algeria', value: 'DZ' },
        { label: 'Angola', value: 'AO' },
        { label: 'Argentina', value: 'AR' },
        { label: 'Armenia', value: 'AM' },
        { label: 'Australia', value: 'AU' },
        { label: 'Azerbaijan', value: 'AZ' },
        { label: 'Bahamas', value: 'BS' },
        { label: 'Bahrain', value: 'BH' },
        { label: 'Bangladesh', value: 'BD' },
        { label: 'Barbados', value: 'BB' },
        { label: 'Belarus', value: 'BY' },
        { label: 'Belize', value: 'BZ' },
        { label: 'Benin', value: 'BJ' },
        { label: 'Bhutan', value: 'BT' },
        { label: 'Bolivia', value: 'BO' },
        { label: 'Botswana', value: 'BW' },
        { label: 'Brazil', value: 'BR' },
        { label: 'Brunei', value: 'BN' },
        { label: 'Burkina Faso', value: 'BF' },
        { label: 'Burundi', value: 'BI' },
        { label: 'Cambodia', value: 'KH' },
        { label: 'Cameroon', value: 'CM' },
        { label: 'Canada', value: 'CA' },
        { label: 'Central African Republic', value: 'CF' },
        { label: 'Chad', value: 'TD' },
        { label: 'Chile', value: 'CL' },
        { label: 'China', value: 'CN' },
        { label: 'Colombia', value: 'CO' },
        { label: 'Comoros', value: 'KM' },
        { label: 'Congo', value: 'CG' },
        { label: 'Costa Rica', value: 'CR' },
        { label: 'Cuba', value: 'CU' },
        { label: 'Djibouti', value: 'DJ' },
        { label: 'Dominica', value: 'DM' },
        { label: 'Dominican Republic', value: 'DO' },
        { label: 'Ecuador', value: 'EC' },
        { label: 'Egypt', value: 'EG' },
        { label: 'El Salvador', value: 'SV' },
        { label: 'Equatorial Guinea', value: 'GQ' },
        { label: 'Eritrea', value: 'ER' },
        { label: 'Ethiopia', value: 'ET' },
        { label: 'Gabon', value: 'GA' },
        { label: 'Gambia', value: 'GM' },
        { label: 'Georgia', value: 'GE' },
        { label: 'Ghana', value: 'GH' },
        { label: 'Grenada', value: 'GD' },
        { label: 'Guatemala', value: 'GT' },
        { label: 'Guinea', value: 'GN' },
        { label: 'Guinea-Bissau', value: 'GW' },
        { label: 'Guyana', value: 'GY' },
        { label: 'Haiti', value: 'HT' },
        { label: 'Honduras', value: 'HN' },
        { label: 'Iceland', value: 'IS' },
        { label: 'India', value: 'IN' },
        { label: 'Indonesia', value: 'ID' },
        { label: 'Iran', value: 'IR' },
        { label: 'Iraq', value: 'IQ' },
        { label: 'Ireland', value: 'IE' },
        { label: 'Israel', value: 'IL' },
        { label: 'Jamaica', value: 'JM' },
        { label: 'Japan', value: 'JP' },
        { label: 'Jordan', value: 'JO' },
        { label: 'Kazakhstan', value: 'KZ' },
        { label: 'Kenya', value: 'KE' },
        { label: 'Kiribati', value: 'KI' },
        { label: 'Kuwait', value: 'KW' },
        { label: 'Kyrgyzstan', value: 'KG' },
        { label: 'Laos', value: 'LA' },
        { label: 'Lebanon', value: 'LB' },
        { label: 'Lesotho', value: 'LS' },
        { label: 'Liberia', value: 'LR' },
        { label: 'Libya', value: 'LY' },
        { label: 'Madagascar', value: 'MG' },
        { label: 'Malawi', value: 'MW' },
        { label: 'Malaysia', value: 'MY' },
        { label: 'Maldives', value: 'MV' },
        { label: 'Mali', value: 'ML' },
        { label: 'Marshall Islands', value: 'MH' },
        { label: 'Mauritania', value: 'MR' },
        { label: 'Mauritius', value: 'MU' },
        { label: 'Mexico', value: 'MX' },
        { label: 'Micronesia', value: 'FM' },
        { label: 'Moldova', value: 'MD' },
        { label: 'Mongolia', value: 'MN' },
        { label: 'Morocco', value: 'MA' },
        { label: 'Mozambique', value: 'MZ' },
        { label: 'Myanmar', value: 'MM' },
        { label: 'Namibia', value: 'NA' },
        { label: 'Nauru', value: 'NR' },
        { label: 'Nepal', value: 'NP' },
        { label: 'Nicaragua', value: 'NI' },
        { label: 'Niger', value: 'NE' },
        { label: 'Nigeria', value: 'NG' },
        { label: 'North Korea', value: 'KP' },
        { label: 'Oman', value: 'OM' },
        { label: 'Pakistan', value: 'PK' },
        { label: 'Palau', value: 'PW' },
        { label: 'Palestine', value: 'PS' },
        { label: 'Panama', value: 'PA' },
        { label: 'Papua New Guinea', value: 'PG' },
        { label: 'Paraguay', value: 'PY' },
        { label: 'Peru', value: 'PE' },
        { label: 'Philippines', value: 'PH' },
        { label: 'Qatar', value: 'QA' },
        { label: 'Russia', value: 'RU' },
        { label: 'Rwanda', value: 'RW' },
        { label: 'Saint Kitts and Nevis', value: 'KN' },
        { label: 'Saint Lucia', value: 'LC' },
        { label: 'Saint Vincent and the Grenadines', value: 'VC' },
        { label: 'Samoa', value: 'WS' },
        { label: 'Saudi Arabia', value: 'SA' },
        { label: 'Senegal', value: 'SN' },
        { label: 'Seychelles', value: 'SC' },
        { label: 'Sierra Leone', value: 'SL' },
        { label: 'Singapore', value: 'SG' },
        { label: 'Solomon Islands', value: 'SB' },
        { label: 'Somalia', value: 'SO' },
        { label: 'South Africa', value: 'ZA' },
        { label: 'South Korea', value: 'KR' },
        { label: 'South Sudan', value: 'SS' },
        { label: 'Sri Lanka', value: 'LK' },
        { label: 'Sudan', value: 'SD' },
        { label: 'Suriname', value: 'SR' },
        { label: 'Syria', value: 'SY' },
        { label: 'Taiwan', value: 'TW' },
        { label: 'Tajikistan', value: 'TJ' },
        { label: 'Tanzania', value: 'TZ' },
        { label: 'Thailand', value: 'TH' },
        { label: 'Timor-Leste', value: 'TL' },
        { label: 'Togo', value: 'TG' },
        { label: 'Tonga', value: 'TO' },
        { label: 'Trinidad and Tobago', value: 'TT' },
        { label: 'Turkmenistan', value: 'TM' },
        { label: 'Tuvalu', value: 'TV' },
        { label: 'Uganda', value: 'UG' },
        { label: 'Uzbekistan', value: 'UZ' },
        { label: 'Venezuela', value: 'VE' },
        { label: 'Vietnam', value: 'VN' }
    ];
    
    // Force the payment tab to be active when returning from Stripe
    useEffect(() => {
        if (isReturnFromStripe) {
            updateActiveIndex(1); // Force payment tab (index 1)
        }
    }, [isReturnFromStripe, updateActiveIndex]);
    
    // Fetch organizations when component mounts
    useEffect(() => {
        if (account_uuid) {
            fetchOrganizations();
        }
    }, [account_uuid]);
    
    // Effect to handle validation when activeIndex changes
    useEffect(() => {
        // If returning from Stripe and activeIndex is 1 (payment tab), don't enforce org selection
        if (isReturnFromStripe) {
            // Force activeIndex to 1 (payment tab) when returning from Stripe
            updateActiveIndex(1);
            return;
        }
        
        // If moving to payment step (activeIndex === 1), validate organization selection
        if (currentActiveIndex === 1) {
            if (!selectedOrganization) {
                setOrgCreationError('Please select an organization to continue');
                // Only update activeIndex if not returning from Stripe
                if (!isReturnFromStripe) {
                    updateActiveIndex(0);
                }
            } else {
                setOrgCreationError(null);
            }
        }
    }, [currentActiveIndex, selectedOrganization, updateActiveIndex, isReturnFromStripe]);

    // Effect to handle Stripe params on mount
    useEffect(() => {
        // Skip if already executed
        if (initialSessionProcessed) {
            return;
        }
        
        // Process initial session if available
        const processInitialSession = async () => {
            
            // Mark as processed to prevent duplicate processing
            setInitialSessionProcessed(true);
            
            // If already paid, don't process again
            if (paymentStatus === 'succeeded') {
                return;
            }
            
            // If returning from Stripe and no org is selected, create a temporary one
            if (isReturnFromStripe && !selectedOrganization && orderDetails) {
                setSelectedOrganization({
                    id: orderDetails.account_uuid || account_uuid || 'temp-org-id',
                    name: 'Payment Organization',
                    account_uuid: orderDetails.account_uuid || account_uuid || 'temp-org-id'
                });
            }
            
            // If we have a session ID directly, we need to retrieve the payment intent from the session
            if (initialSessionId) {
                // Skip if already processed
                const sessionKey = `initial_session_${initialSessionId}_processed`;
                if (sessionStorage.getItem(sessionKey) === 'true') {
                    return;
                }
                
                // Mark this session as processed
                sessionStorage.setItem(sessionKey, 'true');
                
                try {
                    // First check if this looks like a payment intent ID (starts with 'pi_')
                    if (initialSessionId.startsWith('pi_')) {
                        // This is already a payment intent ID
                        await verifyPayment(initialSessionId);
                    } else if (initialSessionId.startsWith('cs_')) {
                        // This is a checkout session ID - get the payment intent from it
                        await handleCheckoutSession(initialSessionId);
                    } else {
                        console.error('Unrecognized ID format:', initialSessionId);
                    }
                } catch (error) {
                    console.error('Error processing initial session:', error);
                }
            }
            // Handle direct success/cancel params
            else if (initialPaymentSuccess) {
                setPaymentStatus('succeeded');
            } else if (initialPaymentCancelled) {
                setPaymentStatus('cancelled');
            }
        };
        
        // Only run once
        processInitialSession();
    }, [initialSessionProcessed]);
    
    useEffect(() => {
        // Set initial pricing based on orderDetails if not already set
        if (orderDetails.payableLines > 0 && (!pricingDetails.total || pricingDetails.total === 0)) {
            // Use our new pricing utility
            const calculatedPricing = calculatePrice(orderDetails.payableLines);
            
            setPricingDetails(prev => ({
                ...prev,
                ...calculatedPricing
            }));
        }
    }, [orderDetails, pricingDetails.total, setPricingDetails]);
    
    // Modify handleStepChange to prevent going back to the organization step when returning from Stripe
    const handleStepChange = (index) => {
        
        // Always prevent navigation away from payment step when returning from Stripe
        if (isReturnFromStripe) {
            if (index !== 1) {
                return;
            }
            // If we're already on the payment tab, just ensure we stay there
            updateActiveIndex(1);
            return;
        }
        
        // Only update if different from current index and it's a number
        if (typeof index === 'number' && index !== currentActiveIndex) {
            
            // If moving to payment step, ensure payment intent is created
            if (index === 1 && selectedOrganization && !clientSecret && !isLoadingIntent) {
                updateActiveIndex(index);
                // Delay the payment intent creation to ensure orderDetails are accessible
                setTimeout(() => {
                    if (orderDetails && orderDetails.payableLines) {
                        // Don't automatically create payment intent when returning from Stripe
                        if (!isReturnFromStripe) {
                            createPaymentIntent();
                        }
                    } else {
                        setPaymentError("Order details are required. Please refresh the page and try again.");
                        updateActiveIndex(0);
                    }
                }, 100);
                return;
            }
            
            updateActiveIndex(index);
        } else {
            console.log("Ignoring step change - index is same as current or not a number");
        }
    };
    
    // Modify the fetchOrganizations function to use mock data as fallback
    const fetchOrganizations = async () => {
        setIsLoadingOrgs(true);
        try {
            const response = await axiosInstance.get(`/api/get_organizations/?account_uuid=${account_uuid}`);
            
            if (response.data.status === 'success') {
                setOrganizations(response.data.organizations || []);
                
                // Select the first organization if available
                if (response.data.organizations && response.data.organizations.length > 0) {
                    setSelectedOrganization(response.data.organizations[0]);
                } else {
                    // If no organizations, show the form to create one
                    setShowNewOrgForm(true);
                }
            } else {
                console.error('Error fetching organizations:', response.data.message);
            }
        } catch (error) {
            // Fallback to mock organizations for development
            console.error('Error fetching organizations:', error);
            setShowNewOrgForm(true);

        } finally {
            setIsLoadingOrgs(false);
        }
    };
    
    // Create a shared validation function
    const validateField = (name, value) => {
        switch (name) {
            case 'name':
                if (!value || value.trim() === '') return 'Organization name is required';
                if (value.length < 2) return 'Organization name must be at least 2 characters';
                if (value.length > 100) return 'Organization name must be less than 100 characters';
                break;
            case 'email':
                if (!value || value.trim() === '') return 'Email is required';
                if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(value)) return 'Invalid email address';
                break;
            case 'country':
                if (!value) return 'Country is required';
                break;
            case 'phone':
                if (value && !/^[0-9\s\+\-\(\)]{6,20}$/.test(value)) return 'Invalid phone number format';
                break;
            case 'domain':
                if (value && !/^[a-zA-Z0-9][a-zA-Z0-9-]{1,61}[a-zA-Z0-9](?:\.[a-zA-Z]{2,})+$/.test(value) && 
                   !/^(https?:\/\/)?([a-zA-Z0-9][a-zA-Z0-9-]{1,61}[a-zA-Z0-9]\.)+[a-zA-Z]{2,}(\/.*)?$/.test(value)) {
                    return 'Invalid website format (e.g., example.com or https://example.com)';
                }
                break;
            case 'address_line1':
                if (value && value.length < 5) return 'Address must be at least 5 characters';
                if (value && value.length > 200) return 'Address must be less than 200 characters';
                break;
            case 'address_line2':
                if (value && value.length > 200) return 'Address line 2 must be less than 200 characters';
                break;
            case 'post_code':
                if (value && value.length < 3) return 'Post code must be at least 3 characters';
                if (value && value.length > 20) return 'Post code must be less than 20 characters';
                break;
            case 'tax_num':
                if (value && !/^[a-zA-Z0-9\.\-]{5,30}$/.test(value)) return 'Invalid tax ID format (alphanumeric, dots and dashes only, 5-30 characters)';
                break;
            default:
                break;
        }
        return null;
    };
    
    // Then modify handleOrgInputChange to use it
    const handleOrgInputChange = (e) => {
        const { name, value } = e.target;
        setNewOrg(prev => ({ ...prev, [name]: value }));
        
        // Validate field using the shared function
        const error = validateField(name, value);
        setFormErrors(prev => ({ ...prev, [name]: error }));
    };
    
    // And validateAllFields would also use it
    const validateAllFields = () => {
        const errors = {};
        
        // Validate each field using the shared function
        Object.entries(newOrg).forEach(([name, value]) => {
            const error = validateField(name, value);
            if (error) errors[name] = error;
        });
        
        setFormErrors(errors);
        return Object.keys(errors).length === 0;
    };
    
    // Select an existing organization
    const handleSelectOrganization = (org) => {
        setSelectedOrganization(org);
        setShowNewOrgForm(false);
    };
    
    // Save new organization
    const saveOrganization = async () => {
        if (!validateAllFields()) {
            return;
        }
        
        setIsSavingOrg(true);
        setOrgCreationError(null);
        
        try {
            const response = await axiosInstance.post('/api/create_organization/', {
                account_uuid: account_uuid,
                ...newOrg
            });
            
            if (response.data.status === 'success') {
                // Set the newly created organization as selected
                setSelectedOrganization(response.data.organization);
                setShowNewOrgForm(false);
                
                // Refresh the organizations list
                fetchOrganizations();
                
                // Move to the payment step
                updateActiveIndex(1);
            } else {
                setOrgCreationError(response.data.message || 'Failed to create organization');
            }
        } catch (error) {
            console.error('Error creating organization:', error);
            setOrgCreationError(
                error.response?.data?.message || 
                'An unexpected error occurred while creating the organization'
            );
        } finally {
            setIsSavingOrg(false);
        }
    };
    
    // Verify a payment intent with the server
    const verifyPayment = async (paymentIntentId) => {
        if (!paymentIntentId) {
            console.error('No payment intent ID provided for verification');
            return null;
        }
        
        // Skip verification if already processed
        if (hasProcessedPaymentIntent(paymentIntentId)) {
            setPaymentStatus('succeeded');
            return { 
                status: 'succeeded', 
                alreadyProcessed: true,
                payment_intent_id: paymentIntentId
            };
        }
        
        try {
            // Get account and audit UUIDs
            const accountId = account_uuid;
            const auditId = audit_uuid;
            
            if (!accountId || !auditId) {
                console.error('Missing account_uuid or audit_uuid for payment verification');
                return null;
            }
            
            // Call API to verify payment status
            const verificationData = await checkPaymentStatus(paymentIntentId);
            
            // Handle verification result
            if (verificationData) {
                if (verificationData.is_paid) {
                    // Payment is confirmed as paid
                    setPaymentStatus('succeeded');
                    
                    // Track this payment intent as processed
                    markPaymentIntentAsProcessed(paymentIntentId);
                    
                    // Call payment success handler
                    await handlePaymentSuccess(paymentIntentId);
                    
                    return {
                        status: 'succeeded',
                        verification: verificationData,
                        payment_intent_id: paymentIntentId
                    };
                } else if (verificationData.stripe_status === 'succeeded') {
                    // Payment succeeded in Stripe but not recorded in our system
                    
                    // First mark as processed to prevent duplicate calls
                    markPaymentIntentAsProcessed(paymentIntentId);
                    
                    // Attempt to record the payment manually
                    const manualResult = await triggerManualPayment(
                        paymentIntentId,
                        accountId,
                        auditId,
                        verificationData
                    );
                    
                    if (manualResult && manualResult.success) {
                        setPaymentStatus('succeeded');
                        
                        // Call payment success handler
                        await handlePaymentSuccess(paymentIntentId);
                        
                        return {
                            status: 'succeeded',
                            manuallyRecorded: true,
                            payment_intent_id: paymentIntentId
                        };
                    } else if (manualResult && manualResult.already_existed) {
                        // Payment was already recorded
                        setPaymentStatus('succeeded');
                        
                        // Call payment success handler
                        await handlePaymentSuccess(paymentIntentId);
                        
                        return {
                            status: 'succeeded',
                            alreadyExisted: true,
                            payment_intent_id: paymentIntentId
                        };
                    } else {
                        console.error('Manual payment recording failed');
                        setPaymentStatus('failed');
                        return {
                            status: 'failed',
                            reason: 'manual_recording_failed',
                            payment_intent_id: paymentIntentId
                        };
                    }
                } else {
                    // Payment not successful or still pending
                    setPaymentStatus(verificationData.stripe_status === 'canceled' ? 'cancelled' : 'failed');
                    return {
                        status: verificationData.stripe_status,
                        verification: verificationData,
                        payment_intent_id: paymentIntentId
                    };
                }
            } else {
                // No verification data
                console.error('No verification data received');
                setPaymentStatus('failed');
                return {
                    status: 'failed',
                    reason: 'no_verification_data',
                    payment_intent_id: paymentIntentId
                };
            }
        } catch (error) {
            console.error('Error verifying payment:', error);
            setPaymentStatus('failed');
            return {
                status: 'failed',
                error: error.message,
                payment_intent_id: paymentIntentId
            };
        }
    };
    
    // Create Stripe checkout session and redirect
    const createPaymentIntent = async (e) => {
        // Don't proceed if we're returning from Stripe already and already paid
        if (initialSessionId && initialPaymentSuccess) {
            return;
        }
        
        // Get Stripe key from various possible sources
        const stripeKey = 
            window.STRIPE_PUBLISHABLE_KEY || 
            process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY || 
            'pk_live_51OytWVRquxwYBnYTW0wyzu5H07yUhbWZRHRQ2DsXu2HIo5gQYlBeN2O9ivlA8tRu8EXdNvXxkAKDHJpKCEdo0dsO00CCWS6dTH';
        
        // Debug Stripe environment at payment creation time
        console.log('Stripe Payment Debug:', {
            stripeKey: stripeKey,
            windowStripeKey: window.STRIPE_PUBLISHABLE_KEY,
            envStripeKey: process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY, 
            windowEnvStripeKey: window.env?.REACT_APP_STRIPE_PUBLISHABLE_KEY,
            loadStripeAvailable: typeof window.loadStripe === 'function',
            stripeAvailable: typeof window.Stripe === 'function',
            stripePromiseExists: !!window.stripePromise,
            orderDetails: orderDetails,
            timestamp: new Date().toISOString()
        });
        
        try {
            setIsLoadingIntent(true);
            setPaymentError(null);
            
            // Prepare success and cancel URLs
            // Include the audit UUID in the URLs for proper redirection
            let successUrl = `${window.location.origin}${window.location.pathname}?success=true&session_id={CHECKOUT_SESSION_ID}`;
            let cancelUrl = `${window.location.origin}${window.location.pathname}?cancelled=true`;
            
            // Prepare the payload
            const payload = {
                orderDetails: {
                    audit_uuid: audit_uuid,
                    account_uuid: typeof selectedOrganization === 'object' ? 
                        (selectedOrganization.account_uuid || selectedOrganization.id || selectedOrganization.name) : 
                        selectedOrganization,
                    payable_lines: orderDetails.payableLines,
                    is_group_audit: orderDetails.isGroupAudit || false,
                    country_code: customerCountry
                },
                successUrl: successUrl,
                cancelUrl: cancelUrl
            };
            const response = await axiosInstance.post('/api/create-payment-intent/', payload);
            
            // Handle response
            const data = response.data;
            
            if (data.error) {
                setPaymentError(data.error);
                setIsLoadingIntent(false);
                setPaymentStatus('failed');
                return;
            }
            
            // For Stripe Checkout, we get a redirect URL
            if (data.url) {
                setClientSecret(data.url);
                // Store session ID for verification
                if (data.session_id) {
                    sessionStorage.setItem('checkout_session_id', data.session_id);
                }
                
                // Set payment status to redirected and then redirect to Stripe
                setPaymentStatus('redirected');
                window.location.href = data.url;
                return;
            }
            
            // For direct payment intents (Stripe Elements) - this shouldn't happen in the new flow
            // but we'll keep it for backward compatibility
            if (data.client_secret) {
                setPaymentError('This payment method is not supported. Please try again.');
                setIsLoadingIntent(false);
                return;
            }
            
            // If we get here, something unexpected happened
            setPaymentError('Unexpected response from server');
            setIsLoadingIntent(false);
            setPaymentStatus('failed');
        } catch (error) {
            console.error("Error creating payment intent:", error);
            setPaymentError(error.response?.data?.error || error.message || 'An unexpected error occurred');
            setIsLoadingIntent(false);
            setPaymentStatus('failed');
        }
    };
    
    // Function to check payment status
    const checkPaymentStatus = async (paymentIntentId) => {
        try {
            
            let effectivePaymentIntentId = paymentIntentId;
            let sessionData = null;
            let sessionAccountUuid = null;
            let sessionAuditUuid = null;
            
            // First determine if this is a checkout session ID or payment intent ID
            const isCheckoutSession = paymentIntentId && paymentIntentId.startsWith('cs_');
            const isPaymentIntent = paymentIntentId && paymentIntentId.startsWith('pi_');
            
            // If it's a checkout session, get the payment intent from it
            if (isCheckoutSession) {
                try {
                    const sessionResponse = await axiosInstance.get(`/api/checkout-session/${paymentIntentId}/`);
                    sessionData = sessionResponse.data;
                    
                    if (!sessionData.payment_intent) {
                        console.error("No payment intent found in session data");
                        return null;
                    }
                    
                    // Use the payment intent from the session
                    effectivePaymentIntentId = sessionData.payment_intent;
                    
                    // Extract metadata
                    sessionAccountUuid = sessionData?.metadata?.account_uuid;
                    sessionAuditUuid = sessionData?.metadata?.audit_uuid;
                } catch (err) {
                    console.error(`Could not retrieve session data for ${paymentIntentId}:`, err);
                    return null;
                }
            } 
            // If it's already a payment intent, try to get any associated session data
            else if (isPaymentIntent) {
                try {
                    // Try to get session data based on payment intent
                    const sessionResponse = await axiosInstance.get(`api/payment-intent-session/${paymentIntentId}/`);
                    sessionData = sessionResponse.data;
                    
                    // Extract metadata
                    sessionAccountUuid = sessionData?.metadata?.account_uuid;
                    sessionAuditUuid = sessionData?.metadata?.audit_uuid;

                } catch (err) {
                    console.warn('Could not retrieve session data, will continue with direct payment verification:', err);
                }
            } else {
                console.error(`Invalid ID format: ${paymentIntentId}. Must start with 'pi_' or 'cs_'.`);
                return null;
            }
            
            // Use effective account UUID and audit UUID (from session or props)
            const effectiveAccountUuid = sessionAccountUuid || account_uuid;
            const effectiveAuditUuid = sessionAuditUuid || audit_uuid;
            
            // Verify the payment status 
            const verificationResponse = await axiosInstance.get(
                `api/verify_payment_status/${effectivePaymentIntentId}/?` +
                `account_uuid=${encodeURIComponent(effectiveAccountUuid || '')}&` +
                `audit_uuid=${encodeURIComponent(effectiveAuditUuid || '')}&` +
                `group_audit_uuid=${encodeURIComponent(groupAuditUuid || '')}&` +
                `is_group_audit=${isGroupAudit}`
            );
            
            // Get verification data
            const verificationData = verificationResponse.data;
            
            // Always include the payment_intent_id in the verification data
            if (!verificationData.payment_intent_id) {
                verificationData.payment_intent_id = effectivePaymentIntentId;
            }
            
            // If payment is verified as paid, track it
            if (verificationData.is_paid && verificationData.payment_intent_id) {
                markPaymentIntentAsProcessed(verificationData.payment_intent_id);
            }
            // If payment succeeded in Stripe but not yet marked as paid, try manual payment recording
            else if (verificationData.stripe_status === 'succeeded' && !verificationData.is_paid) {
                console.log('Payment succeeded in Stripe but not yet recorded as paid');
            }
            
            return verificationData;
        } catch (error) {
            console.error(`Error checking payment status for ${paymentIntentId}:`, error);
            return null;
        }
    };
    
    // Handles a successful payment
    const handlePaymentSuccess = async (paymentIntentId) => {
        try {
            // Check if this payment intent has already been processed
            if (hasProcessedPaymentIntent(paymentIntentId)) {
                console.log(`Payment intent ${paymentIntentId} already processed, skipping success handler`);
                return;
            }

            setPaymentStatus('succeeded');
            
            // Mark this payment intent as processed
            markPaymentIntentAsProcessed(paymentIntentId);
            
            // Trigger callback if provided
            if (successHandler) {
                successHandler({
                    transactionId: paymentIntentId,
                    status: 'succeeded',
                    amount: pricingDetails.total,
                    currency: pricingDetails.currency || 'eur'
                });
            }
            
            // Fetch payment details
            const verificationData = await checkPaymentStatus(paymentIntentId);
            
            // Update UI with payment details
            if (verificationData) {
                setVerificationData(verificationData);
            }
            
            // Close stepper if requested
            if (onClose) {
                onClose(false, true);
            }

            // Call external success handler if provided
            if (onPaymentSuccess) {
                onPaymentSuccess(paymentIntentId);
            }
        } catch (error) {
            console.error('Error in payment success handler:', error);
            handlePaymentError(error);
        }
    };
    
    // Function to trigger manual payment recording
    const triggerManualPayment = async (paymentIntentId, payingAccountUuid, auditUuid, verificationData) => {
        try {
            // Check if this payment intent has already been processed
            if (hasProcessedPaymentIntent(paymentIntentId)) {
                return { 
                    success: true, 
                    already_existed: true,
                    message: "Payment already processed" 
                };
            }
            
            console.log("Recording manual payment", { 
                paymentIntentId, 
                payingAccountUuid, 
                auditUuid,
                isGroupAudit
            });
            
            // Get session data to extract account_uuid and audit_uuid if available
            let sessionData = null;
            if (paymentIntentId) {
                try {
                    const sessionResponse = await axiosInstance.get(`api/payment-intent-session/${paymentIntentId}/`);
                    sessionData = sessionResponse.data;
                } catch (err) {
                    console.error("Failed to fetch session data:", err);
                }
            }
            
            // Extract session metadata if available
            const sessionAccountUuid = sessionData?.metadata?.account_uuid;
            const sessionAuditUuid = sessionData?.metadata?.audit_uuid;
            
            if (sessionAccountUuid && sessionAccountUuid !== payingAccountUuid) {
                console.log(`Using account_uuid ${sessionAccountUuid} from session metadata instead of ${payingAccountUuid}`);
            }
            
            // Construct payment data
            const paymentData = {
                payment_intent_id: paymentIntentId,
                account_uuid: sessionAccountUuid || payingAccountUuid,
                audit_uuid: sessionAuditUuid || auditUuid,
                is_group_audit: isGroupAudit || false,
                group_audit_uuid: groupAuditUuid || null,
                payment_amount: verificationData?.amount || pricingDetails.total,
                currency: verificationData?.currency || pricingDetails.currency || 'eur'
            };
            
            try {
                // Call the manual payment endpoint
                const response = await axiosInstance.post('api/manual_payment_success/', paymentData);
                
                // Mark this payment intent as processed
                markPaymentIntentAsProcessed(paymentIntentId);
                
                if (response.data.status === 'success') {
                    console.log("Manual payment recorded successfully");
                    return {
                        success: true,
                        response: response.data
                    };
                } else if (response.data.already_existed) {
                    console.log("Payment already recorded, proceeding as successful");
                    return {
                        success: true,
                        already_existed: true,
                        response: response.data
                    };
                } else {
                    console.error("Failed to record manual payment:", response.data.message);
                    return {
                        success: false,
                        message: response.data.message || "Unknown error",
                        response: response.data
                    };
                }
            } catch (error) {
                // If the API call fails because the payment was already recorded (error contains "already exists"),
                // treat it as a success
                if (error.response?.data?.already_existed) {
                    console.log("Payment already existed (from error response)");
                    return {
                        success: true,
                        already_existed: true,
                        from_error: true
                    };
                }
                
                console.error("Error calling manual payment API:", error);
                return {
                    success: false,
                    error: error.message || "Unknown error",
                    error_response: error.response?.data
                };
            }
        } catch (error) {
            console.error("Error in triggerManualPayment:", error);
            return {
                success: false,
                error: error.message || "Unknown error"
            };
        }
    };
    
    // Function to handle payment errors
    const handlePaymentError = (error) => {
        console.error('Payment error:', error);
        setPaymentError(error.message || 'An error occurred during payment processing');
        setPaymentStatus('failed');
    };
    
    // Render organization step content
    const renderOrganizationStep = () => {
        if (isLoadingOrgs) {
            return (
                <div className="cs-loading-container">
                    <ProgressSpinner style={{ width: '50px', height: '50px' }} />
                    <p>Loading organizations...</p>
                </div>
            );
        }
        
        return (
            <div className="cs-organization-content">
                {!showNewOrgForm && organizations.length > 0 && (
                    <div className="cs-existing-orgs">
                        <h3 className="cs-form-title">Select an organization for billing</h3>
                        
                        {organizations.map((org, index) => (
                            <div 
                                key={org.id || org.name || `org-${index}`} 
                                className={`cs-org-card ${selectedOrganization?.name === org.name ? 'cs-selected' : ''}`}
                                onClick={() => handleSelectOrganization(org)}
                            >
                                <div className="cs-org-card-header">
                                    <h4>{org.name}</h4>
                                    {selectedOrganization?.name === org.name && (
                                        <i className="pi pi-check-circle" style={{ color: '#32AFC3' }}></i>
                                    )}
                                </div>
                                <div className="cs-org-card-body">
                                    <div className="cs-org-item">{org.email}</div>
                                    {org.address_line1 && (
                                        <div className="cs-org-item">{org.address_line1}</div>
                                    )}
                                    {org.post_code && org.country && (
                                        <div className="cs-org-item">
                                            {org.post_code}, {org.country}
                                        </div>
                                    )}
                                </div>
                            </div>
                        ))}
                        
                        <div className="cs-new-org-button" onClick={() => setShowNewOrgForm(true)}>
                            <i className="pi pi-plus" style={{ marginRight: '8px' }}></i>
                            Add new organization
                        </div>
                    </div>
                )}
                
                {(showNewOrgForm || organizations.length === 0) && (
                    <div className="cs-new-org-form">
                        <h3 className="cs-form-title">{organizations.length === 0 ? 'Create an organization for billing' : 'Add new organization'}</h3>
                        
                        {orgCreationError && (
                            <Message 
                                severity="error" 
                                text={orgCreationError} 
                                style={{ width: '100%', marginBottom: '15px' }}
                            />
                        )}
                        
                        <div className="cs-organization-form cs-card-container">
                            <div className="cs-form-row">
                                <div className="cs-field">
                                    <label htmlFor="name" className="cs-required-field">Organization Name</label>
                                    <InputText
                                        id="name"
                                        name="name"
                                        value={newOrg.name}
                                        onChange={handleOrgInputChange}
                                        onBlur={handleOrgInputChange}
                                        className={formErrors.name ? 'p-invalid' : ''}
                                        placeholder="Enter organization name"
                                        style={{ width: '100%' }}
                                    />
                                    {formErrors.name && <small className="cs-field-error">{formErrors.name}</small>}
                                </div>
                                
                                <div className="cs-field">
                                    <label htmlFor="email" className="cs-required-field">Email</label>
                                    <InputText
                                        id="email"
                                        name="email"
                                        value={newOrg.email}
                                        onChange={handleOrgInputChange}
                                        onBlur={handleOrgInputChange}
                                        className={formErrors.email ? 'p-invalid' : ''}
                                        placeholder="someone@example.com"
                                        style={{ width: '100%' }}
                                    />
                                    {formErrors.email && <small className="cs-field-error">{formErrors.email}</small>}
                                </div>
                            </div>
                            
                            <div className="cs-form-row">
                                <div className="cs-field">
                                    <label htmlFor="domain">Website</label>
                                    <InputText
                                        id="domain"
                                        name="domain"
                                        value={newOrg.domain}
                                        onChange={handleOrgInputChange}
                                        onBlur={handleOrgInputChange}
                                        className={formErrors.domain ? 'p-invalid' : ''}
                                        placeholder="company.com or https://company.com"
                                        style={{ width: '100%' }}
                                    />
                                    {formErrors.domain && <small className="cs-field-error">{formErrors.domain}</small>}
                                </div>
                                
                                <div className="cs-field">
                                    <label htmlFor="phone">Phone</label>
                                    <InputText
                                        id="phone"
                                        name="phone"
                                        value={newOrg.phone}
                                        onChange={handleOrgInputChange}
                                        onBlur={handleOrgInputChange}
                                        className={formErrors.phone ? 'p-invalid' : ''}
                                        placeholder="+1 (123) 456-7890"
                                        style={{ width: '100%' }}
                                    />
                                    {formErrors.phone && <small className="cs-field-error">{formErrors.phone}</small>}
                                </div>
                            </div>
                            
                            <div className="cs-form-row">
                                <div className="cs-field">
                                    <label htmlFor="address_line1">Address</label>
                                    <InputText
                                        id="address_line1"
                                        name="address_line1"
                                        value={newOrg.address_line1}
                                        onChange={handleOrgInputChange}
                                        onBlur={handleOrgInputChange}
                                        className={formErrors.address_line1 ? 'p-invalid' : ''}
                                        placeholder="Street address"
                                        style={{ width: '100%' }}
                                    />
                                    {formErrors.address_line1 && <small className="cs-field-error">{formErrors.address_line1}</small>}
                                </div>
                                
                                <div className="cs-field">
                                    <label htmlFor="address_line2">Address Line 2</label>
                                    <InputText
                                        id="address_line2"
                                        name="address_line2"
                                        value={newOrg.address_line2}
                                        onChange={handleOrgInputChange}
                                        onBlur={handleOrgInputChange}
                                        className={formErrors.address_line2 ? 'p-invalid' : ''}
                                        placeholder="Apartment, suite, unit, etc. (optional)"
                                        style={{ width: '100%' }}
                                    />
                                    {formErrors.address_line2 && <small className="cs-field-error">{formErrors.address_line2}</small>}
                                </div>
                            </div>
                            
                            <div className="cs-form-row">
                                <div className="cs-field">
                                    <label htmlFor="post_code">Postal Code</label>
                                    <InputText
                                        id="post_code"
                                        name="post_code"
                                        value={newOrg.post_code}
                                        onChange={handleOrgInputChange}
                                        onBlur={handleOrgInputChange}
                                        className={formErrors.post_code ? 'p-invalid' : ''}
                                        placeholder="ZIP / Postal code"
                                        style={{ width: '100%' }}
                                    />
                                    {formErrors.post_code && <small className="cs-field-error">{formErrors.post_code}</small>}
                                </div>
                                
                                <div className="cs-field">
                                    <label htmlFor="country" className="cs-required-field">Country</label>
                                    <Dropdown
                                        id="country"
                                        name="country"
                                        value={newOrg.country}
                                        options={countries}
                                        onChange={(e) => {
                                            // Stop propagation to prevent triggering stepper change
                                            e.originalEvent && e.originalEvent.stopPropagation();
                                            handleOrgInputChange(e);
                                        }}
                                        onBlur={(e) => {
                                            // Stop propagation to prevent triggering stepper change
                                            if (e) e.stopPropagation?.();
                                            handleOrgInputChange(e);
                                        }}
                                        placeholder="Select a country"
                                        className={formErrors.country ? 'p-invalid' : ''}
                                        style={{ width: '100%' }}
                                    />
                                    {formErrors.country && <small className="cs-field-error">{formErrors.country}</small>}
                                </div>
                            </div>
                            
                            <div className="cs-form-row">
                                <div className="cs-field">
                                    <label htmlFor="tax_num">Tax ID / VAT Number</label>
                                    <InputText
                                        id="tax_num"
                                        name="tax_num"
                                        value={newOrg.tax_num}
                                        onChange={handleOrgInputChange}
                                        onBlur={handleOrgInputChange}
                                        className={formErrors.tax_num ? 'p-invalid' : ''}
                                        placeholder="e.g. DE123456789"
                                        style={{ width: '100%' }}
                                    />
                                    {formErrors.tax_num && <small className="cs-field-error">{formErrors.tax_num}</small>}
                                </div>
                            </div>
                        </div>
                    </div>
                )}
                
                {/* Form action buttons */}
                <div className="cs-form-actions">
                    <Button 
                        label="Cancel" 
                        className="p-button-text" 
                        onClick={onCancel} 
                    />
                    
                    {organizations.length > 0 && showNewOrgForm && (
                        <Button
                            label="Back to existing organizations"
                            icon="pi pi-arrow-left"
                            className="p-button-text"
                            onClick={() => setShowNewOrgForm(false)}
                        />
                    )}
                    
                    {showNewOrgForm ? (
                        <Button 
                            label="Create & Continue" 
                            icon="pi pi-arrow-right" 
                            iconPos="right" 
                            onClick={saveOrganization}
                            loading={isSavingOrg}
                            disabled={isSavingOrg}
                        />
                    ) : (
                        <Button
                            label="Continue to Payment"
                            icon="pi pi-arrow-right" 
                            iconPos="right" 
                            onClick={handleContinueToPayment} 
                            disabled={!selectedOrganization}
                            className="p-button-primary cs-btn-next"
                        />
                    )}
                </div>
            </div>
        );
    };
    
    // Render payment step
    const renderPaymentStep = () => {
        // If payment succeeded, show success message
        if (paymentStatus === 'succeeded') {
            return (
                <div className="cs-payment-success">
                    <Message 
                        severity="success" 
                        text="Payment processed successfully! Your audit is now ready to begin." 
                        style={{ width: '100%', marginBottom: '20px' }}
                    />
                    <div className="cs-payment-success-details">
                        <p>Thank you for your payment. You can now proceed with your code audit.</p>
                        <Button
                            label="Continue with Audit"
                            className="p-button-success"
                            onClick={() => onClose(false, true)}
                        />
                    </div>
                </div>
            );
        }
        
        // If payment was cancelled, show cancelled message
        if (paymentStatus === 'cancelled') {
            return (
                <div className="cs-payment-error">
                    <Message 
                        severity="info" 
                        text="Your payment was cancelled." 
                        style={{ width: '100%', marginBottom: '20px', background: '#e8363661' }}
                    />
                    <div className="cs-payment-options">
                        <p>You can try again when you're ready or go back to update your organization details.</p>
                        <div className="cs-form-actions">
                            <Button 
                                label="Back" 
                                icon="pi pi-arrow-left" 
                                className="p-button-text" 
                                onClick={() => updateActiveIndex(0)}
                            />
                            <Button
                                label="Try Again"
                                icon="pi pi-refresh"
                                className="p-button-primary"
                                onClick={createPaymentIntent}
                            />
                        </div>
                    </div>
                </div>
            );
        }
        
        // Don't proceed if we're returning from Stripe already and success state
        const isStripeReturn = initialSessionId && initialPaymentSuccess;
        
        return (
            <div className="cs-payment-step">
                {/* Order Summary Card */}
                <Card className="cs-order-summary">
                    <div className="cs-order-details">
                        <div className="cs-order-row">
                            <span>Organization: <b>{selectedOrganization?.name}</b></span>
                        </div>
                        <div className="cs-order-row" style={{ marginTop: '14px' }}>
                            <span>Your order encompasses <span className="cs-highlight" style={{ fontWeight: 'bold' }}>{formatNumber(orderDetails?.payableLines || 0)}</span> lines of code for this code analysis.</span>
                        </div>
                        
                        {/* Show/Hide Price Breakdown toggle */}
                        <div className="cs-order-row cs-breakdown-toggle" onClick={() => setShowPriceBreakdown(!showPriceBreakdown)}>
                            <span>
                                <i className={`pi ${showPriceBreakdown ? 'pi-chevron-down' : 'pi-chevron-right'}`} style={{ marginRight: '5px' }}></i>
                                {showPriceBreakdown ? 'Hide price breakdown' : 'Show price breakdown'}
                            </span>
                        </div>
                        
                        {/* Price Breakdown Details */}
                        {showPriceBreakdown && pricingDetails && (
                            <PriceBreakdown
                                breakdown={pricingDetails.breakdown || []}
                                subtotal={pricingDetails.subtotal || 0}
                                vatRate={pricingDetails.vatRate || 0}
                                total={pricingDetails.total || 0}
                                totalWithTax={pricingDetails.totalWithTax || pricingDetails.total || 0}
                                currency={pricingDetails.currency || 'EUR'}
                                payableLines={orderDetails.payableLines || 0}
                            />
                        )}
                        
                        {/* Currency disclaimer */}
                        <div className="cs-order-row" style={{ fontSize: '0.8rem', color: '#666' }}>
                            {pricingDetails?.vatRate > 0 
                                ? `All prices include VAT (${pricingDetails.vatRate}%) and are billed in ${pricingDetails.currency || 'EUR'}.`
                                : `All prices are billed in ${pricingDetails?.currency || 'EUR'}.`
                            }
                        </div>
                        <div className="cs-order-row cs-total">
                            <span>Total Amount:</span>
                            <span>€{(pricingDetails?.totalWithTax || pricingDetails?.total || 0).toFixed(2)}</span>
                        </div>
                    </div>
                </Card>
                
                {/* Payment Form */}
                {isLoadingIntent ? (
                    <div className="cs-payment-loading">
                        <ProgressSpinner style={{ width: '50px', height: '50px' }} />
                        <p>Initializing payment...</p>
                    </div>
                ) : paymentError ? (
                    <div className="cs-payment-error">
                        <Message 
                            severity="error" 
                            text={paymentError} 
                            style={{ width: '100%', marginBottom: '20px' }}
                        />
                        <div className="cs-payment-actions">
                            <Button 
                                label="Try Again" 
                                icon="pi pi-refresh" 
                                onClick={createPaymentIntent}
                                className="p-button-primary"
                            />
                        </div>
                    </div>
                ) : paymentStatus === 'redirected' ? (
                    <div className="cs-payment-redirecting">
                        <ProgressSpinner style={{ width: '50px', height: '50px' }} />
                        <p>Redirecting to secure payment page...</p>
                    </div>
                ) : (
                    <div className="cs-payment-options">
                        <Button
                            label="Pay Now via Stripe" 
                            icon="pi pi-credit-card"
                            className="p-button-success p-button-lg"
                            onClick={createPaymentIntent}
                            disabled={isStripeReturn} // Disable if returning from Stripe
                            style={{ width: '100%', marginBottom: '20px', padding: '1rem' }}
                        />
                        <div className="cs-payment-security-note">
                            <i className="pi pi-lock" style={{ marginRight: '8px' }}></i>
                            <span>Secure payment processing provided by Stripe. Your payment information is never stored on our servers.</span>
                        </div>
                    </div>
                )}
                
                {/* Back button */}
                <div className="cs-form-actions">
                    {paymentStatus !== 'succeeded' && paymentStatus !== 'redirected' && !isLoadingIntent && (
                        <Button 
                            label="Back" 
                            icon="pi pi-arrow-left" 
                            className="p-button-text" 
                            onClick={() => updateActiveIndex(0)}
                        />
                    )}
                </div>
            </div>
        );
    };
    
    // Function to continue to payment step after selecting or creating an organization
    const handleContinueToPayment = () => {
        if (!selectedOrganization) {
            setFormErrors(prev => ({ ...prev, organization: 'Please select or create an organization' }));
            return;
        }
        // Set pricing details if needed
        if (orderDetails.payableLines && !pricingDetails.total) {
            // Use our new pricing utility instead of manual calculation
            const calculatedPricing = calculatePrice(orderDetails.payableLines);
            
            setPricingDetails(prev => ({
                ...prev,
                ...calculatedPricing
            }));
        }
        updateActiveIndex(1);
    };
    
    // Add this at the beginning of the component to ensure payment tab is active when returning from Stripe
    useEffect(() => {
        // Force the payment tab to be active when returning from Stripe
        // This is a critical effect that runs once when component mounts
        if (isReturnFromStripe) {
            
            // Set a temporary organization if needed
            if (!selectedOrganization && orderDetails) {
                setSelectedOrganization({
                    name: 'Payment Organization',
                    id: orderDetails.account_uuid || account_uuid || 'temp-org-id',
                    account_uuid: orderDetails.account_uuid || account_uuid || 'temp-org-id'
                });
            }
            
            // Force the payment tab to be active - with a slight delay to ensure it happens after render
            setTimeout(() => {
                updateActiveIndex(1);
                
                // Scroll to payment section if needed
                if (stepperRef.current) {
                    stepperRef.current.scrollIntoView({ behavior: 'smooth' });
                }
            }, 50);
        }
    }, []);
    
    // Final safeguard to ensure correct tab is displayed
    useEffect(() => {
        if (isReturnFromStripe) {
            // First apply the effect immediately
            updateActiveIndex(1);
            
            // Then set a small timeout as a backup to ensure the UI has updated properly
            const timeoutId = setTimeout(() => {
                if (currentActiveIndex !== 1) {
                    updateActiveIndex(1);
                    
                    // Find the payment section element and scroll to it
                    const paymentSection = document.querySelector('.cs-payment-section');
                    if (paymentSection) {
                        paymentSection.scrollIntoView({ behavior: 'smooth' });
                    }
                }
            }, 200);
            
            // Cleanup timeout on unmount
            return () => clearTimeout(timeoutId);
        }
    }, [isReturnFromStripe, currentActiveIndex]);
    
    // Add this function before the render method to ensure there's always a breakdown available
    // Ensure pricing details include a proper breakdown
    const ensurePricingBreakdown = () => {
        // If pricing details exist but no breakdown, or empty breakdown
        if (pricingDetails && (!pricingDetails.breakdown || pricingDetails.breakdown.length === 0)) {
            // Generate breakdown on the fly if we have payable lines
            if (orderDetails.payableLines > 0) {
                const freshPricing = calculatePrice(orderDetails.payableLines);
                if (freshPricing.breakdown && freshPricing.breakdown.length > 0) {
                    setPricingDetails(prev => ({
                        ...prev,
                        ...freshPricing
                    }));
                }
            }
        }
    };

    // Call this in useEffect to ensure breakdown is available
    useEffect(() => {
        ensurePricingBreakdown();
    }, [pricingDetails, showPriceBreakdown]);

    
    // Function to add a payment intent to the processed set
    const markPaymentIntentAsProcessed = (paymentIntentId) => {
        if (!paymentIntentId) {
            console.warn('[Payment Tracking] Attempted to mark undefined payment intent as processed');
            return false;
        }
        
        // Skip checkout session IDs - these should be resolved to payment intents first
        if (paymentIntentId.startsWith('cs_')) {
            console.warn(`[Payment Tracking] Received checkout session ID ${paymentIntentId} instead of payment intent ID. Use handleCheckoutSession first.`);
            return false;
        }
        
        if (processedPaymentIntents.has(paymentIntentId)) {
            return false;
        }
        
        // Store in localStorage for persistence across page reloads
        try {
            // Get existing processed intents
            const storedIntents = localStorage.getItem('processed_payment_intents');
            const storedSet = storedIntents ? new Set(JSON.parse(storedIntents)) : new Set();
            
            // Add new intent
            storedSet.add(paymentIntentId);
            
            // Save back to localStorage
            localStorage.setItem(
                'processed_payment_intents', 
                JSON.stringify(Array.from(storedSet))
            );
        } catch (err) {
            console.error('Error storing processed payment intent in localStorage:', err);
        }
        
        // Update without causing rerenders if the value is the same
        setProcessedPaymentIntents(prev => {
            // Only update if not already present
            if (prev.has(paymentIntentId)) {
                return prev;
            }
            
            // Create a new set with the added item
            const newSet = new Set(prev);
            newSet.add(paymentIntentId);
            return newSet;
        });
        
        return true;
    };
    
    // Function to check if a payment intent has been processed
    const hasProcessedPaymentIntent = (paymentIntentId) => {
        if (!paymentIntentId) {
            console.warn('[Payment Tracking] Attempted to check undefined payment intent');
            return false;
        }
        
        // Handle checkout session IDs
        if (paymentIntentId.startsWith('cs_')) {
            console.warn(`[Payment Tracking] Received checkout session ID ${paymentIntentId} instead of payment intent ID. This may not work as expected.`);
            // Allow the check to proceed - it will return false for checkout sessions
        }
        
        // First check local state
        if (processedPaymentIntents.has(paymentIntentId)) {
            return true;
        }
        
        // Then check localStorage for persistence across page reloads
        try {
            const storedIntents = localStorage.getItem('processed_payment_intents');
            if (storedIntents) {
                const storedSet = new Set(JSON.parse(storedIntents));
                if (storedSet.has(paymentIntentId)) {
                    
                    // Update in-memory set
                    setProcessedPaymentIntents(prev => {
                        const newSet = new Set(prev);
                        newSet.add(paymentIntentId);
                        return newSet;
                    });
                    
                    return true;
                }
            }
        } catch (err) {
            console.error('Error checking processed payment intent in localStorage:', err);
        }
        
        const isProcessed = false;
        return isProcessed;
    };

    // Function to specifically handle checkout sessions
    const handleCheckoutSession = async (sessionId) => {
        if (!sessionId) {
            return {
                success: false,
                error: 'No session ID provided'
            };
        }
        
        try {
            
            // Skip if this session has already been processed
            const sessionKey = `processed_session_${sessionId}`;
            const isProcessed = sessionStorage.getItem(sessionKey) === 'true';
            
            if (isProcessed) {
                return {
                    success: true,
                    already_processed: true
                };
            }
            
            // Mark session as being processed
            sessionStorage.setItem(sessionKey, 'true');
            
            // Get session data
            const sessionResponse = await axiosInstance.get(`/api/checkout-session/${sessionId}/`);
            const sessionData = sessionResponse.data;
            
            if (!sessionData || !sessionData.payment_intent) {
                console.error('Invalid session data or missing payment intent');
                return {
                    success: false,
                    error: 'Invalid session data'
                };
            }
            
            const paymentIntentId = sessionData.payment_intent;
            
            // Check if we've already processed this payment intent
            if (hasProcessedPaymentIntent(paymentIntentId)) {
                return {
                    success: true,
                    payment_intent_id: paymentIntentId,
                    already_processed: true
                };
            }
            
            // Verify the payment intent
            const verificationResult = await verifyPayment(paymentIntentId);
            
            return {
                success: verificationResult?.status === 'succeeded',
                payment_intent_id: paymentIntentId,
                verification_result: verificationResult
            };
            
        } catch (error) {
            console.error(`Error processing checkout session ${sessionId}:`, error);
            return {
                success: false,
                error: error.message
            };
        }
    };
    
    // Fix useEffect Stripe callback handler
    useEffect(() => {
        // Skip if useEffect already ran
        const callbackKey = 'stripe_callback_processed';
        if (sessionStorage.getItem(callbackKey) === 'true') {
            return;
        }
        
        // Mark callback as processed
        sessionStorage.setItem(callbackKey, 'true');
        
        const handleStripeCallback = async () => {
            // Only process if we're in a state where we expect a callback
            if (!isReturnFromStripe) return;
            
            // Get URL parameters
            const urlParams = new URLSearchParams(window.location.search);
            const sessionId = urlParams.get('session_id');
            const success = urlParams.get('success');
            const cancelled = urlParams.get('cancelled');
            
            // Clean URL to prevent re-processing
            if (window.history.replaceState) {
                const cleanUrl = window.location.pathname;
                window.history.replaceState({}, document.title, cleanUrl);
            }
            
            // Skip if we've already processed this
            if (paymentStatus === 'succeeded') {
                return;
            }
            
            try {
                // If cancelled, update status and return
                if (cancelled === 'true') {
                    setPaymentStatus('cancelled');
                    return;
                }
                
                // If success flag is set, get the session details
                if (success === 'true' && sessionId) {
                    await handleCheckoutSession(sessionId);
                }
            } catch (error) {
                console.error('Error handling Stripe callback:', error);
            }
        };
        
        // Execute handler once
        handleStripeCallback();
        
    }, [isReturnFromStripe]); // Only run when isReturnFromStripe changes
    
    // Initialize processed payment intents from localStorage
    useEffect(() => {
        try {
            const storedIntents = localStorage.getItem('processed_payment_intents');
            if (storedIntents) {
                const storedArray = JSON.parse(storedIntents);
                if (Array.isArray(storedArray) && storedArray.length > 0) {
                    console.log(`Loading ${storedArray.length} processed payment intents from localStorage`);
                    setProcessedPaymentIntents(new Set(storedArray));
                }
            }
        } catch (err) {
            console.error('Error loading processed payment intents from localStorage:', err);
        }
    }, []);

    // Make handlePaymentSuccess available to parent components
    useEffect(() => {
        if (onPaymentSuccess) {
            onPaymentSuccess.current = handlePaymentSuccess;
        }
    }, [onPaymentSuccess]);

    return (
        <div className="cs-checkout-container">
            <div className="cs-stepper">
            <div className="cs-stepper-header">
                <div className="cs-stepper-tabs">
                    <div 
                            className={`cs-stepper-tab ${currentActiveIndex === 0 ? 'cs-active' : ''} ${isReturnFromStripe ? 'cs-disabled' : ''}`} 
                            onClick={() => !isReturnFromStripe && currentActiveIndex !== 0 && handleStepChange(0)}
                            style={{ 
                                pointerEvents: isReturnFromStripe ? 'none' : 'auto',
                                opacity: isReturnFromStripe ? '0.5' : '1'
                            }}
                    >
                        <span className="cs-stepper-number">1</span>
                        <span className="cs-stepper-title">Organization</span>
                    </div>
                    <div 
                            className={`cs-stepper-tab ${currentActiveIndex === 1 ? 'cs-active' : ''} ${!selectedOrganization && !isReturnFromStripe ? 'cs-disabled' : ''}`}
                            onClick={() => (selectedOrganization || isReturnFromStripe) && handleStepChange(1)}
                    >
                        <span className="cs-stepper-number">2</span>
                        <span className="cs-stepper-title">Payment</span>
                    </div>
                </div>
            </div>
            
            <div className="cs-stepper-content">
                    {/* Always show payment step when returning from Stripe, otherwise show appropriate step */}
                    {isReturnFromStripe ? (
                        renderPaymentStep()
                    ) : (
                        <>
                            {currentActiveIndex === 0 && renderOrganizationStep()}
                            {currentActiveIndex === 1 && renderPaymentStep()}
                        </>
                    )}
            </div>
            </div>
        </div>
    );
};

export default CheckoutStepper;