import React, { useEffect } from 'react';
import { Dialog } from 'primereact/dialog';
import { ChevronDown } from 'lucide-react';
import { references } from '../data/scoreExplanations';
import './ScoreExplanationModal.css';

const formatExample = (example) => {
  return example
    .replace('Good:', '<span style="color: #2CB392; font-weight: bold; font-size: 0.85rem;">Good:</span>')
    .replace('Bad:', '<span style="color: #E83636; font-weight: bold; font-size: 0.85rem;">Bad:</span>');
};

const findReferences = (refNumbers) => {
  if (!refNumbers || !refNumbers.length) return [];
  return references
    .filter(ref => refNumbers.includes(ref.id))
    .sort((a, b) => a.id - b.id);
};

const ScoreExplanationModal = ({ 
  visible, 
  onHide, 
  title, 
  explanation, 
  examples, 
  businessContext,
  score,
  subpoints,
  isMajorPoint,
  references: metricReferences
}) => {
  useEffect(() => {
    // Console log removed
  }, [visible, title, isMajorPoint, subpoints, examples, metricReferences]);

  const modalReferences = findReferences(metricReferences);

  return (
    <Dialog 
      visible={visible} 
      onHide={onHide}
      header={title}
      className={`score-explanation-modal ${isMajorPoint ? 'major-point' : ''}`}
      style={{ width: '80vw' }}
      breakpoints={{ '960px': '75vw', '641px': '90vw' }}
    >
      <div className="modal-content">
        {/* Score display */}
        {score !== undefined && (
          <div className="score-explanation-score">
            <span className="score-explanation-label">Current Score:</span>
            <span className="score-explanation-value">{score}/100</span>
          </div>
        )}

        {/* Explanation Section */}
        <div className="score-explanation-section">
          <h3>Explanation</h3>
          <div className="score-explanation-text">
            {explanation}
          </div>
          
          {/* Subpoints for both major categories and individual metrics */}
          {subpoints && subpoints.length > 0 && (
            <div className="score-explanation-subpoints">
              <h4>{isMajorPoint ? 'Key Components' : 'Key Aspects'}:</h4>
              <div className="subpoints-grid">
                {subpoints.map((subpoint, index) => (
                  <div key={index} className="subpoint-item">
                    <span className="subpoint-name">{subpoint.name}</span>
                    <span className="subpoint-description">{subpoint.description}</span>
                  </div>
                ))}
              </div>
            </div>
          )}
          
          {/* Examples for individual metrics */}
          {!isMajorPoint && examples && examples.length > 0 && (
            <div className="score-explanation-examples">
              <h4>Examples:</h4>
              {examples.map((example, index) => (
                <div key={index} className="score-explanation-example">
                  <code dangerouslySetInnerHTML={{ __html: formatExample(example) }} />
                </div>
              ))}
            </div>
          )}
        </div>

        {/* Arrow Separator */}
        <div className="score-explanation-separator">
          <ChevronDown size={40} />
        </div>

        {/* Business Context Section */}
        <div className="score-explanation-section score-explanation-business">
          <h3>Business Impact</h3>
          <div className="score-explanation-text">
            {businessContext}
          </div>
        </div>

        {/* References Section - only show if there are references */}
        {modalReferences && modalReferences.length > 0 && (
          <>
            <div className="score-explanation-separator references-separator">
              <span className="separator-text">References</span>
            </div>
            <div className="score-explanation-section score-explanation-references">
              <div className="references-list">
                {modalReferences.map((ref) => (
                  <div key={ref.id} className="reference-item">
                    <span className="reference-number">[{ref.id}]</span>
                    <span className="reference-text">
                      {ref.authors} ({ref.year}). {ref.title}. <i style={{ fontSize: '0.8rem' }}>{ref.publication}</i>.{' '}
                      <a 
                        href={ref.url} 
                        target="_blank" 
                        rel="noopener noreferrer"
                        className="reference-link"
                      >
                        View Paper
                      </a>
                    </span>
                  </div>
                ))}
              </div>
            </div>
          </>
        )}
      </div>
    </Dialog>
  );
};

export default ScoreExplanationModal; 