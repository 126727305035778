import React from 'react';
import './FormattedText.css';

const FormattedText = ({ text, className = '' }) => {
  if (!text) return null;

  const lines = text.split('\n');
  
  return (
    <div className={`formatted-text ${className}`}>
      {lines.map((line, index) => {
        // Determine line type
        const isHeading1 = line.trim().startsWith('# ');
        const isHeading2 = line.trim().startsWith('## ');
        const isHeading3 = line.trim().startsWith('### ');
        const isSection = !isHeading1 && !isHeading2 && !isHeading3 && 
                         line.includes(':') && 
                         !line.startsWith('-') && 
                         !line.startsWith('*') && 
                         line.split(' ').length <= 10; // Only treat as section if it's short
        const isBullet = line.trim().startsWith('-');
        const isSubBullet = line.trim().startsWith('*');
        
        // Remove bullet point characters and heading markers
        let cleanLine = line
          .replace(/^-\s*/, '')
          .replace(/^\*\s*/, '')
          .replace(/^#\s+/, '')
          .replace(/^##\s+/, '')
          .replace(/^###\s+/, '')
          .trim();

        // Transform **text** into <strong>text</strong>
        const formattedLine = cleanLine.split(/(\*\*.*?\*\*)/).map((part, i) => {
          if (part.startsWith('**') && part.endsWith('**')) {
            return <strong key={i}>{part.slice(2, -2)}</strong>;
          }
          return part;
        });
        
        // Choose the appropriate HTML element based on heading level
        if (isHeading1) {
          return <h1 key={index} className="formatted-text-h1">{formattedLine}</h1>;
        } else if (isHeading2) {
          return <h2 key={index} className="formatted-text-h2">{formattedLine}</h2>;
        } else if (isHeading3) {
          return <h3 key={index} className="formatted-text-h3">{formattedLine}</h3>;
        } else {
          return (
            <p 
              key={index}
              className={`
                formatted-text-line
                ${isSection ? 'section-line' : ''}
                ${isBullet ? 'bullet-line' : ''}
                ${isSubBullet ? 'sub-bullet-line' : ''}
              `}
            >
              {formattedLine}
            </p>
          );
        }
      })}
    </div>
  );
};

export default FormattedText; 