import React from 'react';
import { formatNumber, formatPrice, formatRate, PRICING_TIERS } from '../../utils/pricingUtils';
import './PriceBreakdown.css';

/**
 * PriceBreakdown component for displaying detailed pricing information
 * @param {Object} props - Component props
 * @param {Array} props.breakdown - Pricing breakdown array
 * @param {number} props.subtotal - Subtotal amount (before tax)
 * @param {number} props.vatRate - VAT rate percentage
 * @param {number} props.total - Total amount
 * @param {number} props.totalWithTax - Total amount with tax
 * @param {string} props.currency - Currency code (default 'EUR')
 * @param {number} props.payableLines - Total payable lines of code
 */
const PriceBreakdown = ({ 
    breakdown = [], 
    subtotal = 0, 
    vatRate = 0, 
    total = 0, 
    totalWithTax = 0,
    currency = 'EUR',
    payableLines = 0
}) => {
    // Currency symbol mapping
    const currencySymbols = {
        'EUR': '€',
        'USD': '$',
        'GBP': '£'
    };
    
    // Get currency symbol or default to code
    const currencySymbol = currencySymbols[currency] || currency;
    
    // Calculate tier progress for visual representation
    const getTierPercentage = () => {
        // Handle edge case with no payable lines
        if (!payableLines || payableLines <= 0) {
            return {
                tier: 'Base',
                percentage: 0,
                color: getTierColor('Base')
            };
        }
        
        // Find which tier the payable lines fall into
        let currentTier = PRICING_TIERS[0];
        
        for (const tier of PRICING_TIERS) {
            if (payableLines <= tier.maxLines) {
                currentTier = tier;
                break;
            }
        }
        
        // Calculate percentage within the current tier
        const prevTierMaxLines = PRICING_TIERS[PRICING_TIERS.indexOf(currentTier) - 1]?.maxLines || 0;
        const linesInCurrentTier = payableLines - prevTierMaxLines;
        const maxLinesInTier = currentTier.maxLines - prevTierMaxLines;
        
        // If in Base tier, just use simple percentage since prevTierMaxLines is 0
        let percentage = 0;
        if (currentTier.name === 'Base') {
            percentage = Math.min(100, (payableLines / currentTier.maxLines) * 100);
        } else {
            // Calculate percentage (capped at 100%)
            percentage = Math.min(100, (linesInCurrentTier / maxLinesInTier) * 100);
        }
        
        return {
            tier: currentTier.name,
            percentage: percentage.toFixed(1),
            color: getTierColor(currentTier.name)
        };
    };
    
    // Get color based on tier
    const getTierColor = (tierName) => {
        switch (tierName) {
            case 'Base':
                return '#4CAF50'; // Green
            case 'Start-up':
                return '#2196F3'; // Blue
            case 'Growth':
                return '#FF9800'; // Orange
            case 'Enterprise':
                return '#9C27B0'; // Purple
            default:
                return '#32AFC3'; // Default teal
        }
    };
    
    // Get tier info for visual display
    const tierProgress = getTierPercentage();
    
    return (
        <div className="pb-container">
            <div className="pb-header">
                <h4>Price Breakdown</h4>
                <div className="pb-total-lines">
                    <span>Total Lines of Code:</span> 
                    <span className="pb-value">{formatNumber(payableLines)}</span>
                </div>
                
                {/* Add visual tier representation */}
                <div className="pb-tier-progress">
                    <div className="pb-tier-label">
                        <span>Current tier: <strong>{tierProgress.tier}</strong></span>
                        <span>{tierProgress.percentage}% of tier</span>
                    </div>
                    <div className="pb-progress-bar">
                        <div 
                            className="pb-progress-fill" 
                            style={{ 
                                width: `${tierProgress.percentage}%`,
                                backgroundColor: tierProgress.color 
                            }}
                        ></div>
                    </div>
                </div>
            </div>
            
            <div className="pb-breakdown-items">
                {breakdown.map((item, index) => (
                    <div key={`breakdown-${index}`} className="pb-item">
                        <div className="pb-item-header">
                            <div className="pb-tier-name-container">
                                <span className="pb-tier-name">{item.tier} tier</span>
                                {item.tier === 'Base' ? 
                                    <span className="pb-tier-descriptor">(First {formatNumber(PRICING_TIERS[0].maxLines)} lines)</span> : 
                                    <span className="pb-tier-descriptor">(Lines {formatNumber(item.startLine || 0)} to {formatNumber(item.endLine || PRICING_TIERS.find(t => t.name === item.tier)?.maxLines || 0)})</span>
                                }
                            </div>
                            <span className="pb-tier-amount">{currencySymbol}{formatPrice(item.amount)}</span>
                        </div>
                        {item.tier !== 'Base' && (
                            <div className="pb-item-details">
                                <div className="pb-item-description">
                                    <span className="pb-tier-used">
                                        {formatNumber(item.lines)} lines at {currencySymbol}{formatRate(item.rate)} per line
                                    </span>
                                </div>
                            </div>
                        )}
                    </div>
                ))}
            </div>
            
            <div className="pb-summary">
                {subtotal > 0 && (
                    <div className="pb-summary-row pb-subtotal">
                        <span>Subtotal (excl. tax)</span>
                        <span>{currencySymbol}{formatPrice(subtotal)}</span>
                    </div>
                )}
                
                {vatRate > 0 && (
                    <div className="pb-summary-row pb-tax">
                        <span>VAT ({vatRate}%)</span>
                        <span>{currencySymbol}{formatPrice(totalWithTax - subtotal)}</span>
                    </div>
                )}
                
                <div className="pb-summary-row pb-total">
                    <span>Total Amount</span>
                    <span>{currencySymbol}{formatPrice(vatRate > 0 ? totalWithTax : total)}</span>
                </div>
            </div>

            <div className="pb-disclaimer">
                {vatRate > 0 
                    ? `All prices include VAT (${vatRate}%) and are billed in ${currency}.`
                    : `All prices are billed in ${currency}.`
                }
            </div>
        </div>
    );
};

export default PriceBreakdown; 