import React from 'react';
import './Terms.css';

const Privacy = () => {
  return (
    <div className="terms-page">
      <header className="terms-header">
        <h1 className="terms-logo">CodeDD</h1>
        <h2 className="terms-title">Privacy Policy</h2>
        <p className="terms-date">Last updated August 26, 2024</p>
      </header>
      
      <div className="terms-container">
        <h3 className="terms-section-title">1. Introduction</h3>
        <p className="terms-paragraph">At CodeDD, we take your privacy seriously. This Privacy Policy explains how we collect, use, disclose, and safeguard your information when you use our software-as-a-service application, related applications, software, products, and services (collectively, the "Service").</p>
        <p className="terms-paragraph">This Privacy Policy is designed to help you understand our practices regarding your personal data in line with the General Data Protection Regulation (GDPR) and other applicable data protection laws.</p>
        <p className="terms-paragraph">By accessing or using our Service, you acknowledge that you have read, understood, and agree to be bound by the terms of this Privacy Policy. If you do not agree with our policies and practices, please do not use our Service.</p>

        <h3 className="terms-section-title">2. Data Controller Information</h3>
        <p className="terms-paragraph">CodeDD e.U. (hereinafter "CodeDD", "we", "us", or "our") is the data controller responsible for your personal data.</p>
        <p className="terms-paragraph">Contact information:</p>
        <ul className="terms-list">
          <li>Legal entity: CodeDD e.U.</li>
          <li>Address: Wötzling 2, 3233 Kilb, Austria</li>
          <li>Email: <a href="mailto:info@codedd.ai">info@codedd.ai</a></li>
        </ul>

        <h3 className="terms-section-title">3. What Information We Collect</h3>
        
        <h4 className="terms-subsection-title">3.1. Personal Data</h4>
        <p className="terms-paragraph">We may collect the following categories of personal data from you:</p>
        <ul className="terms-list">
          <li><strong>Account Information:</strong> When you register for an Account, we collect your name, email address, and other contact information.</li>
          <li><strong>Billing Information:</strong> If you purchase a paid Organization, we collect payment information, billing address, and transaction details.</li>
          <li><strong>Profile Information:</strong> Information you provide in your user profile, such as profile picture, job title, or organization.</li>
          <li><strong>User Content:</strong> Information you submit to our Service, including source code for analysis, comments, and feedback.</li>
          <li><strong>Communication Data:</strong> Records of your interactions with us, including support requests and feedback.</li>
        </ul>

        <h4 className="terms-subsection-title">3.2. Usage Data</h4>
        <p className="terms-paragraph">We automatically collect certain information when you visit, use, or navigate our Service. This information does not reveal your specific identity but may include:</p>
        <ul className="terms-list">
          <li>Device and connection information (such as IP address, browser type, operating system)</li>
          <li>Usage details (such as pages visited, features used, time spent on the Service)</li>
          <li>Performance and error data</li>
          <li>Referral sources</li>
        </ul>

        <h4 className="terms-subsection-title">3.3. Technical Data About Your Projects</h4>
        <p className="terms-paragraph">When you use our Service for code analysis, we collect:</p>
        <ul className="terms-list">
          <li>Source code you submit for analysis</li>
          <li>Code repositories structure</li>
          <li>Programming languages and frameworks used</li>
          <li>Meta-information about your projects</li>
        </ul>

        <h3 className="terms-section-title">4. How We Collect Information</h3>
        <p className="terms-paragraph">We collect information through:</p>
        <ul className="terms-list">
          <li><strong>Direct Interactions:</strong> Information you provide when creating an account, submitting code for analysis, contacting support, or using our Service.</li>
          <li><strong>Automated Technologies:</strong> As you navigate through our Service, we may use cookies, server logs, and similar technologies to collect usage data.</li>
          <li><strong>Third Parties:</strong> We may receive information about you from third parties such as payment processors or authentication services.</li>
        </ul>

        <h3 className="terms-section-title">5. How We Use Your Information</h3>
        <p className="terms-paragraph">We use the information we collect for various purposes, including:</p>

        <h4 className="terms-subsection-title">5.1. Provision of Service</h4>
        <ul className="terms-list">
          <li>To provide and maintain our Service</li>
          <li>To process transactions and manage your Account</li>
          <li>To perform code analysis and generate reports</li>
          <li>To respond to your inquiries and provide customer support</li>
        </ul>

        <h4 className="terms-subsection-title">5.2. Improvement and Development</h4>
        <ul className="terms-list">
          <li>To improve and optimize our Service</li>
          <li>To develop new products, services, and features</li>
          <li>To understand how users interact with our Service</li>
        </ul>

        <h4 className="terms-subsection-title">5.3. Communication</h4>
        <ul className="terms-list">
          <li>To communicate with you about updates, security alerts, and support</li>
          <li>To provide news and information about our Service that may be of interest to you</li>
        </ul>

        <h4 className="terms-subsection-title">5.4. Legal Compliance and Protection</h4>
        <ul className="terms-list">
          <li>To comply with applicable laws and regulations</li>
          <li>To enforce our terms and policies</li>
          <li>To protect our rights, privacy, safety, or property</li>
          <li>To protect against legal liability</li>
        </ul>

        <h3 className="terms-section-title">6. Legal Basis for Processing</h3>
        <p className="terms-paragraph">Under the GDPR, we process your personal data based on the following legal grounds:</p>
        <ul className="terms-list">
          <li><strong>Performance of Contract:</strong> Processing necessary for the performance of our contract with you to provide the Service.</li>
          <li><strong>Legitimate Interests:</strong> Processing necessary for our legitimate interests, such as improving our Service, preventing fraud, and ensuring network security.</li>
          <li><strong>Legal Obligation:</strong> Processing necessary to comply with our legal obligations.</li>
          <li><strong>Consent:</strong> Where you have given consent for specific processing activities.</li>
        </ul>

        <h3 className="terms-section-title">7. How We Share Your Information</h3>
        <p className="terms-paragraph">We may share your information with the following categories of third parties:</p>

        <h4 className="terms-subsection-title">7.1. Service Providers</h4>
        <p className="terms-paragraph">We may share your information with third-party vendors, service providers, and contractors who perform services on our behalf, such as:</p>
        <ul className="terms-list">
          <li>Payment processors</li>
          <li>Cloud hosting providers</li>
          <li>Analytics providers</li>
          <li>Customer support services</li>
          <li>Large Language Model providers (such as OpenAI or Anthropic) under commercial agreements</li>
        </ul>

        <h4 className="terms-subsection-title">7.2. Legal Requirements</h4>
        <p className="terms-paragraph">We may disclose your information if required to do so by law or in response to valid requests by public authorities (e.g., a court or government agency).</p>

        <h4 className="terms-subsection-title">7.3. Business Transfers</h4>
        <p className="terms-paragraph">If we are involved in a merger, acquisition, or sale of all or a portion of our assets, your information may be transferred as part of that transaction.</p>

        <h4 className="terms-subsection-title">7.4. With Your Consent</h4>
        <p className="terms-paragraph">We may share your information with third parties when you have given us your consent to do so.</p>

        <h3 className="terms-section-title">8. International Transfers</h3>
        <p className="terms-paragraph">Your information may be transferred to, and maintained on, computers located outside of your state, province, country, or other governmental jurisdiction where the data protection laws may differ from those of your jurisdiction.</p>
        <p className="terms-paragraph">When we transfer personal data outside the European Economic Area (EEA), we ensure a similar degree of protection is afforded to it by implementing appropriate safeguards, such as:</p>
        <ul className="terms-list">
          <li>Using EU Commission-approved standard contractual clauses</li>
          <li>Transferring to countries with an adequacy decision from the EU Commission</li>
          <li>Implementing additional technical and organizational measures as necessary</li>
        </ul>

        <h3 className="terms-section-title">9. Data Security</h3>
        <p className="terms-paragraph">We have implemented appropriate technical and organizational measures to ensure a level of security appropriate to the risk, including:</p>
        <ul className="terms-list">
          <li>End-to-end encryption of source code</li>
          <li>Storage exclusively on European servers (IONOS)</li>
          <li>Access controls and authentication procedures</li>
          <li>Regular security assessments</li>
          <li>Permanent deletion of source code upon audit completion</li>
        </ul>
        <p className="terms-paragraph">Despite our efforts, no method of transmission over the Internet or electronic storage is 100% secure. We cannot guarantee absolute security of your data.</p>

        <h3 className="terms-section-title">10. Data Retention</h3>
        <p className="terms-paragraph">We will retain your personal data only for as long as necessary to fulfill the purposes for which it was collected, including for the purposes of satisfying any legal, accounting, or reporting requirements.</p>
        <p className="terms-paragraph">Specific retention periods:</p>
        <ul className="terms-list">
          <li>Account information: For the duration of your account and a reasonable period thereafter</li>
          <li>Source code: Temporarily stored only for the duration of the audit process and deleted upon completion</li>
          <li>Usage data: Up to 36 months for analytical purposes</li>
          <li>Billing information: As required by applicable tax and accounting laws</li>
        </ul>

        <h3 className="terms-section-title">11. Your Data Protection Rights</h3>
        <p className="terms-paragraph">Under the GDPR and other applicable data protection laws, you have certain rights regarding your personal data:</p>
        <ul className="terms-list">
          <li><strong>Right of Access:</strong> You have the right to request a copy of your personal data.</li>
          <li><strong>Right to Rectification:</strong> You have the right to request correction of inaccurate or incomplete personal data.</li>
          <li><strong>Right to Erasure:</strong> You have the right to request the deletion of your personal data under certain conditions.</li>
          <li><strong>Right to Restrict Processing:</strong> You have the right to request restriction of processing of your personal data under certain conditions.</li>
          <li><strong>Right to Data Portability:</strong> You have the right to request the transfer of your personal data to another organization or directly to you.</li>
          <li><strong>Right to Object:</strong> You have the right to object to the processing of your personal data under certain conditions.</li>
          <li><strong>Right to Withdraw Consent:</strong> You have the right to withdraw consent where we rely on consent to process your personal data.</li>
        </ul>
        <p className="terms-paragraph">To exercise these rights, please contact us at contact@codedd.ai. We will respond to your request within one month. This period may be extended by two further months where necessary, taking into account the complexity and number of requests.</p>

        <h3 className="terms-section-title">12. Cookies and Tracking Technologies</h3>
        <p className="terms-paragraph">We use cookies and similar tracking technologies to track activity on our Service and hold certain information.</p>
        <p className="terms-paragraph">Types of cookies we use:</p>
        <ul className="terms-list">
          <li><strong>Essential Cookies:</strong> Necessary for the operation of our Service.</li>
          <li><strong>Analytical/Performance Cookies:</strong> Allow us to recognize and count the number of visitors and see how visitors move around our Service.</li>
          <li><strong>Functionality Cookies:</strong> Enable us to personalize content and remember your preferences.</li>
        </ul>
        <p className="terms-paragraph">You can instruct your browser to refuse all cookies or to indicate when a cookie is being sent. However, if you do not accept cookies, you may not be able to use some portions of our Service.</p>

        <h3 className="terms-section-title">13. Children's Privacy</h3>
        <p className="terms-paragraph">Our Service is not directed to individuals under the age of 16. We do not knowingly collect personal data from children under 16. If we become aware that we have collected personal data from a child under 16 without verification of parental consent, we will take steps to remove that information from our servers.</p>

        <h3 className="terms-section-title">14. Third-Party Links</h3>
        <p className="terms-paragraph">Our Service may contain links to third-party websites or services that are not owned or controlled by CodeDD. We have no control over, and assume no responsibility for, the content, privacy policies, or practices of any third-party websites or services. We strongly advise you to review the privacy policy of every site you visit.</p>

        <h3 className="terms-section-title">15. Changes to This Privacy Policy</h3>
        <p className="terms-paragraph">We may update our Privacy Policy from time to time. We will notify you of any changes by posting the new Privacy Policy on this page and updating the "Last updated" date at the top of this Privacy Policy.</p>
        <p className="terms-paragraph">We will notify you of material changes to this Privacy Policy at least 30 days before the change takes effect by posting a notice on our website or sending an email to the primary email address specified in your Account. Your continued use of our Service after such notice constitutes your acceptance of the changes.</p>
        <p className="terms-paragraph">We encourage you to review this Privacy Policy periodically for any changes.</p>

        <h3 className="terms-section-title">16. Data Protection Authority</h3>
        <p className="terms-paragraph">If you have a concern about our privacy practices, including the way we handle your personal data, you can report it to the Austrian Data Protection Authority (Datenschutzbehörde):</p>
        <ul className="terms-list">
          <li>Website: <span className="terms-url">https://www.dsb.gv.at/</span></li>
          <li>Address: Barichgasse 40-42, 1030 Vienna, Austria</li>
          <li>Phone: +43 1 52 152-0</li>
          <li>Email: dsb@dsb.gv.at</li>
        </ul>

        <h3 className="terms-section-title">17. Contact Us</h3>
        <p className="terms-paragraph">If you have any questions about this Privacy Policy, please contact us at:</p>
        <ul className="terms-list">
          <li>Email: contact@codedd.ai</li>
          <li>Mail: CodeDD e.U., Wötzling 2, 3233 Kilb, Austria</li>
        </ul>

        <h3 className="terms-section-title">18. Processing of Source Code</h3>
        <p className="terms-paragraph">When you submit source code for analysis through our Service, please note the following specific provisions:</p>
        <ul className="terms-list">
          <li>Your source code is temporarily stored and processed solely for the purpose of providing you with analysis and audit results.</li>
          <li>Source code is permanently deleted upon completion of the analysis process.</li>
          <li>Access to source code is limited to essential CodeDD systems and processes, as well as licensed Large Language Model providers under commercial agreements.</li>
          <li>We implement robust technical measures to secure your source code, including end-to-end encryption and temporary storage on European servers only.</li>
          <li>We do not use your source code to train our models or for any purpose other than providing you with the requested Service.</li>
        </ul>

        <h3 className="terms-section-title">19. Specific Provisions for Enterprise Customers</h3>
        <p className="terms-paragraph">For customers on an Enterprise Plan, additional privacy and security provisions may apply as specified in your service agreement. These may include:</p>
        <ul className="terms-list">
          <li>Customized data retention policies</li>
          <li>Enhanced security measures</li>
          <li>Dedicated data storage</li>
          <li>Tailored processing limitations</li>
          <li>Custom reporting and compliance documentation</li>
        </ul>
        <p className="terms-paragraph">Enterprise customers should refer to their specific service agreement for details on these additional provisions.</p>
      </div>
    </div>
  );
};

export default Privacy;
