import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import axiosInstance from '../axiosConfig';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { InputText } from 'primereact/inputtext';
import { ProgressSpinner } from 'primereact/progressspinner';
import { Paginator } from 'primereact/paginator';
import { Tooltip } from 'primereact/tooltip';
import { Tag } from 'primereact/tag';
import AdminTabMenu from './components/TabMenu';
import './AdminAudits.css';

const formatNumber = (number) => {
    return new Intl.NumberFormat('en-US').format(number);
};

const StatValue = ({ loading, value, className = '' }) => {
    if (loading) {
        return (
            <span className={`admin-stat-value ${className}`}>
                <ProgressSpinner style={{width: '20px', height: '20px'}} strokeWidth="4" />
            </span>
        );
    }
    return (
        <span className={`admin-stat-value ${className}`}>
            {value}
        </span>
    );
};

const getTooltipDescription = (statType) => {
    const tooltipMap = {
        totalAudits: "Total number of audits in the system",
        startedAudits: "Number of audits that have been started",
        expiredAudits: "Number of audits that have expired",
        auditsLastWeek: "Number of audits created in the last 7 days",
        auditsLastMonth: "Number of audits created in the last 30 days"
    };
    return tooltipMap[statType] || "";
};

export default function AdminAudits() {
    const navigate = useNavigate();
    const [activeTabIndex, setActiveTabIndex] = useState(1); // Set to 1 for Audits tab
    const [loading, setLoading] = useState(true);
    const [loadingMetrics, setLoadingMetrics] = useState(true);
    const [metrics, setMetrics] = useState({
        totalAudits: 0,
        startedAudits: 0,
        expiredAudits: 0,
        auditsLastWeek: 0,
        auditsLastMonth: 0
    });
    const [audits, setAudits] = useState([]);
    const [totalRecords, setTotalRecords] = useState(0);
    const [lazyParams, setLazyParams] = useState({
        first: 0,
        rows: 10,
        page: 0,
        search: ''
    });
    const [globalFilter, setGlobalFilter] = useState('');

    // Handle tab change
    const handleTabChange = (e) => {
        setActiveTabIndex(e.index);
        
        // Navigate to the appropriate route based on the tab index
        switch (e.index) {
            case 0:
                navigate('/admin/dashboard');
                break;
            case 1:
                navigate('/admin/audits');
                break;
            case 2:
                navigate('/admin/users');
                break;
            case 3:
                navigate('/admin/settings');
                break;
            default:
                break;
        }
    };

    // Fetch metrics data
    useEffect(() => {
        const fetchMetrics = async () => {
            setLoadingMetrics(true);
            try {
                // Get account UUID from localStorage
                const accountUuid = localStorage.getItem('accountUuid');
                
                // Call our new backend API endpoint
                const response = await axiosInstance.get('/admin/audit_metrics/', {
                    params: {
                        account_uuid: accountUuid // Add account UUID to the request
                    }
                });
                
                if (response.data.status === 'success') {
                    setMetrics({
                        totalAudits: response.data.data.total_audits,
                        startedAudits: response.data.data.started_audits,
                        expiredAudits: response.data.data.expired_audits,
                        auditsLastWeek: response.data.data.audits_last_week,
                        auditsLastMonth: response.data.data.audits_last_month
                    });
                }
            } catch (error) {
                console.error('Error fetching audit metrics:', error);
                // Show error notification or fallback to default values
                setMetrics({
                    totalAudits: 0,
                    startedAudits: 0,
                    expiredAudits: 0,
                    auditsLastWeek: 0,
                    auditsLastMonth: 0
                });
            } finally {
                setLoadingMetrics(false);
            }
        };

        fetchMetrics();
    }, []);

    // Fetch audits data with pagination and search
    useEffect(() => {
        const fetchAudits = async () => {
            setLoading(true);
            try {
                // Get account UUID from localStorage
                const accountUuid = localStorage.getItem('accountUuid');
                
                // Call our new backend API endpoint
                const response = await axiosInstance.get('/admin/audits/', {
                    params: {
                        page: lazyParams.page + 1, // API uses 1-indexed pages
                        per_page: lazyParams.rows,
                        search: lazyParams.search,
                        account_uuid: accountUuid // Add account UUID to the request
                    }
                });
                
                if (response.data.status === 'success') {
                    setAudits(response.data.data.audits);
                    setTotalRecords(response.data.data.total);
                }
            } catch (error) {
                console.error('Error fetching audits:', error);
                // Show error notification or fallback to empty array
                setAudits([]);
                setTotalRecords(0);
            } finally {
                setLoading(false);
            }
        };

        fetchAudits();
    }, [lazyParams]);

    const onPage = (event) => {
        setLazyParams(prevParams => ({
            ...prevParams,
            first: event.first,
            page: event.page,
            rows: event.rows
        }));
    };

    const onSearch = (e) => {
        const value = e.target.value;
        setGlobalFilter(value);
        
        // Reset to first page when searching
        setLazyParams(prevParams => ({
            ...prevParams,
            first: 0,
            page: 0,
            search: value
        }));
    };

    // Table templates and formatters
    const isPublicTemplate = (rowData) => {
        return (
            <div className="audit-visibility-icon">
                {rowData.is_public ? (
                    <i className="pi pi-globe" style={{ color: 'var(--primary-color)' }}></i>
                ) : (
                    <i className="pi pi-lock" style={{ color: 'var(--text-color-secondary)' }}></i>
                )}
            </div>
        );
    };

    const statusTemplate = (rowData) => {
        let severity;
        let status = rowData.status;
        
        // Truncate status if longer than 10 words
        const words = status.split(' ');
        if (words.length > 10) {
            status = words.slice(0, 10).join(' ') + '...';
        }
        
        switch (rowData.status) {
            case 'Audit completed':
                severity = 'success';
                break;
            case 'Error':
            case 'Audit failed':
                severity = 'danger';
                break;
            case 'Audit started':
            case 'Score calculated':
            case 'Contextualization completed':
            case 'Consolidation completed':
                severity = 'info';
                break;
            case 'File information imported':
                severity = 'warning';
                break;
            default:
                severity = 'warning';
        }
        
        return <Tag value={status} severity={severity} className="audit-status-tag" />;
    };

    const createdAtTemplate = (rowData) => {
        const date = new Date(rowData.ai_synthesis);
        const now = new Date();
        const diffTime = Math.abs(now - date);
        const diffDays = Math.floor(diffTime / (1000 * 60 * 60 * 24));
        
        if (diffDays === 0) {
            return 'Today';
        } else if (diffDays === 1) {
            return 'Yesterday';
        } else if (diffDays < 7) {
            return `${diffDays} days ago`;
        } else if (diffDays < 30) {
            return `${Math.floor(diffDays / 7)} weeks ago`;
        } else {
            return `${Math.floor(diffDays / 30)} months ago`;
        }
    };

    const nameTemplate = (rowData) => {
        const navigateToAudit = () => {
            const path = rowData.status === "File information imported" 
                ? `/${rowData.id}/audit-scope-selection`
                : `/${rowData.id}/audit-summary`;
            navigate(path);
        };

        return (
            <div 
                className="audit-name-link"
                onClick={navigateToAudit}
            >
                {rowData.name}
            </div>
        );
    };

    const repoUrlTemplate = (rowData) => {
        return <div className="audit-repo-url">{rowData.repo_url}</div>;
    };

    const auditorTemplate = (rowData) => {
        const initials = rowData.auditor.substring(0, 2).toUpperCase();
        return (
            <div className="audit-auditor">
                <div className="audit-auditor-avatar">{initials}</div>
                <div className="audit-auditor-email">{rowData.auditor}</div>
            </div>
        );
    };

    const fileCountTemplate = (rowData) => {
        return <div className="audit-file-count">{formatNumber(rowData.file_count)}</div>;
    };

    const locTemplate = (rowData) => {
        return <div className="audit-loc">{formatNumber(rowData.loc)}</div>;
    };

    const actionsTemplate = (rowData) => {
        return (
            <div className="audit-actions">
                <button 
                    className="p-button p-button-text p-button-sm"
                    onClick={(e) => {
                        e.stopPropagation(); // Prevent row click
                        navigate(`/${rowData.id}/logs`);
                    }}
                >
                    <i className="pi pi-list" style={{ fontSize: '1rem' }}></i>
                </button>
            </div>
        );
    };

    const header = (
        <div className="admin-table-header">
            <h2>Audit List</h2>
            <div className="admin-search-container">
                <i className="pi pi-search admin-search-icon" />
                <InputText 
                    value={globalFilter} 
                    onChange={onSearch} 
                    placeholder="Search audits..." 
                    className="admin-search-input"
                />
            </div>
        </div>
    );

    const renderLoader = () => {
        return (
            <div className="admin-audits-loader-container">
                <div className="admin-audits-loader"></div>
            </div>
        );
    };

    return (
        <div className="admin-audits-page-layout">
            <AdminTabMenu activeIndex={activeTabIndex} onTabChange={handleTabChange} />
            
            <div className="admin-audits-container">
                <div className="admin-audits-section">
                    {/* Metrics Section */}
                    <div className="admin-metrics-section">
                        <div className="admin-metric-card">
                            <div className="admin-metric-title-container">
                                <div className="admin-metric-title">Total Audits</div>
                                <i className="pi pi-info-circle admin-tooltip-icon" data-pr-tooltip={getTooltipDescription('totalAudits')} />
                            </div>
                            <StatValue loading={loadingMetrics} value={formatNumber(metrics.totalAudits)} />
                        </div>
                        
                        <div className="admin-metric-card">
                            <div className="admin-metric-title-container">
                                <div className="admin-metric-title">Started Audits</div>
                                <i className="pi pi-info-circle admin-tooltip-icon" data-pr-tooltip={getTooltipDescription('startedAudits')} />
                            </div>
                            <StatValue loading={loadingMetrics} value={formatNumber(metrics.startedAudits)} />
                        </div>
                        
                        <div className="admin-metric-card">
                            <div className="admin-metric-title-container">
                                <div className="admin-metric-title">Expired Audits</div>
                                <i className="pi pi-info-circle admin-tooltip-icon" data-pr-tooltip={getTooltipDescription('expiredAudits')} />
                            </div>
                            <StatValue loading={loadingMetrics} value={formatNumber(metrics.expiredAudits)} />
                        </div>
                        
                        <div className="admin-metric-card">
                            <div className="admin-metric-title-container">
                                <div className="admin-metric-title">Audits Last Week</div>
                                <i className="pi pi-info-circle admin-tooltip-icon" data-pr-tooltip={getTooltipDescription('auditsLastWeek')} />
                            </div>
                            <StatValue loading={loadingMetrics} value={formatNumber(metrics.auditsLastWeek)} />
                        </div>
                        
                        <div className="admin-metric-card">
                            <div className="admin-metric-title-container">
                                <div className="admin-metric-title">Audits Last Month</div>
                                <i className="pi pi-info-circle admin-tooltip-icon" data-pr-tooltip={getTooltipDescription('auditsLastMonth')} />
                            </div>
                            <StatValue loading={loadingMetrics} value={formatNumber(metrics.auditsLastMonth)} />
                        </div>
                    </div>
                    
                    {/* Audits Table */}
                    <div className="admin-audits-table-section">
                        <div className="admin-audits-card">
                            {loading && audits.length === 0 ? (
                                renderLoader()
                            ) : (
                                <>
                                    <DataTable 
                                        value={audits}
                                        lazy
                                        loading={loading}
                                        dataKey="id"
                                        header={header}
                                        emptyMessage="No audits found"
                                        className="p-datatable-audits"
                                    >
                                        <Column field="is_public" header="Public" body={isPublicTemplate} style={{ width: '5%' }} />
                                        <Column field="name" header="Name" body={nameTemplate} style={{ width: '20%' }} />
                                        <Column field="repo_url" header="Repository" body={repoUrlTemplate} style={{ width: '15%' }} />
                                        <Column field="auditor" header="Auditor" body={auditorTemplate} style={{ width: '15%' }} />
                                        <Column field="ai_synthesis" header="Created" body={createdAtTemplate} style={{ width: '10%' }} />
                                        <Column field="status" header="Status" body={statusTemplate} style={{ width: '10%' }} />
                                        <Column field="file_count" header="Files" body={fileCountTemplate} style={{ width: '7.5%' }} />
                                        <Column field="loc" header="Lines of Code" body={locTemplate} style={{ width: '10%' }} />
                                        <Column field="actions" header="Logs" body={actionsTemplate} style={{ width: '7.5%' }} />
                                    </DataTable>
                                    
                                    <Paginator 
                                        first={lazyParams.first} 
                                        rows={lazyParams.rows} 
                                        totalRecords={totalRecords} 
                                        onPageChange={onPage}
                                        className="admin-audits-paginator"
                                    />
                                </>
                            )}
                        </div>
                    </div>
                </div>
                
                <Tooltip target=".admin-tooltip-icon" />
            </div>
        </div>
    );
}
