import React, { useState, useEffect } from 'react';
import axiosInstance from '../axiosConfig';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, Link } from 'react-router-dom';
import { Button } from 'primereact/button';
import { Dialog } from 'primereact/dialog';
import InviteAuditors from './InviteAuditors';
import ExportAuditResults from './ExportData';
import AuditBadgeHelper from './AuditBadgeHelper';
import { useAuditAccess } from '../utils/AuditAccessContext';
import { logout } from '../features/user/userSlice';


import './GlobalSidebar.css';

const GlobalSidebar = ({ onLockToggle }) => {
  const navigate = useNavigate();
  const { isLoggedIn } = useSelector(state => state.user);
  const { hasAccess, isManager, auditUuid, isPublic } = useAuditAccess();
  const [expanded, setExpanded] = useState(false);
  const [locked, setLocked] = useState(false);
  const [showInviteModal, setShowInviteModal] = useState(false);
  const [showExportModal, setShowExportModal] = useState(false);
  const [showBadgeModal, setShowBadgeModal] = useState(false);
  const isAdmin = useSelector(state => state.user.isAdmin);
  const dispatch = useDispatch();

  useEffect(() => {
    const timer = setTimeout(() => {
      setLocked(true);
    }, 5000);

    return () => clearTimeout(timer);
  });

  // Replace toggle approach with explicit state setting
  const handleMouseEnter = () => {
    if (!locked) {
      setExpanded(true);
    }
  };

  const handleMouseLeave = () => {
    if (!locked) {
      setExpanded(false);
    }
  };

  const lockSidebar = () => {
    const newLockedState = !locked;
    setLocked(newLockedState);
    if (onLockToggle) {
      onLockToggle(newLockedState);
    }
  };

  const openInviteModal = () => {
    setShowInviteModal(true);
  };

  const closeInviteModal = () => {
    setShowInviteModal(false);
  };

  const openExportModal = () => {
    if (!auditUuid) {
      console.error('No audit UUID available');
      return;
    }
    setShowExportModal(true);
  };

  const closeExportModal = () => {
    setShowExportModal(false);
  };

  const openBadgeModal = () => {
    if (!auditUuid) {
      console.error('No audit UUID available');
      return;
    }
    setShowBadgeModal(true);
  };

  const closeBadgeModal = () => {
    setShowBadgeModal(false);
  };

  const handleLogout = async () => {
    try {
        const response = await axiosInstance.post('logout/');

        if (response.data.status === 'success') {
            localStorage.clear();
            dispatch(logout());
            
            const cookies = document.cookie.split(';');
            const domain = window.location.hostname;
            const paths = ['/', '/django_codedd'];
            
            cookies.forEach(cookie => {
                const cookieName = cookie.split('=')[0].trim();
                paths.forEach(path => {
                    document.cookie = `${cookieName}=; expires=Thu, 01 Jan 1970 00:00:00 GMT; path=${path}`;
                    document.cookie = `${cookieName}=; expires=Thu, 01 Jan 1970 00:00:00 GMT; path=${path}; domain=${domain}`;
                });
            });
            
            navigate('/login', { replace: true });
        } else {
            console.error('Logout failed:', response.data.message);
            forceLogout();
        }
    } catch (error) {
        console.error('Error during logout:', error);
        forceLogout();
    }
};

  const forceLogout = () => {
    localStorage.clear();
    dispatch(logout());
    navigate('/login', { replace: true });
  };

  const SidebarButton = ({ icon, label, onClick, className }) => (
    <button className={`sidebar-button ${className}`} onClick={onClick}>
      <i className={`pi ${icon}`}></i>
      <span className="button-label">{label}</span>
    </button>
  );

  const isPublicAuditView = isPublic && !isLoggedIn;

  return (
    <div 
      className={`global-sidebar ${expanded ? 'expanded' : ''}`}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
    >
      <div className="sidebar-header">
        <div className="logo-container-sidebar">
          <Link to={isLoggedIn ? "/dashboard" : "/"}>
            <img src="/logo192.png" alt="Logo" className="sidebar-logo" />
          </Link>
        </div>
        
        {expanded && (
          <Button
            icon={locked ? 'pi pi-lock' : 'pi pi-lock-open'}
            className="lock-button"
            onClick={lockSidebar}
          />
        )}
      </div>

      <div className="sidebar-content">
        <div className="sidebar-top-section">
          {isPublicAuditView ? (
            <>
              <SidebarButton
                icon="pi-user-plus"
                label="Register"
                onClick={() => navigate('/register')}
                className="register-button"
              />
              <SidebarButton
                icon="pi-sign-in"
                label="Login"
                onClick={() => navigate('/login')}
                className="login-button"
              />
            </>
          ) : (
            <>
              <SidebarButton
                icon="pi-home"
                label="Dashboard"
                onClick={() => navigate('/dashboard')}
                className="dashboard-button"
              />

              {isLoggedIn && hasAccess && isManager && (
                <SidebarButton
                  icon="pi-users"
                  label="Manage Access"
                  onClick={openInviteModal}
                  className="manage-access-button"
                />
              )}

              {isLoggedIn && hasAccess && (
                <>
                  <SidebarButton
                    icon="pi-download"
                    label="Export Data"
                    onClick={openExportModal}
                    className="export-data-button"
                  />
                  
                  <SidebarButton
                    icon="pi-shield"
                    label="Create Badge"
                    onClick={openBadgeModal}
                    className="create-badge-button"
                  />
                </>
              )}
            </>
          )}
        </div>

        {!isPublicAuditView && (
          <div className="sidebar-bottom-section">
            {isAdmin === true && (
              <>
                <SidebarButton
                  icon="pi-android"
                  label="Admin Panel"
                  onClick={() => navigate('/admin/dashboard')}
                  className="admin-button"
                />
                {hasAccess && (
                  <SidebarButton
                    icon="pi-list"
                    label="Audit Logs"
                    onClick={() => navigate(`/${auditUuid}/logs`)}
                    className="audit-logs-button"
                  />
                )}
              </>
            )}
            {isLoggedIn && (
              <>
                <SidebarButton
                  icon="pi-user"
                  label="Account"
                  onClick={() => navigate('/account')}
                  className="account-button"
                />
                <SidebarButton
                  icon="pi-sign-out"
                  label="Logout"
                  onClick={handleLogout}
                  className="logout-button"
                />
              </>
            )}
          </div>
        )}
      </div>

      {isLoggedIn && hasAccess && isManager &&(
      <Dialog 
        className="manage-access-modal" 
        visible={showInviteModal} 
        onHide={closeInviteModal} 
        header="Manage Audit Access" 
        draggable={false}
      >
        <InviteAuditors closeModal={closeInviteModal} />
      </Dialog>
      )}

      {isLoggedIn && hasAccess && (
      <Dialog 
        className="exports-modal" 
        visible={showExportModal} 
        onHide={closeExportModal} 
        header="Export Audit Results" 
        draggable={false}
      >
        <ExportAuditResults 
          closeModal={closeExportModal}
          auditUuid={auditUuid}
        />
      </Dialog>
      )}

      {isLoggedIn && hasAccess && (
        <Dialog 
          className="badge-modal" 
          visible={showBadgeModal} 
          onHide={closeBadgeModal} 
          header="Create Badge" 
          draggable={false}
        >
          <AuditBadgeHelper 
            auditUuid={auditUuid}
          />
        </Dialog>
      )}
    </div>
  );
};

export default GlobalSidebar;