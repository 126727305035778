// Flags.js
import React, { useState, useEffect } from 'react';
import axiosInstance from '../axiosConfig';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Message } from 'primereact/message';
import { Panel } from 'primereact/panel';
import { useParams, Navigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { checkAuditStatus } from '../utils/Actions';
import useAuditDetails from '../utils/useAuditDetails';

import FlagsGraph from './flags_components/FlagsGraph';
import TotalToFix from './flags_components/TimeToFixTotal';
import TotalToFixDomain from './flags_components/TimeToFixDomain';
import PageLayout from '../components/PageLayout';

import './Flags.css';

 
function FlagsView() {
  const { auditUuid } = useParams();
  const [flaggedFiles, setFlaggedFiles] = useState([]);
  const [loading, setLoading] = useState(true);
  const [sunburstData, setSunburstData] = useState(null);
  const [timeToFixTotalData, setTimeToFixTotalData] = useState(null);
  const [timeToFixTotalDomainData, setTimeToFixTotalDomainData] = useState(null);
  const [totalTimeToFixAllFlags, setTotalTimeToFixAllFlags] = useState(null);
  const [redFlagCount, setRedFlagCount] = useState(0);
  const [organgeFlagCount, setOrgangeFlagCount] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [shouldRedirect, setShouldRedirect] = useState(false);

  const dispatch = useDispatch();
  const auditAccessDetails = useSelector(state => state.audits[auditUuid]);
  
  // Use the new hook to get audit details
  const { 
    auditDetails, 
    headerProps, 
    loading: auditLoading, 
    isDataLoaded 
  } = useAuditDetails(auditUuid);

  // Check if the user has access to the audit
  useEffect(() => {
    if (!auditAccessDetails) {
      console.log("Audit details are not available yet.");
    } else if (auditAccessDetails.error) {
      console.error('Audit access error:', auditAccessDetails.error);
      setShouldRedirect(true);
    } else if (!auditAccessDetails.isPublic && !auditAccessDetails.hasAccess) {
      // Only redirect if the audit is private AND user doesn't have access
      setShouldRedirect(true);
    } else {
      setShouldRedirect(false);
    }
  }, [auditAccessDetails]);

  // Check audit status
  useEffect(() => {
    const checkStatus = async () => {
        if (!auditUuid) return;
        try {
            await dispatch(checkAuditStatus(auditUuid));
        } catch (error) {
            console.error('Error checking audit status:', error);
        }
    };
    checkStatus();
  }, [auditUuid, dispatch]);

  // Fetch flags data
  useEffect(() => {
    let isActive = true;

    const fetchFlagsData = async () => {
      if (!auditAccessDetails || (!auditAccessDetails.isPublic && !auditAccessDetails.hasAccess)) {
        return;
      }

      try {
        setLoading(true);
        const flagsResponse = await axiosInstance.get(`flags/${auditUuid}/`);
        
        if (!isActive) return;

        const flagsData = flagsResponse.data;
        setSunburstData(flagsData.flagData || null);
        setTimeToFixTotalData(flagsData.flagSummaryTotal || null);
        setTimeToFixTotalDomainData(flagsData.flagSummaryByDomain || null);
        setTotalTimeToFixAllFlags(flagsData.totalTimeToFixAllFlags || 0);

        const sortedFlaggedFiles = (flagsData.flaggedFiles || []).sort((a, b) => 
          (a.flag_color === 'Red' && b.flag_color !== 'Red') ? -1 : 
          (a.flag_color !== 'Red' && b.flag_color === 'Red') ? 1 : 0
        );
        setFlaggedFiles(sortedFlaggedFiles);

      } catch (error) {
        console.error('Error in fetchFlagsData:', error);
      } finally {
        if (isActive) {
          setLoading(false);
        }
      }
    };

    if (isDataLoaded) {
      fetchFlagsData();
    }

    return () => {
      isActive = false;
    };
  }, [auditUuid, auditAccessDetails, isDataLoaded]);

  useEffect(() => {
    if (flaggedFiles.length > 0) {
      const redFlags = flaggedFiles.filter(file => file.flag_color === 'Red');
      const orangeFlags = flaggedFiles.filter(file => file.flag_color === 'Orange');
      setRedFlagCount(redFlags.length);
      setOrgangeFlagCount(orangeFlags.length);
    }
  }, [flaggedFiles]);

  if (shouldRedirect) {
    return <Navigate to="/" />;
  }

  // Custom body template for the flag color
  const flagColorTemplate = (rowData) => {
    return <div style={{ width: '20px', height: '20px', borderRadius: '50%', backgroundColor: rowData.flag_color === 'Red' ? '#E83636' : '#DE7539' }}></div>;
  };

  // Update: Extract file name from file path
  const fileNameTemplate = (rowData) => {
    const parts = rowData.file_path.split('/');
    const fileName = parts.length > 5 ? parts.slice(5).join('/') : rowData.file_path;
    const color = rowData.flag_color === 'Red' ? '#E83636' : '#DE7539';
    return <strong style={{ color: color }}>{fileName}</strong>;
  };

  // Custom body template for displaying time to fix
  const timeToFixTemplate = (rowData) => {
    return rowData.time_to_fix_flag !== 'N/A' ? `${rowData.time_to_fix_flag} hrs` : 'N/A';
  };

  // Helper function for text truncation and styling
  const truncateText = (text) => {
    if (text && text.length > 120) {  // Check if text is not undefined and has length greater than 120
      return text.substring(0, text.lastIndexOf(' ', 120)) + '...';
    }
    return text || '';  // Return an empty string if text is undefined or null
  };

  // Custom body template for the "Purpose" column
  const purposeTemplate = (rowData) => {
    const truncatedText = truncateText(rowData.script_purpose);
    return <div style={{ fontSize: '14px' }}>{truncatedText}</div>;
  };

  const renderDataTable = () => {
    const paginatorTemplate = 'FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown';
    const firstRow = (currentPage - 1) * rowsPerPage;

    return (
        <DataTable value={flaggedFiles}
            stripedRows
            paginator
            paginatorTemplate={paginatorTemplate}
            currentPageReportTemplate="Showing {first} to {last} of {totalRecords}"
            rows={rowsPerPage}
            totalRecords={auditDetails?.file_count || 0}
            first={firstRow}
            onPageChange={(e) => {
                setCurrentPage(e.first / e.rows + 1);
                setRowsPerPage(e.rows);
            }}
            tableStyle={{ minWidth: '80%' }}
            style={{fontSize: "normal"}}>
            <Column body={flagColorTemplate} style={{ width: 'auto' }}> </Column>
            <Column field="file_path" header="File" body={fileNameTemplate} style={{ width: 'auto' }}></Column>
            <Column field="domain" header="Domain" style={{ width: 'auto' }}></Column>
            <Column field="reasons_of_flag" header="Reasons" style={{ width: 'auto' }}></Column>
            <Column field="script_purpose" header="Purpose" body={purposeTemplate} style={{ width: 'auto' }}></Column>
            <Column field="time_to_fix_flag" header="Time to fix (estimate)" body={timeToFixTemplate} style={{ width: 'auto' }}></Column>
        </DataTable>
    );
};

  const renderNoFlagsMessage = () => (
    <div className="flags-card">
      <Message
        style={{ border: 'solid #696cff', borderWidth: '0 0 0 6px', color: '#696cff' }}
        className="border-primary w-full justify-content-start"
        severity="info"
        content={<div>No flags were found!</div>}
      />
    </div>
  );

  const renderFlagDescriptions = () => {
    return (
      <div className="flag-descriptions">
        <p><strong style={{ color: '#E83636' }}>Red Flag: </strong>indicates a critical issue that poses a high risk, such as security vulnerabilities or major functional defects.</p>
        <p><strong style={{ color: '#DE7539' }}>Orange Flag: </strong>suggests a significant concern that may not be immediately critical but requires attention, such as minor security concerns, weak error handling, or non-compliance with typical coding standards.</p>
      </div>
    );
  };


  // Inside your FlagsView component
  const renderGraphContainer = () => {
    const isNonZero = (obj) => Object.values(obj).some(value => value > 0);

    const hasFlags = (timeToFixTotalData && isNonZero(timeToFixTotalData)) ||
                     (timeToFixTotalDomainData && Object.keys(timeToFixTotalDomainData).length > 0);
  
    return (
      <div className="flags-graph-container">
        <div className="flags-graph-disclaimer">
          Note: Estimated times to fix are AI-generated predictions and may vary based on developer experience, 
          project complexity, and interdependencies not fully captured in the static analysis.
        </div>
        <div className="sunburst-container">
          <div className="sunburst-header-container">
            <h3>Flags & File Domain Distribution</h3>
            <p className="sunburst-description">Visual representation of flags across different domains in the codebase</p>
          </div>
          {sunburstData && <FlagsGraph data={sunburstData} />}
        </div>
        {hasFlags && (
          <>
            <div className="time-to-fix-total-container">
              <div className="sunburst-header-container">
                <h3>Estimated Time to Fix by Flag Type</h3>
                <p className="sunburst-description">Total: <strong>{totalTimeToFixAllFlags}</strong> hours</p>
              </div>
              {timeToFixTotalData && Object.keys(timeToFixTotalData).length > 0 && 
                <TotalToFix data={timeToFixTotalData} />
              }
            </div>
            <div className="time-to-fix-domain-container">
              <div className="sunburst-header-container">
                <h3>Estimated Time to Fix by Domain</h3>
                <p className="sunburst-description">Distribution of fix effort across code domains</p>
              </div>
              {timeToFixTotalDomainData && Object.keys(timeToFixTotalDomainData).length > 0 && 
                (() => {
                  // Flatten the nested domain data structure for total calculation
                  const flattenedDomainData = {};
                  Object.keys(timeToFixTotalDomainData).forEach(domain => {
                    const domainData = timeToFixTotalDomainData[domain];
                    // Sum up Red and Orange flag times for each domain
                    flattenedDomainData[domain] = Object.values(domainData).reduce((sum, time) => sum + time, 0);
                  });
                  
                  // Only render if we have some non-zero values
                  return Object.values(flattenedDomainData).some(value => value > 0) ? 
                    <TotalToFixDomain 
                      data={flattenedDomainData} 
                      colorData={timeToFixTotalDomainData}
                      colors={{
                        Red: "#E83636", 
                        Orange: "#DE7539"
                      }} 
                    /> : null;
                })()
              }
            </div>
          </>
        )}
      </div>
    );
  };

  return (
    <PageLayout 
      headerProps={headerProps}
      loading={loading || auditLoading}
      className="flags-layout"
    >
      <div className="flags-container">
        {!(loading || auditLoading) && (
          <>
            <div className="flags-header">
              <p>
                The audit has marked <strong style={{ color: '#E83636' }}>{redFlagCount} files </strong> with a red flag  
                and <strong style={{ color: '#DE7539' }}>{organgeFlagCount} files </strong> with an orange flag.
              </p>
              {renderFlagDescriptions()}
            </div>
            <div className="flags-content">
              {sunburstData && renderGraphContainer()}
            </div>
            <div className="flag-table">
              {flaggedFiles.length > 0 ? renderDataTable() : renderNoFlagsMessage()}
            </div>
          </>
        )}
      </div>
    </PageLayout>
  );
}

export default FlagsView;
