import React, { useRef, useState, useEffect } from 'react';
import { motion } from 'framer-motion';
import { HelpCircle } from 'lucide-react';
import ScoreExplanationModal from './ScoreExplanationModal';
import { getScoreExplanation } from '../data/scoreExplanations';
import './SummaryScoreGraph.css';

const SummaryScoreGraph = ({ 
  scoreAttribute, 
  isMainChapter, 
  auditDetails, 
  animationDelay = 0,
  className,
  showAvgLine = false,
  isNotRequired = false 
}) => {
  const rawScoreValue = auditDetails ? auditDetails[scoreAttribute] : 0;
  const finalScore = isNotRequired ? 0 : (rawScoreValue === '-' ? 0 : Math.round(rawScoreValue));
  const [currentScore, setCurrentScore] = useState(0);
  const [showExplanationModal, setShowExplanationModal] = useState(false);
  const scoreBarRef = useRef(null);
  const [scoreBarWidth, setScoreBarWidth] = useState(0);
  const graphRef = useRef(null);
  const indicatorSize = isMainChapter ? 48 : 40;

  const explanationData = getScoreExplanation(scoreAttribute);
  const isMajorPoint = explanationData.isMajorPoint;

  const getScoreColor = (score) => {
    if (isNotRequired) return "#888";
    if (score < 33) return "#E83636";
    if (score < 66) return "#DE7539";
    return "#2CB392";
  };

  useEffect(() => {
    const updateScoreBarWidth = () => {
      if (scoreBarRef.current) {
        setScoreBarWidth(scoreBarRef.current.offsetWidth);
      }
    };

    updateScoreBarWidth();
    window.addEventListener('resize', updateScoreBarWidth);
    return () => window.removeEventListener('resize', updateScoreBarWidth);
  }, []);

  useEffect(() => {
    let animationTimer;
    
    const startAnimation = async () => {
      await new Promise(resolve => setTimeout(resolve, animationDelay));
      
      const duration = 800;
      const fps = 60;
      const frames = duration / (1000 / fps);
      const increment = finalScore / frames;
      let current = 0;

      const animate = () => {
        if (current < finalScore) {
          current = Math.min(current + increment, finalScore);
          setCurrentScore(Math.round(current));
          animationTimer = requestAnimationFrame(animate);
        }
      };

      animate();
    };

    startAnimation();

    return () => {
      if (animationTimer) {
        cancelAnimationFrame(animationTimer);
      }
    };
  }, [finalScore, animationDelay]);

  const leftPosition = (currentScore / 100) * scoreBarWidth;
  const currentColor = getScoreColor(currentScore);

  const handleHelpClick = (e) => {
    e.stopPropagation();
    setShowExplanationModal(true);
  };

  return (
    <>
      <div 
        ref={graphRef} 
        className={`score-slider-container ${className}`}
        style={{ 
          position: 'relative',
          pointerEvents: showExplanationModal ? 'none' : 'auto'
        }}
      >
        {/* Help button */}
        <button
          className="help-button"
          onClick={handleHelpClick}
        >
          <HelpCircle 
            size={20} 
            color={isMajorPoint || isMainChapter ? "#32AFC3" : "#64748b"} 
          />
        </button>

        <div className="score-bar" ref={scoreBarRef}>
          <motion.div 
            className="score-fill"
            initial={{ width: 0 }}
            animate={{ width: isNotRequired ? '0%' : `${currentScore}%` }}
            transition={{ duration: 0.5, ease: "easeOut" }}
            style={{ 
              backgroundColor: isNotRequired ? '#F5F5F5' : currentColor,
              height: '8px'
            }}
          />
        </div>
        
        {/* Average line for main chapters */}
        {isMainChapter && showAvgLine && (
          <div 
            className="avg-line" 
            style={{
              position: 'absolute',
              left: `${leftPosition}px`,
              bottom: '100%',
              width: '2px',
              height: '40px',
              backgroundColor: currentColor,
              transform: 'translateX(-50%)'
            }}
          >
            <div 
              style={{
                position: 'absolute',
                top: '0',
                left: '50%',
                transform: 'translate(-50%, -100%)',
                width: '0',
                height: '0',
                borderLeft: '6px solid transparent',
                borderRight: '6px solid transparent',
                borderBottom: `8px solid ${currentColor}`
              }}
            />
            <span 
              style={{
                position: 'absolute',
                left: '-20px',
                top: '50%',
                transform: 'translateY(-50%)',
                color: currentColor,
                fontSize: '12px',
                writingMode: 'vertical-rl',
                textOrientation: 'mixed'
              }}
            >
              avg
            </span>
          </div>
        )}

        {/* Score indicator */}
        <motion.div 
          className={`score-graph-indicator ${isMainChapter ? 'main-chapter' : 'sub-chapter'} ${isNotRequired ? 'not-required' : ''}`}
          initial={{ scale: 0.5, opacity: 0 }}
          animate={{ 
            x: isNotRequired ? -20 : leftPosition - (indicatorSize / 2),
            scale: 1,
            opacity: 1
          }}
          transition={{ 
            duration: 0.5,
            ease: "easeOut",
          }}
          style={{
            width: `${indicatorSize}px`,
            height: `${indicatorSize}px`,
            backgroundColor: isNotRequired ? 'transparent' : (isMainChapter ? currentColor : '#F5F5F5'),
            border: isNotRequired ? '2px solid #ccc' : (isMainChapter ? 'none' : `2px solid ${currentColor}`),
            position: 'absolute',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            borderRadius: '50%',
            color: isNotRequired ? '#888' : (isMainChapter ? 'white' : currentColor),
            fontWeight: 'bold',
            boxShadow: isNotRequired ? 'none' : '0 2px 4px rgba(0,0,0,0.1)'
          }}
        >
          {isNotRequired ? '-' : currentScore}
        </motion.div>
      </div>

      <ScoreExplanationModal
        visible={showExplanationModal}
        onHide={() => {
          setShowExplanationModal(false);
        }}
        title={explanationData.title}
        explanation={explanationData.explanation}
        examples={explanationData.examples}
        businessContext={explanationData.businessContext}
        score={currentScore}
        subpoints={explanationData.subpoints}
        isMajorPoint={isMajorPoint}
        references={explanationData.references}
      />
    </>
  );
};

export default SummaryScoreGraph;