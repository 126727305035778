import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axiosInstance from '../../axiosConfig';

// Cache duration constants
const CACHE_DURATION = {
    DEFAULT: 30 * 60 * 1000,     // 30 minutes
    MAXIMUM: 24 * 60 * 60 * 1000, // 24 hours
    MINIMUM: 5 * 60 * 1000       // 5 minutes
};
 
// Helper to determine if cache is valid
const isCacheValid = (cachedStats) => {
    if (!cachedStats?.lastFetched) return false;
    
    const now = new Date().getTime();
    const cacheAge = now - new Date(cachedStats.lastFetched).getTime();
    
    // If we're in development mode, use shorter cache duration
    if (process.env.NODE_ENV === 'development') {
        return cacheAge < CACHE_DURATION.MINIMUM;
    }
    
    // For production, use default cache duration
    return cacheAge < CACHE_DURATION.DEFAULT;
};

// Helper to determine if specific data section cache is valid
const isSectionCacheValid = (cachedStats, section) => {
    if (!cachedStats?.lastFetched || !cachedStats?.lastFetched[section]) return false;
    
    const now = new Date().getTime();
    const cacheAge = now - new Date(cachedStats.lastFetched[section]).getTime();
    
    // If we're in development mode, use shorter cache duration
    if (process.env.NODE_ENV === 'development') {
        return cacheAge < CACHE_DURATION.MINIMUM;
    }
    
    // For production, use default cache duration
    return cacheAge < CACHE_DURATION.DEFAULT;
};

// Async thunks for each data section
export const fetchDevelopmentAnalysis = createAsyncThunk(
    'development/fetchDevelopmentAnalysis',
    async (auditUuid, { getState }) => {
        const state = getState();
        const cachedStats = state.development[auditUuid];
        
        if (cachedStats && isSectionCacheValid(cachedStats, 'developmentAnalysis')) {
            console.debug('Returning cached development analysis');
            return {
                auditUuid,
                data: cachedStats.developmentAnalysis,
                section: 'developmentAnalysis'
            };
        }
        
        console.debug('Fetching fresh development analysis');
        const response = await axiosInstance.get(`get_git_development_analysis/${auditUuid}/`);
        
        if (response.data.status !== 'success') {
            throw new Error('Failed to fetch development analysis');
        }
        
        return {
            auditUuid,
            data: response.data.data.development_analysis,
            section: 'developmentAnalysis'
        };
    }
);

export const fetchAdditionalStats = createAsyncThunk(
    'development/fetchAdditionalStats',
    async (auditUuid, { getState }) => {
        const state = getState();
        const cachedStats = state.development[auditUuid];
        
        if (cachedStats && isSectionCacheValid(cachedStats, 'additionalStats')) {
            console.debug('Returning cached additional stats');
            return {
                auditUuid,
                data: {
                    releaseStats: cachedStats.releaseStats,
                    branchStats: cachedStats.branchStats,
                    mergeStats: cachedStats.mergeStats,
                    metaInfo: cachedStats.metaInfo,
                    repositoryInfo: cachedStats.repositoryInfo
                },
                section: 'additionalStats'
            };
        }
        
        console.debug('Fetching fresh additional stats');
        const response = await axiosInstance.get(`get_git_additional_stats/${auditUuid}/`);
        
        if (response.data.status !== 'success') {
            throw new Error('Failed to fetch additional stats');
        }
        
        const additionalData = response.data.data;
        return {
            auditUuid,
            data: {
                releaseStats: additionalData.release_stats || null,
                branchStats: additionalData.branch_stats || null,
                mergeStats: additionalData.merge_stats || null,
                metaInfo: additionalData.meta_info || null,
                repositoryInfo: additionalData.repository_info || null
            },
            section: 'additionalStats'
        };
    }
);

// Legacy thunk that fetches non-core stats
export const fetchGitStatistics = createAsyncThunk(
    'development/fetchGitStatistics',
    async (auditUuid, { getState, dispatch }) => {
        // Check if we have cached data
        const state = getState();
        const cachedStats = state.development[auditUuid];
        
        if (isCacheValid(cachedStats)) {
            console.debug('Returning cached development data');
            return cachedStats;
        }
        
        // If we don't have cached data, fetch each section separately
        // Note: Core stats are deliberately excluded here - they should always be fetched directly
        await Promise.all([
            dispatch(fetchDevelopmentAnalysis(auditUuid)),
            dispatch(fetchAdditionalStats(auditUuid))
        ]);
        
        // Return the now updated state
        return getState().development[auditUuid];
    }
);

// Slice for development data
const developmentSlice = createSlice({
    name: 'development',
    initialState: {},
    reducers: {
        clearDevelopmentData: (state, action) => {
            if (action.payload) {
                delete state[action.payload];
            } else {
                return {};
            }
        },
        invalidateCache: (state, action) => {
            if (action.payload && state[action.payload]) {
                if (state[action.payload].lastFetched) {
                    if (typeof state[action.payload].lastFetched === 'string') {
                        // Legacy format
                        delete state[action.payload].lastFetched;
                    } else {
                        // New format with section timestamps
                        state[action.payload].lastFetched = {};
                    }
                }
            }
        },
        updateGitStatistics: (state, action) => {
            const { auditUuid, data } = action.payload;
            if (!auditUuid || !data) return;

            // Initialize state for this audit if it doesn't exist
            if (!state[auditUuid] || typeof state[auditUuid] !== 'object') {
                state[auditUuid] = {};
            }

            // Initialize lastFetched if it doesn't exist
            if (!state[auditUuid].lastFetched || typeof state[auditUuid].lastFetched !== 'object') {
                state[auditUuid].lastFetched = {};
            }

            // Handle the data update
            const filteredData = { ...data };
            
            // Remove lastFetched from the data object as we handle it separately
            const lastFetched = filteredData.lastFetched || {};
            delete filteredData.lastFetched;
            
            // Remove core stats if they are present
            delete filteredData.timeBasedStats;
            delete filteredData.authorStats;
            delete filteredData.codeChurnStats;
            delete filteredData.collaborationStats;
            delete filteredData.developerExpertise;
            delete filteredData.dailyCommits;

            // Update the state with filtered data
            Object.entries(filteredData).forEach(([key, value]) => {
                if (value !== undefined) {
                    state[auditUuid][key] = value;
                }
            });

            // Update lastFetched timestamps
            Object.entries(lastFetched).forEach(([key, timestamp]) => {
                state[auditUuid].lastFetched[key] = timestamp;
            });
        }
    },
    extraReducers: (builder) => {
        builder
            // Handle legacy thunk
            .addCase(fetchGitStatistics.fulfilled, (state, action) => {
                // This is now handled by the individual section thunks
            })
            .addCase(fetchGitStatistics.rejected, (state, action) => {
                console.error('Failed to fetch git statistics:', action.error);
            })
            
            // Handle development analysis
            .addCase(fetchDevelopmentAnalysis.fulfilled, (state, action) => {
                const { auditUuid, data, section } = action.payload;
                if (!state[auditUuid]) {
                    state[auditUuid] = { lastFetched: {} };
                }
                state[auditUuid].developmentAnalysis = data;
                if (!state[auditUuid].lastFetched) {
                    state[auditUuid].lastFetched = {};
                }
                state[auditUuid].lastFetched[section] = new Date().toISOString();
            })
            
            // Handle additional stats
            .addCase(fetchAdditionalStats.fulfilled, (state, action) => {
                const { auditUuid, data, section } = action.payload;
                if (!state[auditUuid]) {
                    state[auditUuid] = { lastFetched: {} };
                }
                Object.entries(data).forEach(([key, value]) => {
                    state[auditUuid][key] = value;
                });
                if (!state[auditUuid].lastFetched) {
                    state[auditUuid].lastFetched = {};
                }
                state[auditUuid].lastFetched[section] = new Date().toISOString();
            });
    }
});

// Export actions
export const { 
    clearDevelopmentData, 
    invalidateCache,
    updateGitStatistics 
} = developmentSlice.actions;

// Selectors
export const selectGitStatistics = (state, auditUuid) => state.development[auditUuid];

export const selectDevelopmentAnalysis = (state, auditUuid) => {
    // Get development analysis from state if available
    const auditData = state.development[auditUuid];
    if (auditData?.developmentAnalysis) {
        // Use console.debug to avoid performance impact of console.log
        if (process.env.NODE_ENV !== 'production') {
            console.debug('Returning cached development analysis');
        }
        return auditData.developmentAnalysis;
    }
    return null;
};

export const selectAdditionalStats = (state, auditUuid) => {
    const auditData = state.development[auditUuid];
    if (!auditData) return {};
    
    // Use console.debug to avoid performance impact of console.log
    if (process.env.NODE_ENV !== 'production') {
        console.debug('Returning cached additional stats');
    }
    
    return {
        releaseStats: auditData.releaseStats || null,
        branchStats: auditData.branchStats || null,
        mergeStats: auditData.mergeStats || null,
        metaInfo: auditData.metaInfo || null,
        repositoryInfo: auditData.repositoryInfo || null
    };
};

export default developmentSlice.reducer;
