import React, { useRef, useEffect, useState } from 'react';
import * as d3 from 'd3';
import { Tooltip } from 'primereact/tooltip';
import './FlagsGraph.css';

const FlagsGraph = ({ data }) => {
  const ref = useRef();
  const [selectedSegment, setSelectedSegment] = useState(null);
  const [tooltipContent, setTooltipContent] = useState('');
  const [tooltipVisible, setTooltipVisible] = useState(false);
  const [tooltipPosition, setTooltipPosition] = useState({ x: 0, y: 0 });
  const [flagDetails, setFlagDetails] = useState(null);

  useEffect(() => {
    if (!data || !ref.current) return;

    // Clear any existing SVG
    d3.select(ref.current).selectAll("*").remove();

    // Make width responsive to container
    const containerWidth = ref.current.parentElement.clientWidth;
    // Use a fixed width approach to prevent size changes
    const width = Math.min(500, containerWidth);
    const height = width;
    const radius = width / 6;

    // Calculate responsive font size based on width
    const baseFontSize = width / 40; // This will scale the font with the container
    const fontSize = Math.max(8, Math.min(12, baseFontSize)); // Clamp between 8px and 12px

    // Enhanced color scheme matching the DevelopmentActivityGraph
    const colors = {
      "No flags": "#8D8D8D",
      "Red": "#E83636",
      "Orange": "#DE7539",
      "Green": "#2CB392"
    };

    const hierarchy = d3.hierarchy(data)
      .sum(d => d.value)
      .sort((a, b) => b.value - a.value);
      
    const root = d3.partition()
      .size([2 * Math.PI, hierarchy.height + 1])(hierarchy);
      
    root.each(d => d.current = d);

    const arc = d3.arc()
      .startAngle(d => d.x0)
      .endAngle(d => d.x1)
      .padAngle(d => Math.min((d.x1 - d.x0) / 2, 0.005))
      .padRadius(radius * 1.5)
      .innerRadius(d => d.y0 * radius)
      .outerRadius(d => Math.max(d.y0 * radius, d.y1 * radius - 1));

    // Create fixed SVG with explicit dimensions
    const svg = d3.select(ref.current)
      // Set explicit dimensions to maintain consistency
      .attr("width", width)
      .attr("height", height)
      .attr("viewBox", `-${width / 2},${-height / 2},${width},${height}`)
      .style("font", `${fontSize}px sans-serif`)
      .style("max-width", "100%")
      .style("height", "auto")
      .style("margin", "0 auto")
      .style("display", "block")
      .style("overflow", "visible"); // Ensure overflow is handled properly

    // Add a subtle background circle
    svg.append("circle")
      .attr("r", radius)
      .attr("fill", "#F9FAFB")
      .attr("stroke", "#E5E7EB")
      .attr("stroke-width", 1)
      .attr("vector-effect", "non-scaling-stroke");

    const path = svg.append("g")
      .selectAll("path")
      .data(root.descendants().slice(1))
      .join("path")
      .attr("class", "arc")
      .attr("fill", d => {
        if (d.depth === 1) {
          return colors[d.data.name] || "#8D8D8D"; // Use the flag color directly
        } else if (d.depth === 2) {
          return colors[d.parent.data.name] || "#8D8D8D"; // Use parent flag color for domains
        }
      })
      .attr("fill-opacity", d => {
        if (d.depth === 1) {
          return 1.0;
        } else if (d.depth === 2) {
          const siblings = d.parent.children;
          const maxValue = d3.max(siblings, s => s.value);
          return d.value === maxValue ? 1.0 : 0.7;
        }
        return 0.7;
      })
      .attr("pointer-events", d => (arcVisible(d) && d.depth <= 2) ? "auto" : "none")
      .attr("d", d => arc(d))
      .on("mouseover", function(event, d) {
        // Use filter instead of stroke for hover effect to prevent layout shifts
        d3.select(this)
          .transition()
          .duration(200)
          .attr("fill-opacity", d => d.depth === 1 ? 0.9 : 0.8)
          // Don't use stroke for hover effect, use filter/opacity instead
          .style("filter", "drop-shadow(0px 0px 2px rgba(255,255,255,0.5))");

        // Calculate tooltip position
        const [x, y] = arc.centroid(d);
        const svgRect = ref.current.getBoundingClientRect();
        const centerX = svgRect.left + svgRect.width / 2;
        const centerY = svgRect.top + svgRect.height / 2;
        
        const tooltipX = centerX + x;
        const tooltipY = centerY + y;
        
        // Get path to current node (breadcrumb)
        const pathToRoot = d.ancestors().reverse().map(d => d.data.name).join(" / ");
        
        // Format estimate if available
        let estimate = '';
        if (d.data.estimate) {
          estimate = `\nEstimated time to fix: ${formatEstimate(d.data.estimate)}`;
        }
        
        // Create tooltip content
        const content = `${pathToRoot}${estimate}\nFiles affected: ${d.value}`;
        
        setTooltipContent(content);
        setTooltipPosition({ x: tooltipX, y: tooltipY });
        setTooltipVisible(true);
        
        // Set selected segment for details panel
        setSelectedSegment(d);
        setFlagDetails({
          name: d.data.name,
          path: pathToRoot,
          files: d.value,
          estimate: d.data.estimate,
          severity: getSeverity(d.data.name)
        });
      })
      .on("mouseout", function() {
        d3.select(this)
          .transition()
          .duration(200)
          .attr("fill-opacity", d => {
            if (d.depth === 1) return 1.0;
            if (d.depth === 2) {
              const siblings = d.parent.children;
              const maxValue = d3.max(siblings, s => s.value);
              return d.value === maxValue ? 1.0 : 0.7;
            }
            return 0.7;
          })
          // Remove filter instead of changing stroke
          .style("filter", "none");
          
        setTooltipVisible(false);
      });

    // Add titles for better accessibility
    path.append("title")
      .text(d => `${d.ancestors().map(d => d.data.name).reverse().join("/")}\n${d.value}`);

    // Enhanced labels with better visibility
    const label = svg.append("g")
      .attr("pointer-events", "none")
      .attr("text-anchor", "middle")
      .style("user-select", "none")
      .selectAll("text")
      .data(root.descendants().slice(1))
      .join("text")
      .attr("dy", "0.35em")
      .attr("fill-opacity", d => +labelVisible(d))
      .attr("transform", d => labelTransform(d))
      .style("font-weight", d => d.depth === 1 ? "500" : "400")
      .style("font-size", d => d.depth === 1 ? `${fontSize + 1}px` : `${fontSize}px`)
      .style("fill", d => d.depth === 1 ? "#fff" : "#333")
      .text(d => d.data.name);

    // Center circle with animation on hover only
    const parent = svg.append("circle")
      .datum(root)
      .attr("r", radius)
      .attr("fill", "white")
      .attr("stroke", "#E5E7EB")
      .attr("stroke-width", 1)
      .attr("vector-effect", "non-scaling-stroke")
      .attr("cursor", "default")
      .attr("pointer-events", "all")
      .on("mouseover", function() {
        d3.select(this)
          .transition()
          .duration(200)
          .attr("fill", "#F3F4F6")
          // Don't change stroke width
          .style("filter", "drop-shadow(0px 0px 2px rgba(0,0,0,0.1))");
      })
      .on("mouseout", function() {
        d3.select(this)
          .transition()
          .duration(200)
          .attr("fill", "white")
          // Don't change stroke width
          .style("filter", "none");
      });

    // Add center label
    svg.append("text")
      .attr("text-anchor", "middle")
      .attr("dy", "0.35em")
      .style("font-size", `${fontSize + 2}px`)
      .style("font-weight", "600")
      .style("fill", "#4B5563")
      .style("pointer-events", "none")
      .text("All Files");

    function arcVisible(d) {
      return d.y1 <= 3 && d.y0 >= 1 && d.x1 > d.x0;
    }

    function labelVisible(d) {
      // Adjust the visibility threshold based on the width
      const threshold = width < 300 ? 0.06 : 0.03;
      return d.y1 <= 3 && d.y0 >= 1 && (d.y1 - d.y0) * (d.x1 - d.x0) > threshold;
    }

    function labelTransform(d) {
      const x = (d.x0 + d.x1) / 2 * 180 / Math.PI;
      const y = (d.y0 + d.y1) / 2 * radius;
      return `rotate(${x - 90}) translate(${y},0) rotate(${x < 180 ? 0 : 180})`;
    }

  }, [data]);

  // Format time estimate for better readability
  const formatEstimate = (minutes) => {
    if (!minutes) return 'N/A';
    
    if (minutes < 60) {
      return `${minutes} min`;
    } else if (minutes < 24 * 60) {
      const hours = Math.floor(minutes / 60);
      const remainingMinutes = minutes % 60;
      return remainingMinutes > 0 
        ? `${hours} hr ${remainingMinutes} min` 
        : `${hours} hr`;
    } else {
      const days = Math.floor(minutes / (24 * 60));
      const remainingHours = Math.floor((minutes % (24 * 60)) / 60);
      return remainingHours > 0 
        ? `${days} day${days !== 1 ? 's' : ''} ${remainingHours} hr` 
        : `${days} day${days !== 1 ? 's' : ''}`;
    }
  };

  // Get severity level based on flag name
  const getSeverity = (flagName) => {
    if (flagName === "Red") return "high";
    if (flagName === "Orange") return "medium";
    return "low";
  };

  // Render flag details panel
  const renderFlagDetails = () => {
    if (!flagDetails) {
      return (
        <div className="flags-details-panel flags-details-placeholder">
          <div className="flags-details-placeholder-icon">
            <i className="pi pi-info-circle"></i>
          </div>
          <div className="flags-details-placeholder-text">
            Hover over the graph segments to reveal more details about code quality flags
          </div>
        </div>
      );
    }
    
    return (
      <div className="flags-details-panel">
        <div className="flags-details-item">
          <span className="flags-details-label">Category:</span>
          <span className={`flags-details-value flags-priority-${flagDetails.severity}`}>
            {flagDetails.name}
          </span>
        </div>
        
        <div className="flags-details-item">
          <span className="flags-details-label">Files Affected:</span>
          <span className="flags-details-value">{flagDetails.files}</span>
        </div>
        
        {flagDetails.estimate && (
          <div className="flags-details-item">
            <span className="flags-details-label">Estimated Time to Fix:</span>
            <span className="flags-details-value">{formatEstimate(flagDetails.estimate)}</span>
          </div>
        )}
      </div>
    );
  };

  return (
    <div className="flags-graph-sunburst-container">
      <div className="flags-graph-content">
        <svg 
          ref={ref} 
          style={{
            width: "336px", // Fixed width to match your screenshot
            height: "336px", // Fixed height to match your screenshot
            boxSizing: "border-box" // Use border-box to include padding/border in dimensions
          }}
          // Maintain exact aspect ratio
          preserveAspectRatio="xMidYMid meet"
          // Add a fixed viewBox to prevent resizing
          viewBox="-168,-168,336,336"
        ></svg>
        
        {/* Tooltip element for d3 interaction */}
        {tooltipVisible && (
          <div 
            className="graph-tooltip"
            id="flags-graph-tooltip"
            style={{
              position: 'fixed',
              left: `${tooltipPosition.x}px`,
              top: `${tooltipPosition.y}px`,
              opacity: 1,
              pointerEvents: 'none'
            }}
          />
        )}
        
        {/* Legend */}
        <div className="flags-graph-legend">
          {Object.entries({ "Red": "#E83636", "Orange": "#DE7539", "No flags": "#8D8D8D" }).map(([name, color]) => (
            <div key={name} className="flags-graph-legend-item">
              <div className="flags-graph-legend-color" style={{ backgroundColor: color }}></div>
              <span>{name}</span>
            </div>
          ))}
        </div>
        
        {/* Details Panel */}
        {renderFlagDetails()}
      </div>
      
      {/* PrimeReact Tooltip */}
      <Tooltip 
        target="#flags-graph-tooltip"
        content={tooltipContent}
        position="right"
        className="flags-graph-tooltip"
        showDelay={50}
      />
    </div>
  );
};

export default FlagsGraph;
