/**
 * Pricing utilities for calculating and formatting code audit pricing
 */

// Pricing tiers configuration
export const PRICING_TIERS = [
    { name: 'Base', maxLines: 50000, price: 250, pricePerLine: 0, product_id: 'prod_RwX6aR9vz1uxjB' },
    { name: 'Start-up', maxLines: 1000000, pricePerLine: 0.0035, product_id: 'prod_RwX1x5ixeY4P3K' },
    { name: 'Growth', maxLines: 3000000, pricePerLine: 0.00275, product_id: 'prod_RyMxEtGA96X1Zi' },
    { name: 'Enterprise', maxLines: 10000000, pricePerLine: 0.00225, product_id: 'prod_RyMyMu2aryhNfs' }
];

/**
 * Calculate the price based on lines of code and tier structure
 * @param {number} payableLines - Total payable lines of code
 * @returns {Object} - Object containing pricing details and breakdown
 */
export const calculatePrice = (payableLines) => {
    // Ensure payableLines is a valid number
    const lines = Math.max(0, parseInt(payableLines) || 0);
    
    let tier = 'Base';
    let basePrice = 0;
    let additionalPrice = 0;
    let breakdown = [];
    
    // Skip calculation if no lines
    if (lines === 0) {
        return {
            basePrice: 0,
            tierPrice: 0,
            total: 0,
            subtotal: 0,
            totalWithTax: 0,
            currency: 'EUR',
            selectedTier: 'Base',
            breakdown: []
        };
    }
    
    // Determine which tier applies based on the number of lines
    let selectedTierIndex = 0;
    for (let i = 0; i < PRICING_TIERS.length; i++) {
        const tierInfo = PRICING_TIERS[i];
        if (lines <= tierInfo.maxLines) {
            selectedTierIndex = i;
            tier = tierInfo.name;
            break;
        }
        if (i === PRICING_TIERS.length - 1) {
            selectedTierIndex = i;
            tier = tierInfo.name;
        }
    }
    
    // Base price is always charged
    basePrice = PRICING_TIERS[0].price;
    breakdown.push({
        tier: PRICING_TIERS[0].name,
        description: `Base fee (First ${formatNumber(PRICING_TIERS[0].maxLines)} lines)`,
        lines: Math.min(lines, PRICING_TIERS[0].maxLines),
        rate: PRICING_TIERS[0].pricePerLine,
        amount: basePrice,
        product_id: PRICING_TIERS[0].product_id
    });
    
    // Calculate additional costs for higher tiers if applicable
    if (lines > PRICING_TIERS[0].maxLines) {
        // For each tier that applies, calculate the price for lines in that tier range
        for (let i = 1; i <= selectedTierIndex; i++) {
            const previousTierMax = PRICING_TIERS[i-1].maxLines;
            const currentTierMax = PRICING_TIERS[i].maxLines;
            const linesInThisTier = Math.min(lines, currentTierMax) - previousTierMax;
            
            if (linesInThisTier > 0) {
                // Calculate cost directly by multiplying the number of lines by the per-line price
                const tierCost = linesInThisTier * PRICING_TIERS[i].pricePerLine;
                additionalPrice += tierCost;
                
                // Create a more descriptive breakdown entry
                const startLine = previousTierMax + 1;
                const endLine = Math.min(lines, currentTierMax);
                
                breakdown.push({
                    tier: PRICING_TIERS[i].name,
                    description: `${PRICING_TIERS[i].name} tier (Lines ${formatNumber(startLine)} to ${formatNumber(currentTierMax)})`,
                    lines: linesInThisTier,
                    rate: PRICING_TIERS[i].pricePerLine,
                    amount: tierCost,
                    product_id: PRICING_TIERS[i].product_id,
                    startLine: startLine,
                    endLine: endLine
                });
            }
        }
    }
    
    const total = basePrice + additionalPrice;
    
    return {
        basePrice,
        tierPrice: additionalPrice,
        total,
        subtotal: total, // Same as total if no tax
        totalWithTax: total, // Will be updated if tax is applied
        currency: 'EUR',
        selectedTier: tier,
        breakdown
    };
};

/**
 * Format a number with thousands separators
 * @param {number} num - Number to format
 * @returns {string} - Formatted number string
 */
export const formatNumber = (num) => {
    return num.toLocaleString('de-DE');
};

/**
 * Format a price value
 * @param {number} price - Price to format
 * @param {number} decimals - Number of decimal places
 * @returns {string} - Formatted price string
 */
export const formatPrice = (price, decimals = 2) => {
    return price.toFixed(decimals);
};

/**
 * Format a price rate value with appropriate precision
 * @param {number} rate - Rate to format
 * @returns {string} - Formatted rate string
 */
export const formatRate = (rate) => {
    if (rate === 0) return '0';
    // Show more decimal places for small rates
    return rate.toFixed(rate < 0.01 ? 5 : 4);
}; 