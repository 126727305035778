import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    treeStructure: null,
    selectedKeys: {},
    scopeSummary: {
        numberOfFiles: 0,
        linesOfCode: 0
    },
    auditStatus: null,
    groupAuditUuid: null,
    isLoading: false,
    error: null,
    lastUpdated: null,
    currentAuditUuid: null
};

// Add utility function for processing tree structure
const processTreeStructure = (flatData) => {
    if (!flatData) return null;
    
    const pathMap = new Map();
    const tree = [];
    
    // First pass: create all nodes and map them
    flatData.forEach(item => {
        const node = {
            ...item,
            children: [],
            parent: null,
            key: item.path // Add key for PrimeReact TreeTable
        };
        pathMap.set(item.path, node);
    });

    // Second pass: build hierarchy
    flatData.forEach(item => {
        const node = pathMap.get(item.path);
        if (item.parent_path && pathMap.has(item.parent_path)) {
            const parent = pathMap.get(item.parent_path);
            parent.children.push(node);
            node.parent = parent;
        } else {
            tree.push(node);
        }
    });

    // Third pass: calculate folder states
    const calculateFolderState = (node) => {
        if (node.type !== 'Folder') return node.is_selected;
        
        let hasSelected = false;
        let hasUnselected = false;
        let selectableChildren = 0;
        let selectedChildren = 0;
        
        node.children.forEach(child => {
            if (child.type !== 'Folder' && child.size > 0) {
                selectableChildren++;
                if (child.is_selected) {
                    selectedChildren++;
                    hasSelected = true;
                } else {
                    hasUnselected = true;
                }
            } else if (child.type === 'Folder') {
                const childState = calculateFolderState(child);
                if (childState === true) hasSelected = true;
                if (childState === false) hasUnselected = true;
                if (childState === 'partial') {
                    hasSelected = true;
                    hasUnselected = true;
                }
            }
        });
        
        if (selectableChildren === 0 && node.children.length === 0) return false;
        if (hasSelected && hasUnselected) {
            node.is_partial = true;
            node.is_selected = false;
            return 'partial';
        }
        node.is_partial = false;
        node.is_selected = hasSelected;
        return hasSelected;
    };

    tree.forEach(root => calculateFolderState(root));
    return tree;
};

export const auditScopeSlice = createSlice({
    name: 'auditScope',
    initialState,
    reducers: {
        setTreeStructure: (state, action) => {
            state.treeStructure = processTreeStructure(action.payload);
            state.lastUpdated = Date.now();
        },
        setSelectedKeys: (state, action) => {
            state.selectedKeys = action.payload;
        },
        setScopeSummary: (state, action) => {
            state.scopeSummary = action.payload;
        },
        setAuditStatus: (state, action) => {
            state.auditStatus = action.payload;
        },
        setGroupAuditUuid: (state, action) => {
            state.groupAuditUuid = action.payload;
        },
        setCurrentAuditUuid: (state, action) => {
            state.currentAuditUuid = action.payload;
        },
        setLoading: (state, action) => {
            state.isLoading = action.payload;
        },
        setError: (state, action) => {
            state.error = action.payload;
        },
        clearAuditScope: (state) => {
            return initialState;
        }
    }
});

export const {
    setTreeStructure,
    setSelectedKeys,
    setScopeSummary,
    setAuditStatus,
    setGroupAuditUuid,
    setCurrentAuditUuid,
    setLoading,
    setError,
    clearAuditScope
} = auditScopeSlice.actions;

// Selectors
export const selectAuditScope = (state) => state.auditScope;
export const selectTreeStructure = (state) => state.auditScope.treeStructure;
export const selectSelectedKeys = (state) => state.auditScope.selectedKeys;
export const selectScopeSummary = (state) => state.auditScope.scopeSummary;
export const selectAuditScopeStatus = (state) => state.auditScope.auditStatus;
export const selectGroupAuditUuid = (state) => state.auditScope.groupAuditUuid;
export const selectIsLoading = (state) => state.auditScope.isLoading;
export const selectError = (state) => state.auditScope.error;
export const selectLastUpdated = (state) => state.auditScope.lastUpdated;
export const selectCurrentAuditUuid = (state) => state.auditScope.currentAuditUuid;

export default auditScopeSlice.reducer; 