import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import axiosInstance from '../axiosConfig';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { InputText } from 'primereact/inputtext';
import { ProgressSpinner } from 'primereact/progressspinner';
import { Paginator } from 'primereact/paginator';
import { Tooltip } from 'primereact/tooltip';
import { Tag } from 'primereact/tag';
import AdminTabMenu from './components/TabMenu';
import './AdminUsers.css';

const formatNumber = (number) => {
    return new Intl.NumberFormat('en-US').format(number);
};

const StatValue = ({ loading, value, className = '' }) => {
    if (loading) {
        return (
            <span className={`admin-user-stat-value ${className}`}>
                <ProgressSpinner style={{width: '20px', height: '20px'}} strokeWidth="4" />
            </span>
        );
    }
    return (
        <span className={`admin-user-stat-value ${className}`}>
            {value}
        </span>
    );
};

const getTooltipDescription = (statType) => {
    const tooltipMap = {
        totalUsers: "Total number of registered users",
        activeUsers: "Users who logged in within the last 30 days",
        usersWithAudits: "Users who have created at least one audit",
        usersWithoutAudits: "Users who haven't created any audits",
        avgAuditsPerUser: "Average number of audits per user",
        newUsersLastMonth: "Users who registered in the last 30 days"
    };
    return tooltipMap[statType] || "";
};

export default function AdminUsers() {
    const navigate = useNavigate();
    const [activeTabIndex, setActiveTabIndex] = useState(2); // Set to 2 for Users tab
    const [loading, setLoading] = useState(true);
    const [loadingMetrics, setLoadingMetrics] = useState(true);
    const [metrics, setMetrics] = useState({
        totalUsers: 0,
        activeUsers: 0,
        usersWithAudits: 0,
        usersWithoutAudits: 0,
        avgAuditsPerUser: 0,
        newUsersLastMonth: 0
    });
    const [users, setUsers] = useState([]);
    const [totalRecords, setTotalRecords] = useState(0);
    const [lazyParams, setLazyParams] = useState({
        first: 0,
        rows: 10,
        page: 0,
        search: ''
    });
    const [globalFilter, setGlobalFilter] = useState('');

    // Handle tab change
    const handleTabChange = (e) => {
        setActiveTabIndex(e.index);
        
        switch (e.index) {
            case 0:
                navigate('/admin/dashboard');
                break;
            case 1:
                navigate('/admin/audits');
                break;
            case 2:
                navigate('/admin/users');
                break;
            case 3:
                navigate('/admin/settings');
                break;
            default:
                break;
        }
    };

    // Fetch metrics data
    useEffect(() => {
        const fetchMetrics = async () => {
            setLoadingMetrics(true);
            try {
                const accountUuid = localStorage.getItem('accountUuid');
                const response = await axiosInstance.get('/admin/user_metrics/', {
                    params: {
                        account_uuid: accountUuid
                    }
                });
                
                if (response.data.status === 'success') {
                    setMetrics({
                        totalUsers: response.data.data.total_users,
                        activeUsers: response.data.data.active_users,
                        usersWithAudits: response.data.data.users_with_audits,
                        usersWithoutAudits: response.data.data.users_without_audits,
                        avgAuditsPerUser: response.data.data.avg_audits_per_user,
                        newUsersLastMonth: response.data.data.new_users_last_month
                    });
                }
            } catch (error) {
                console.error('Error fetching user metrics:', error);
                setMetrics({
                    totalUsers: 0,
                    activeUsers: 0,
                    usersWithAudits: 0,
                    usersWithoutAudits: 0,
                    avgAuditsPerUser: 0,
                    newUsersLastMonth: 0
                });
            } finally {
                setLoadingMetrics(false);
            }
        };

        fetchMetrics();
    }, []);

    // Fetch users data
    useEffect(() => {
        const fetchUsers = async () => {
            setLoading(true);
            try {
                const accountUuid = localStorage.getItem('accountUuid');
                const response = await axiosInstance.get('/admin/users/', {
                    params: {
                        page: lazyParams.page + 1,
                        per_page: lazyParams.rows,
                        search: lazyParams.search,
                        account_uuid: accountUuid
                    }
                });
                
                if (response.data.status === 'success') {
                    setUsers(response.data.data.users);
                    setTotalRecords(response.data.data.total);
                }
            } catch (error) {
                console.error('Error fetching users:', error);
                setUsers([]);
                setTotalRecords(0);
            } finally {
                setLoading(false);
            }
        };

        fetchUsers();
    }, [lazyParams]);

    const onPage = (event) => {
        setLazyParams(prevParams => ({
            ...prevParams,
            first: event.first,
            page: event.page,
            rows: event.rows
        }));
    };

    const onSearch = (e) => {
        const value = e.target.value;
        setGlobalFilter(value);
        
        setLazyParams(prevParams => ({
            ...prevParams,
            first: 0,
            page: 0,
            search: value
        }));
    };

    // Table templates
    const isAdminTemplate = (rowData) => {
        return (
            <div className="user-admin-icon">
                {rowData.is_admin ? (
                    <i className="pi pi-shield" style={{ color: 'var(--primary-color)' }}></i>
                ) : (
                    <i className="pi pi-user" style={{ color: 'var(--text-color-secondary)' }}></i>
                )}
            </div>
        );
    };

    const nameTemplate = (rowData) => {
        return (
            <div className="user-name">
                <div className="user-name-avatar">
                    {(rowData.name || rowData.email).substring(0, 2).toUpperCase()}
                </div>
                <div className="user-name-details">
                    <span className="user-full-name">{rowData.name || 'N/A'}</span>
                    <span className="user-email">{rowData.email}</span>
                </div>
            </div>
        );
    };

    const createdAtTemplate = (rowData) => {
        const date = new Date(rowData.create_date);
        const now = new Date();
        const diffTime = Math.abs(now - date);
        const diffDays = Math.floor(diffTime / (1000 * 60 * 60 * 24));
        
        if (diffDays === 0) {
            return 'Today';
        } else if (diffDays === 1) {
            return 'Yesterday';
        } else if (diffDays < 7) {
            return `${diffDays} days ago`;
        } else if (diffDays < 30) {
            return `${Math.floor(diffDays / 7)} weeks ago`;
        } else {
            return `${Math.floor(diffDays / 30)} months ago`;
        }
    };

    const lastSignInTemplate = (rowData) => {
        if (!rowData.last_sign_in) return 'Never';
        
        const date = new Date(rowData.last_sign_in);
        const now = new Date();
        const diffTime = Math.abs(now - date);
        const diffDays = Math.floor(diffTime / (1000 * 60 * 60 * 24));
        
        if (diffDays === 0) {
            return 'Today';
        } else if (diffDays === 1) {
            return 'Yesterday';
        } else if (diffDays < 7) {
            return `${diffDays} days ago`;
        } else if (diffDays < 30) {
            return `${Math.floor(diffDays / 7)} weeks ago`;
        } else {
            return `${Math.floor(diffDays / 30)} months ago`;
        }
    };

    const auditCountTemplate = (rowData) => {
        return (
            <div className="user-audit-count">
                {formatNumber(rowData.audit_count)}
            </div>
        );
    };

    const header = (
        <div className="admin-table-header">
            <h2>User List</h2>
            <div className="admin-search-container">
                <i className="pi pi-search admin-search-icon" />
                <InputText 
                    value={globalFilter} 
                    onChange={onSearch} 
                    placeholder="Search users..." 
                    className="admin-search-input"
                />
            </div>
        </div>
    );

    const renderLoader = () => {
        return (
            <div className="admin-users-loader-container">
                <div className="admin-users-loader"></div>
            </div>
        );
    };

    return (
        <div className="admin-users-page-layout">
            <AdminTabMenu activeIndex={activeTabIndex} onTabChange={handleTabChange} />
            
            <div className="admin-users-container">
                <div className="admin-users-section">
                    {/* Metrics Section */}
                    <div className="admin-metrics-section">
                        <div className="admin-metric-card">
                            <div className="admin-metric-title-container">
                                <div className="admin-metric-title">Total Users</div>
                                <i className="pi pi-info-circle admin-tooltip-icon" data-pr-tooltip={getTooltipDescription('totalUsers')} />
                            </div>
                            <StatValue loading={loadingMetrics} value={formatNumber(metrics.totalUsers)} />
                        </div>
                        
                        <div className="admin-metric-card">
                            <div className="admin-metric-title-container">
                                <div className="admin-metric-title">Active Users</div>
                                <i className="pi pi-info-circle admin-tooltip-icon" data-pr-tooltip={getTooltipDescription('activeUsers')} />
                            </div>
                            <StatValue loading={loadingMetrics} value={formatNumber(metrics.activeUsers)} />
                        </div>
                        
                        <div className="admin-metric-card">
                            <div className="admin-metric-title-container">
                                <div className="admin-metric-title">Users with Audits</div>
                                <i className="pi pi-info-circle admin-tooltip-icon" data-pr-tooltip={getTooltipDescription('usersWithAudits')} />
                            </div>
                            <StatValue loading={loadingMetrics} value={formatNumber(metrics.usersWithAudits)} />
                        </div>
                        
                        <div className="admin-metric-card">
                            <div className="admin-metric-title-container">
                                <div className="admin-metric-title">Users without Audits</div>
                                <i className="pi pi-info-circle admin-tooltip-icon" data-pr-tooltip={getTooltipDescription('usersWithoutAudits')} />
                            </div>
                            <StatValue loading={loadingMetrics} value={formatNumber(metrics.usersWithoutAudits)} />
                        </div>
                        
                        <div className="admin-metric-card">
                            <div className="admin-metric-title-container">
                                <div className="admin-metric-title">Avg Audits per User</div>
                                <i className="pi pi-info-circle admin-tooltip-icon" data-pr-tooltip={getTooltipDescription('avgAuditsPerUser')} />
                            </div>
                            <StatValue loading={loadingMetrics} value={metrics.avgAuditsPerUser} />
                        </div>

                        <div className="admin-metric-card">
                            <div className="admin-metric-title-container">
                                <div className="admin-metric-title">New Users (30d)</div>
                                <i className="pi pi-info-circle admin-tooltip-icon" data-pr-tooltip={getTooltipDescription('newUsersLastMonth')} />
                            </div>
                            <StatValue loading={loadingMetrics} value={formatNumber(metrics.newUsersLastMonth)} />
                        </div>
                    </div>
                    
                    {/* Users Table */}
                    <div className="admin-users-table-section">
                        <div className="admin-users-card">
                            {loading && users.length === 0 ? (
                                renderLoader()
                            ) : (
                                <>
                                    <DataTable 
                                        value={users}
                                        lazy
                                        loading={loading}
                                        dataKey="id"
                                        header={header}
                                        emptyMessage="No users found"
                                        className="p-datatable-users"
                                    >
                                        <Column field="is_admin" header="Role" body={isAdminTemplate} style={{ width: '5%' }} />
                                        <Column field="name" header="User" body={nameTemplate} style={{ width: '30%' }} />
                                        <Column field="create_date" header="Registered" body={createdAtTemplate} style={{ width: '15%' }} />
                                        <Column field="last_sign_in" header="Last Sign In" body={lastSignInTemplate} style={{ width: '15%' }} />
                                        <Column field="audit_count" header="Audits" body={auditCountTemplate} style={{ width: '10%' }} />
                                    </DataTable>
                                    
                                    <Paginator 
                                        first={lazyParams.first} 
                                        rows={lazyParams.rows} 
                                        totalRecords={totalRecords} 
                                        onPageChange={onPage}
                                        className="admin-users-paginator"
                                    />
                                </>
                            )}
                        </div>
                    </div>
                </div>
                
                <Tooltip target=".admin-tooltip-icon" />
            </div>
        </div>
    );
}