import React, { useEffect, useRef, useState } from 'react';
import * as d3 from 'd3';

const UserStatisticsGraph = ({ data, timePeriod, aggregation }) => {
    const d3Container = useRef(null);
    const [dimensions, setDimensions] = useState({ width: 600, height: 400 });

    useEffect(() => {
        function handleResize() {
            if (d3Container.current) {
                const { width } = d3Container.current.getBoundingClientRect();
                setDimensions({ width: width, height: Math.min(400, width * 0.75) });
            }
        }

        window.addEventListener('resize', handleResize);
        handleResize();

        return () => window.removeEventListener('resize', handleResize);
    }, []);

    const getIntelligentTicks = (domain, width, timePeriod, aggregation) => {
        const start = domain[0];
        const end = domain[1];
        const timeSpan = end - start;
        const pixelsPerTick = 50; // Adjust this value to change tick density

        let interval;
        if (aggregation === 'daily') {
            if (width / timeSpan * (24 * 60 * 60 * 1000) > pixelsPerTick) {
                interval = d3.timeDay;
            } else {
                interval = d3.timeWeek;
            }
        } else if (aggregation === 'weekly') {
            if (width / timeSpan * (7 * 24 * 60 * 60 * 1000) > pixelsPerTick) {
                interval = d3.timeWeek;
            } else {
                interval = d3.timeMonth;
            }
        } else { // 'monthly'
            if (width / timeSpan * (30 * 24 * 60 * 60 * 1000) > pixelsPerTick) {
                interval = d3.timeMonth;
            } else {
                interval = d3.timeYear;
            }
        }

        return interval.range(start, end);
    };

    useEffect(() => {
        
        if (!data || data.length === 0) {
            console.log("No data available for rendering");
            return;
        }

        if (d3Container.current) {
            const svg = d3.select(d3Container.current);
            svg.selectAll("*").remove();

            const { width, height } = dimensions;
            const margin = { top: 20, right: 30, bottom: 50, left: 40 };
            const innerWidth = width - margin.left - margin.right;
            const innerHeight = height - margin.top - margin.bottom;

            try {
                // Extract dates for x-axis domain
                const dates = data.map(d => d.date);
                
                const x = d3.scaleTime()
                    .domain(d3.extent(dates))
                    .range([0, innerWidth]);

                // Find the maximum value across all metrics for y-axis domain
                const maxValue = d3.max(data, d => Math.max(
                    d.users || 0, 
                    d.active_users || 0, 
                    d.new_users || 0
                ));
                
                const y = d3.scaleLinear()
                    .domain([0, maxValue || 10]) // Fallback to 10 if maxValue is 0
                    .nice()
                    .range([innerHeight, 0]);

                // Create line generators for each metric
                const totalUsersLine = d3.line()
                    .x(d => x(d.date))
                    .y(d => y(d.users || 0));
                    
                const activeUsersLine = d3.line()
                    .x(d => x(d.date))
                    .y(d => y(d.active_users || 0));
                    
                const newUsersLine = d3.line()
                    .x(d => x(d.date))
                    .y(d => y(d.new_users || 0));

                const g = svg.append("g")
                    .attr("transform", `translate(${margin.left},${margin.top})`);

                // Determine appropriate tick format based on aggregation
                const tickFormat = aggregation === 'monthly' 
                    ? d3.timeFormat("%b %Y") 
                    : d3.timeFormat("%b %d");

                const xAxis = d3.axisBottom(x)
                    .tickValues(getIntelligentTicks(x.domain(), innerWidth, timePeriod, aggregation || 'monthly'))
                    .tickFormat(tickFormat);

                g.append("g")
                    .attr("transform", `translate(0,${innerHeight})`)
                    .call(xAxis)
                    .selectAll("text")
                    .style("text-anchor", "end")
                    .attr("dx", "-.8em")
                    .attr("dy", ".15em")
                    .attr("transform", "rotate(-45)");

                g.append("g")
                    .call(d3.axisLeft(y));

                // Draw lines for each metric
                g.append("path")
                    .datum(data)
                    .attr("fill", "none")
                    .attr("stroke", "#36A2EB")
                    .attr("stroke-width", 1.5)
                    .attr("d", totalUsersLine);
                    
                g.append("path")
                    .datum(data)
                    .attr("fill", "none")
                    .attr("stroke", "#FF6384")
                    .attr("stroke-width", 1.5)
                    .attr("d", activeUsersLine);
                    
                g.append("path")
                    .datum(data)
                    .attr("fill", "none")
                    .attr("stroke", "#4BC0C0")
                    .attr("stroke-width", 1.5)
                    .attr("d", newUsersLine);

                // Add legend
                const legendData = [
                    { label: 'Total Users', color: '#36A2EB' },
                    { label: 'Active Users', color: '#FF6384' },
                    { label: 'New Users', color: '#4BC0C0' }
                ];
                
                const legend = svg.append("g")
                    .attr("font-family", "sans-serif")
                    .attr("font-size", 10)
                    .attr("text-anchor", "end")
                    .selectAll("g")
                    .data(legendData)
                    .enter().append("g")
                    .attr("transform", (d, i) => `translate(0,${i * 20})`);

                legend.append("rect")
                    .attr("x", width - 19)
                    .attr("width", 19)
                    .attr("height", 19)
                    .attr("fill", d => d.color);

                legend.append("text")
                    .attr("x", width - 24)
                    .attr("y", 9.5)
                    .attr("dy", "0.32em")
                    .text(d => d.label);
            } catch (error) {
                console.error("Error rendering chart:", error);
            }
        }
    }, [data, dimensions, timePeriod, aggregation]);

    return (
        <div style={{ width: '100%', height: '100%' }}>
            {(!data || data.length === 0) ? (
                <div className="no-data-message">No data available for the selected time period</div>
            ) : (
                <svg
                    className="d3-component"
                    width={dimensions.width}
                    height={dimensions.height}
                    ref={d3Container}
                />
            )}
        </div>
    );
};

export default UserStatisticsGraph;