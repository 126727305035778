import React, { useState, useEffect } from 'react';
import { MegaMenu } from 'primereact/megamenu';
import { Link } from 'react-router-dom';
import './Navigation.css';
import NavigationMobile from './components/NavigationMobile';

const Navigation = () => {
    const [isMobile, setIsMobile] = useState(window.innerWidth < 1000);

    useEffect(() => {
        const handleResize = () => {
            setIsMobile(window.innerWidth < 1000);
        };

        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    const items = [
        {
            label: 'Platform',
            items: [
                [
                    {
                        items: [
                            {
                                label: 'Platform',
                                template: (item, options) => {
                                    return (
                                        <Link to="/platform" className="megamenu-item-link">
                                            <div className="megamenu-item">
                                                <h3 className="megamenu-title">{item.label}</h3>
                                                <p className="megamenu-description">
                                                    Easy, fully transparent and secure AI-powered Code Due Diligence platform
                                                </p>
                                                <span className="megamenu-link">
                                                    Explore our platform
                                                    <i className="pi pi-angle-right"></i>
                                                </span>
                                            </div>
                                        </Link>
                                    );
                                }
                            }
                        ]
                    }
                ],
                [
                    {
                        items: [
                            {
                                label: 'Why CodeDD',
                                template: (item, options) => {
                                    return (
                                        <Link to="/why-codedd" className="megamenu-item-link">
                                            <div className="megamenu-item">
                                                <h3 className="megamenu-title">{item.label}</h3>
                                                <p className="megamenu-description">
                                                    8 reasons why Investors and Startups use CodeDD
                                                </p>
                                                <span className="megamenu-link">
                                                    Learn more
                                                    <i className="pi pi-angle-right"></i>
                                                </span>
                                            </div>
                                        </Link>
                                    );
                                }
                            }
                        ]
                    }
                ],
                [
                    {
                        items: [
                            {
                                label: 'Security',
                                template: (item, options) => {
                                    return (
                                        <Link to="/security" className="megamenu-item-link">
                                            <div className="megamenu-item">
                                                <h3 className="megamenu-title">{item.label}</h3>
                                                <p className="megamenu-description">
                                                    How CodeDD safeguards your code and Intellectual Property
                                                </p>
                                                <span className="megamenu-link">
                                                    Learn more
                                                    <i className="pi pi-angle-right"></i>
                                                </span>
                                            </div>
                                        </Link>
                                    );
                                }
                            }
                        ]
                    }
                ]
            ]
        },
        {
            label: 'Discover',
            url: '/discover'
        },
        {
            label: 'Pricing',
            url: '/pricing'
        }
    ];

    const start = (
        <Link to="/" className="logo-link">
            <img src="/logo192.png" alt="CodeDD Logo" style={{width: '40px'}} />
        </Link>
    );

    const end = (
        <div className="nav-end-container">
            <Link to="/contact-sales" className="free-trial-button">
                Book a demo
            </Link>
            <Link to="/register" className="login-link">
                <span>Register</span>
            </Link>
            {!isMobile && (
                <Link to="/login" className="login-link">
                    <span>Login</span>
                </Link>
            )}
        </div>
    );

    if (isMobile) {
        return <NavigationMobile items={items} />;
    }

    return (
        <header className="sticky-header">
            <MegaMenu
                model={items}
                orientation="horizontal"
                start={start}
                end={end}
                className="codedd-megamenu"
            />
        </header>
    );
};

export default Navigation;