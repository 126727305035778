import React, { useEffect, useRef, useState, useCallback } from 'react';
import * as d3 from 'd3';
import { Tooltip } from 'primereact/tooltip';
import { InputText } from 'primereact/inputtext';
import './DevelopmentActivityGraph.css';

const DevelopmentActivityGraph = ({ gitStats, auditUuid }) => {
    const svgRef = useRef(null);
    const overviewRef = useRef(null);  // New ref for overview graph
    const containerRef = useRef(null);
    const [dimensions, setDimensions] = useState({ width: 0, height: 400 });
    const [customTimeRange, setCustomTimeRange] = useState(null);
    const [searchQuery, setSearchQuery] = useState('');
    const [selectedDevelopers, setSelectedDevelopers] = useState(new Set());
    const [availableDevelopers, setAvailableDevelopers] = useState([]);
    const [tooltipContent, setTooltipContent] = useState('');
    const [tooltipPosition, setTooltipPosition] = useState({ x: 0, y: 0 });
    const [showTooltip, setShowTooltip] = useState(false);
    const [activeDevelopers, setActiveDevelopers] = useState(new Set());
    const [developerColors, setDeveloperColors] = useState(new Map());
    const [aggregatedStats, setAggregatedStats] = useState(null);
    const [loading, setLoading] = useState(false);
    const [timeScale, setTimeScale] = useState(null);
    const [processedDataCache, setProcessedDataCache] = useState(new Map());
    const [showTopContributors, setShowTopContributors] = useState(false);
    const [avatarUrl, setAvatarUrl] = useState(null);
    const [isTransitioning, setIsTransitioning] = useState(false);

    // Add data processing utilities
    const processDataForTimeWindow = useCallback((startDate, endDate, padding = 0.2) => {
        if (!startDate || !endDate || !aggregatedStats) return [];

        const cacheKey = `${startDate.getTime()}-${endDate.getTime()}-${Array.from(selectedDevelopers).join(',')}`;
        if (processedDataCache.has(cacheKey)) {
            return processedDataCache.get(cacheKey);
        }

        // Calculate padded window to prevent edge effects
        const timeRange = endDate - startDate;
        const paddedStart = new Date(startDate.getTime() - timeRange * padding);
        const paddedEnd = new Date(endDate.getTime() + timeRange * padding);

        // Only process data for selected developers
        const developerData = Array.from(selectedDevelopers).map(developer => {
            const dailyCommits = aggregatedStats?.time_based_stats?.activity_periods?.daily_commits_by_author[developer] || {};
            const commits = [];

            // Always include all dates in the range, with zero values for days without commits
            let currentDate = new Date(paddedStart);
            while (currentDate <= paddedEnd) {
                const dateStr = currentDate.toISOString().split('T')[0];
                commits.push({
                    date: new Date(currentDate),
                    count: dailyCommits[dateStr] || 0
                });
                currentDate.setDate(currentDate.getDate() + 1);
            }

            return {
                developer,
                commits,
                color: developerColors.get(developer)
            };
        });

        // Cache the processed data
        setProcessedDataCache(prevCache => {
            const newCache = new Map(prevCache);
            newCache.set(cacheKey, developerData);
            if (newCache.size > 10) {
                const oldestKey = Array.from(newCache.keys())[0];
                newCache.delete(oldestKey);
            }
            return newCache;
        });

        return developerData;
    }, [selectedDevelopers, aggregatedStats, developerColors]);

    // Add data smoothing utility
    const smoothData = useCallback((data, smoothingFactor = 0.3) => {
        if (!data || data.length <= 2) return data;

        // Calculate time difference between points to adjust smoothing
        const timeScale = (data[data.length - 1].date - data[0].date) / (1000 * 60 * 60 * 24); // in days
        const dynamicSmoothingFactor = Math.min(smoothingFactor, 0.8 * (30 / timeScale)); // Adjust smoothing based on time range

        // Use d3's curve factory for smoothing
        const smoothLine = d3.line()
            .x(d => d.date)
            .y(d => d.count)
            .curve(d3.curveCatmullRom.alpha(dynamicSmoothingFactor));

        // Generate smoothed points
        const points = data.map(d => [d.date, d.count]);
        const smoothedPath = smoothLine(points);

        // Convert back to our data format
        return data.map((d, i) => ({
            date: d.date,
            count: d.count,
            smoothed: points[i][1]
        }));
    }, []);

    // Add data decimation utility with zero preservation
    const decimateData = useCallback((data, maxPoints = 200) => {
        if (!data || data.length <= maxPoints) return data;

        const timeRange = data[data.length - 1].date - data[0].date;
        const stride = Math.ceil(data.length / maxPoints);

        // Keep track of maximum value in each stride window
        const decimated = [];
        for (let i = 0; i < data.length; i += stride) {
            const window = data.slice(i, Math.min(i + stride, data.length));
            const maxInWindow = window.reduce((max, point) => point.count > max.count ? point : max, window[0]);
            
            // Always include zero points at transitions
            if (decimated.length > 0 && decimated[decimated.length - 1].count > 0 && maxInWindow.count === 0) {
                decimated.push(window[0]); // Include first zero
            }
            decimated.push(maxInWindow);
            
            // Include last point in window if it's different
            const lastInWindow = window[window.length - 1];
            if (lastInWindow !== maxInWindow && lastInWindow.count !== maxInWindow.count) {
                decimated.push(lastInWindow);
            }
        }

        return decimated;
    }, []);

    // Effect to process data from props only
    useEffect(() => {
        if (gitStats && !aggregatedStats) {
            setAggregatedStats({
                time_based_stats: gitStats.timeBasedStats,
                author_stats: gitStats.authorStats,
                code_churn_stats: gitStats.codeChurnStats,
                collaboration_stats: gitStats.collaborationStats,
                release_stats: gitStats.releaseStats,
                branch_stats: gitStats.branchStats,
                merge_stats: gitStats.mergeStats,
                meta_info: gitStats.metaInfo,
                repository_info: gitStats.repositoryInfo,
                development_analysis: gitStats.developmentAnalysis,
                developer_expertise: gitStats.developerExpertise
            });
        }
    }, [gitStats]);

    // Effect to set initial time range based on first commit
    useEffect(() => {
        if (!aggregatedStats?.time_based_stats?.activity_periods?.first_commit_date) return;
        
        const firstCommitDate = new Date(aggregatedStats.time_based_stats.activity_periods.first_commit_date);
        const lastCommitDate = new Date(aggregatedStats.time_based_stats.activity_periods.last_commit_date || new Date());
        
        const totalTimeSpan = lastCommitDate - firstCommitDate;
        const fiveDaysInMs = 5 * 24 * 60 * 60 * 1000; // 5 days in milliseconds

        // If total time span is less than 5 days, show entire range
        if (totalTimeSpan <= fiveDaysInMs) {
        setCustomTimeRange({
                start: firstCommitDate,
                end: lastCommitDate
            });
        } else {
            // Show at least 5 days or 5% of total time, whichever is larger
            const timeWindow = Math.max(fiveDaysInMs, totalTimeSpan * 0.05);
            setCustomTimeRange({
                start: firstCommitDate,
                end: new Date(firstCommitDate.getTime() + timeWindow)
            });
        }
    }, [aggregatedStats]);

    // Effect to update active developers based on the current time window
    useEffect(() => {
        if (!aggregatedStats?.time_based_stats?.activity_periods?.daily_commits_by_author) {
            return;
        }

        const dailyCommitsByAuthor = aggregatedStats.time_based_stats.activity_periods.daily_commits_by_author;
        const startDate = customTimeRange?.start || new Date();
        const endDate = customTimeRange?.end || new Date();

        const active = new Set();
        Object.entries(dailyCommitsByAuthor).forEach(([developer, commits]) => {
            // Check if developer has commits within the selected time range
            const hasCommitsInRange = Object.entries(commits).some(([dateStr, count]) => {
                const commitDate = new Date(dateStr);
                return count > 0 && commitDate >= startDate && commitDate <= endDate;
            });
            
            if (hasCommitsInRange) {
                active.add(developer);
            }
        });

        setActiveDevelopers(active);
        
        // Only update selected developers if none are currently selected
        if (selectedDevelopers.size === 0) {
            setSelectedDevelopers(active);
        }
    }, [aggregatedStats, customTimeRange]);

    // Single initialization effect for developers
    useEffect(() => {
        const authorStats = aggregatedStats?.author_stats;
        const dailyCommitsByAuthor = aggregatedStats?.time_based_stats?.activity_periods?.daily_commits_by_author;
        const firstCommitDate = aggregatedStats?.time_based_stats?.activity_periods?.first_commit_date;
        
        if (!authorStats || !dailyCommitsByAuthor || !firstCommitDate) {
            return;
        }

        // Get all developers sorted by commit count
        const sortedDevelopers = Object.entries(authorStats)
            .map(([developer, stats]) => ({
                developer,
                commitCount: stats.commit_count || 0
            }))
            .sort((a, b) => b.commitCount - a.commitCount);

        // Set available developers
        const developers = sortedDevelopers.map(d => d.developer);
        setAvailableDevelopers(developers);

        // Initialize developer colors
        const colorScale = d3.scaleOrdinal(d3.schemeCategory10);
        const newColors = new Map();
        
        developers.forEach((developer, index) => {
            newColors.set(developer, colorScale(index));
        });
        
        setDeveloperColors(newColors);

        // Find developer with most contributions in the first month
        const firstMonth = new Date(firstCommitDate);
        const endOfFirstMonth = new Date(firstMonth.getFullYear(), firstMonth.getMonth() + 1, 0);
        
        const earlyContributions = developers.map(developer => {
            let count = 0;
            const commits = dailyCommitsByAuthor[developer] || {};
            
            Object.entries(commits).forEach(([dateStr, commitCount]) => {
                const date = new Date(dateStr);
                if (date >= firstMonth && date <= endOfFirstMonth) {
                    count += commitCount;
                }
            });
            
            return { developer, count };
        });

        // Sort by early contributions and select the top contributor
        const topEarlyContributor = earlyContributions
            .sort((a, b) => b.count - a.count)
            .find(d => d.count > 0);

        if (topEarlyContributor) {
            setSelectedDevelopers(new Set([topEarlyContributor.developer]));
        } else {
            // Fallback to most commits overall if no early contributions found
            setSelectedDevelopers(new Set([developers[0]]));
        }

        // Set active developers (top 20 by commit count)
        setActiveDevelopers(new Set(developers.slice(0, 20)));
    }, [aggregatedStats?.author_stats, aggregatedStats?.time_based_stats]);

    // Update dimensions when container size changes
    useEffect(() => {
        if (!containerRef.current) return;

        const updateDimensions = () => {
            const containerWidth = containerRef.current.getBoundingClientRect().width;
            if (containerWidth > 0) {
                setDimensions({ width: containerWidth, height: 400 });
            }
        };

        // Initial update
        updateDimensions();

        // Update dimensions on window resize
        window.addEventListener('resize', updateDimensions);

        // Use ResizeObserver for container size changes
        const resizeObserver = new ResizeObserver(() => {
            updateDimensions();
        });

        resizeObserver.observe(containerRef.current);

        return () => {
            window.removeEventListener('resize', updateDimensions);
            resizeObserver.disconnect();
        };
    }, []);

    // Modify the main graph rendering effect
    useEffect(() => {
        if (!dimensions.width || !svgRef.current || loading || !customTimeRange) {
            return;
        }

        // Clear existing content
        const svgElement = d3.select(svgRef.current);
        svgElement.selectAll("*").remove();

        // Setup margins and dimensions
        const margin = { 
            top: 20,
            right: 30, 
            bottom: 50,
            left: 60
        };
        const width = dimensions.width - margin.left - margin.right;
        const height = dimensions.height - margin.top - margin.bottom;

        // Create main SVG with clipping path
        const svg = svgElement
            .attr("width", width + margin.left + margin.right)
            .attr("height", height + margin.top + margin.bottom)
            .append("g")
            .attr("transform", `translate(${margin.left},${margin.top})`);

        // Add clipping path for data area
        svg.append("defs")
            .append("clipPath")
            .attr("id", "clip")
            .append("rect")
            .attr("width", width)
            .attr("height", height);

        // Process data for current window with padding
        const processedData = processDataForTimeWindow(customTimeRange.start, customTimeRange.end);

        // Calculate max commits for visible range
        const maxCommits = Math.max(
            ...processedData.map(data => 
                Math.max(...data.commits.map(c => c.count))
            ),
            1
        );

        // Setup scales
        const x = d3.scaleTime()
            .domain([customTimeRange.start, customTimeRange.end])
            .range([0, width])
            .nice();

        const y = d3.scaleLinear()
            .domain([0, maxCommits + (maxCommits * 0.1)])
            .range([height, 0]);

        // Add X axis
        const xAxis = svg.append("g")
            .attr("class", "x-axis")
            .attr("transform", `translate(0,${height})`)
            .call(d3.axisBottom(x)
                .ticks(Math.min(width / 80, 10))
                .tickFormat(d3.timeFormat("%Y-%m-%d")))
            .attr("color", "#9CA3AF")
            .style("font-size", "11px");

        // Rotate x-axis labels
        xAxis.selectAll("text")
            .style("text-anchor", "end")
            .attr("dx", "-.8em")
            .attr("dy", ".15em")
            .attr("transform", "rotate(-45)");

        // Add Y axis
        const yAxis = svg.append("g")
            .attr("class", "y-axis")
            .call(d3.axisLeft(y).ticks(5))
            .attr("color", "#9CA3AF")
            .style("font-size", "11px");

        // Add grid lines
        svg.selectAll("grid-line")
            .data(y.ticks(5))
            .enter()
            .append("line")
            .attr("class", "grid-line")
            .attr("x1", 0)
            .attr("x2", width)
            .attr("y1", d => y(d))
            .attr("y2", d => y(d))
            .attr("stroke", "#E5E7EB")
            .attr("stroke-width", 1)
            .attr("stroke-dasharray", "2,2");

        // Add Y axis label
        svg.append("text")
            .attr("transform", "rotate(-90)")
            .attr("y", -margin.left + 15)
            .attr("x", -(height / 2))
            .attr("dy", "1em")
            .style("text-anchor", "middle")
            .style("fill", "#6B7280")
            .style("font-size", "14px")
            .text("Commits per developer");

        // Create container for data visualization with clipping
        const chartArea = svg.append("g")
            .attr("clip-path", "url(#clip)");

        // Render data if available
        if (processedData.length > 0) {
            processedData.forEach((data, i) => {
                // Decimate data points based on zoom level
                const decimatedCommits = decimateData(data.commits);
                
                // Apply smoothing
                const smoothedCommits = smoothData(decimatedCommits);

                // Create line generator with smooth curve
                const line = d3.line()
                    .defined(d => true) // Always define the line to include zero points
                    .x(d => x(d.date))
                    .y(d => y(d.count))
                    .curve(d3.curveMonotoneX);

                // Create area generator
                const area = d3.area()
                    .defined(d => true) // Always define the area to include zero points
                    .x(d => x(d.date))
                    .y0(height)
                    .y1(d => y(d.count))
                    .curve(d3.curveMonotoneX);

                // Add area to clipped container
                chartArea.append("path")
                    .datum(smoothedCommits)
                    .attr("class", `area-${i}`)
                    .attr("fill", data.color)
                    .attr("fill-opacity", 0.1)
                    .attr("d", area);

                // Add line to clipped container
                chartArea.append("path")
                    .datum(smoothedCommits)
                    .attr("class", `line-${i}`)
                    .attr("fill", "none")
                    .attr("stroke", data.color)
                    .attr("stroke-width", 2)
                    .attr("d", line);

                // Only add dots for significant commits
                const significantCommits = smoothedCommits.filter(d => d.count > maxCommits * 0.05); // Reduced threshold
                chartArea.selectAll(`.dot-${i}`)
                    .data(significantCommits)
                    .enter()
                    .append("circle")
                    .attr("class", `dot-${i} tooltip-dot`)
                    .attr("cx", d => x(d.date))
                    .attr("cy", d => y(d.count))
                    .attr("r", 4)
                    .style("fill", data.color)
                    .style("opacity", 0.7)
                    .on("mouseover", function(event, d) {
                        if (!d || !d.date || !d.count) return;
                        
                        d3.select(this)
                            .transition()
                            .duration(200)
                            .attr("r", 6)
                            .style("opacity", 1);

                        const content = `${data.developer.split('<')[0].trim()}\n${d.count.toLocaleString()} commits on ${d.date.toLocaleDateString()}`;
                        setTooltipContent(content);
                        
                        const containerRect = containerRef.current.getBoundingClientRect();
                        const dotRect = this.getBoundingClientRect();
                        
                        setTooltipPosition({
                            x: dotRect.left - containerRect.left + dotRect.width,
                            y: dotRect.top - containerRect.top
                        });
                        setShowTooltip(true);
                    })
                    .on("mouseout", function() {
                        d3.select(this)
                            .transition()
                            .duration(200)
                            .attr("r", 4)
                            .style("opacity", 0.7);
                        setShowTooltip(false);
                    });
            });
        }
    }, [dimensions, customTimeRange, processDataForTimeWindow, decimateData, smoothData, loading, selectedDevelopers]);

    // Add new effect for overview graph
    useEffect(() => {
        if (!aggregatedStats?.time_based_stats || !dimensions.width || !overviewRef.current || loading) return;

        const overviewHeight = 50;
        const markerHeight = 15; // Height for the markers section
        const totalHeight = overviewHeight + markerHeight;
        const margin = { top: 0, right: 0, bottom: markerHeight, left: 0 };
        const width = dimensions.width;
        const height = overviewHeight - margin.top - margin.bottom;
        const markerLineHeight = 8; // Height of each marker line

        d3.select(overviewRef.current).selectAll("*").remove();

        const svg = d3.select(overviewRef.current)
            .attr("width", width)
            .attr("height", totalHeight)
            .append("g");

        // Get the full time range data
        const firstCommitDate = new Date(aggregatedStats.time_based_stats.activity_periods.first_commit_date);
        const lastCommitDate = new Date(aggregatedStats.time_based_stats.activity_periods.last_commit_date || new Date());
        
        // Calculate window position based on customTimeRange
        const windowStartDate = customTimeRange ? customTimeRange.start : new Date(lastCommitDate - ((lastCommitDate - firstCommitDate) * 0.1));
        const windowEndDate = customTimeRange ? customTimeRange.end : lastCommitDate;

        // Calculate window width based on dates
        const timeScale = d3.scaleTime()
            .domain([firstCommitDate, lastCommitDate])
            .range([0, width]);
        
        const windowWidth = timeScale(windowEndDate) - timeScale(windowStartDate);

        // Get total commits data for all time
        const totalCommitsData = Object.entries(aggregatedStats.time_based_stats.activity_periods?.total_daily_commits || {})
            .map(([date, count]) => ({
                date: new Date(date),
                count: count || 0
            }))
            .filter(d => {
                const dateObj = new Date(d.date);
                return !isNaN(dateObj.getTime()) && typeof d.count === 'number';
            })
            .sort((a, b) => a.date - b.date);

        if (totalCommitsData.length === 0) return;

        // Setup scales
        const x = d3.scaleTime()
            .domain([firstCommitDate, lastCommitDate])
            .range([0, width]);

        const y = d3.scaleLinear()
            .domain([0, d3.max(totalCommitsData, d => d.count)])
            .range([height, 0]);

        // Create area generator
        const area = d3.area()
            .x(d => x(d.date))
            .y0(height)
            .y1(d => y(d.count))
            .curve(d3.curveMonotoneX);

        // Define gradient
        const gradient = svg.append("defs")
            .append("linearGradient")
            .attr("id", "area-gradient")
            .attr("gradientUnits", "userSpaceOnUse")
            .attr("x1", 0)
            .attr("y1", 0)
            .attr("x2", 0)
            .attr("y2", height);

        gradient.append("stop")
            .attr("offset", "0%")
            .attr("stop-color", "#2CB392")
            .attr("stop-opacity", 1);

        gradient.append("stop")
            .attr("offset", "100%")
            .attr("stop-color", "#2CB392")
            .attr("stop-opacity", 0.3);

        // Add the area with gradient (moved up by markerHeight)
        svg.append("path")
            .datum(totalCommitsData)
            .attr("class", "dev-activity-overview-area")
            .attr("transform", `translate(0,${margin.top})`)
            .attr("d", area)
            .attr("fill", "url(#area-gradient)")
            .attr("stroke", "#2CB392")
            .attr("stroke-width", 1);

        // Get selected developer's commit dates
        const selectedDeveloper = Array.from(selectedDevelopers)[0];
        if (selectedDeveloper) {
            const developerCommits = Object.entries(aggregatedStats.time_based_stats.activity_periods?.daily_commits_by_author[selectedDeveloper] || {})
                .map(([date, count]) => ({
                    date: new Date(date),
                    count
                }))
                .filter(d => d.count > 0)
                .sort((a, b) => a.date - b.date);

            // Add commit indicators below the graph
            const indicatorsGroup = svg.append("g")
                .attr("class", "developer-commit-indicators")
                .attr("transform", `translate(0,${height + margin.top + 5})`); // 5px padding from graph

            indicatorsGroup.selectAll("line")
                .data(developerCommits)
                .enter()
                .append("line")
                .attr("x1", d => x(d.date))
                .attr("x2", d => x(d.date))
                .attr("y1", 0)
                .attr("y2", markerLineHeight)
                .attr("stroke", developerColors.get(selectedDeveloper))
                .attr("stroke-width", 2)
                .attr("opacity", 0.8);
        }

        // Add the time window overlay (moved up by markerHeight)
        const timeWindow = svg.append("rect")
            .attr("class", "time-window")
            .attr("transform", `translate(0,${margin.top})`)
            .attr("x", timeScale(windowStartDate))
            .attr("y", 0)
            .attr("width", windowWidth)
            .attr("height", height)
            .attr("fill", "rgba(44, 179, 146, 0.3)")
            .attr("stroke", "#2CB392")
            .attr("stroke-width", 1)
            .style("cursor", "move");

        let isWiggling = false;
        let isBeingDragged = false;
        let hasWiggled = false;  // Track if initial wiggle has happened

        // Add wiggle animation
        const wiggleAnimation = () => {
            if (isBeingDragged || hasWiggled) return;
            
            isWiggling = true;
            const currentX = parseFloat(timeWindow.attr("x"));
            const maxOffset = Math.min(20, width * 0.03); // Reduced offset
            
            timeWindow
                .transition()
                .duration(800)
                .ease(d3.easeSinInOut)
                .attr("x", currentX - maxOffset)
                .transition()
                .duration(800)
                .ease(d3.easeSinInOut)
                .attr("x", currentX + maxOffset)
                .transition()
                .duration(800)
                .ease(d3.easeSinInOut)
                .attr("x", currentX)
                .on("end", () => {
                    isWiggling = false;
                    hasWiggled = true;  // Mark that wiggle has happened
                });
        };

        // Make the time window draggable
        const drag = d3.drag()
            .on("start", function(event) {
                isBeingDragged = true;
                hasWiggled = true;
                if (isWiggling) {
                    timeWindow.interrupt();
                    isWiggling = false;
                }
                
                // Add dragging class to parent SVG
                d3.select(overviewRef.current).classed("time-window-dragging", true);
                
                timeWindow
                    .attr("fill", "rgba(44, 179, 146, 0.1)")
                    .attr("stroke-width", 2);
                
                // Store initial positions
                const currentX = parseFloat(timeWindow.attr("x"));
                
                d3.select(this)
                    .attr("data-initial-x", currentX)
                    .attr("data-initial-mouse-x", event.sourceEvent.clientX);
            })
            .on("drag", function(event) {
                const initialX = parseFloat(d3.select(this).attr("data-initial-x"));
                const initialMouseX = parseFloat(d3.select(this).attr("data-initial-mouse-x"));
                const currentMouseX = event.sourceEvent.clientX;
                const dx = currentMouseX - initialMouseX;
                let newX = initialX + dx;
                
                // Constrain the window within the overview graph
                newX = Math.max(0, Math.min(width - windowWidth, newX));
                
                // Update window position immediately
                timeWindow.attr("x", newX);
                
                // Calculate new dates based on drag position
                const newStartDate = timeScale.invert(newX);
                const newEndDate = timeScale.invert(newX + windowWidth);
                
                // Update the main graph's time range immediately
                setCustomTimeRange({
                    start: new Date(newStartDate),
                    end: new Date(newEndDate)
                });
            })
            .on("end", function() {
                isBeingDragged = false;
                
                // Remove dragging class
                d3.select(overviewRef.current).classed("time-window-dragging", false);
                
                // Restore original styling
                timeWindow
                    .attr("fill", "rgba(44, 179, 146, 0.3)")
                    .attr("stroke-width", 1);
            });

        timeWindow.call(drag);

        // Start wiggle animation after a delay (only if it hasn't happened yet)
        const timer = setTimeout(wiggleAnimation, 1000);

        // Cleanup
        return () => {
            clearTimeout(timer);
            if (timeWindow) {
                timeWindow.interrupt();
            }
        };

    }, [aggregatedStats, dimensions, customTimeRange, selectedDevelopers, developerColors]);

    // Add useEffect to position the timeframe window on mount
    useEffect(() => {
        if (!overviewRef.current || !customTimeRange || !timeScale) return;

        const timeWindow = d3.select(overviewRef.current).select(".time-window");
        if (!timeWindow.empty()) {
            const newX = timeScale(customTimeRange.start);
            const newWidth = timeScale(customTimeRange.end) - newX;
            
            timeWindow
                .attr("x", newX)
                .attr("width", newWidth);
        }
    }, [customTimeRange, timeScale]);

    // Filter developers based on search
    const filteredDevelopers = availableDevelopers.filter(dev => 
        dev.toLowerCase().includes(searchQuery.toLowerCase())
    );

    // Update the developer selection handler
    const handleDeveloperSelection = (developer) => {
        setIsTransitioning(true);
        setTimeout(() => {
            setSelectedDevelopers(new Set([developer]));
            setIsTransitioning(false);
        }, 300); // Match the CSS transition duration
    };

    // Update the renderDeveloperChips function
    const renderDeveloperChips = () => {
        // Get either top contributors or active developers based on toggle
        let activeDevs;
        if (showTopContributors) {
            // Get top 5 contributors by total commits
            activeDevs = availableDevelopers
                .filter(dev => !searchQuery || dev.toLowerCase().includes(searchQuery.toLowerCase()))
                .sort((a, b) => {
                    const aStats = aggregatedStats?.author_stats?.[a];
                    const bStats = aggregatedStats?.author_stats?.[b];
                    return (bStats?.commit_count || 0) - (aStats?.commit_count || 0);
                })
                .slice(0, 5);
        } else {
                    // Get active developers that match search
            activeDevs = filteredDevelopers
                        .filter(developer => activeDevelopers.has(developer))
                        .filter(developer => 
                            !searchQuery || 
                            developer.toLowerCase().includes(searchQuery.toLowerCase())
                )
                .sort((a, b) => {
                    const aStats = aggregatedStats?.author_stats?.[a];
                    const bStats = aggregatedStats?.author_stats?.[b];
                        return (bStats?.commit_count || 0) - (aStats?.commit_count || 0);
                    });
        }

        const displayDevs = activeDevs.slice(0, 4);
        const remainingCount = activeDevs.length - 4;

                    return (
                        <>
                <div className="dev-activity-toggle" onClick={() => setShowTopContributors(!showTopContributors)}>
                    {showTopContributors ? "Show contributors from time frame" : "Show most contributors"}
                </div>
                            {displayDevs.map(developer => {
                                const isSelected = selectedDevelopers.has(developer);
                                const developerColor = developerColors.get(developer);
                    const commitCount = aggregatedStats?.author_stats?.[developer]?.commit_count || 0;
                                
                                let chipStyle;
                                if (isSelected) {
                                    chipStyle = {
                            backgroundColor: developerColor + 'CC',
                                        color: 'white',
                                        boxShadow: '0 2px 4px rgba(0,0,0,0.1)',
                                        border: `2px solid ${developerColor}`
                                    };
                                } else {
                                    chipStyle = {
                            backgroundColor: '#FFFFFF',
                                        color: '#8D8D8D',
                                        boxShadow: 'none',
                            border: `2px solid ${developerColor}99`
                                    };
                                }

                                return (
                                    <div
                                        key={developer}
                                        className={`dev-activity-developer-chip ${isSelected ? 'selected' : ''}`}
                                        style={chipStyle}
                                        onClick={() => handleDeveloperSelection(developer)}
                        >
                            <span className="dev-name">{developer.split('<')[0].trim()}</span>
                            <span className="dev-commit-count">({commitCount} commits)</span>
                                    </div>
                                );
                            })}
                            {remainingCount > 0 && (
                                <div
                                    className="dev-activity-developer-chip remaining-chip"
                                    style={{
                                        backgroundColor: 'white',
                                        color: '#8D8D8D',
                                        border: '2px solid #E5E7EB',
                                        boxShadow: 'none'
                                    }}
                                >
                        +{remainingCount} contributor{remainingCount === 1 ? '' : 's'} in this time frame
                                </div>
                            )}
                        </>
                    );
    };

    // Update the months calculation function
    const getMonthsBetween = (startDate, endDate) => {
        if (!startDate || !endDate) return 0;
        const start = new Date(startDate);
        const end = new Date(endDate);
        
        // Calculate full months between dates
        const years = end.getFullYear() - start.getFullYear();
        const months = end.getMonth() - start.getMonth();
        const totalMonths = (years * 12) + months;
        
        // Add partial month if there are remaining days
        const dayDiff = end.getDate() - start.getDate();
        const partialMonth = dayDiff > 0 ? 1 : 0;
        
        return Math.max(0, totalMonths + partialMonth);
    };

    // Add utility function for formatting date to MM/YYYY
    const formatDateMMYYYY = (dateString) => {
        const date = new Date(dateString);
        return `${String(date.getMonth() + 1).padStart(2, '0')}/${date.getFullYear()}`;
    };

    // Update the avatar fetching function
    const getAvatarUrl = useCallback(async (developerString) => {
        try {
            // Try to extract GitHub username from common patterns
            const githubPatterns = [
                /github\.com\/([^\/\s]+)/i,  // Match github.com/username
                /github\.com:([^\/\s]+)/i,    // Match github.com:username
                /([^\/\s@]+)@github\.com/i    // Match username@github.com
            ];
            
            for (const pattern of githubPatterns) {
                const match = developerString.match(pattern);
                if (match && match[1]) {
                    const username = match[1];
                    // Try to fetch the avatar directly from GitHub profile
                    const response = await fetch(`https://github.com/${username}.png`);
                    if (response.ok) {
                        return response.url;
                    }
                }
            }
            
            // If no GitHub username found, try to extract a name
            const nameMatch = developerString.match(/(.*?)\s*(?:<|$)/);
            if (nameMatch && nameMatch[1]) {
                const name = nameMatch[1].trim();
                // Use name initials for a default avatar
                const initials = name
                    .split(/\s+/)
                    .map(word => word[0])
                    .join('')
                    .toUpperCase()
                    .slice(0, 2);
                    
                // Create a data URL for an SVG avatar with initials
                const svg = `
                    <svg width="200" height="200" xmlns="http://www.w3.org/2000/svg">
                        <rect width="100%" height="100%" fill="#E5E7EB"/>
                        <text x="50%" y="50%" font-family="Arial" font-size="80" 
                            fill="#6B7280" text-anchor="middle" dy=".3em">${initials}</text>
                    </svg>
                `;
                return `data:image/svg+xml;base64,${btoa(svg)}`;
            }
            
            return null;
        } catch (error) {
            console.error('Error fetching avatar:', error);
            return null;
        }
    }, []);

    // Effect to fetch avatar when developer changes
    useEffect(() => {
        const selectedDeveloper = Array.from(selectedDevelopers)[0];
        if (selectedDeveloper) {
            getAvatarUrl(selectedDeveloper).then(url => setAvatarUrl(url));
        }
    }, [selectedDevelopers, getAvatarUrl]);

    // Update the developer info rendering
    const renderDeveloperInfo = () => {
        const selectedDeveloper = Array.from(selectedDevelopers)[0];
        if (!selectedDeveloper || !aggregatedStats?.author_stats?.[selectedDeveloper]) {
            return null;
        }

        // Normalize the email to match the developer_expertise keys
        const normalizeEmail = (email) => {
            // Extract email from format like "Name <email>"
            const match = email.match(/<(.+?)>/);
            return match ? match[1] : email;
        };

        const normalizedEmail = normalizeEmail(selectedDeveloper);
        const devStats = aggregatedStats.author_stats[selectedDeveloper];
        const devExpertise = aggregatedStats?.developer_expertise?.[normalizedEmail] || {};
        
        const firstCommit = devStats.first_commit;
        const lastCommit = devStats.last_commit;
        const monthsActive = getMonthsBetween(firstCommit, lastCommit);
        const name = devExpertise.name || selectedDeveloper.split('<')[0].trim();

        // Get developer-specific code churn stats from the author stats
        const devChurn = devStats.code_churn || {};
        const devAdditions = devChurn.additions || 0;
        const devDeletions = devChurn.deletions || 0;
        const ratio = devDeletions / (devAdditions || 1);

        // Process domain contributions for visualization
        const domainContributions = devExpertise.domain_contributions || {};
        
        const totalContributions = Object.values(domainContributions).reduce((a, b) => a + b, 0);
        
        const sortedDomains = Object.entries(domainContributions)
            .sort((a, b) => b[1] - a[1])
            .map(([domain, count]) => ({
                domain,
                count,
                percentage: totalContributions > 0 ? (count / totalContributions) * 100 : 0
            }));

        // Get domain colors
        const getDomainColor = (domain) => {
            const colors = {
                'Backend': '#2CB392',
                'Frontend': '#32AFC3',
                'Infrastructure': '#6366F1',
                'Database': '#8B5CF6',
                'Testing': '#EC4899',
                'Documentation': '#F59E0B'
            };
            return colors[domain] || '#9CA3AF';
        };

        // Update the domain bars rendering
        const renderDomainBars = (sortedDomains) => {
            return sortedDomains.slice(0, 3).map(({ domain, count, percentage }) => (
                <div 
                    key={domain} 
                    className={`domain-bar-container ${isTransitioning ? 'exiting' : ''}`}
                    data-pr-tooltip={`User contributed in ${count} ${domain.toLowerCase()} file${count !== 1 ? 's' : ''}`}
                >
                    <div className="domain-label">
                        <span className="domain-label-text">{domain}</span>
                        <span className="domain-count">{count}</span>
                    </div>
                    <div className="domain-bar-wrapper">
                        <div 
                            className="domain-bar"
                            style={{ 
                                width: `${percentage}%`,
                                backgroundColor: getDomainColor(domain)
                            }}
                        />
                    </div>
                </div>
            ));
        };

        // Update the remaining domains text rendering
        const renderRemainingDomains = (sortedDomains) => {
            if (sortedDomains.length <= 3) return null;
            
            return (
                <div 
                    className={`remaining-domains-text ${isTransitioning ? 'exiting' : ''}`}
                    data-pr-tooltip={
                        "User contributed in the domains: " + 
                        sortedDomains.slice(3).map(({ domain, count }) => 
                            `${domain} (${count} file${count !== 1 ? 's' : ''})`)
                        .join(', ')
                    }
                >
                    +{sortedDomains.length - 3} domains with in total {
                        sortedDomains.slice(3).reduce((acc, { count }) => acc + count, 0)
                    } files
                </div>
            );
        };

        return (
            <div className="dev-activity-developer-info-content">
                <div className="dev-info-header">
                    <span className="dev-info-label">Contributor Information</span>
                    <div className="dev-info-profile">
                        {avatarUrl && (
                            <img 
                                src={avatarUrl} 
                                alt={name} 
                                className="dev-info-avatar"
                                onError={(e) => e.target.style.display = 'none'}
                            />
                        )}
                        <div className="dev-info-name-container">
                            <span className="dev-info-name">{name}</span>
                        </div>
                    </div>
                </div>

                <div className="dev-info-data">
                    <div className="dev-info-row">
                        <span className="dev-info-label">Active Development:</span>
                        <span className="dev-info-value">
                            {`${formatDateMMYYYY(firstCommit)} - ${formatDateMMYYYY(lastCommit)} (${monthsActive} months)`}
                        </span>
                    </div>

                    <div className="dev-info-row">
                        <span className="dev-info-label">Contributed to files:</span>
                        <span className="dev-info-value">
                            <a 
                                href={`/${auditUuid}/files?contributor=${encodeURIComponent(selectedDeveloper)}`}
                                className="dev-info-link"
                            >
                                {devExpertise.total_files_w_domains || 0} file{devExpertise.total_files_w_domains !== 1 ? 's' : ''} within audit
                            </a>
                            {` (${devExpertise.total_files || 0} total file${devExpertise.total_files !== 1 ? 's' : ''})`}
                        </span>
                    </div>

                    <div className="dev-info-row">
                        <span className="dev-info-label">Code Changes Ratio:</span>
                        <div className="dev-info-value ratio-container">
                            <span className="ratio-text">
                                {`1 : ${Math.round(ratio * 100) / 100}`}
                            </span>
                            <div className="ratio-bar-container" 
                                 data-pr-tooltip={`Additions: ${devAdditions.toLocaleString()} lines - Deletions: ${devDeletions.toLocaleString()} lines`}>
                                <svg className="ratio-bar" width="100%" height="8" preserveAspectRatio="none">
                                    <rect
                                        x="0"
                                        y="0"
                                        width="100%"
                                        height="8"
                                        fill="#F3F4F6"
                                        rx="4"
                                    />
                                    {devAdditions > 0 && (
                                        <rect
                                            className="additions"
                                            x="0"
                                            y="0"
                                            width={`${(devAdditions / (devAdditions + devDeletions)) * 100 - 0.5}%`}
                                            height="8"
                                            rx={devDeletions > 0 ? "4 0 0 4" : "4"}
                                        />
                                    )}
                                    {devDeletions > 0 && (
                                        <rect
                                            className="deletions"
                                            x={`${(devAdditions / (devAdditions + devDeletions)) * 100 + 0.5}%`}
                                            y="0"
                                            width={`${(devDeletions / (devAdditions + devDeletions)) * 100 - 0.5}%`}
                                            height="8"
                                            rx={devAdditions > 0 ? "0 4 4 0" : "4"}
                                        />
                                    )}
                                </svg>
                            </div>
                        </div>
                    </div>

                    {sortedDomains.length > 0 && (
                        <div className="dev-info-row">
                            <span className="dev-info-label">Active domains:</span>
                            <div className="dev-info-value domain-container">
                                {renderDomainBars(sortedDomains)}
                                {renderRemainingDomains(sortedDomains)}
                                <Tooltip target=".ratio-bar-container" position="top" />
                                <Tooltip target=".domain-bar-container" position="top" />
                                <Tooltip target=".remaining-domains-text" position="top" />
                            </div>
                        </div>
                    )}
                </div>
            </div>
        );
    };

    return (
        <div className="dev-activity-container" ref={containerRef}>
            <div className="dev-activity-developers-container">
                <div className="dev-activity-developers">
                    <span className="p-input-icon-left">
                    <InputText
                        value={searchQuery}
                        onChange={(e) => setSearchQuery(e.target.value)}
                        placeholder="Search developers"
                        className="dev-activity-search-input"
                    />
                    </span>
                    {renderDeveloperChips()}
                </div>
                <div className="dev-activity-developer-vertical-line"></div>
                <div className="dev-activity-developer-info">
                    {renderDeveloperInfo()}
                </div>
            </div>
            <div className="dev-activity-graph">
                {loading ? (
                    <div className="dev-activity-loader">Loading...</div>
                ) : (
                    <>
                        <svg ref={overviewRef} className="dev-activity-overview-graph"></svg>
                        <svg ref={svgRef}></svg>
                        {showTooltip && (
                            <div 
                                className="graph-tooltip"
                                style={{
                                    position: 'absolute',
                                    left: `${tooltipPosition.x}px`,
                                    top: `${tooltipPosition.y}px`,
                                    opacity: 1,
                                    pointerEvents: 'none'
                                }}
                                data-pr-tooltip={tooltipContent}
                            />
                        )}
                    </>
                )}
                <Tooltip 
                    target=".graph-tooltip"
                    position="right"
                    className="dev-activity-tooltip"
                    showDelay={0}
                />
            </div>
        </div>
    );
};

export default DevelopmentActivityGraph; 