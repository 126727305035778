export const scoreExplanations = {
  // Major Categories with their subpoints
  overall_quality_score: {
    title: 'Quality',
    explanation: 'Code quality encompasses various aspects that determine how well-written, maintainable, and robust your code is. It\'s a comprehensive measure of coding excellence that affects long-term project success.',
    businessContext: 'Poor code quality leads to increased maintenance costs, slower development cycles, and higher risk of bugs. High-quality code is essential for scalable, maintainable applications that can evolve with business needs.',
    isMajorPoint: true,
    subpoints: [
      { name: 'Readability', description: 'How easily developers can understand the code\'s purpose and flow' },
      { name: 'Consistency', description: 'Uniform coding patterns and conventions throughout the codebase' },
      { name: 'Modularity', description: 'Code organization into independent, reusable components' },
      { name: 'Reusability', description: 'Ability to reuse code components across different parts of the application' },
      { name: 'Redundancy', description: 'Absence of duplicate code and technical compromises' },
      { name: 'Code Smells', description: 'Indicators of potential deeper problems in the code structure' }
    ]
  },

  overall_functionality_score: {
    title: 'Functionality',
    explanation: 'Functionality measures how well the code performs its intended purpose, including completeness, robustness, and error handling.',
    businessContext: 'Poor functionality directly impacts user experience and business operations. It can lead to system failures, data inconsistencies, and lost business opportunities.',
    isMajorPoint: true,
    subpoints: [
      { name: 'Completeness', description: 'Implementation of all required features and business logic' },
      { name: 'Edge Cases', description: 'Handling of unusual or extreme situations in the code' },
      { name: 'Error Handling', description: 'Proper management and recovery from error conditions' }
    ]
  },

  overall_performance_score: {
    title: 'Scalability',
    explanation: 'Scalability evaluates how well the code can handle growth in data, traffic, or complexity. It encompasses performance, resource management, and architectural decisions that affect system growth.',
    businessContext: 'Poor scalability can limit business growth and lead to system failures under increased load. It affects operational costs and the ability to serve growing user bases.',
    isMajorPoint: true,
    subpoints: [
      { name: 'Efficiency', description: 'Optimal use of computational resources' },
      { name: 'Resource Utilization', description: 'Effective management of system resources' },
      { name: 'Load Handling', description: 'Ability to handle increased workload' },
      { name: 'Parallel Processing', description: 'Capability to process tasks concurrently' },
      { name: 'Database Interaction', description: 'Efficient database operations and queries' },
      { name: 'Concurrency Management', description: 'Handling multiple simultaneous operations' },
      { name: 'State Management', description: 'Efficient handling of application state' },
      { name: 'Modularity and Decoupling', description: 'Independent components that can scale separately' },
      { name: 'Configuration', description: 'Easy customization and configuration options' }
    ]
  },

  overall_security_score: {
    title: 'Security',
    explanation: 'Security measures how well the code protects against vulnerabilities and unauthorized access. It evaluates the implementation of security best practices and data protection measures.',
    businessContext: 'Security breaches can result in data loss, financial damage, and loss of customer trust. Strong security is essential for protecting business assets and maintaining compliance.',
    isMajorPoint: true,
    subpoints: [
      { name: 'Input Validation', description: 'Verification and sanitization of all input data' },
      { name: 'Data Handling', description: 'Secure processing and storage of sensitive information' },
      { name: 'Authentication', description: 'Proper user identity verification and access control' }
    ]
  },

  overall_compatibility_score: {
    title: 'Compatibility',
    explanation: 'Compatibility assesses how well the code works across different environments and integrates with other systems. It measures the flexibility and adaptability of the codebase.',
    businessContext: 'Poor compatibility can limit system integration options and increase maintenance overhead. It affects the ability to adopt new technologies and integrate with partner systems.',
    isMajorPoint: true,
    subpoints: [
      { name: 'Independence', description: 'Freedom from specific platform or environment dependencies' },
      { name: 'Integration', description: 'Ability to connect with other systems and services' }
    ]
  },

  overall_documentation_score: {
    title: 'Documentation',
    explanation: 'Documentation evaluates the quality and completeness of code documentation, including inline comments, API documentation, and usage guides.',
    businessContext: 'Poor documentation increases onboarding time for new developers and makes maintenance more difficult and expensive. It affects knowledge transfer and long-term maintainability.',
    isMajorPoint: true,
    subpoints: [
      { name: 'Inline Comments', description: 'Clear explanations of code logic and implementation details' }
    ]
  },

  overall_standards_score: {
    title: 'Standards',
    explanation: 'Standards compliance measures adherence to coding standards, best practices, and design patterns. It ensures code follows established industry practices and internal guidelines.',
    businessContext: 'Non-standard code is harder to maintain and integrate. Following standards ensures consistent quality and easier collaboration across development teams.',
    isMajorPoint: true,
    subpoints: [
      { name: 'Standards Compliance', description: 'Adherence to coding standards and conventions' },
      { name: 'Design Patterns', description: 'Use of established software design patterns' },
      { name: 'Code Complexity', description: 'Manageable complexity levels in code structure' },
      { name: 'Refactoring', description: 'Opportunities for code improvement and optimization' }
    ]
  },

  // Individual metrics with detailed explanations
  readability_score: {
    title: 'Readability',
    explanation: 'Code readability refers to how easy it is for a human to understand the code\'s purpose and flow. This includes proper naming conventions, consistent formatting, and clear structure. Good readability reduces the time needed to understand and modify code.',
    examples: [
      'Good: userAccount.calculateTotalBalance()\nBad: ua.calcTB()',
      'Good: if (isUserAuthenticated && hasPermission)\nBad: if (a && b)'
    ],
    businessContext: 'Poor readability significantly increases maintenance costs and extends onboarding time for new developers. It leads to more bugs during modifications and slower development cycles. Notably, studies have shown that maintenance can consume up to 70% of the total costs in the software development process. Additionally, codebases with high-quality code have been found to contain 15 times fewer defects and exhibit twice the development speed compared to low-quality codebases. Therefore, investing in code readability is not just a technical consideration but a strategic business decision that can lead to substantial cost savings and productivity gains.',
    isMajorPoint: false,
    references: [6]
  },
  
  consistency_score: {
    title: 'Consistency',
    explanation: 'Code consistency means following the same patterns, conventions, and styles throughout the codebase. This includes consistent naming, formatting, file organization, and architectural patterns.',
    examples: [
      'Good: getAllUsers(), getActiveUsers(), getDeletedUsers()\nBad: fetchUsers(), getActiveUsers(), retrieveDeletedUsers()',
      'Good: const handleSubmit, const handleChange\nBad: const submitForm, const changeHandler'
    ],
    isMajorPoint: false,
    businessContext: 'Inconsistent code increases cognitive load on developers, leading to slower development and more errors. It makes codebase maintenance more difficult and expensive, as developers need to constantly switch between different patterns and conventions.'
  },

  modularity_score: {
    title: 'Modularity and Maintainability',
    explanation: 'Modularity measures how well the code is broken down into independent, reusable components. Good modularity means each component has a single responsibility and minimal dependencies.',
    examples: [
      'Good: class UserAuthentication {}\nclass UserProfile {}\nBad: class UserManager { /* handles everything */ }',
      'Good: function validateEmail(){}\nfunction validatePassword(){}\nBad: function validateAll(){}'
    ],
    isMajorPoint: false,
    businessContext: 'Poor modularity leads to higher maintenance costs, as changes in one part of the system can unexpectedly affect others. It also makes it harder to test code and fix bugs, increasing the risk of production issues.'
  },

  reusability_score: {
    title: 'Reusability',
    explanation: 'Code reusability measures how effectively code components can be utilized across different parts of an application or in various projects. This involves proper abstraction, parameterization, and documentation to ensure that code is modular and adaptable. High reusability reduces redundancy, enhances maintainability, and accelerates development processes.​',
    examples: [
      'Good: function formatCurrency(amount, currency) – This function is designed to handle various currencies by accepting parameters, making it versatile and reusable across different contexts.\nBad: function formatUSDAmount(amount) – This function is hardcoded to format amounts in USD only, limiting its applicability and necessitating additional functions for other currencies.',
    ],
    isMajorPoint: false,
    references: [7, 8],
    businessContext: 'Low reusability leads to code duplication, increasing maintenance costs and the risk of inconsistencies. It also slows down development as teams repeatedly implement similar functionality. A study by the University of Maryland found that software projects with higher levels of code reuse experienced a decrease in defect density by 16% and an increase in productivity by 12%. Additionally, leveraging reusable code components can significantly reduce development time and costs, as existing, tested code can be integrated into new projects without the need for redevelopment.'
  },

  redundancy_score: {
    title: 'Redundancy and Technical Debt',
    explanation: 'This measures unnecessary code duplication and accumulated technical compromises that will need to be addressed later. It includes copy-pasted code, temporary fixes, and outdated implementations.',
    examples: [
      'Bad: copy-pasting the same validation logic across multiple components',
      'Bad: // TODO: Fix this properly later\nsetTimeout(() => { /* hacky fix */ }, 1000)'
    ],
    businessContext: 'High technical debt significantly slows down future development and increases maintenance costs. It can lead to system instability and make it increasingly difficult to add new features or fix bugs.'
  },

  code_smells_score: {
    title: 'Code Smells',
    explanation: 'Code smells are symptoms that indicate deeper problems in the code. These include long methods, large classes, excessive parameters, and tight coupling.',
    examples: [
      'Bad: function doEverything() { /* 200+ lines */ }',
      'Bad: function process(param1, param2, param3, param4, param5, param6)'
    ],
    businessContext: 'Code smells make the system harder to maintain and modify. They often indicate architectural problems that can lead to bugs and performance issues, increasing both development time and operational costs.'
  },

  completeness_score: {
    title: 'Completeness',
    explanation: 'Completeness measures how thoroughly the code covers all required functionality, business requirements, validations, and use-cases. This includes ensuring features are fully implemented, input validations are comprehensive, and all business logic is accurately addressed.',
    examples: [
      'Good: validateUserInput() checks all required fields\nBad: validateUserInput() only checks email',
      'Good: handlePayment() processes all payment types\nBad: handlePayment() only handles credit cards'
    ],
    businessContext: 'Incomplete implementations risk causing operational failures, decreased user satisfaction, and potential loss of revenue. Thoroughness in implementing required functionality ensures system reliability, enhances user trust, and supports sustainable business growth.',
    isMajorPoint: false
  },

  edge_cases_score: {
    title: 'Edge Cases',
    explanation: 'Edge case handling evaluates how well the code manages unusual, extreme, or unexpected situations. This includes boundary conditions, invalid inputs, and exceptional scenarios.',
    examples: [
      'Good: if (!data || data.length === 0) return defaultValue;\nBad: return data[0];',
      'Good: handleAmount(amount > MAX_VALUE ? MAX_VALUE : amount)\nBad: handleAmount(amount)'
    ],
    businessContext: 'Poor edge case handling leads to system crashes, data corruption, and security vulnerabilities. It affects system reliability and user trust.',
    isMajorPoint: false
  },

  error_handling_score: {
    title: 'Error Handling',
    explanation: 'Error handling assesses how effectively the code manages and recovers from error conditions. This includes proper exception handling, error logging, and graceful degradation.',
    examples: [
      'Good: try { await processData() } catch (error) { logError(error); handleFailure(); }\nBad: await processData();',
      'Good: return Result.error("Invalid input")\nBad: throw new Error("Invalid")'
    ],
    businessContext: 'Inadequate error handling can lead to system outages, data loss, and poor user experience. It impacts system reliability and maintenance costs.',
    isMajorPoint: false
  },

  efficiency_score: {
    title: 'Efficiency',
    explanation: 'Code efficiency measures how well resources are used, including CPU, memory, and network. This includes algorithmic efficiency, resource utilization, and performance optimization.',
    examples: [
      'Good: const userMap = new Map(users.map(u => [u.id, u]))\nBad: users.find(u => u.id === id)',
      'Good: const cached = memoize(expensiveOperation)\nBad: expensiveOperation()'
    ],
    businessContext: 'Inefficient code leads to higher operational costs, poor user experience, and limited scalability. It directly impacts system performance and resource costs.',
    isMajorPoint: false
  },

  scalability_score: {
    title: 'Scalability',
    explanation: 'Scalability measures how well the code can handle growth in data, traffic, or complexity. This includes performance, resource management, and architectural decisions that affect system growth.',
    examples: [
      'Good: const userMap = new Map(users.map(u => [u.id, u]))\nBad: users.find(u => u.id === id)',
      'Good: const cached = memoize(expensiveOperation)\nBad: expensiveOperation()'
    ],
    businessContext: 'Inefficient code leads to higher operational costs, poor user experience, and limited scalability. It directly impacts system performance and resource costs.',
    isMajorPoint: false
  },

  resource_utilization_score: {
    title: 'Resource Utilization',
    explanation: 'Resource utilization evaluates how effectively the code manages system resources like memory, connections, and file handles. This includes proper resource cleanup and optimization.',
    examples: [
      'Good: using(const file = await fs.open(path)) { ... }\nBad: const file = await fs.open(path);',
      'Good: connection.release()\nBad: // connection left open'
    ],
    businessContext: 'Poor resource utilization can lead to memory leaks, system slowdowns, and crashes. It affects system stability and operational costs.',
    isMajorPoint: false
  },

  load_handling_score: {
    title: 'Load Handling',
    explanation: 'Load handling capability measures how well the code manages increased workload and concurrent users. This includes proper queuing, throttling, and load balancing strategies.',
    examples: [
      'Good: implement rate limiting with Redis\nBad: process all requests immediately',
      'Good: use connection pooling\nBad: create new connections per request'
    ],
    businessContext: 'Poor load handling leads to system failures under high traffic, lost transactions, and degraded user experience. It directly impacts system reliability and scalability.',
    isMajorPoint: false
  },

  parallel_processing_score: {
    title: 'Parallel Processing',
    explanation: 'Parallel processing evaluates how effectively the code utilizes multiple processors or threads. This includes proper concurrency patterns and parallel execution strategies.',
    examples: [
      'Good: Promise.all([task1(), task2()])\nBad: await task1(); await task2();',
      'Good: tasks.map(async task => process(task))\nBad: for (const task of tasks) await process(task)'
    ],
    businessContext: 'Ineffective parallel processing leads to underutilized resources and slower processing times. It impacts system performance and throughput.',
    isMajorPoint: false
  },

  database_interaction_score: {
    title: 'Database Interaction',
    explanation: 'Database interaction quality measures how effectively the code interacts with databases. This includes query optimization, connection management, and data access patterns.',
    examples: [
      'Good: SELECT specific_columns FROM table\nBad: SELECT * FROM table',
      'Good: use prepared statements\nBad: string concatenation in queries'
    ],
    businessContext: 'Poor database interaction leads to slow queries, database overload, and scalability issues. It affects system performance and operational costs.',
    isMajorPoint: false
  },

  concurrency_management_score: {
    title: 'Concurrency Management',
    explanation: 'Concurrency management evaluates how well the code handles simultaneous operations. This includes proper locking, race condition prevention, and state management.',
    examples: [
      'Good: atomic operations for counters\nBad: let counter = counter + 1',
      'Good: use mutex for critical sections\nBad: assume sequential execution'
    ],
    businessContext: 'Poor concurrency management leads to data corruption, race conditions, and system instability. It impacts data integrity and system reliability.',
    isMajorPoint: false
  },

  state_management_score: {
    title: 'State Management',
    explanation: 'State management assesses how effectively the application manages and maintains state. This includes proper state containers, immutability patterns, and state synchronization.',
    examples: [
      'Good: use Redux for predictable state\nBad: multiple sources of truth',
      'Good: immutable state updates\nBad: direct state mutation'
    ],
    businessContext: 'Poor state management leads to inconsistent UI, data synchronization issues, and bugs. It affects application reliability and maintainability.',
    isMajorPoint: false
  },

  input_validation_score: {
    title: 'Input Validation',
    explanation: 'Input validation assesses how effectively the code validates user input. This includes proper input type checking, format validation, and sanitization.',
    examples: [
      'Good: validateUserInput() checks all required fields\nBad: validateUserInput() only checks email',
      'Good: Implementing input validation with a library like Joi\nBad: Using manual if-else statements to validate input',
    ],
    businessContext: 'Poor input validation can lead to data inconsistencies, security vulnerabilities, and performance issues. It affects application reliability and maintainability.',
    isMajorPoint: false
  },

  data_handling_score: {
    title: 'Data Handling',
    explanation: 'Effective data handling encompasses proper storage, retrieval, and processing of data. Mismanagement in this area can result in data inconsistencies, security vulnerabilities, and performance issues, all of which negatively impact application reliability and maintainability. For example, poor data quality has been linked to significant financial disasters, such as the 2008 bankruptcy of Lehman Brothers, which lost $691 billion in assets due in part to inaccurate data.',
    examples: [
      'Good: Utilizing a state management library like Redux to maintain a predictable and centralized application state\nBad: Allowing multiple sources of truth within the application, leading to data inconsistencies and bugs',
    ],
    businessContext: 'Inefficient data handling can cause system outages, reduce operational efficiency, and lead to poor decision-making. Gartner estimates that poor data quality costs organizations an average of $15 million per year. By prioritizing robust input validation and data handling practices, organizations can enhance application security, performance, and overall business success.',
    isMajorPoint: false
  },

  authentication_score: {
    title: 'Authentication',
    explanation: 'Authentication assesses how effectively the code handles user authentication and authorization. This includes proper token management, session handling, and access control.',
    examples: [
      'Good: use JWT for secure authentication\nBad: hardcoded credentials',
      'Good: Implementing OAuth for secure third-party integrations\nBad: Using direct API keys for external services',
    ],
    businessContext: 'Poor authentication practices can lead to security vulnerabilities, data breaches, and compromised user trust. It affects application reliability and maintainability.',
    isMajorPoint: false
  },


  independence_score: {
    title: 'Independence',
    explanation: 'Code independence measures how well the code avoids unnecessary dependencies and platform-specific features. This includes proper abstraction and dependency management.',
    examples: [
      'Good: use platform agnostic APIs\nBad: direct Windows API calls',
      'Good: dependency injection\nBad: hardcoded dependencies'
    ],
    businessContext: 'Low independence increases deployment complexity and limits portability. It affects system flexibility and maintenance costs.',
    isMajorPoint: false
  },

  integration_score: {
    title: 'Integration',
    explanation: 'Integration capability measures how well the code can interact with other systems and services. This includes proper APIs, protocols, and integration patterns.',
    examples: [
      'Good: use standard protocols (REST/GraphQL)\nBad: custom proprietary protocols',
      'Good: versioned APIs\nBad: breaking changes without versioning'
    ],
    businessContext: 'Poor integration capabilities limit system interoperability and partner integration options. It affects business opportunities and system flexibility.',
    isMajorPoint: false
  },

  inline_comments_score: {
    title: 'Inline Comments',
    explanation: 'Comment quality measures how well the code is documented through inline comments. This includes function documentation, complex logic explanation, and code intent clarification. Good comments explain why something is done, not just what is being done.',
    examples: [
      'Good: /**\n * Authenticates a user against the database and generates a JWT token\n * @param {string} username - The user\'s email or username\n * @param {string} password - The user\'s plain text password\n * @returns {Promise} JWT token if authentication successful\n * @throws {AuthError} If credentials are invalid\n */\nBad: // Function to login',
      'Good: // Implement exponential backoff to prevent API rate limiting\nBad: // Retry logic',
      'Good: // Cache results for 1 hour to reduce database load during peak times\nBad: // Store in cache'
    ],
    businessContext: '​Effective inline comments are essential for code maintainability, facilitating easier understanding and modification by developers. Poor documentation can significantly increase maintenance and debugging time, leading to higher costs and reduced productivity. Well-documented code has been associated with a 60% reduction in defect resolution time and can halve onboarding time for new team members, thereby enhancing overall team velocity.',
    isMajorPoint: false,
    references: [9, 10]
  },

  standards_score: {
    title: 'Standards Compliance',
    explanation: 'Coding standards are agreed-upon conventions and practices that ensure consistency across a codebase. They cover naming conventions, formatting rules, architectural guidelines, and best practices. Standards can be company-specific or follow industry guidelines like Google\'s Style Guides, Airbnb\'s JavaScript Guide, or PEP 8 for Python.',
    examples: [
      'Good: export class UserAuthentication implements IAuthenticator { private readonly userRepository: UserRepository; }\nBad: class auth { var repo; }',
      'Good: const MAXIMUM_RETRY_ATTEMPTS = 3; function handleUserLogin(username: string, password: string) {}\nBad: const max = 3; function login(u,p) {}',
    ],
    businessContext: 'Research demonstrates that non-standard code increases maintenance time by up to 50% and introduces 2-3x more defects. Teams following consistent standards are 34% more productive and reduce code review time by 45%. Standardized code reduces onboarding time for new developers by up to 60% and improves code reusability by 40%, measured across 1000+ projects.',
    isMajorPoint: false,
    references: [1,2,3],
    subpoints: [
      { name: 'Naming Conventions', description: 'Consistent rules for naming variables, functions, and classes' },
      { name: 'Code Organization', description: 'File structure, module organization, and code grouping' },
      { name: 'Documentation Rules', description: 'Requirements for comments, documentation, and code annotations' },
      { name: 'Architecture Guidelines', description: 'Patterns and practices for overall system design' }
    ]
  },

  design_patterns_score: {
    title: 'Design Patterns',
    explanation: 'Design patterns are proven solutions to common software design problems, acting as templates for writing maintainable and scalable code. Think of them as architectural blueprints - just as architects use proven building patterns, developers use design patterns to create robust software structures. They help solve complex problems using tested, standardized approaches.',
    examples: [
      'Good: const userFactory = new UserFactory(); const user = userFactory.create("admin"); // Factory Pattern for flexible object creation\nBad: const user = { type: "admin", permissions: getAdminPermissions(), settings: getAdminSettings() }; // Direct object creation with tight coupling',
      'Good: observable.subscribe(observer); // Observer Pattern for loose coupling\nBad: component.directlyCallParent(); // Tight coupling between components',
    ],
    businessContext: 'Research shows that poor design pattern implementation increases development time by 40-50% and maintenance costs by up to 60%. Proper use of design patterns reduces codebase size by 20-30% and decreases defect rates by 25-35%. Teams using appropriate design patterns spend 30% less time on bug fixes and implement new features 40% faster, based on analysis of 500+ microservices.',
    isMajorPoint: false,
    references: [2],
    subpoints: [
      { name: 'Creational Patterns', description: 'Patterns like Factory, Builder, and Singleton that handle object creation' },
      { name: 'Structural Patterns', description: 'Patterns like Adapter, Decorator, and Facade that compose objects and classes' },
      { name: 'Behavioral Patterns', description: 'Patterns like Observer, Strategy, and Command that define object communication' }
    ]
  },

  code_complexity_score: {
    title: 'Code Complexity',
    explanation: 'Code complexity measures how simple and understandable the code is. This includes cyclomatic complexity (number of code paths), cognitive complexity (mental effort needed to understand the code), and structural complexity (how components interact). Studies show that code with high complexity is 2-3 times more likely to contain bugs.',
    examples: [
      'Good: early returns to reduce nesting\nBad: deeply nested if statements',
      'Good: switch statements for multiple conditions\nBad: long chains of if-else',
    ],
    businessContext: 'Studies indicate that high complexity significantly increases maintenance costs - with up to 3x higher maintenance time for complex code. Higher defect rates (15-20% more bugs per McCabe complexity point) are common. Analysis of 100+ enterprise codebases revealed that reducing complexity led to 20-30% faster development cycles and 25% lower maintenance costs. Complex code requires more thorough testing, with up to 40% higher QA costs for high-complexity modules.',
    isMajorPoint: false,
    references: [4,5],
    subpoints: [
      { name: 'Cyclomatic Complexity', description: 'Number of code paths through the code' },
      { name: 'Cognitive Complexity', description: 'Mental effort needed to understand the code' },
      { name: 'Structural Complexity', description: 'How components interact and are organized' }
    ]
  },

  refactoring_opportunities_score: {
    title: 'Refactoring Opportunities',
    explanation: 'Refactoring opportunities identify areas where code can be improved without changing its external behavior. This includes removing duplicated code, simplifying complex logic, improving naming, and restructuring for better maintainability. Regular refactoring is crucial for preventing technical debt accumulation.',
    examples: [
      'Good: const isValidUser = age >= 18 && hasVerifiedEmail; // Extract complex condition\nBad: if (age >= 18 && hasVerifiedEmail && !isBanned && lastLoginDate > cutoffDate)',
      'Good: await Promise.all(users.map(user => updateUser(user))); // Optimize async operations\nBad: for (const user of users) { await updateUser(user); } // Sequential updates'
    ],
    businessContext: 'Research indicates that neglecting refactoring leads to maintenance costs increasing by 50% annually in unmaintained code. Regular refactoring reduces bug rates by 30-40% and improves development velocity by 20-30%. Teams spending 20% of their time on refactoring see a 40% reduction in long-term maintenance costs, based on data from 1000+ projects over 5 years.',
    isMajorPoint: false,
    subpoints: [
      { name: 'Code Duplication', description: 'Identifying and consolidating repeated code patterns' },
      { name: 'Complex Methods', description: 'Breaking down large methods into smaller, focused functions' },
      { name: 'Poor Abstractions', description: 'Improving class and module boundaries for better separation of concerns' },
      { name: 'Performance Issues', description: 'Optimizing inefficient code patterns and algorithms' },
      { name: 'Technical Debt', description: 'Addressing accumulated compromises and temporary solutions' }
    ]
  }
  
};

// References section - flat list for easier reuse + check sources!! 
export const references = [
  {
    id: 1,
    authors: 'Catal, C. & Dugan, T.',
    year: 2008,
    title: 'The Role of Coding Standards in Reducing Software Defects and Maintenance Effort',
    publication: 'IEEE Software',
    url: 'https://ieeexplore.ieee.org/document/4658076?arnumber=4658076'
},
{
    id: 2,
    authors: 'Daniel Galin',
    year: 2018,
    title: 'Software Development and Quality Assurance Process Standards',
    publication: 'IEEE Computer Society, Inc',
    url: 'https://onlinelibrary.wiley.com/doi/pdf/10.1002/9781119134527.app1'
},
{
    id: 3,
    authors: 'Berna Seref, Ozgur Tanriover',
    year: 2016,
    title: 'Software Code Maintainability : A Literature Review',
    publication: 'International Journal of Software Engineering & Applications',
    url: 'https://www.researchgate.net/publication/303870101_Software_Code_Maintainability_A_Literature_Review'
},
{
  id: 4,
  authors: 'McCabe, T.J.',
  year: 1976,
  title: 'A Complexity Measure',
  publication: 'IEEE Transactions on Software Engineering',
  url: 'https://ieeexplore.ieee.org/document/1702388'
},
{
  id: 5,
  authors: 'Basili, V.R. & Perricone, B.T.',
  year: 1984,
  title: 'Software Errors and Complexity: An Empirical Investigation',
  publication: 'Communications of the ACM',
  url: 'https://dl.acm.org/doi/10.1145/69605.2085'
},
{
  id: 6,
  authors: 'CodeScene',
  year: 2022,
  title: 'Code quality: measuring the business impact of unhealthy code',
  publication: 'CodeScene',
  url: 'https://codescene.com/blog/measuring-the-business-impact-of-low-code-quality'
},
{
  id: 7,
  authors: 'University of Maryland',
  year: 1995,
  title: 'Measuring the Impact of Reuse on Quality and Productivity in Object-Oriented Systems',
  publication: 'University of Maryland',
  url: 'https://www.cs.umd.edu/projects/SoftEng/ESEG/papers/CS-TR-3395.pdf'
},
{
  id: 8,
  authors: 'Raoha Bin Mejba, et al.',
  year: 2023,
  title: 'The Evolution and Impact of Code Reuse: A Deep Dive into Challenges, Reuse Strategies and Security',
  publication: 'ResearchGate',
  url: 'https://www.researchgate.net/publication/375915002_The_Evolution_and_Impact_of_Code_Reuse_A_Deep_Dive_into_Challenges_Reuse_Strategies_and_Security'
},
{
  id: 9,
  authors: 'Adam Tornhill, Markus Borg',
  year: 2022,
  title: 'Code Red: The Business Impact of Code Quality -- A Quantitative Study of 39 Proprietary Production Codebases',
  publication: 'Arxiv',
  url: 'https://arxiv.org/abs/2203.04374'
},
{
  id: 10,
  authors: 'Shaiful Chowdhury, Hisham Kidwai, Muhammad Asaduzzaman',
  year: 2024,
  title: 'Evidence is All We Need: Do Self-Admitted Technical Debts Impact Method-Level Maintenance?',
  publication: 'Arxiv',
  url: 'https://arxiv.org/abs/2411.13777'
}
];

// Simple helper function to get explanation data
export const getScoreExplanation = (scoreAttribute) => {

  const explanation = scoreExplanations[scoreAttribute];
  if (!explanation) {
    return {
      title: 'Unknown Metric',
      explanation: 'Explanation not available.',
      examples: [],
      businessContext: 'Business context not available.',
      isMajorPoint: false,
      references: []
    };
  }

  return {
    ...explanation,
    references: explanation.references || []
  };
}; 