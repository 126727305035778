import React, { useRef, useState, useEffect, useCallback, useMemo } from 'react';
import { TreeTable } from 'primereact/treetable';
import { Column } from 'primereact/column';
import { Checkbox } from 'primereact/checkbox';
import { Editor } from 'primereact/editor';
import { Button } from 'primereact/button';
import { Toast } from 'primereact/toast';
import AuditScopeSummaryTabMenu from './AuditScopeSummaryTabMenu';
import { useParams, useLocation, useNavigate } from 'react-router-dom';
import './AuditScopeSelection.css';
import axiosInstance from '../axiosConfig';
import LoadingAnimation from '../components/LoadingAnimation';
import { useDispatch, useSelector } from 'react-redux';
import {
    setAuditStatus,
    setLoading,
    setError,
    selectTreeStructure,
    selectSelectedKeys,
    selectScopeSummary,
    selectAuditScopeStatus,
    selectGroupAuditUuid,
    selectIsLoading,
    selectError,
    selectCurrentAuditUuid,
    selectLastUpdated
} from '../features/auditScope/auditScopeSlice';

// Constants
const COMPLETED_STATUSES = [
    'Audit started',
    'File auditing completed',
    'Scores calculated',
    'Contextualization completed',
    'Contextualization failed',
    'Consolidation and analysis completed',
    'Audit failed',
    'Audit completed',
    'Process failed'
];

function AuditScopeSelection() {
    const dispatch = useDispatch();

    // Redux selectors
    const treeStructureRedux = useSelector(selectTreeStructure);
    const reduxSelectedKeys = useSelector(selectSelectedKeys);
    const reduxScopeSummary = useSelector(selectScopeSummary);
    const reduxAuditStatus = useSelector(selectAuditScopeStatus);
    const reduxGroupAuditUuid = useSelector(selectGroupAuditUuid);
    const reduxIsLoading = useSelector(selectIsLoading);
    const reduxError = useSelector(selectError);
    const lastUpdated = useSelector(selectLastUpdated);
    const currentAuditUuid = useSelector(selectCurrentAuditUuid);

    // Local state declarations
    const [isFolderProcessing, setIsFolderProcessing] = useState(false);
    const [fileStats, setFileStats] = useState({
        pathMap: new Map(),
        selectedPaths: new Set(),
        folderContents: new Map(),
        summary: { numberOfFiles: 0, linesOfCode: 0 }
    });
    const [nodes, setNodes] = useState(treeStructureRedux || []);
    const [selectedPaths, setSelectedPaths] = useState(new Set());
    const [selectedNodes, setSelectedNodes] = useState([]);
    const [selectAll, setSelectAll] = useState(false);
    const [globalFilter, setGlobalFilter] = useState(null);
    const [expandedRows, setExpandedRows] = useState({});
    const [showSidebar, setShowSidebar] = useState(true);
    const [processingStatus, setProcessingStatus] = useState('loading');
    const [isDataProcessing, setIsDataProcessing] = useState(!treeStructureRedux);
    const [hasUnsavedChanges, setHasUnsavedChanges] = useState(false);
    const [loadingProgress, setLoadingProgress] = useState(0);
    const [editorContent, setEditorContent] = useState('');
    const [loadingNodes, setLoadingNodes] = useState({});
    const [fullData, setFullData] = useState(null);
    const [visibleNodes, setVisibleNodes] = useState([]);
    const [expandedPathsState, setExpandedPathsState] = useState(new Set());
    const [initialSelectedFiles, setInitialSelectedFiles] = useState(new Set());
    const [selectedNodeKeys, setSelectedNodeKeys] = useState({});
    const [isInitialized, setIsInitialized] = useState(false);
    const [isFullyMounted, setIsFullyMounted] = useState(false);
    const [showContent, setShowContent] = useState(false);
    const [needsRebuild, setNeedsRebuild] = useState(true);
    const [expandingFolders, setExpandingFolders] = useState(new Set());
    const [userRole, setUserRole] = useState(null);
    const [isGroupAudit, setIsGroupAudit] = useState(false);
    const [apiGroupAuditUuid, setApiGroupAuditUuid] = useState(null);
    const [isPaid, setIsPaid] = useState(false);
    const [shouldOpenCheckoutModal, setShouldOpenCheckoutModal] = useState(false);
    const [stripeSessionId, setStripeSessionId] = useState(null);
    const [stripeSuccess, setStripeSuccess] = useState(false);
    const [stripeCancelled, setStripeCancelled] = useState(false);

    // Refs and router hooks
    const toast = useRef(null);
    const params = useParams();
    const location = useLocation();
    const navigate = useNavigate();
    const auditUuid = params.auditUuid || new URLSearchParams(location.search).get('auditUuid');
    const persistRoot = JSON.parse(localStorage.getItem('persist:root'));
    const user = JSON.parse(persistRoot?.user || '{}');
    const accountUuid = user.accountUuid;

    // Check for URL parameters from Stripe redirect
    useEffect(() => {
        const urlParams = new URLSearchParams(window.location.search);
        const sessionId = urlParams.get('session_id');
        const success = urlParams.get('success');
        const cancelled = urlParams.get('cancelled');
        
        // If we have Stripe callback parameters
        if (sessionId && (success === 'true' || cancelled === 'true')) {
            console.log('Detected Stripe callback parameters in AuditScopeSelection');
            
            // Store the parameters to pass to CheckoutModal
            setStripeSessionId(sessionId);
            setStripeSuccess(success === 'true');
            setStripeCancelled(cancelled === 'true');
            
            // Flag to open CheckoutModal
            setShouldOpenCheckoutModal(true);
            
            // Clean URL to prevent re-processing
            setTimeout(() => {
                if (window.history.replaceState) {
                    const cleanUrl = window.location.pathname;
                    window.history.replaceState({}, document.title, cleanUrl);
                }
            }, 1000);
        }
    }, [location.search]);

    // -------------------------
    // Helper functions
    // -------------------------
    const buildTreeStructure = useCallback((files) => {
        if (!files || !Array.isArray(files)) return [];
        // (Keep same special files and sorting logic)
        const specialFiles = [
            'README.md',
            'LICENSE',
            'package.json',
            'tsconfig.json',
            '.gitignore',
            '.npmignore'
        ];

        const getFileWeight = (name) => {
            const index = specialFiles.indexOf(name);
            return index === -1 ? specialFiles.length : index;
        };

        const sortNodes = (nodes) => {
            return nodes.sort((a, b) => {
                const aName = (a.data.name || '').toLowerCase();
                const bName = (b.data.name || '').toLowerCase();
                const aIsFolder = a.data.type === 'Folder';
                const bIsFolder = b.data.type === 'Folder';
                if (aIsFolder === bIsFolder) {
                    if (!aIsFolder) {
                        const aWeight = getFileWeight(a.data.name);
                        const bWeight = getFileWeight(b.data.name);
                        if (aWeight !== bWeight) {
                            return aWeight - bWeight;
                        }
                    }
                    return aName.localeCompare(bName);
                }
                return aIsFolder ? -1 : 1;
            });
        };

        const nodesByPath = new Map();
        files.forEach(file => {
            nodesByPath.set(file.path, {
                key: file.path,
                data: {
                    name: file.name,
                    type: file.type,
                    size: file.size,
                    is_selected: file.is_selected,
                    is_partial: file.is_partial,
                    path: file.path
                },
                children: []
            });
        });

        const rootNodes = [];
        nodesByPath.forEach(node => {
            const originalItem = files.find(f => f.path === node.data.path);
            const parentPath = originalItem ? originalItem.parent_path : null;
            if (parentPath && nodesByPath.has(parentPath)) {
                nodesByPath.get(parentPath).children.push(node);
            } else {
                rootNodes.push(node);
            }
        });

        const sortRecursively = (nodes) => {
            nodes = sortNodes(nodes);
            nodes.forEach(node => {
                if (node.children && node.children.length > 0) {
                    node.children = sortRecursively(node.children);
                }
            });
            return nodes;
        };

        let result = sortRecursively(rootNodes);
        if (result.length === 1 && result[0].data.type === "Folder") {
            result = sortRecursively(result[0].children);
        }
        return result;
    }, []);

    const convertToTreeTableNodes = useCallback(
        (nodesArray, level = 0, maxLevel = Infinity, currentFullData, currentFileStats) => {
            if (!nodesArray || !Array.isArray(nodesArray)) return [];
            const processNode = (node) => {
                if (!node) return null;
                const hasChildren = node.children && node.children.length > 0;
                const isExpanded = expandedPathsState.has(node.data.path);
                const childrenData =
                    hasChildren && (level < maxLevel || isExpanded)
                        ? convertToTreeTableNodes(
                              node.children,
                              level + 1,
                              maxLevel,
                              currentFullData,
                              currentFileStats
                          )
                        : [];
                return {
                    key: node.data.path,
                    data: {
                        name: node.data.name,
                        type: node.data.type,
                        size: node.data.size,
                        is_selected: node.data.is_selected,
                        path: node.data.path,
                        fullPath: node.data.path,
                        hasChildren: hasChildren,
                    },
                    children: hasChildren ? childrenData.filter(Boolean) : undefined,
                    leaf: !hasChildren,
                };
            };
            return nodesArray.map(processNode).filter(Boolean);
        },
        [expandedPathsState]
    );

    const recalcSelection = (node) => {
        if (node.children && node.children.length > 0) {
            let allSelected = true;
            let someSelected = false;
            node.children.forEach(child => {
                const childState = recalcSelection(child);
                if (childState === true) {
                    someSelected = true;
                } else if (childState === false) {
                    allSelected = false;
                } else { // 'partial'
                    allSelected = false;
                    someSelected = true;
                }
            });
            if (allSelected) {
                node.data.is_selected = true;
                node.data.is_partial = false;
                return true;
            } else if (someSelected) {
                node.data.is_selected = false;
                node.data.is_partial = true;
                return 'partial';
            } else {
                node.data.is_selected = false;
                node.data.is_partial = false;
                return false;
            }
        } else {
            // Leaf node: return its own selection state.
            return node.data.is_selected;
        }
    };

    // Rendering of the tree table

    // New helper to check if a folder has at least one selectable file in its subtree
    const folderHasSelectableChildren = (node) => {
        if (!node.children || node.children.length === 0) {
            return false;
        }
        for (let child of node.children) {
            // If child is a file (non-folder) and has size > 0, that's selectable.
            if (child.data.type !== 'Folder' && child.data.size > 0) {
                return true;
            }
            // If child is a folder, check recursively.
            if (child.data.type === 'Folder' && folderHasSelectableChildren(child)) {
                return true;
            }
        }
        return false;
    };

    const isFileSelectable = useCallback((fileItem) => {
        return fileItem && fileItem.type !== 'Folder' && fileItem.size > 0;
    }, []);

    const nodeTemplate = useCallback((node) => {
        // If it's a file, we use isFileSelectable to check.
        // If it's a folder, we only treat it as selectable if it has at least one selectable child.
        const isFolder = node.data.type === 'Folder';
        const isSelectableFolder = isFolder && folderHasSelectableChildren(node);
        const isNodeSelectable = isFileSelectable(node.data) || isSelectableFolder;
        return (
            <span
                className={isNodeSelectable ? '' : 'non-selectable-node'}
                data-p-type={node.data.type}
                title={node.data.name}
            >
                {node.data.name}
            </span>
        );
    }, [isFileSelectable]);

    const rowClassName = (node) => {
        let classes = node.data.type; // "Folder", "Source Code", etc.
  
        // A row should be disabled if:
        // 1. It's not a selectable file (size === 0 or not a file)
        // 2. It's a folder without any selectable children
        const isFolder = node.data.type === 'Folder';
        const isSelectableFolder = isFolder && folderHasSelectableChildren(node);
        const isNodeSelectable = isFileSelectable(node.data) || isSelectableFolder;
  
        if (!isNodeSelectable) {
            classes += ' disabled';
        }
  
        if (expandingFolders.has(node.data.path)) {
            classes += ' expanding';
        }
  
        return classes;
    };

    // -------------------------
    // Combined Data Fetching and Initialization
    // -------------------------
    useEffect(() => {
        if (!auditUuid || !accountUuid) return;
        const fetchCombinedData = async () => {
            dispatch(setLoading(true));
            setIsDataProcessing(true);
            try {
                // First fetch the audit role to determine if it's a group audit
                try {
                    const auditRoleResponse = await axiosInstance.get(`identify_audit_role/`, {
                        params: {
                            account_uuid: accountUuid,
                            audit_uuid: auditUuid
                        }
                    });
                    
                    if (auditRoleResponse.data) {
                        // Check if the expected properties exist in the response
                        if (typeof auditRoleResponse.data.role === 'undefined') {
                            console.warn('[AuditScopeSelection] Warning: role property missing in API response');
                        }
                        
                        if (typeof auditRoleResponse.data.isGroupAudit === 'undefined') {
                            // If isGroupAudit is missing, default to false
                            setIsGroupAudit(false);
                        } else {
                            // Only set isGroupAudit to true if it's explicitly true in the response
                            setIsGroupAudit(auditRoleResponse.data.isGroupAudit === true);
                        }
                        
                        // Set values with fallbacks
                        setUserRole(auditRoleResponse.data.role || 'unknown');
                        
                        if (auditRoleResponse.data.isGroupAudit === true && auditRoleResponse.data.groupAuditUuid) {
                            setApiGroupAuditUuid(auditRoleResponse.data.groupAuditUuid);
                        } else {
                            // Reset groupAuditUuid in case it was previously set
                            setApiGroupAuditUuid(null);
                        }
                    }
                } catch (error) {
                    console.error(`Error fetching audit role:`, error);
                    toast.current?.show({
                        severity: 'error',
                        summary: 'Error',
                        detail: 'Failed to identify audit role. Continuing with other data fetching.',
                        life: 3000
                    });
                }

                // Then start both API calls for audit scope concurrently.
                const auditScopePromise = axiosInstance.get(`get_audit_scope_selector/`, {
                    params: { audit_uuid: auditUuid, account_uuid: accountUuid },
                    onDownloadProgress: (progressEvent) => {
                        const percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
                        setLoadingProgress(percentCompleted);
                        if (percentCompleted === 100) {
                            setLoadingProgress(0);
                        }
                    }
                });
                const summaryPromise = axiosInstance.get(`api/audit_scope_summary/${auditUuid}/`);
    
                // Wait for both responses.
                const [auditScopeResponse, summaryResponse] = await Promise.all([auditScopePromise, summaryPromise]);
                
                if (
                    auditScopeResponse.data.status === 'complete' &&
                    Array.isArray(auditScopeResponse.data.tree_structure) &&
                    auditScopeResponse.data.tree_structure.length > 0
                ) {
                    // Set the audit status in Redux immediately when we have valid data
                    dispatch(setAuditStatus("File information imported"));
                    
                    // Check if the audit is paid for
                    if (summaryResponse.data && summaryResponse.data.is_paid === true) {
                        setIsPaid(true);
                    }
                    
                    // Use the flat data directly from backend.
                    const flatData = auditScopeResponse.data.tree_structure;
                    
                    setFullData(flatData);
                    // Build the tree structure from the flattened list.
                    const tree = buildTreeStructure(flatData);
    
                    // Determine selected files from the flat data.
                    const newSelectedPaths = new Set();
                    let numberOfFiles = 0;
                    let linesOfCode = 0;
                    flatData.forEach(item => {
                        // Only select files that are both selected and selectable
                        if (item.type !== 'Folder' && item.is_selected && isFileSelectable(item)) {
                            newSelectedPaths.add(item.path);
                            numberOfFiles++;
                            linesOfCode += item.size || 0;
                        }
                    });
    
                    // Get summary data from the summary API.
                    const summaryData = summaryResponse.data;
    
                    // Update state using summary data (for AuditSummaryTabMenu) and the computed selectedPaths.
                    setFileStats(prev => ({
                        ...prev,
                        selectedPaths: newSelectedPaths,
                        summary: {
                            numberOfFiles: summaryData.number_files || numberOfFiles,
                            linesOfCode: summaryData.lines_of_code || linesOfCode
                        }
                    }));
                    setVisibleNodes(tree);
                    setNodes(tree);
                    setSelectedPaths(newSelectedPaths);
                    setNeedsRebuild(false);
                    setIsInitialized(true);
                    dispatch(setError(null));
                } else {
                    throw new Error('Invalid or empty tree structure data received');
                }
            } catch (error) {
                console.error('Error in fetchCombinedData:', error);
                const errorMessage = error.response?.data?.message || error.message || 'Error fetching audit scope';
                dispatch(setError(errorMessage));
                setProcessingStatus('error');
                toast.current?.show({
                    severity: 'error',
                    summary: 'Error',
                    detail: errorMessage,
                    life: 3000
                });
                if (error.response?.status === 403 || error.response?.status === 400) {
                    sessionStorage.removeItem('navigationState');
                    navigate('/dashboard');
                }
            } finally {
                dispatch(setLoading(false));
                setIsDataProcessing(false);
            }
        };
    
        fetchCombinedData().catch(error => {
            console.error('Unhandled error in fetchCombinedData:', error);
            dispatch(setError('Unexpected error occurred while fetching data'));
        });
    }, [auditUuid, accountUuid, dispatch, navigate, buildTreeStructure]);

    // -------------------------
    // Fetch existing comments
    // -------------------------
    useEffect(() => {
        axiosInstance.get(`api/get_file_selection_comments/${auditUuid}/`)
            .then(response => {
                if (response.data && response.data.comments.length > 0) {
                    setEditorContent(response.data.comments[0]);
                } else {
                    setEditorContent('');
                }
            })
            .catch(error => {
                console.error('Error fetching file comments:', error);
            });
    }, [auditUuid]);

    // -------------------------
    // Update Selection Logic (with full folder propagation)
    // -------------------------
    const updateSelection = (node, isSelected) => {
        // Don't allow selection changes if the audit is not in the right status, is paid for, or is completed
        if (isSelectionDisabled()) {
            return;
        }

        const updateNodeAndChildren = (n, selectValue) => {
            if (!n) return;
            n.data.is_selected = selectValue;
            n.data.is_partial = false;
            if (n.children && n.children.length > 0) {
                n.children.forEach(child => updateNodeAndChildren(child, selectValue));
            }
        };
    
        const updateTree = (nodes) => {
            return nodes.map(n => {
                if (n.key === node.key) {
                    updateNodeAndChildren(n, isSelected);
                } else if (n.children && n.children.length > 0) {
                    n.children = updateTree(n.children);
                }
                return n;
            });
        };
    
        const newNodes = updateTree(visibleNodes);
    
        // Bubble-up update: recalc parent states along the tree.
        const updateParents = (nodes, targetKey) => {
            for (let n of nodes) {
                if (n.children && n.children.length > 0) {
                    if (n.children.find(child => child.key === targetKey)) {
                        recalcSelection(n);
                        return true;
                    } else if (updateParents(n.children, targetKey)) {
                        recalcSelection(n);
                        return true;
                    }
                }
            }
            return false;
        };
        updateParents(newNodes, node.key);
    
        // Recalculate entire tree state for consistency.
        newNodes.forEach(rootNode => recalcSelection(rootNode));
    
        // Rebuild file summary.
        const newSelectedPaths = new Set();
        let numberOfFiles = 0;
        let linesOfCode = 0;
        const traverseTree = (n) => {
            if (!n.children || n.children.length === 0) {
                if (n.data.type !== 'Folder' && n.data.is_selected) {
                    newSelectedPaths.add(n.key);
                    numberOfFiles++;
                    linesOfCode += n.data.size || 0;
                }
            } else {
                n.children.forEach(child => traverseTree(child));
            }
        };
        newNodes.forEach(root => traverseTree(root));
    
        // Update all relevant state
        setFileStats(prev => ({
            ...prev,
            selectedPaths: newSelectedPaths,
            summary: { numberOfFiles, linesOfCode }
        }));
        setVisibleNodes(newNodes);
        setSelectedPaths(newSelectedPaths);
        setSelectedNodes(Array.from(newSelectedPaths).map(path => ({ key: path })));
        setHasUnsavedChanges(true);
    };
    
    // Helper function to check if selection is disabled
    const isSelectionDisabled = () => {
        return reduxAuditStatus !== "File information imported" || isPaid || COMPLETED_STATUSES.includes(reduxAuditStatus);
    };
    
    // Checkbox component
    const CustomCheckbox = ({ node, reduxAuditStatus, updateSelection }) => {
        const isFile = node.data.type !== 'Folder';
        const isSelected = isFile
            ? fileStats.selectedPaths.has(node.data.path)
            : node.data.is_selected;
        const isPartial = node.data.is_partial;
        const disabled = isSelectionDisabled();
        
        return (
            <div
                className={`custom-checkbox ${disabled ? "disabled" : ""}`}
                onClick={() => {
                    if (!disabled) {
                        updateSelection(node, !(isSelected || isPartial));
                    }
                }}
                style={{ opacity: disabled ? 0.6 : 1, cursor: disabled ? 'not-allowed' : 'pointer' }}
            >
                <div className={`checkbox-box ${isSelected ? 'checked' : ''} ${isPartial ? 'partial' : ''}`}>
                    {isSelected && !isPartial && (
                        <svg viewBox="0 0 14 14" className="check-icon">
                            <path d="M11.5,3.5 l-5,5 l-2.5-2" stroke="white" strokeWidth="2" fill="none" />
                        </svg>
                    )}
                    {isPartial && (
                        <svg width="14" height="14" viewBox="0 0 14 14">
                            <path d="M2 7h10" stroke="white" strokeWidth="2" strokeLinecap="round" />
                        </svg>
                    )}
                </div>
            </div>
        );
    };

    const selectionTemplate = (node) => {
        const disabled = isSelectionDisabled();

        // If it's a folder, we only show the checkbox if it has at least one selectable child
        const isFolder = node.data.type === 'Folder';
        const isSelectableFolder = isFolder && folderHasSelectableChildren(node);

        if ((node.data.type !== 'Folder' && node.data.size > 0) || isSelectableFolder) {
            return (
                <CustomCheckbox
                    node={node}
                    reduxAuditStatus={reduxAuditStatus}
                    updateSelection={updateSelection}
                />
            );
        }
        return null;
    };

    const renderLinesOfCode = (node) => {
        return node.data.size > 0 ? node.data.size.toLocaleString('de-DE') : null;
    };

    // -------------------------
    // TreeTable Selection and Summary Helpers
    // -------------------------
    const selectedNodesData = useMemo(() => {
        if (!nodes || !selectedPaths) return [];
        const selected = [];
        const processNode = (node) => {
            if (!node || !node.data) return;
            if (node.data.type !== 'Folder' && selectedPaths.has(node.data.path)) {
                selected.push(node);
            }
            if (node.children && Array.isArray(node.children)) {
                node.children.forEach(processNode);
            }
        };
        nodes.forEach(processNode);
        return selected;
    }, [nodes, selectedPaths]);

    const selectableNodesCount = useMemo(() => {
        if (!nodes) return 0;
        const countSelectable = (n) => {
            if (!n || !n.data) return 0;
            let count = 0;
            if (n.data.type !== 'Folder' && n.data.size > 0) count += 1;
            if (n.children && Array.isArray(n.children)) {
                count += n.children.reduce((sum, child) => sum + countSelectable(child), 0);
            }
            return count;
        };
        return nodes.reduce((total, node) => total + countSelectable(node), 0);
    }, [nodes]);

    const selectedNodesCount = useMemo(() => {
        if (!selectedNodeKeys) return 0;
        return Object.values(selectedNodeKeys).filter(val => val.checked).length;
    }, [selectedNodeKeys]);

    useEffect(() => {
        if (!nodes || !selectedNodeKeys) return;
        const shouldBeSelectAll = selectedNodesCount > 0 && selectedNodesCount >= selectableNodesCount;
        if (selectAll !== shouldBeSelectAll) {
            setSelectAll(shouldBeSelectAll);
        }
    }, [selectedNodesCount, selectableNodesCount, nodes, selectedNodeKeys, selectAll]);

    useEffect(() => {
        setSelectedNodes(selectedNodesData);
    }, [selectedNodesData]);

    // -------------------------
    // onSelectAllChange (Select All / Deselect All)
    // -------------------------
    const onSelectAllChange = useCallback((e) => {
        // Prevent changes if selection is disabled
        if (isSelectionDisabled()) {
            return;
        }
        
        const selectValue = !selectAll;
        
        // Set the loading overlay immediately.
        setIsFolderProcessing(true);
        
        // Use a setTimeout to yield control to the browser, ensuring the overlay renders.
        setTimeout(() => {
            // Update the flat fullData with the new selection state.
            const newFullData = fullData.map(item => ({
                ...item,
                is_selected: selectValue,
                is_partial: false
            }));
    
            // Build newSelectedPaths and newSelectedNodeKeys for selectable (non-folder) items.
            const newSelectedPaths = new Set();
            const newSelectedNodeKeys = {};
            newFullData.forEach(item => {
                if (item.type !== 'Folder' && isFileSelectable(item)) {
                    newSelectedNodeKeys[item.path] = { checked: selectValue, partialChecked: false };
                    if (selectValue) {
                        newSelectedPaths.add(item.path);
                    }
                }
            });
    
            // Create a temporary fileStats to pass into convertToTreeTableNodes.
            const newFileStats = { ...fileStats, selectedPaths: newSelectedPaths };
    
            // Update the flat data.
            setFullData(newFullData);
    
            // Rebuild the tree from newFullData.
            const tree = buildTreeStructure(newFullData);
    
            const maxLevel = Array.from(expandedPathsState).reduce(
                (max, p) => Math.max(max, p.split("/").length),
                1
            ) + 1;
    
            const newNodes = convertToTreeTableNodes(tree, 0, maxLevel, newFullData, newFileStats);
    
            newNodes.forEach(root => recalcSelection(root));
    
            // Recalculate the summary directly from the flat data.
            let numberOfFiles = 0;
            let linesOfCode = 0;
            newFullData.forEach(item => {
                if (item.type !== 'Folder' && isFileSelectable(item) && item.is_selected) {
                    numberOfFiles++;
                    linesOfCode += item.size || 0;
                }
            });
    
            // Update fileStats, visibleNodes, selectAll, selectedPaths and selectedNodeKeys.
            setFileStats(prev => ({
                ...prev,
                selectedPaths: newSelectedPaths,
                summary: { numberOfFiles, linesOfCode }
            }));
            setVisibleNodes(newNodes);
            setSelectAll(selectValue);
            setSelectedPaths(newSelectedPaths);
            setSelectedNodeKeys(newSelectedNodeKeys);
    
            // Remove the loading overlay.
            setIsFolderProcessing(false);
        }, 50);
    }, [reduxAuditStatus, fullData, expandedPathsState, fileStats, isFileSelectable, buildTreeStructure, convertToTreeTableNodes, recalcSelection]);
    
    // -------------------------
    // Node Expand/Collapse Logic
    // -------------------------
    const onNodeExpand = (event) => {
        const path = event.node.data.path;
        setExpandingFolders((prev) => new Set([...prev, path]));
      
        // Allow the UI to update first.
        setTimeout(() => {
            const newExpandedPaths = new Set(expandedPathsState);
            newExpandedPaths.add(path);
            setExpandedPathsState(newExpandedPaths);
            
            // Build the tree from fullData.
            const tree = buildTreeStructure(fullData);
            const maxLevel = Array.from(newExpandedPaths).reduce(
                (max, p) => Math.max(max, p.split("/").length),
                1
            ) + 1;
            
            const newNodes = convertToTreeTableNodes(tree, 0, maxLevel, fullData, fileStats);
            newNodes.forEach((rootNode) => recalcSelection(rootNode));
            
            setVisibleNodes(newNodes);
        
            // Remove folder from loading state.
            setExpandingFolders((prev) => {
                const next = new Set(prev);
                next.delete(path);
                return next;
            });
        }, 100);
    };
      
    const onNodeCollapse = (event) => {
        const path = event.node.data.path;
        const newExpandedPaths = new Set(expandedPathsState);
        newExpandedPaths.delete(path);
        setExpandedPathsState(newExpandedPaths);
        
        const tree = buildTreeStructure(fullData);
        const maxLevel = Array.from(newExpandedPaths).reduce(
            (max, p) => Math.max(max, p.split("/").length),
            1
        ) + 1;
        
        const newNodes = convertToTreeTableNodes(tree, 0, maxLevel, fullData, fileStats);
        newNodes.forEach((rootNode) => recalcSelection(rootNode));
        setVisibleNodes(newNodes);
    };

    // -------------------------
    // Final UI Rendering
    // -------------------------
    const tableheader = (
        <div className="table-header">
            <div className="select-container">
                <div className="select-all-container">
                    <Checkbox 
                        onChange={onSelectAllChange} 
                        checked={selectAll} 
                        disabled={isSelectionDisabled()}
                        style={{ opacity: isSelectionDisabled() ? 0.6 : 1 }}
                    />
                    <label style={{ opacity: isSelectionDisabled() ? 0.6 : 1 }}>
                        {selectAll ? 'Deselect All' : 'Select All'}
                    </label>
                </div>
            </div>
        </div>
    );

    const renderEditorHeader = () => {
        return (
            <span className="ql-formats">
                <button className="ql-bold" title="Bold" aria-label="Bold"></button>
                <button className="ql-italic" title="Italic" aria-label="Italic"></button>
                <button className="ql-underline" title="Underline" aria-label="Underline"></button>
                <button className="ql-strike" title="Strike" aria-label="Strike"></button>
                <button className="ql-header" value="1" title="Header 1" aria-label="Header 1"></button>
                <button className="ql-header" value="2" title="Header 2" aria-label="Header 2"></button>
                <button className="ql-list" value="bullet" title="Bullet List" aria-label="Bullet List"></button>
                <button className="ql-clean" title="Remove Formatting" aria-label="Clean"></button>
            </span>
        );
    };

    const editorHeader = renderEditorHeader();

    const renderContent = () => {
        // Show loading animation while data is being prepared or needs rebuild
        if (!showContent || needsRebuild) {
            return <LoadingAnimation />;
        }
        if (reduxIsLoading || processingStatus === 'processing' || (isDataProcessing && !visibleNodes.length)) {
            return <LoadingAnimation />;
        }
        if (reduxError) {
            console.error('Rendering error state. Redux error:', reduxError);
            return (
                <div className="error-container" style={{ marginTop: '4rem', marginLeft: '4rem' }}>
                    <i className="pi pi-exclamation-triangle" style={{ fontSize: '2rem', color: 'var(--red-500)' }}></i>
                    <h3>Error Loading Repository Data</h3>
                    <p>There was an error loading the repository data. Please try refreshing the page.</p>
                    <Button label="Refresh Page" icon="pi pi-refresh" onClick={() => window.location.reload()} />
                </div>
            );
        }
        return (
            <>
                <div className="header-text">
                    <h2>Select files you want to include in your due diligence.</h2>
                    <p className="sub-text">
                        CodeDD automatically will only select source-code files to process.
                    </p>
                    <p className="sub-text">
                        If you want to include or exclude other files, please select them manually.
                    </p>
                </div>

                <AuditScopeSummaryTabMenu
                    visible={showSidebar}
                    onHide={() => setShowSidebar(false)}
                    selectedNodes={selectedNodes}
                    groupAuditUuid={isGroupAudit ? (apiGroupAuditUuid || reduxGroupAuditUuid) : null}
                    auditStatus={reduxAuditStatus}
                    hasUnsavedChanges={hasUnsavedChanges}
                    onSelectionSaved={() => setHasUnsavedChanges(false)}
                    scopeSummary={fileStats.summary}
                    selectedFiles={Array.from(fileStats.selectedPaths)}
                    userRole={userRole}
                    isGroupAudit={isGroupAudit}
                    setIsPaidForParent={setIsPaid}
                    shouldOpenCheckoutModal={shouldOpenCheckoutModal}
                    stripeSessionId={stripeSessionId}
                    stripeSuccess={stripeSuccess}
                    stripeCancelled={stripeCancelled}
                    resetStripeParams={() => setShouldOpenCheckoutModal(false)}
                    __debug_selectedNodesLength={selectedNodes?.length}
                    __debug_selectedPathsSize={fileStats.selectedPaths?.size}
                />

                <div className="treetable-container">
                    <TreeTable
                        header={tableheader}
                        rowClassName={rowClassName}
                        value={visibleNodes}
                        expandedKeys={Array.from(expandedPathsState).reduce((acc, path) => ({ ...acc, [path]: true }), {})}
                        onExpand={onNodeExpand}
                        onCollapse={onNodeCollapse}
                        tableStyle={{ minWidth: '50rem' }}
                        lazy={false}
                    >
                        <Column selectionMode="multiple" headerStyle={{ width: '3rem' }} body={selectionTemplate} />
                        <Column
                            field="data.name"
                            header="Name"
                            expander
                            className="name-column"
                            body={(node) => {
                                return (
                                  <span title={node.data.name}>
                                    {loadingNodes[node.key] && (
                                      <i className="pi pi-spin pi-spinner" style={{ marginRight: '8px' }} />
                                    )}
                                    {nodeTemplate(node)}
                                  </span>
                                );
                            }}
                        />
                        <Column field="data.size" header="Lines of Code" body={renderLinesOfCode}/>
                        <Column field="data.type" header="Type" body={(node) => <span>{node.data.type}</span>} />
                    </TreeTable>
                    {(isFolderProcessing || expandingFolders.size > 0) && (
                        <div className="treetable-loading-overlay"></div>
                    )}
                </div>

                <div className="comments-container">
                    <h3>You can comment on your file selection here:</h3>
                    <Editor
                        style={{ height: '250px' }}
                        headerTemplate={editorHeader}
                        value={editorContent}
                        onTextChange={(e) => setEditorContent(e.htmlValue)}
                    />
                    <Button
                        className="save_button"
                        label="Save"
                        icon="pi pi-check"
                        onClick={() => {
                            axiosInstance.post(`api/post_file_selection_comments/`, {
                                audit_uuid: auditUuid,
                                comment: editorContent
                            })
                            .then(() => {
                                toast.current.show({
                                    severity: 'success',
                                    summary: 'Success',
                                    detail: 'Comment submitted successfully',
                                    life: 3000
                                });
                            })
                            .catch(error => {
                                console.error('Error submitting comment:', error);
                                toast.current.show({
                                    severity: 'error',
                                    summary: 'Error',
                                    detail: 'Error submitting comment',
                                    life: 3000
                                });
                            });
                        }}
                        style={{ marginTop: '1rem' }}
                    />
                    <Toast ref={toast} />
                </div>
            </>
        );
    };

    // Delay showing content until fully mounted.
    useEffect(() => {
        if (isInitialized && !isFullyMounted && !needsRebuild) {
            setIsFullyMounted(true);
            setTimeout(() => {
                setShowContent(true);
            }, 1000);
        }
    }, [isInitialized, isFullyMounted, needsRebuild]);

    return (
        <div className="audit-scope-selection">
            {(reduxIsLoading || !reduxAuditStatus) ? (
                <LoadingAnimation />
            ) : (
                <div className="audit-scope-content">
                    {renderContent()}
                </div>
            )}
        </div>
    );
}

export default AuditScopeSelection;
