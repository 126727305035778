import React, { useState, useEffect, useRef, useCallback, useMemo } from 'react';
import { Sidebar } from 'primereact/sidebar';
import { Button } from 'primereact/button';
import { ProgressBar } from 'primereact/progressbar';
import { useNavigate, useLocation } from 'react-router-dom';
import { Message } from 'primereact/message';
import { Toast } from 'primereact/toast'; // Import Toast
import PropTypes from 'prop-types'; // Import PropTypes for prop validation

import './AuditScopeSummaryTabMenu.css';
import { useCsrfToken } from '../utils/CsrfTokenContext'; // Assuming context path
import axiosInstance from '../axiosConfig'; // Assuming axios config path
import CheckoutModal from './checkout/CheckoutModal'; // Assuming component path

// --- Constants ---
const BATCH_SIZE = 1000;
const POLL_INTERVAL_MS = 5000;
const MAX_POLL_ATTEMPTS = 60; // 5 minutes total
const COMPLETED_STATUSES = [
    'Audit started',
    'File auditing completed',
    'Scores calculated',
    'Contextualization completed',
    'Contextualization failed',
    'Consolidation and analysis completed',
    'Audit failed',
    'Audit completed',
    'Process failed'
];
const TOAST_LIFE = 3000;
const LONG_TOAST_LIFE = 5000;

// --- Helper Functions ---

/**
 * Checks if the audit status indicates completion.
 * @param {string} status - The current audit status.
 * @returns {boolean} True if the status is a completed status.
 */
const isAuditCompletedStatus = (status) => COMPLETED_STATUSES.includes(status);

/**
 * Checks if the audit status is 'deleted'.
 * @param {string} status - The current audit status.
 * @returns {boolean} True if the status is 'deleted'.
 */
const isAuditDeletedStatus = (status) => status === 'deleted';

/**
 * Formats numbers with German locale separators.
 * @param {number} number - The number to format.
 * @param {number} [maximumFractionDigits=0] - Maximum fraction digits.
 * @returns {string} Formatted number string.
 */
const formatNumberDE = (number, maximumFractionDigits = 0) => {
    return (number || 0).toLocaleString('de-DE', { maximumFractionDigits });
};

/**
 * A simple error boundary component to catch rendering errors.
 */
class ErrorBoundary extends React.Component {
    constructor(props) {
        super(props);
        this.state = { hasError: false, error: null };
    }

    static getDerivedStateFromError(error) {
        return { hasError: true, error };
    }

    componentDidCatch(error, errorInfo) {
        // Log the error to an error reporting service
        console.error("ErrorBoundary caught an error:", error, errorInfo);
        // You could also display a fallback UI here if needed
    }

    render() {
        if (this.state.hasError) {
            // You can render any custom fallback UI
            return (
                <div style={{ padding: '1rem' }}>
                    <Message severity="error" text="Something went wrong in this section. Please try refreshing." />
                    {/* Optionally show error details in development */}
                    {process.env.NODE_ENV === 'development' && this.state.error && (
                        <pre style={{ marginTop: '1rem', whiteSpace: 'pre-wrap' }}>
                            {this.state.error.toString()}
                        </pre>
                    )}
                </div>
            );
        }

        return this.props.children;
    }
}

ErrorBoundary.propTypes = {
    children: PropTypes.node.isRequired,
};


// --- Custom Hooks ---

/**
 * Hook to manage fetching initial audit data (summary, budget, payment status).
 * @param {string} auditUuid - The UUID of the audit.
 * @param {boolean} isVisible - Whether the parent component is visible.
 */
const useAuditInitialData = (auditUuid, isVisible) => {
    const [initialSummary, setInitialSummary] = useState({ numberOfFiles: 0, linesOfCode: 0 });
    const [locBudget, setLocBudget] = useState(0);
    const [accountUuid, setAccountUuid] = useState("");
    const [isPaid, setIsPaid] = useState(false);
    const [isLoading, setIsLoading] = useState(true); // Add loading state
    const [error, setError] = useState(null);         // Add error state

    const fetchData = useCallback(async () => {
        if (!auditUuid) return;

        setIsLoading(true);
        setError(null);

        try {
            // Fetch summary and payment status
            const summaryResponse = await axiosInstance.get(`api/audit_scope_summary/${auditUuid}/`);
            setInitialSummary({
                numberOfFiles: summaryResponse.data.number_files || 0,
                linesOfCode: summaryResponse.data.lines_of_code || 0
            });
            setIsPaid(summaryResponse.data.is_paid || false);

            // Fetch Account UUID from localStorage
            const persistRoot = JSON.parse(localStorage.getItem('persist:root') || '{}');
            const user = JSON.parse(persistRoot?.user || '{}');
            const fetchedAccountUuid = user.accountUuid;

            if (fetchedAccountUuid) {
                setAccountUuid(fetchedAccountUuid);
                // Fetch LOC budget
                const budgetResponse = await axiosInstance.get(`api/get_loc_buget/?account_uuid=${fetchedAccountUuid}`);
                setLocBudget(budgetResponse.data.loc_budget || 0);
            } else {
                setError("Account information not found. Please log in again."); // User-friendly error
            }
        } catch (err) {
            setError(err.response?.data?.error || err.message || 'Failed to load initial audit data.');
        } finally {
            setIsLoading(false);
        }
    }, [auditUuid]);

    useEffect(() => {
        // Fetch on mount and when visibility changes to true
        if (isVisible) {
            fetchData();
        }
    }, [auditUuid, isVisible, fetchData]);

    // Function to manually update paid status (e.g., after payment)
    const updatePaidStatus = useCallback((newIsPaid) => {
        setIsPaid(newIsPaid);
    }, []);

    return { initialSummary, locBudget, accountUuid, isPaid, isLoading, error, updatePaidStatus, refreshData: fetchData };
};

/**
 * Hook to manage file selection saving and metrics calculation polling.
 * @param {string} auditUuid - The UUID of the audit.
 * @param {Array} selectedFiles - Array of selected file paths.
 * @param {function} onSelectionSaved - Callback when selection is successfully saved and metrics calculated.
 * @param {object} toastRef - Ref to the PrimeReact Toast component.
 */
const useFileSelection = (auditUuid, selectedFiles, onSelectionSaved, toastRef) => {
    const [isSaving, setIsSaving] = useState(false);
    const [isMetricsCalculating, setIsMetricsCalculating] = useState(false);
    const [metricsProgress, setMetricsProgress] = useState(0);
    const [auditReady, setAuditReady] = useState(false);
    const [hasError, setHasError] = useState(false);
    const { csrfToken } = useCsrfToken(); // Assuming useCsrfToken provides the token

    const pollMetricsStatus = useCallback(async (taskId) => {
        try {
            const response = await axiosInstance.get(`api/get_metrics_status/${taskId}/`);

            if (response.data.status === 'SUCCESS') {
                setMetricsProgress(100); // Ensure progress shows 100%
                return true;
            } else if (response.data.status === 'FAILURE') {
                throw new Error('Metrics calculation failed');
            } else {
                // Still processing (PENDING or STARTED)
                // Optional: Update progress based on response if available
                // setMetricsProgress(response.data.progress || metricsProgress);
                return false;
            }
        } catch (error) {
            // Rethrow to be caught by the calling function
            throw error;
        }
    }, []); // No dependencies needed if axiosInstance is stable

    const saveSelection = useCallback(async () => {
        if (!selectedFiles || selectedFiles.length === 0 || !auditUuid) {
            toastRef.current?.show({ severity: 'warn', summary: 'Warning', detail: 'No files selected to save.', life: TOAST_LIFE });
            return;
        }

        setIsSaving(true);
        setIsMetricsCalculating(false); // Reset metrics state
        setMetricsProgress(0);
        setAuditReady(false);
        setHasError(false); // Reset error state at the beginning
        let metricsTaskId = null;

        try {
            const allFiles = selectedFiles;
            const totalBatches = Math.ceil(allFiles.length / BATCH_SIZE);

            for (let i = 0; i < totalBatches; i++) {
                const batch = allFiles.slice(i * BATCH_SIZE, (i + 1) * BATCH_SIZE);
                const isFirstBatch = i === 0;
                const isFinalBatch = i === totalBatches - 1;

                const fileSelectionPayload = {
                    files: batch,
                    auditUuid: auditUuid,
                    is_final_batch: isFinalBatch,
                    is_first_batch: isFirstBatch,
                    total_expected_files: allFiles.length
                };

                const response = await axiosInstance.post(`api/store_file_selection/`, fileSelectionPayload, {
                    headers: { 'X-CSRFToken': csrfToken } // Ensure CSRF token is included if needed by backend
                });

                if (response.data.status === 'error') {
                    throw new Error(response.data.message || 'Failed to save file selection batch');
                }

                // Update progress based on batches processed
                const progress = Math.round(((i + 1) / totalBatches) * 100 * 0.5); // Saving is 50% of the process
                setMetricsProgress(progress);

                if (isFinalBatch && response.data.metricsTaskId) {
                    metricsTaskId = response.data.metricsTaskId;
                    setIsMetricsCalculating(true); // Start metrics calculation phase
                    setMetricsProgress(50); // Set progress to 50% after saving
                }
            }

            // If we got a metrics task ID, start polling
            if (metricsTaskId) {
                let attempts = 0;
                let isComplete = false;

                while (attempts < MAX_POLL_ATTEMPTS && !isComplete) {
                    try {
                        isComplete = await pollMetricsStatus(metricsTaskId);
                        if (isComplete) {
                            setAuditReady(true);
                            onSelectionSaved(); // Notify parent component
                            toastRef.current?.show({ severity: 'success', summary: 'Success', detail: 'File selection saved and metrics calculated.', life: TOAST_LIFE });
                            break; // Exit loop
                        }
                    } catch (pollError) {
                        // Error during polling, stop polling and show error
                        throw new Error(`Metrics calculation failed: ${pollError.message}`);
                    }

                    attempts++;
                    // Update progress during polling (e.g., 50% + percentage of attempts)
                    const pollingProgress = 50 + Math.round((attempts / MAX_POLL_ATTEMPTS) * 50);
                    setMetricsProgress(pollingProgress);

                    if (attempts < MAX_POLL_ATTEMPTS) {
                        await new Promise(resolve => setTimeout(resolve, POLL_INTERVAL_MS));
                    }
                }

                if (attempts >= MAX_POLL_ATTEMPTS && !isComplete) {
                    throw new Error('Metrics calculation timed out');
                }
            } else if (totalBatches > 0) {
                // If saving finished but no metrics task ID (e.g., maybe no files qualified?)
                setAuditReady(true); // Consider audit ready even without metrics if save succeeded
                onSelectionSaved();
                 toastRef.current?.show({ severity: 'success', summary: 'Success', detail: 'File selection saved.', life: TOAST_LIFE });
            }

        } catch (error) {
            setHasError(true); // Set error flag
            toastRef.current?.show({
                severity: 'error',
                summary: 'Error',
                detail: error.message || 'Failed to save file selection or calculate metrics.',
                life: LONG_TOAST_LIFE // Longer display for errors
            });
            setAuditReady(false); // Ensure audit is not marked as ready on error
        } finally {
            setIsSaving(false);
            setIsMetricsCalculating(false); // Ensure this is false on completion or error
            // Keep progress at 100 if successful, otherwise let it be where it failed or reset
            if (!auditReady && !hasError) setMetricsProgress(0); // Use hasError instead of error
        }
    }, [selectedFiles, auditUuid, csrfToken, pollMetricsStatus, onSelectionSaved, toastRef, hasError]);

    return { isSaving, isMetricsCalculating, metricsProgress, auditReady, saveSelection };
};


/**
 * Hook to manage starting the audit process.
 * @param {string} auditUuid - The UUID of the audit.
 * @param {string} accountUuid - The UUID of the account.
 * @param {function} onHide - Callback to hide the sidebar.
 * @param {object} navigate - React Router navigate function.
 * @param {object} toastRef - Ref to the PrimeReact Toast component.
 */
const useAuditStart = (auditUuid, accountUuid, onHide, navigate, toastRef) => {
    const [isStarting, setIsStarting] = useState(false);
    const { csrfToken } = useCsrfToken();

    const startAudit = useCallback(async () => {
        if (!auditUuid || !accountUuid) {
            toastRef.current?.show({ severity: 'error', summary: 'Error', detail: 'Cannot start audit. Missing required information.', life: TOAST_LIFE });
            return;
        }

        setIsStarting(true);

        try {
            // 1. Check if audit can be started
            await axiosInstance.get(`api/can_start_audit/`, {
                params: { auditUuid, accountUuid }
            });

            // 2. Send notification email (fire and forget, or handle error separately if critical)
            axiosInstance.post(`send_audit_started_email/`, { auditUuid }, {
                 headers: { 'X-CSRFToken': csrfToken }
            }).catch(emailError => {
                console.warn('Failed to send audit started email:', emailError);
                // Non-critical error, proceed with audit start
            });

            // 3. Trigger the main process
            await axiosInstance.post(`api/trigger_main_process/`, { auditUuid, accountUuid }, {
                 headers: { 'X-CSRFToken': csrfToken }
            });

            // Show success message
            toastRef.current?.show({
                severity: 'success',
                summary: 'Success',
                detail: 'Audit started successfully!',
                life: TOAST_LIFE
            });

            // Navigate to the audit summary view
            console.log('Navigating to audit summary page.');
            navigate(`/${auditUuid}/audit-summary`);
            onHide(); // Close the sidebar

        } catch (error) {
            console.error('Error during audit initiation:', error);
            const errorMessage = error.response?.data?.error || error.message || 'Failed to start the audit. Please try again.';
            toastRef.current?.show({
                severity: 'error',
                summary: 'Audit Start Failed',
                detail: errorMessage,
                life: LONG_TOAST_LIFE
            });
        } finally {
            setIsStarting(false);
        }
    }, [auditUuid, accountUuid, csrfToken, navigate, onHide, toastRef]);

    return { isStarting, startAudit };
};


/**
 * Hook to manage payment processing and Stripe interactions.
 * @param {object} params - Parameters including Stripe flags, IDs, callbacks, audit details.
 */
const usePaymentProcessing = ({
    shouldOpenCheckoutModal, stripeSessionId, stripeSuccess, stripeCancelled, resetStripeParams,
    scopeSummary, locBudget, auditUuid, accountUuid, isGroupAudit, groupAuditUuid,
    updatePaidStatus, // Callback from useAuditInitialData
    setIsPaidForParent, // Prop callback
    toastRef,
    refreshInitialData // Add refreshInitialData parameter
}) => {
    const [checkoutModalVisible, setCheckoutModalVisible] = useState(false);
    const [orderDetails, setOrderDetails] = useState(null);
    const [isProcessingPayment, setIsProcessingPayment] = useState(false);
    const [stripeRedirectProcessed, setStripeRedirectProcessed] = useState(false);
    const { csrfToken } = useCsrfToken();

    // Effect to handle opening checkout modal after Stripe redirect
    useEffect(() => {
        if (shouldOpenCheckoutModal && stripeSessionId && !stripeRedirectProcessed) {
            const payableLines = Math.max(0, (scopeSummary?.linesOfCode || 0) - (locBudget || 0));
            const stripeRedirectOrderDetails = {
                linesOfCode: scopeSummary?.linesOfCode || 0,
                payableLines: payableLines,
                freeLines: Math.min(locBudget || 0, scopeSummary?.linesOfCode || 0),
                audit_uuid: auditUuid,
                account_uuid: accountUuid
            };

            setOrderDetails(stripeRedirectOrderDetails);
            setCheckoutModalVisible(true);
            setStripeRedirectProcessed(true); // Mark as processed for this session ID
            // Reset URL params via parent callback
            resetStripeParams();
        } else if (shouldOpenCheckoutModal && !stripeSessionId && !stripeRedirectProcessed) {
            // If modal should open but no session ID (e.g., user navigated back), reset flags
            resetStripeParams();
        }
    }, [
        shouldOpenCheckoutModal, stripeSessionId, stripeSuccess, stripeCancelled,
        scopeSummary, locBudget, auditUuid, accountUuid, resetStripeParams,
        stripeRedirectProcessed
    ]);

    const openCheckout = useCallback(() => {
        const payableLines = Math.max(0, (scopeSummary?.linesOfCode || 0) - (locBudget || 0));
        const newOrderDetails = {
            linesOfCode: scopeSummary?.linesOfCode || 0,
            payableLines: payableLines,
            freeLines: Math.min(locBudget || 0, scopeSummary?.linesOfCode || 0),
            audit_uuid: auditUuid,
            account_uuid: accountUuid
        };
        setOrderDetails(newOrderDetails);
        setCheckoutModalVisible(true);
    }, [scopeSummary, locBudget, auditUuid, accountUuid]);

    const hideCheckout = useCallback(() => {
        setCheckoutModalVisible(false);
        // Reset redirect processed flag when modal is manually closed,
        // allowing it to reopen if URL params are still present somehow (unlikely but safe)
        setStripeRedirectProcessed(false);
    }, []);

    // Function to verify payment status with retries
    const verifyPaymentWithRetries = useCallback(async (transactionId) => {
        let paymentConfirmed = false;
        let retryCount = 0;
        const maxRetries = 3;
        const retryDelay = 2000; // 2 seconds

        while (!paymentConfirmed && retryCount < maxRetries) {
            try {
                if (retryCount > 0) {
                    await new Promise(resolve => setTimeout(resolve, retryDelay));
                }

                // Option 1: Check the audit's paid status directly
                const refreshResponse = await axiosInstance.get(`api/audit_scope_summary/${auditUuid}/`);
                if (refreshResponse.data.is_paid) {
                    paymentConfirmed = true;
                    break;
                } else {
                    // Option 2: Check payment transaction status via dedicated endpoint
                    const verifyResponse = await axiosInstance.get(`api/verify_payment_status/${transactionId}/`).catch(e => {
                        return { data: { is_paid: false, audit_already_paid: false } }; // Default to not paid on error
                    });

                    if (verifyResponse.data.is_paid || verifyResponse.data.audit_already_paid || verifyResponse.data.status === 'succeeded') {
                        paymentConfirmed = true;
                        break;
                    }
                }
            } catch (error) {
                console.error(`Error during payment verification attempt ${retryCount + 1}:`, error);
                // Continue retrying unless it's the last attempt
            }
            retryCount++;
        }
        return paymentConfirmed;
    }, [auditUuid]);

    // Function to attempt manual recording of payment as a fallback
    const attemptManualPaymentRecord = useCallback(async (paymentDetails) => {
        if (!accountUuid || !auditUuid || !paymentDetails?.transactionId) {
            return false;
        }

        const paymentData = {
            payment_intent_id: paymentDetails.transactionId,
            account_uuid: accountUuid,
            audit_uuid: auditUuid,
            is_group_audit: isGroupAudit || false,
            send_receipt: true, // Assuming we always want a receipt
            // Provide sensible defaults or extract from paymentDetails if available
            amount: paymentDetails.amount || 0, // Use actual amount if available
            currency: paymentDetails.currency || 'eur', // Use actual currency if available
            customer_email: paymentDetails.billing_details?.email, // Optional
            country_code: paymentDetails.billing_details?.address?.country, // Optional
        };

        // Remove undefined optional fields
        Object.keys(paymentData).forEach(key => paymentData[key] === undefined && delete paymentData[key]);

        try {
             const response = await axiosInstance.post('/api/manual_payment_success/', paymentData, {
                 headers: { 'X-CSRFToken': csrfToken }
            });

            if (response.data.success || response.data.already_existed) {
                return true;
            } else {
                return false;
            }
        } catch (error) {
            return false;
        }
    }, [accountUuid, auditUuid, isGroupAudit, csrfToken]);

     // Final check of audit's paid status
    const finalPaymentCheck = useCallback(async () => {
        try {
            const finalCheck = await axiosInstance.get(`api/audit_scope_summary/${auditUuid}/`);
            return finalCheck.data.is_paid;
        } catch (finalError) {
            console.error('Error during final payment status check:', finalError);
            return false; // Assume not paid if check fails
        }
    }, [auditUuid]);


    // Main handler for successful payment callback from CheckoutModal
    const handlePaymentSuccess = useCallback(async (paymentDetails) => {
        if (!paymentDetails || !paymentDetails.transactionId) {
            console.warn('Invalid payment details received:', { 
                hasDetails: !!paymentDetails,
                hasTransactionId: !!paymentDetails?.transactionId 
            });
            toastRef.current?.show({ 
                severity: 'error', 
                summary: 'Payment Error', 
                detail: 'Invalid payment confirmation received.', 
                life: TOAST_LIFE 
            });
            return;
        }
        setIsProcessingPayment(true);

        try {
            // Optimistic UI update
            updatePaidStatus(true);
            if (typeof setIsPaidForParent === 'function') {
                setIsPaidForParent(true);
            }

            // Verify payment status with retries
            let paymentConfirmed = await verifyPaymentWithRetries(paymentDetails.transactionId);

            // If verification failed, attempt manual recording
            if (!paymentConfirmed) {
                console.log('Payment verification failed, attempting manual recording...');
                paymentConfirmed = await attemptManualPaymentRecord(paymentDetails);
                
                // After manual recording, wait a short moment and verify again
                if (paymentConfirmed) {
                    await new Promise(resolve => setTimeout(resolve, 2000)); // Wait 2 seconds
                    paymentConfirmed = await verifyPaymentWithRetries(paymentDetails.transactionId);
                }
            }

            // Perform one last check after potential manual recording
            if (paymentConfirmed) {
                const isFinallyPaid = await finalPaymentCheck();
                if (!isFinallyPaid) {
                    console.warn('Payment verification mismatch:', {
                        transactionId: paymentDetails.transactionId,
                        auditUuid: auditUuid,
                    });
                    // Force refresh the audit data to get latest status
                    refreshInitialData();
                }
                updatePaidStatus(isFinallyPaid);
                if (typeof setIsPaidForParent === 'function') {
                    setIsPaidForParent(isFinallyPaid);
                }
                paymentConfirmed = isFinallyPaid;
            }
                // Force refresh the audit data to ensure UI is up to date
                refreshInitialData();

        } catch (error) {
            console.error('Payment processing error:', {
                errorType: error.name,
                errorMessage: error.message,
                auditUuid: auditUuid,
                ...(process.env.NODE_ENV === 'development' && { stack: error.stack })
            });

            toastRef.current?.show({
                severity: 'error',
                summary: 'Payment Processing Error',
                detail: 'An unexpected error occurred while confirming your payment. Please contact support if the issue persists.',
                life: LONG_TOAST_LIFE
            });
        } finally {
            setIsProcessingPayment(false);
        }
    }, [
        updatePaidStatus, 
        setIsPaidForParent, 
        verifyPaymentWithRetries,
        attemptManualPaymentRecord, 
        finalPaymentCheck, 
        toastRef,
        auditUuid,
        refreshInitialData // Add refreshInitialData to dependencies
    ]);


    return {
        checkoutModalVisible,
        orderDetails,
        isProcessingPayment,
        openCheckout,
        hideCheckout,
        handlePaymentSuccess,
        initialStripeParams: { // Pass these directly to CheckoutModal
            initialSessionId: stripeSessionId,
            initialPaymentSuccess: stripeSuccess,
            initialPaymentCancelled: stripeCancelled,
            isReturnFromStripe: (stripeSessionId !== null || stripeSuccess || stripeCancelled),
            activeIndex: (stripeSessionId !== null || stripeSuccess || stripeCancelled) ? 1 : 0
        }
    };
};

// --- Child Components (Memoized for Performance) ---

const AuditScopeDisplay = React.memo(({ scopeSummary }) => {
    return (
        <div className="audit-summary">
            <h2>Audit scope</h2>
            <h4>The following content will be audited:</h4>
            <div className="files-to-audit">
                <span><strong>{formatNumberDE(scopeSummary?.numberOfFiles)}</strong></span>
                <label> files</label>
            </div>
            <div className="lines-of-code">
                <span><strong>{formatNumberDE(scopeSummary?.linesOfCode)}</strong></span>
                <label> total lines of code</label>
            </div>
        </div>
    );
});
AuditScopeDisplay.propTypes = {
    scopeSummary: PropTypes.shape({
        numberOfFiles: PropTypes.number,
        linesOfCode: PropTypes.number,
    }),
};
AuditScopeDisplay.displayName = 'AuditScopeDisplay';


const FileSelectionSection = React.memo(({
    onConfirm,
    isDisabled,
    isLoading, // Combined loading state (saving or metrics)
    isMetricsCalculating,
    metricsProgress,
    noFilesSelected,
    isAuditDeleted
}) => {
    const handleRefresh = () => window.location.reload();

    const renderProgress = () => {
        if (isLoading) {
            return (
                <div className="progress-section" style={{ marginTop: '10px', marginBottom: '10px' }}>
                    <ProgressBar
                        value={metricsProgress}
                        showValue={true}
                        style={{ height: '6px' }}
                    />
                    <small className="progress-text">
                        {isMetricsCalculating
                            ? 'Calculating final metrics...'
                            : 'Processing file selection...'}
                    </small>
                </div>
            );
        }
        return null;
    };

    return (
        <>
            <Button
                label="Save File Selection"
                className="confirm-button"
                onClick={onConfirm}
                disabled={isDisabled || isLoading || isAuditDeleted} // Disable during loading and if deleted
                style={{ marginTop: '1rem', width: '95%', height: '40px' }} // Use width instead of minWidth
            />

            {noFilesSelected && !isAuditDeleted && (
                 <>
                    <div style={{ margin: '1rem 0' }}>
                        <Message
                            severity="info"
                            text="No files selected. Please select files to proceed."
                            style={{ width: '100%' }} // Ensure message fills width
                        />
                    </div>
                    <div style={{ display: 'flex', justifyContent: 'center', marginBottom: '1rem' }}>
                        <Button
                            icon="pi pi-refresh"
                            onClick={handleRefresh}
                            tooltip="Refresh page to reload files"
                            className="p-button-secondary p-button-outlined" // Style refresh button
                        />
                    </div>
                 </>
            )}

            {renderProgress()}
        </>
    );
});
FileSelectionSection.propTypes = {
    onConfirm: PropTypes.func.isRequired,
    isDisabled: PropTypes.bool.isRequired,
    isLoading: PropTypes.bool.isRequired,
    isMetricsCalculating: PropTypes.bool.isRequired,
    metricsProgress: PropTypes.number.isRequired,
    noFilesSelected: PropTypes.bool.isRequired,
    isAuditDeleted: PropTypes.bool.isRequired,
};
FileSelectionSection.displayName = 'FileSelectionSection';


const CheckoutSection = React.memo(({
    auditReady,
    isAuditCompleted,
    isAuditDeleted,
    isPaid,
    hasUnsavedChanges,
    locBudget,
    scopeSummary,
    isSavingSelection, // From useFileSelection
    isStartingAudit, // From useAuditStart
    isProcessingPayment, // From usePaymentProcessing
    onShowCheckout,
    onStartAudit,
    onNavigateToReport,
    onContactSales,
}) => {
    const linesOfCode = scopeSummary?.linesOfCode || 0;
    const budget = locBudget || 0;
    const payableLines = useMemo(() => Math.max(0, linesOfCode - budget), [linesOfCode, budget]);
    const needsPayment = useMemo(() => payableLines > 0, [payableLines]);
    const canStartAudit = useMemo(() => budget >= linesOfCode || isPaid, [budget, linesOfCode, isPaid]);
    const showCheckoutButton = useMemo(() => needsPayment && !isPaid && !isAuditCompleted, [needsPayment, isPaid, isAuditCompleted]);
    const showStartAuditButton = useMemo(() => canStartAudit && !isAuditCompleted, [canStartAudit, isAuditCompleted]);
    const showTalkToSalesButton = useMemo(() => needsPayment && !isPaid && !isAuditCompleted, [needsPayment, isPaid, isAuditCompleted]);

    // Consolidated loading state for disabling buttons
    const isLoading = isSavingSelection || isStartingAudit || isProcessingPayment;

    const handleContactSalesClick = useCallback(() => {
        window.location.href = 'mailto:info@codedd.ai';
    }, []);

    if (!auditReady && !isAuditCompleted && !isPaid) {
        // Don't show checkout section until selection is saved/confirmed or audit is already paid/completed
        return null;
    }

    return (
        <div className={`audit-order ${isAuditDeleted ? 'disabled' : ''}`} style={{ marginTop: '1.5rem' }}>
            {/* Warning for unsaved changes only shown when relevant */}
            {hasUnsavedChanges && !isAuditCompleted && !isPaid && (
                <Message
                    severity="warn"
                    text='Selection changed. Please "Save File Selection" to update.'
                    style={{ margin: '1rem 0', width: '95%' }}
                />
            )}

            <h2>Checkout</h2>

            <div className="audit-info-row">
                <span>Free LoC Budget:</span>
                <span className="audit-value">
                    <b>{formatNumberDE(budget)}</b>
                </span>
            </div>
            <div className="audit-info-row-small">
                <span style={{ fontStyle: "italic", fontSize: "14px" }}>Remaining after audit:</span>
                <span className="audit-value" style={{ fontStyle: "italic", fontSize: "12px" }}>
                    <i>{formatNumberDE(Math.max(0, budget - linesOfCode))}</i>
                </span>
            </div>

            <div className="audit-info-row">
                <span>Selected LoC:</span>
                <span className="audit-value">
                    <b>{formatNumberDE(linesOfCode)}</b>
                </span>
            </div>

            <hr className="audit-divider" />

            <div className="audit-info-row">
                <span>Payable LoC:</span>
                <span className="audit-value">
                    <b>{formatNumberDE(payableLines)}</b>
                </span>
            </div>

            {/* Payment Status Indicator */}
            {isPaid && (
                <div className="payment-status-paid" style={{ color: '#2CB392', margin: '10px 0', fontWeight: 'bold', textAlign: 'center' }}>
                    <i className="pi pi-check-circle" style={{ marginRight: '8px' }}></i>
                    <span>Payment Completed</span>
                </div>
            )}
             {isProcessingPayment && (
                <div className="payment-status-processing" style={{ color: '#FFC107', margin: '10px 0', fontWeight: 'bold', textAlign: 'center' }}>
                    <i className="pi pi-spin pi-spinner" style={{ marginRight: '8px' }}></i>
                    <span>Processing Payment...</span>
                </div>
            )}


            {/* Action Buttons */}
            <div style={{ marginTop: '1rem', display: 'flex', flexDirection: 'column', gap: '0.75rem', width: '95%', margin: 'auto' }}>
                {/* Checkout Button */}
                {showCheckoutButton && (
                    <Button
                        label="Proceed to Checkout"
                        icon="pi pi-credit-card"
                        className="checkout-button p-button-success" // Use success style
                        onClick={onShowCheckout}
                        disabled={isLoading || hasUnsavedChanges || isAuditDeleted}
                    />
                )}

                {/* Start Audit or To Report Button */}
                {isAuditCompleted ? (
                    <Button
                        label="To Audit Report"
                        icon="pi pi-file"
                        className="to-audit-report-button"
                        onClick={onNavigateToReport}
                        disabled={isAuditDeleted} // Only disabled if deleted
                        style={{ backgroundColor: '#32AFC3', color: 'white' }}
                    />
                ) : (
                    showStartAuditButton && (
                        <Button
                            label="Start Audit"
                            className={`start-audit-button ${isStartingAudit ? 'p-button-text p-button-plain' : ''}`}
                            onClick={onStartAudit}
                            icon={isStartingAudit ? 'pi pi-spin pi-spinner' : 'pi pi-bolt'}
                            disabled={isLoading || isAuditDeleted || hasUnsavedChanges} // Disable if loading, deleted, or unsaved changes
                            style={{ backgroundColor: '#32AFC3', color: 'white' }}
                        />
                    )
                )}


                {/* Talk to Sales Button */}
                {showTalkToSalesButton && (
                     <>
                        <div className="audit-warning" style={{ fontSize: '0.875rem', textAlign: 'center', marginTop:'0.5rem' }}>
                             Insufficient free Loc Budget.
                        </div>
                        <Button 
                            label="Talk to sales"
                            icon="pi pi-arrow-right"
                            className="talk-to-sales-button"
                            onClick={handleContactSalesClick} // Use memoized handler
                            disabled={isLoading || isAuditDeleted}
                            style={{
                                backgroundColor: '#323232',
                                color: 'white',
                                border: '2px solid #323232',
                                minWidth: '95%',
                                marginTop: '1rem',
                                fontWeight: '600'
                            }}
                        />
                    </>
                )}
            </div>
        </div>
    );
});
CheckoutSection.propTypes = {
    auditReady: PropTypes.bool.isRequired,
    isAuditCompleted: PropTypes.bool.isRequired,
    isAuditDeleted: PropTypes.bool.isRequired,
    isPaid: PropTypes.bool.isRequired,
    hasUnsavedChanges: PropTypes.bool.isRequired,
    locBudget: PropTypes.number.isRequired,
    scopeSummary: PropTypes.shape({ linesOfCode: PropTypes.number }),
    isSavingSelection: PropTypes.bool.isRequired,
    isStartingAudit: PropTypes.bool.isRequired,
    isProcessingPayment: PropTypes.bool.isRequired,
    onShowCheckout: PropTypes.func.isRequired,
    onStartAudit: PropTypes.func.isRequired,
    onNavigateToReport: PropTypes.func.isRequired,
    onContactSales: PropTypes.func.isRequired, // Prop removed as logic is internal now
};
CheckoutSection.displayName = 'CheckoutSection';


const GroupAuditActions = React.memo(({ onNavigateBack, isAuditDeleted }) => {
     return (
        <Button
            label="Back to Overview"
            icon="pi pi-angle-left"
            className="back-button p-button-outlined" // Use outlined style
            style={{ marginTop: '25px', width: '95%', border: '1px solid #8D8D8D' }}
            onClick={onNavigateBack}
            disabled={isAuditDeleted}
        />
     );
});
GroupAuditActions.propTypes = {
    onNavigateBack: PropTypes.func.isRequired,
    isAuditDeleted: PropTypes.bool.isRequired,
};
GroupAuditActions.displayName = 'GroupAuditActions';

// --- Main Component ---

const AuditScopeSummaryTabMenu = ({
    visible,
    onHide,
    selectedNodes = [], // Default value
    groupAuditUuid = null, // Default value
    userRole = null, // Default value
    auditStatus = '', // Default value
    hasUnsavedChanges = false, // Default value
    onSelectionSaved: onSelectionSavedProp, // Renamed to avoid conflict
    scopeSummary: scopeSummaryProp = null, // Default value
    selectedFiles = [], // Default value
    isGroupAudit = false, // Default value
    setIsPaidForParent = null, // Default value
    // Stripe redirect props
    shouldOpenCheckoutModal = false, // Default value
    stripeSessionId = null, // Default value
    stripeSuccess = false, // Default value
    stripeCancelled = false, // Default value
    resetStripeParams
}) => {
    const toast = useRef(null);
    const location = useLocation();
    const navigate = useNavigate();
    const auditUuid = useMemo(() => location.pathname.split('/')[1], [location.pathname]);

    // --- Hooks ---
    const {
        initialSummary, // Use prop 'scopeSummaryProp' for display instead
        locBudget,
        accountUuid,
        isPaid: isPaidInitially, // Initial paid status
        isLoading: isLoadingInitialData,
        error: initialDataError,
        updatePaidStatus, // Function to update isPaid state
        refreshData: refreshInitialData // Function to refresh data
    } = useAuditInitialData(auditUuid, visible);

    // Use the hook's paid state, allowing it to be updated by payment processing
    const [isPaid, setIsPaidState] = useState(isPaidInitially);
    useEffect(() => {
        setIsPaidState(isPaidInitially); // Sync hook state with local state
    }, [isPaidInitially]);

    // Callback to update both local and hook state
    const handleSetPaid = useCallback((paid) => {
        setIsPaidState(paid);
        updatePaidStatus(paid); // Update the hook's internal state if needed elsewhere
         if (typeof setIsPaidForParent === 'function') {
            setIsPaidForParent(paid);
        }
    }, [updatePaidStatus, setIsPaidForParent]);


    const {
        isSaving: isSavingSelection,
        isMetricsCalculating,
        metricsProgress,
        auditReady,
        saveSelection
    } = useFileSelection(auditUuid, selectedFiles, onSelectionSavedProp, toast);

    const { isStarting: isStartingAudit, startAudit } = useAuditStart(
        auditUuid,
        accountUuid,
        onHide,
        navigate,
        toast
    );

    const {
        checkoutModalVisible,
        orderDetails,
        isProcessingPayment,
        openCheckout,
        hideCheckout,
        handlePaymentSuccess,
        initialStripeParams
    } = usePaymentProcessing({
        shouldOpenCheckoutModal, stripeSessionId, stripeSuccess, stripeCancelled, resetStripeParams,
        scopeSummary: scopeSummaryProp, // Use prop directly
        locBudget, auditUuid, accountUuid, isGroupAudit, groupAuditUuid,
        updatePaidStatus: handleSetPaid, // Pass the combined update function
        setIsPaidForParent, // Pass parent callback directly
        toastRef: toast,
        refreshInitialData // Add refreshInitialData to the hook parameters
    });

    // --- Derived State and Event Handlers ---
    const isAuditCompleted = useMemo(() => isAuditCompletedStatus(auditStatus), [auditStatus]);
    const isAuditDeleted = useMemo(() => isAuditDeletedStatus(auditStatus), [auditStatus]);

    // Use prop scopeSummary for calculations, fallback to initialSummary if prop is null/undefined briefly
    const currentScopeSummary = scopeSummaryProp || initialSummary;

    const noFilesSelected = useMemo(() => {
         // Check both prop 'selectedFiles' and the calculated 'scopeSummaryProp'
        const hasSelectedPropFiles = selectedFiles && selectedFiles.length > 0;
        const hasSummaryFiles = currentScopeSummary && currentScopeSummary.numberOfFiles > 0;
        // Consider 'no files' if EITHER the direct selection is empty OR the summary shows 0 files.
        // This covers cases before/after saving selection.
        return !hasSelectedPropFiles && !hasSummaryFiles;
    }, [selectedFiles, currentScopeSummary]);


    // Combined loading state
    const isLoading = isSavingSelection || isStartingAudit || isLoadingInitialData || isProcessingPayment;

    // Determine if the "Save File Selection" button should be disabled
    const isConfirmDisabled = useMemo(() => (
        noFilesSelected ||
        isAuditCompleted ||
        isAuditDeleted ||
        isPaid || // Cannot re-save selection if already paid
        isLoading || // Disable during any loading state
        (auditReady && !hasUnsavedChanges) // Disabled if audit is ready and no changes made
    ), [noFilesSelected, isAuditCompleted, isAuditDeleted, isPaid, isLoading, auditReady, hasUnsavedChanges]);

    const navigateToAuditReport = useCallback(() => {
        navigate(`/${auditUuid}/audit-summary`);
    }, [navigate, auditUuid]);

    const handleBackToOverview = useCallback(() => {
        if (isGroupAudit === true && groupAuditUuid) {
            navigate(`/${groupAuditUuid}/audit-invitation`, {
                state: { groupAuditUuid }
            });
        } else {
            console.warn('Cannot navigate back - group audit information is missing.');
            toast.current?.show({
                severity: 'warn',
                summary: 'Navigation Error',
                detail: 'Cannot navigate back - group audit information is missing',
                life: TOAST_LIFE
            });
        }
    }, [isGroupAudit, groupAuditUuid, navigate]);

    // Effect to show initial data loading error
    useEffect(() => {
        if (initialDataError) {
            toast.current?.show({ severity: 'error', summary: 'Error Loading Data', detail: initialDataError, life: LONG_TOAST_LIFE });
        }
    }, [initialDataError]);

     // Refresh initial data when modal becomes visible again, if needed
     useEffect(() => {
        if (visible) {
            refreshInitialData();
        }
    }, [visible, refreshInitialData]);


    return (
        <>
            {/* Toast needs to be outside Sidebar or managed globally */}
            <Toast ref={toast} />
            <Sidebar
                visible={visible} // Control visibility via prop
                modal={false}
                position="right"
                style={{ width: '320px' }} // Slightly wider?
                onHide={onHide}
                dismissable={false} // Prevent dismissal on outside click
                showCloseIcon={false} // Hide the close icon
                className="audit-scope-summary-sidebar" // Add a class for specific styling
            >
                 <ErrorBoundary> {/* Wrap content in Error Boundary */}
                    <div className={`audit-sidebar-content ${isAuditDeleted ? 'audit-disabled-content' : ''}`}>

                        {/* Initial Loading Indicator */}
                        {isLoadingInitialData && <ProgressBar mode="indeterminate" style={{ height: '6px', marginBottom: '1rem' }} />}

                        {/* Display Audit Scope */}
                        {!isLoadingInitialData && <AuditScopeDisplay scopeSummary={currentScopeSummary} />}

                        {/* File Selection Actions */}
                         {!isLoadingInitialData && (
                            <FileSelectionSection
                                onConfirm={saveSelection}
                                isDisabled={isConfirmDisabled}
                                isLoading={isSavingSelection || isMetricsCalculating} // Loading specific to this section
                                isMetricsCalculating={isMetricsCalculating}
                                metricsProgress={metricsProgress}
                                noFilesSelected={noFilesSelected}
                                isAuditDeleted={isAuditDeleted}
                            />
                         )}


                        {/* Conditional Checkout/Start Audit section */}
                        {!(isGroupAudit === true && groupAuditUuid) && !isLoadingInitialData && (
                            <CheckoutSection
                                auditReady={auditReady}
                                isAuditCompleted={isAuditCompleted}
                                isAuditDeleted={isAuditDeleted}
                                isPaid={isPaid}
                                hasUnsavedChanges={hasUnsavedChanges}
                                locBudget={locBudget}
                                scopeSummary={currentScopeSummary}
                                isSavingSelection={isSavingSelection}
                                isStartingAudit={isStartingAudit}
                                isProcessingPayment={isProcessingPayment}
                                onShowCheckout={openCheckout}
                                onStartAudit={startAudit}
                                onNavigateToReport={navigateToAuditReport}
                                onContactSales={() => { window.location.href = 'mailto:info@codedd.ai'; }} // Simple handler ok here
                             />
                        )}

                        {/* Group Audit Actions */}
                        {(isGroupAudit === true && groupAuditUuid) && !isLoadingInitialData && (
                            <GroupAuditActions
                                onNavigateBack={handleBackToOverview}
                                isAuditDeleted={isAuditDeleted}
                            />
                        )}

                        {/* Display Error Message if initial data failed */}
                        {initialDataError && !isLoadingInitialData && (
                            <Message severity="error" text={`Failed to load audit details: ${initialDataError}`} style={{ marginTop: '1rem', width: '95%' }}/>
                        )}

                         {/* Global Disabled Overlay if Audit is Deleted */}
                         {isAuditDeleted && (
                            <div className="deleted-overlay">
                                <Message severity="warn" text="This audit has been deleted." />
                            </div>
                         )}

                    </div>
                 </ErrorBoundary>
            </Sidebar>

            {/* Checkout Modal */}
            {/* Render modal outside sidebar? Or ensure it overlays correctly */}
            <CheckoutModal
                visible={checkoutModalVisible}
                onHide={hideCheckout}
                orderDetails={orderDetails}
                account_uuid={accountUuid} // Passed from hook
                audit_uuid={auditUuid}     // Passed from hook/location
                successHandler={handlePaymentSuccess} // From usePaymentProcessing
                isGroupAudit={isGroupAudit}
                groupAuditUuid={groupAuditUuid}
                // Pass initial Stripe params directly from hook
                {...initialStripeParams}
            />
        </>
    );
};

// --- PropTypes ---
AuditScopeSummaryTabMenu.propTypes = {
    visible: PropTypes.bool.isRequired,
    onHide: PropTypes.func.isRequired,
    selectedNodes: PropTypes.array, // Keep for isConfirmDisabled logic if needed
    groupAuditUuid: PropTypes.string,
    userRole: PropTypes.string, // Keep for potential future use
    auditStatus: PropTypes.string,
    hasUnsavedChanges: PropTypes.bool,
    onSelectionSaved: PropTypes.func.isRequired,
    scopeSummary: PropTypes.shape({
        numberOfFiles: PropTypes.number,
        linesOfCode: PropTypes.number,
        // is_paid might be here too, but we fetch fresh status
    }),
    selectedFiles: PropTypes.array, // Array of file paths/objects
    isGroupAudit: PropTypes.bool,
    setIsPaidForParent: PropTypes.func, // Callback to update parent's state
    // Stripe redirect props
    shouldOpenCheckoutModal: PropTypes.bool,
    stripeSessionId: PropTypes.string,
    stripeSuccess: PropTypes.bool,
    stripeCancelled: PropTypes.bool,
    resetStripeParams: PropTypes.func.isRequired,
};

export default AuditScopeSummaryTabMenu;