import React, { useState, useEffect, useRef } from 'react';
import { TabMenu } from 'primereact/tabmenu';
import { useLocation, useNavigate } from 'react-router-dom';
import { Menu } from 'primereact/menu';
import { Button } from 'primereact/button';

import './TabMenu.css';

const AdminTabMenu = ({ activeIndex, onTabChange }) => {
  const location = useLocation();
  const navigate = useNavigate();
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);
  const [isMenuVisible, setIsMenuVisible] = useState(false);
  const menu = useRef(null);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
    };

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  // Define admin menu items
  const items = [
    { label: 'Dashboard', icon: 'pi pi-fw pi-home', route: '/admin/dashboard' },
    { label: 'Audits', icon: 'pi pi-fw pi-list', route: '/admin/audits' },
    { label: 'Users', icon: 'pi pi-fw pi-users', route: '/admin/users' },
    { label: 'Settings', icon: 'pi pi-fw pi-cog', route: '/admin/settings' }
  ];

  useEffect(() => {
    // Find the active tab based on the current route
    const currentPath = location.pathname;
    const index = items.findIndex(item => currentPath.includes(item.route.split('/').pop()));
    
    // If we found a matching route and it's different from current activeIndex
    if (index !== -1 && index !== activeIndex) {
      onTabChange({ index });
    }
  }, [location.pathname, activeIndex, onTabChange, items]);

  const handleItemClick = (index) => {
    onTabChange({ index });
    navigate(items[index].route);
    
    if (isMobile) {
      setIsMenuVisible(false);
    }
  };

  const toggleMenu = (event) => {
    if (menu.current) {
      menu.current.toggle(event);
    }
    setIsMenuVisible(prev => !prev);
  };

  const menuModel = items.map((item, index) => ({
    label: item.label,
    icon: item.icon,
    command: () => handleItemClick(index),
  }));

  return (
    <div className="admin-tab-menu-container">
      {isMobile ? (
        <>
          <div className="admin-mobile-tab-header">
            <span className="admin-current-tab">
              <i className={items[activeIndex].icon}></i>
              {items[activeIndex].label}
            </span>
            <Menu model={menuModel} popup ref={menu} id="admin_popup_menu" onHide={() => setIsMenuVisible(false)} />
            <Button icon="pi pi-bars" onClick={toggleMenu} aria-controls="admin_popup_menu" aria-haspopup />
          </div>
        </>
      ) : (
        <TabMenu 
          model={items.map((item, index) => ({
            ...item,
            command: () => handleItemClick(index),
            className: activeIndex === index ? 'active-tab' : ''
          }))} 
          activeIndex={activeIndex} 
          className="admin-custom-tabmenu" 
        />
      )}
    </div>
  );
};

export default AdminTabMenu;