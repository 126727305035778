import React, { useState, useEffect, useRef } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Button } from 'primereact/button';
import { Calendar } from 'primereact/calendar';
import { Dropdown } from 'primereact/dropdown';
import { InputText } from 'primereact/inputtext';
import { Dialog } from 'primereact/dialog';
import { Message } from 'primereact/message';
import axiosInstance from '../../axiosConfig';
import './Logs.css';

const Logs = () => {
    const { auditUuid } = useParams();
    const navigate = useNavigate();
    const [logs, setLogs] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [filters, setFilters] = useState({
        startDate: null,
        endDate: null,
        level: null,
        stepName: null,
        stepStatus: null,
        moduleName: null,
        search: ''
    });
    const [selectedLog, setSelectedLog] = useState(null);
    const [showDetails, setShowDetails] = useState(false);
    const dt = useRef(null);

    // Get account_uuid from localStorage
    const accountUuid = localStorage.getItem('accountUuid');
    console.log('accountUuid from Logs', accountUuid);

    const levelOptions = [
        { label: 'All Levels', value: null },
        { label: 'INFO', value: 'INFO' },
        { label: 'WARNING', value: 'WARNING' },
        { label: 'ERROR', value: 'ERROR' },
        { label: 'DEBUG', value: 'DEBUG' }
    ];

    const moduleOptions = [
        { label: 'All Modules', value: null },
        { label: 'Contextualizer', value: 'contextualizer' },
        { label: 'AI Contextualizer', value: 'ai_contextualizer' },
        { label: 'AI Auditor', value: 'ai_auditor' },
        { label: 'Data Ingestion', value: 'data_ingestion' }
    ];

    const statusOptions = [
        { label: 'All Statuses', value: null },
        { label: 'Pending', value: 'PENDING' },
        { label: 'Running', value: 'RUNNING' },
        { label: 'In Progress', value: 'IN_PROGRESS' },
        { label: 'Processing', value: 'PROCESSING' },
        { label: 'Completed', value: 'COMPLETED' },
        { label: 'Failed', value: 'FAILED' },
        { label: 'Retrying', value: 'RETRYING' },
        { label: 'Incomplete', value: 'INCOMPLETE' }
    ];

    const getStatusLabel = (status) => {
        if (!status) return null;
        
        // Normalize the status to uppercase for consistent handling
        const normalizedStatus = status.toUpperCase();
        
        const statusMap = {
            'IN_PROGRESS': 'In Progress',
            'RUNNING': 'Running',
            'COMPLETED': 'Completed',
            'FAILED': 'Failed',
            'PENDING': 'Pending',
            'PROCESSING': 'Processing',
            'RETRYING': 'Retrying',
            'INCOMPLETE': 'Incomplete'
        };
        
        // Try to find the status in the map, or return the original status with first letter capitalized
        return statusMap[normalizedStatus] || 
               (status.charAt(0).toUpperCase() + status.slice(1).toLowerCase());
    };

    useEffect(() => {
        if (!accountUuid) {
            navigate('/dashboard');
            return;
        }
        fetchLogs();
    }, [filters, accountUuid, navigate]);

    // Add a function to ensure each log has a unique ID
    const ensureLogsHaveIds = (logsArray) => {
        if (!Array.isArray(logsArray)) return [];
        
        return logsArray.map((log, index) => {
            // If the log already has an id, use it
            if (log.id) return log;
            
            // Otherwise, create a unique id based on timestamp and index
            const timestamp = log.timestamp ? new Date(log.timestamp).getTime() : Date.now();
            return {
                ...log,
                id: `log_${timestamp}_${index}`
            };
        });
    };

    const fetchLogs = async () => {
        try {
            setLoading(true);
            setError(null);
            const response = await axiosInstance.get(`/api/audit/${auditUuid}/logs/`, {
                params: {
                    account_uuid: accountUuid,
                    start_date: filters.startDate?.toISOString(),
                    end_date: filters.endDate?.toISOString(),
                    level: filters.level,
                    step_name: filters.stepName,
                    step_status: filters.stepStatus,
                    module_name: filters.moduleName,
                    search: filters.search
                }
            });
            
            // Ensure logs is always an array
            if (response.data && response.data.status === "success" && Array.isArray(response.data.logs)) {
                // Handle the specific response format from the backend with logs in a 'logs' property
                console.log("Received logs:", response.data.logs.length);
                setLogs(ensureLogsHaveIds(response.data.logs));
            } else if (Array.isArray(response.data)) {
                setLogs(ensureLogsHaveIds(response.data));
            } else if (response.data && typeof response.data === 'object' && Array.isArray(response.data.results)) {
                // If the API returns a paginated response with a 'results' property
                setLogs(ensureLogsHaveIds(response.data.results));
            } else if (response.data && typeof response.data === 'object') {
                // If the API returns an object with logs as properties
                console.warn('API returned an object instead of an array for logs. Converting to array.');
                const logsArray = Object.values(response.data);
                setLogs(ensureLogsHaveIds(logsArray));
            } else {
                // If we can't determine the structure, set an empty array
                console.error('Unexpected API response format for logs:', response.data);
                setLogs([]);
                setError('Received invalid data format from the server.');
            }
        } catch (error) {
            console.error('Error fetching logs:', error);
            const statusCode = error.response?.status;
            
            // Handle different status codes
            switch (statusCode) {
                case 401: // Unauthorized
                case 400: // Bad Request
                    navigate('/dashboard');
                    return;
                case 403: // Forbidden
                    setError('Access denied. Admin privileges required.');
                    setLogs([]);
                    break;
                default:
                    setError(error.response?.data?.error || 'Failed to fetch logs.');
                    setLogs([]);
            }
        } finally {
            setLoading(false);
        }
    };

    const handleRefresh = () => {
        fetchLogs();
    };

    const formatTimestamp = (timestamp) => {
        if (!timestamp) return 'N/A';
        return new Date(timestamp).toLocaleString();
    };

    const levelBodyTemplate = (rowData) => {
        if (!rowData || !rowData.level) {
            return <span className="log-level-unknown">UNKNOWN</span>;
        }
        const levelClass = `log-level-${rowData.level.toLowerCase()}`;
        return <span className={levelClass}>{rowData.level}</span>;
    };

    const statusBodyTemplate = (rowData) => {
        if (!rowData || !rowData.step_status) return null;
        
        // Normalize the status to uppercase for consistent handling
        const normalizedStatus = rowData.step_status.toUpperCase().replace(' ', '_');
        const statusClass = `step-status-${normalizedStatus.toLowerCase()}`;
        
        return <span className={statusClass}>{getStatusLabel(rowData.step_status)}</span>;
    };

    const cleanMessage = (message) => {
        if (!message) return '';
        
        try {
            // If message is a JSON string
            if (typeof message === 'string' && message.trim().startsWith('{')) {
                const parsed = JSON.parse(message);
                let displayMessage = parsed.message || '';
                
                // Add error details if present
                if (parsed.extras?.error_meta) {
                    const errorMeta = parsed.extras.error_meta;
                    displayMessage += ` (${errorMeta.error_type}: ${errorMeta.error_message})`;
                }
                
                // Add step status if present
                if (parsed.extras?.step_status) {
                    displayMessage += ` [${parsed.extras.step_status}]`;
                }
                
                return displayMessage;
            }
            
            // If message is already an object
            if (typeof message === 'object' && message !== null) {
                let displayMessage = message.message || '';
                
                // Add error details if present
                if (message.extras?.error_meta) {
                    const errorMeta = message.extras.error_meta;
                    displayMessage += ` (${errorMeta.error_type}: ${errorMeta.error_message})`;
                }
                
                // Add step status if present
                if (message.extras?.step_status) {
                    displayMessage += ` [${message.extras.step_status}]`;
                }
                
                return displayMessage;
            }
        } catch (e) {
            // If parsing fails, continue with string cleaning
        }

        // Remove timestamp and log level prefixes for plain text messages
        return message.replace(/^\[\d{4}-\d{2}-\d{2}\s\d{2}:\d{2}:\d{2},\d{3}\]\s*\[[A-Z]+\]\s*\[[0-9a-f-]+\]\s*/, '')
                     .replace(/^{.*"message":\s*"(.*?)".*}$/, '$1')
                     .trim();
    };

    const formatMessageForModal = (message) => {
        if (!message) return '';

        try {
            // If message is already an object or a JSON string
            let messageObj = typeof message === 'object' ? message : JSON.parse(message);
            
            return (
                <div className="structured-message">
                    <div className="message-main">{messageObj.message}</div>
                    <div className="message-details">
                        {messageObj.extras && (
                            <div className="extras-section">
                                <h3>Additional Information</h3>
                                <div className="extras-grid">
                                    {Object.entries(messageObj.extras).map(([key, value]) => {
                                        // Skip error_meta as it will be shown separately
                                        if (key === 'error_meta') return null;
                                        
                                        return (
                                            <div key={key} className="extras-item">
                                                <span className="extras-label">{key.replace(/_/g, ' ')}:</span>
                                                <span className="extras-value">
                                                    {typeof value === 'object' ? JSON.stringify(value, null, 2) : value}
                                                </span>
                                            </div>
                                        );
                                    })}
                                </div>
                            </div>
                        )}
                        {messageObj.extras?.error_meta && (
                            <div className="message-error">
                                <h3>Error Details</h3>
                                <div className="error-type">Type: {messageObj.extras.error_meta.error_type}</div>
                                <div className="error-message">Message: {messageObj.extras.error_meta.error_message}</div>
                                {messageObj.extras.error_meta.stack_trace && (
                                    <div className="stack-trace">
                                        <div className="stack-trace-label">Stack Trace:</div>
                                        <pre>{messageObj.extras.error_meta.stack_trace}</pre>
                                    </div>
                                )}
                            </div>
                        )}
                    </div>
                </div>
            );
        } catch (e) {
            // If parsing fails, try to display metadata from the log record
            if (selectedLog?.metadata) {
                return (
                    <div className="structured-message">
                        <div className="message-main">{message}</div>
                        <div className="message-details">
                            <div className="extras-section">
                                <h3>Additional Information</h3>
                                <div className="extras-grid">
                                    {Object.entries(selectedLog.metadata).map(([key, value]) => (
                                        <div key={key} className="extras-item">
                                            <span className="extras-label">{key.replace(/_/g, ' ')}:</span>
                                            <span className="extras-value">
                                                {typeof value === 'object' ? JSON.stringify(value, null, 2) : value}
                                            </span>
                                        </div>
                                    ))}
                                </div>
                            </div>
                        </div>
                    </div>
                );
            }
            // If all else fails, return as plain text
            return <pre>{message}</pre>;
        }
    };

    const onRowClick = (e) => {
        setSelectedLog(e.data);
        setShowDetails(true);
    };

    const header = (
        <div className="logs-table-header">
            <h2>Audit Chain Logs</h2>
            <div className="filter-container">
                <Calendar 
                    value={filters.startDate}
                    onChange={(e) => setFilters({...filters, startDate: e.value})}
                    showTime
                    placeholder="Start Date"
                    className="p-mr-2"
                />
                <Calendar 
                    value={filters.endDate}
                    onChange={(e) => setFilters({...filters, endDate: e.value})}
                    showTime
                    placeholder="End Date"
                    className="p-mr-2"
                />
                <Dropdown 
                    value={filters.level}
                    options={levelOptions}
                    onChange={(e) => setFilters({...filters, level: e.value})}
                    placeholder="Select Level"
                    className="p-mr-2"
                />
                <Dropdown 
                    value={filters.moduleName}
                    options={moduleOptions}
                    onChange={(e) => setFilters({...filters, moduleName: e.value})}
                    placeholder="Select Module"
                    className="p-mr-2"
                />
                <Dropdown 
                    value={filters.stepStatus}
                    options={statusOptions}
                    onChange={(e) => setFilters({...filters, stepStatus: e.value})}
                    placeholder="Select Status"
                    className="p-mr-2"
                />
                <InputText 
                    value={filters.search}
                    onChange={(e) => setFilters({...filters, search: e.target.value})}
                    placeholder="Search messages..."
                    className="p-mr-2"
                />
                <div className="button-container">
                    <Button 
                        icon="pi pi-refresh" 
                        onClick={handleRefresh}
                        className="p-button-rounded p-button-text"
                        tooltip="Refresh Logs"
                    />
                    <Button
                        icon="pi pi-arrow-left"
                        onClick={() => navigate(`/${auditUuid}/audit-summary`)}
                        className="p-button-rounded p-button-text"
                        tooltip="Return to Audit"
                    />
                </div>
            </div>
        </div>
    );

    const renderDetailsContent = () => {
        if (!selectedLog) return null;
        return (
            <div className="log-details">
                <div className="detail-row">
                    <span className="detail-label">Timestamp:</span>
                    <span>{formatTimestamp(selectedLog.timestamp)}</span>
                </div>
                <div className="detail-row">
                    <span className="detail-label">Level:</span>
                    <span className={`log-level-${selectedLog.level ? selectedLog.level.toLowerCase() : 'unknown'}`}>
                        {selectedLog.level || 'UNKNOWN'}
                    </span>
                </div>
                <div className="detail-row">
                    <span className="detail-label">Chain ID:</span>
                    <span>{selectedLog.chain_id || 'N/A'}</span>
                </div>
                <div className="detail-row">
                    <span className="detail-label">Step Name:</span>
                    <span>{selectedLog.step_name || 'N/A'}</span>
                </div>
                <div className="detail-row">
                    <span className="detail-label">Step Status:</span>
                    {selectedLog.step_status ? statusBodyTemplate(selectedLog) : <span>N/A</span>}
                </div>
                <div className="detail-row">
                    <span className="detail-label">Message:</span>
                    <div className="message-content">
                        {selectedLog.message ? formatMessageForModal(selectedLog.message) : 'No message available'}
                    </div>
                </div>
                {selectedLog.error_details && (
                    <div className="detail-row">
                        <span className="detail-label">Error Details:</span>
                        <pre className="error-content">
                            {JSON.stringify(selectedLog.error_details, null, 2)}
                        </pre>
                    </div>
                )}
                {selectedLog.metadata && (
                    <div className="detail-row">
                        <span className="detail-label">Metadata:</span>
                        <pre className="metadata-content">
                            {JSON.stringify(selectedLog.metadata, null, 2)}
                        </pre>
                    </div>
                )}
            </div>
        );
    };

    return (
        <div className="logs-container">
            {error && (
                <Message severity="error" text={error} className="p-mb-3" />
            )}
            {/* Add debugging information */}
            {process.env.NODE_ENV === 'development' && (
                <div className="debug-info" style={{ display: 'none' }}>
                    <pre>
                        {JSON.stringify({
                            logsIsArray: Array.isArray(logs),
                            logsLength: logs?.length,
                            logsType: typeof logs
                        }, null, 2)}
                    </pre>
                </div>
            )}
            <DataTable
                ref={dt}
                value={Array.isArray(logs) ? logs : []} // Ensure value is always an array
                header={header}
                loading={loading}
                dataKey="id"
                paginator
                rows={10}
                rowsPerPageOptions={[10, 25, 50]}
                className="p-datatable-logs"
                emptyMessage={error || "No logs found"}
                responsiveLayout="scroll"
                onRowClick={onRowClick}
                selectionMode="single"
            >
                <Column 
                    field="timestamp" 
                    header="Timestamp" 
                    body={(row) => formatTimestamp(row.timestamp)}
                    sortable 
                />
                <Column 
                    field="level" 
                    header="Level" 
                    body={levelBodyTemplate}
                    sortable 
                />
                <Column 
                    field="module_name" 
                    header="Module" 
                    sortable 
                    body={(row) => {
                        if (!row) return 'N/A';
                        // Try to get module_name from different possible locations
                        const moduleName = row.module_name || 
                                         (row.metadata && row.metadata.module_name) || 
                                         (typeof row.message === 'object' && row.message.extras && row.message.extras.module_name) ||
                                         'N/A';
                        return <span className="module-name">{moduleName}</span>;
                    }}
                />
                <Column 
                    field="step_name" 
                    header="Step" 
                    sortable 
                    body={(row) => row && row.step_name ? row.step_name : 'N/A'}
                />
                <Column 
                    field="step_status" 
                    header="Status" 
                    body={statusBodyTemplate}
                    sortable 
                />
                <Column 
                    field="message" 
                    header="Message" 
                    style={{ maxWidth: '400px' }}
                    body={(row) => row && row.message ? <div className="message-cell">{cleanMessage(row.message)}</div> : <div className="message-cell">No message</div>}
                />
            </DataTable>

            <Dialog
                visible={showDetails}
                onHide={() => setShowDetails(false)}
                header="Log Details"
                modal
                style={{ width: '80vw' }}
                className="log-details-dialog"
            >
                {renderDetailsContent()}
            </Dialog>
        </div>
    );
};

export default Logs;
