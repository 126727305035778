import React, { useState, useEffect, useRef } from 'react';
import { Button } from 'primereact/button';
import { InputText } from 'primereact/inputtext';
import { Toast } from 'primereact/toast';
import { TabView, TabPanel } from 'primereact/tabview';
import './AuditBadgeHelper.css';
import axiosInstance from '../axiosConfig';

const AuditBadgeHelper = ({ auditUuid }) => {
    const [loading, setLoading] = useState(true);
    const [staticBadge, setStaticBadge] = useState('');
    const toast = useRef(null);
    const svgRef = useRef(null);
    
    // Construct URLs using environment variables
    const baseUrl = process.env.FRONTEND_URL || window.location.origin;
    const backendUrl = process.env.REACT_APP_API_URL || baseUrl.replace(':3000', ':8000');
    
    // Construct badge and audit URLs
    const badgeUrl = `${backendUrl}audit_badge/${auditUuid}`;
    const auditUrl = `${baseUrl}/${auditUuid}/audit-summary`;

    // Different markdown formats
    const markdownFormats = {
        github: `[![CodeDD Audit](${badgeUrl})](${auditUrl})`,
        gitlab: `[![CodeDD Audit](${badgeUrl})](${auditUrl})`,
        html: `<a href="${auditUrl}"><img src="${badgeUrl}" alt="CodeDD Audit"></a>`
    };
    
    useEffect(() => {
        const validateBadge = async () => {
            try {
                // Get the badge SVG for static download
                const response = await axiosInstance.get(`audit_badge/${auditUuid}/`, {
                    responseType: 'text'
                });
                setStaticBadge(response.data);
                setLoading(false);
            } catch (error) {
                console.error('Error validating badge:', error);
                toast.current.show({
                    severity: 'error',
                    summary: 'Error',
                    detail: 'Failed to validate badge. The badge may still work when added to your README.',
                    life: 3000
                });
                setLoading(false);
            }
        };

        validateBadge();
    }, [auditUuid]);
    
    const copyToClipboard = (text) => {
        navigator.clipboard.writeText(text).then(() => {
            toast.current.show({
                severity: 'success',
                summary: 'Copied!',
                detail: 'Badge code copied to clipboard',
                life: 3000
            });
        }).catch(() => {
            toast.current.show({
                severity: 'error',
                summary: 'Error',
                detail: 'Failed to copy to clipboard',
                life: 3000
            });
        });
    };

    const downloadStaticBadge = () => {
        // Properly format the SVG for standalone viewing
        let processedSvg = staticBadge;
        
        try {
            // Strip any BOM (Byte Order Mark) or whitespace before XML declaration
            processedSvg = processedSvg.trim().replace(/^\uFEFF/, '');
            
            // Remove existing XML declaration if present
            processedSvg = processedSvg.replace(/<\?xml[^>]*\?>/, '');
            
            // Add fresh XML declaration
            processedSvg = '<?xml version="1.0" encoding="UTF-8" standalone="no"?>\n' + processedSvg;
            
            // Ensure SVG has proper namespace declarations
            if (!processedSvg.includes('xmlns=')) {
                processedSvg = processedSvg.replace('<svg', '<svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink"');
            }
            
            // Add viewBox if missing
            if (!processedSvg.includes('viewBox=')) {
                processedSvg = processedSvg.replace('<svg', '<svg viewBox="0 0 500 120"');
            }
            
            // Make sure the SVG tag is complete with closing bracket
            if (!processedSvg.includes('<svg ')) {
                toast.current.show({
                    severity: 'error',
                    summary: 'Error',
                    detail: 'Invalid SVG format. Please try downloading PNG instead.',
                    life: 3000
                });
                return;
            }
            
            // Create a blob from the processed SVG content
            const blob = new Blob([processedSvg], { type: 'image/svg+xml' });
            const url = URL.createObjectURL(blob);
            
            // Create a link and trigger download
            const a = document.createElement('a');
            a.href = url;
            a.download = `codedd-badge-${auditUuid}.svg`;
            document.body.appendChild(a);
            a.click();
            
            // Clean up
            setTimeout(() => {
                document.body.removeChild(a);
                URL.revokeObjectURL(url);
            }, 100);
            
            toast.current.show({
                severity: 'success',
                summary: 'Downloaded',
                detail: 'Badge SVG downloaded. You can now upload it to your repository.',
                life: 3000
            });
            
        } catch (error) {
            console.error('Error creating SVG:', error);
            toast.current.show({
                severity: 'error',
                summary: 'Error',
                detail: 'Failed to create SVG file. Please try downloading PNG instead.',
                life: 3000
            });
        }
    };

    const downloadPngBadge = () => {
        if (!svgRef.current || !svgRef.current.complete) {
            toast.current.show({
                severity: 'error',
                summary: 'Error',
                detail: 'Badge image not available. Please try again later.',
                life: 3000
            });
            return;
        }

        try {
            // Create a canvas to draw the SVG
            const canvas = document.createElement('canvas');
            canvas.width = 500;
            canvas.height = 120;
            const ctx = canvas.getContext('2d');
            
            // Create a new image with crossOrigin to avoid tainted canvas
            const img = new Image();
            img.crossOrigin = 'anonymous';
            
            img.onload = function() {
                try {
                    // Draw the image on the canvas
                    ctx.fillStyle = '#1c2b40'; // Background color matching the badge
                    ctx.fillRect(0, 0, canvas.width, canvas.height);
                    ctx.drawImage(img, 0, 0, 500, 120);
                    
                    // Convert canvas to PNG and download
                    try {
                        // Get PNG data URL
                        const pngUrl = canvas.toDataURL('image/png');
                        
                        // Create a link and trigger download
                        const a = document.createElement('a');
                        a.href = pngUrl;
                        a.download = `codedd-badge-${auditUuid}.png`;
                        document.body.appendChild(a);
                        a.click();
                        
                        // Clean up
                        setTimeout(() => {
                            document.body.removeChild(a);
                        }, 100);
                        
                        toast.current.show({
                            severity: 'success',
                            summary: 'Downloaded',
                            detail: 'Badge PNG downloaded. You can now upload it to your repository.',
                            life: 3000
                        });
                    } catch (e) {
                        console.error('Error creating PNG:', e);
                        // If canvas is tainted, try alternate method
                        fallbackPngDownload();
                    }
                } catch (e) {
                    console.error('Error drawing image:', e);
                    fallbackPngDownload();
                }
            };
            
            // Handle errors
            img.onerror = function(e) {
                console.error('Error loading image:', e);
                fallbackPngDownload();
            };
            
            // Set the image source - use the current rendered SVG if available
            if (svgRef.current && svgRef.current.complete && svgRef.current.naturalHeight !== 0) {
                img.src = badgeUrl;
            } else {
                fallbackPngDownload();
            }
        } catch (e) {
            console.error('Error in PNG generation:', e);
            fallbackPngDownload();
        }
    };
    
    // Fallback method for PNG creation when canvas drawing fails
    const fallbackPngDownload = () => {
        toast.current.show({
            severity: 'info',
            summary: 'Using fallback method',
            detail: 'Creating a simplified PNG version of the badge.',
            life: 3000
        });
        
        // Create a simplified badge
        const canvas = document.createElement('canvas');
        canvas.width = 500;
        canvas.height = 120;
        const ctx = canvas.getContext('2d');
        
        // Draw background
        ctx.fillStyle = '#1c2b40';
        ctx.fillRect(0, 0, canvas.width, canvas.height);
        
        // Draw text
        ctx.fillStyle = '#FFFFFF';
        ctx.font = 'bold 28px Arial';
        ctx.textAlign = 'center';
        ctx.fillText('CodeDD Audit Badge', 250, 50);
        ctx.font = '14px Arial';
        ctx.fillText('View audit details by clicking this badge', 250, 80);
        
        // Convert to PNG and download
        try {
            const pngUrl = canvas.toDataURL('image/png');
            const a = document.createElement('a');
            a.href = pngUrl;
            a.download = `codedd-badge-${auditUuid}.png`;
            document.body.appendChild(a);
            a.click();
            
            setTimeout(() => {
                document.body.removeChild(a);
            }, 100);
            
            toast.current.show({
                severity: 'success',
                summary: 'Downloaded',
                detail: 'Simple badge PNG downloaded. You can now upload it to your repository.',
                life: 3000
            });
        } catch (e) {
            console.error('Error in fallback PNG creation:', e);
            toast.current.show({
                severity: 'error',
                summary: 'Error',
                detail: 'Failed to create PNG file. Please contact support.',
                life: 3000
            });
        }
    };
    
    return (
        <div className="badge-helper-content">
            <Toast ref={toast} />
            
            <h3>Preview</h3>
            <div className="badge-preview">
                {loading ? (
                    <div>Loading badge preview...</div>
                ) : (
                    <img 
                        ref={svgRef}
                        src={badgeUrl} 
                        alt="CodeDD Audit Badge"
                        onError={(e) => {
                            e.target.onerror = null;
                            toast.current.show({
                                severity: 'warn',
                                summary: 'Preview Failed',
                                detail: 'Could not load badge preview, but the badge may still work in your README.',
                                life: 5000
                            });
                        }}
                    />
                )}
            </div>

            <div className="static-badge-options">
                <h3>Download Badge</h3>
                <p>If you're experiencing issues with dynamic badges (especially in GitLab), download a static version:</p>
                <div className="download-buttons">
                    <Button 
                        icon="pi pi-download" 
                        label="Download SVG" 
                        onClick={downloadStaticBadge} 
                        disabled={loading}
                        className="badge-download-button"
                    />
                    <Button 
                        icon="pi pi-download" 
                        label="Download PNG" 
                        onClick={downloadPngBadge} 
                        disabled={loading}
                        className="badge-download-button"
                    />
                </div>
                <p className="note">Note: PNG format is more compatible with most applications, but SVG has better quality when scaled.</p>
            </div>
            
            <TabView>
                <TabPanel header="GitHub">
                    <div className="badge-code">
                        <InputText value={markdownFormats.github} readOnly className="w-full" />
                        <Button 
                            icon="pi pi-copy"
                            onClick={() => copyToClipboard(markdownFormats.github)}
                            className="p-button-rounded p-button-text"
                        />
                    </div>
                </TabPanel>
                <TabPanel header="GitLab">
                    <div className="badge-instructions gitlab-instructions">
                        <p>For GitLab, we recommend uploading the static badge:</p>
                        <ol>
                            <li>Download the static badge (PNG recommended) using the buttons above</li>
                            <li>Upload the file to your GitLab repository (e.g., in an <code>assets</code> folder)</li>
                            <li>Use the following markdown in your README:</li>
                        </ol>
                        <div className="badge-code">
                            <InputText value={`[![CodeDD Audit](./assets/codedd-badge-${auditUuid}.png)](${auditUrl})`} readOnly className="w-full" />
                            <Button 
                                icon="pi pi-copy"
                                onClick={() => copyToClipboard(`[![CodeDD Audit](./assets/codedd-badge-${auditUuid}.png)](${auditUrl})`)}
                                className="p-button-rounded p-button-text"
                            />
                        </div>
                        <p className="note">Note: You may need to adjust the path based on where you store the image file.</p>
                        <p>If you prefer to try the dynamic badge (may not work in all GitLab environments):</p>
                        <div className="badge-code">
                            <InputText value={markdownFormats.gitlab} readOnly className="w-full" />
                            <Button 
                                icon="pi pi-copy"
                                onClick={() => copyToClipboard(markdownFormats.gitlab)}
                                tooltip="Copy to clipboard"
                                className="p-button-rounded p-button-text"
                            />
                        </div>
                    </div>
                </TabPanel>
                <TabPanel header="HTML">
                    <div className="badge-code">
                        <InputText value={markdownFormats.html} readOnly className="w-full" />
                        <Button 
                            icon="pi pi-copy"
                            onClick={() => copyToClipboard(markdownFormats.html)}
                            tooltip="Copy to clipboard"
                            className="p-button-rounded p-button-text"
                        />
                    </div>
                </TabPanel>
            </TabView>
            
            <div className="badge-instructions">
                <h3>Instructions</h3>
                <ol>
                    <li>Select the appropriate format for your platform</li>
                    <li>Copy the code above</li>
                    <li>Paste it in your README.md file or website</li>
                    <li>Commit and push the changes</li>
                </ol>
                <p>The dynamic badge will automatically update when your audit scores change.</p>
                <p className="note">Note: Some platforms may have restrictions on displaying external images. If the badge doesn't display, use the static badge option instead.</p>
            </div>
        </div>
    );
};

export default AuditBadgeHelper; 