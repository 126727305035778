// audit_invitation_components/invitee_view.js
import React, { useEffect, useState } from 'react';
import { Button } from 'primereact/button';
import { InputText } from 'primereact/inputtext';
import { Message } from 'primereact/message';
import './InviteeView.css';

// Implement onConfirmClick function together with rendering the button to confirm audit scope selection. 
//Pass setAuditReady as a prop to enable the audit start section.

const InviteeView = ({
    auditName,
    gitUrl,
    isCheckingUrl,
    isValidUrl,
    isLoading,
    errorMessage,
    handleInputChange,
    handleSubmit,
    userName,
    ShowCredentialsModal,
    auditFinished,
    auditStarted,
    isDuplicateRepo,
    warningMessage,
    importStatus,
    readyForAudit
}) => {
    const isAddButtonDisabled = !gitUrl || isLoading || isCheckingUrl || !isValidUrl || auditStarted || isDuplicateRepo || readyForAudit;
    const showSpinner = isLoading || isCheckingUrl;
    const [showMessage, setShowMessage] = useState(false);

    const getProgressFromStatus = (status) => {
        if (!status) return 0;

        // Handle detailed progress information for repository processing
        if (status.startsWith('Repository imported|')) {
            const [_, filesProgress, statusText] = status.split('|');
            const [processed, total] = filesProgress.split('/');
            
            // Calculate progress: 20% base + up to 80% based on file processing
            const fileProgress = (parseInt(processed) / parseInt(total));
            return 20 + (fileProgress * 80);
        }

        // Base progress states
        const progressMap = {
            'Audit session created': 20,
            'File information imported': 100
        };
        return progressMap[status] || 0;
    };

    const getProgressMessage = (status) => {
        if (!status) return 'Processing repository...';

        // Handle detailed progress information
        if (status.startsWith('Repository imported|')) {
            const [_, filesProgress, statusText] = status.split('|');
            
            if (statusText.startsWith('Processing repository data:')) {
                return statusText;
            }
            
            return `Processing repository... (${filesProgress} files)`;
        }

        // Fallback for simple status messages
        const messageMap = {
            'Audit session created': 'Creating audit session and downloading repository...',
            'File information imported': 'Import completed! Redirecting...'
        };
        return messageMap[status] || 'Processing repository...';
    };

    const getProgressDetails = (status) => {
        if (status.startsWith('Repository imported|')) {
            const [_, filesProgress, statusText] = status.split('|');
            const [processed, total] = filesProgress.split('/');
            
            // Calculate single percentage based on both processing and importing
            let percentage = Math.round((parseInt(processed) / parseInt(total)) * 100);
            
            // Extract processing rate if available
            const rateMatch = statusText.match(/at ([0-9.]+) files\/s/);
            const rate = rateMatch ? rateMatch[1] : 0;
            
            return {
                message: `Processing data: ${processed}/${total} files (${percentage}%)${rate ? ` at ${rate} files/s` : ''}`
            };
        }
        return null;
    };

    useEffect(() => {
        if (isLoading && !ShowCredentialsModal) {
            const timer = setTimeout(() => {
                setShowMessage(true);
            }, 10000);  // Delay of 10 seconds

            return () => clearTimeout(timer);  // Cleanup the timer
        }
    }, [isLoading, ShowCredentialsModal]);

    return (
        <div>
            <div className='audit-invitation-header' style={{ paddingLeft: "5%", paddingTop: "30px" }}>
                <h1 style={{ fontSize: "24px" }}>
                    Invitation to audit: <span style={{ color: "#C049DE", fontSize: "24px" }}>{auditName}</span>
                </h1>
                <p className="sub-text" style={{ color: "#8D8D8D", marginTop: "10px" }}>
                    You have been invited by <strong>{userName}</strong> to submit your code repositories for auditing.
                </p>
                <p className="sub-text" style={{ color: "#8D8D8D", marginTop: "10px" }}>
                    {auditStarted 
                        ? "The audit has started. You can no longer add or modify repositories."
                        : "Add all relevant repositories, and if necessary, adapt the files selection you want to include in the due diligence."
                    }
                </p>
                <hr style={{ border: "1px solid #32AFC3", marginBottom: "45px", marginTop: "15px", maxWidth: '900px' }} />
            </div>
            {!auditFinished && !auditStarted && ( 
                <div className="repository-input-section">
                    <div className="header-container">Add a <strong>repository</strong> to be audited:</div>
                    <div className="input-section-container">
                        <div style={{ display: 'flex', gap: '5px', width: '100%' }}>
                            <div className="input-progress-container" style={{ flex: 1 }}>
                                <InputText
                                    id="gitUrl"
                                    value={gitUrl}
                                    onChange={handleInputChange}
                                    placeholder="e.g. https://github.com/tensorflow/tensorflow"
                                    className={`${!isValidUrl || isDuplicateRepo ? 'p-invalid' : ''}`}
                                    disabled={isLoading}
                                />
                                {isLoading && importStatus && (
                                    <div 
                                        className="progress-overlay"
                                        style={{ 
                                            width: `${getProgressFromStatus(importStatus.status) || 20}%`
                                        }}
                                    />
                                )}
                            </div>
                            <Button 
                                className='add-repo-button' 
                                label={isCheckingUrl ? 'Adding' : 'Add'}
                                onClick={handleSubmit} 
                                disabled={isAddButtonDisabled}
                                icon={showSpinner ? 'pi pi-spin pi-spinner' : 'pi pi-plus'}
                                iconPos="left"
                            />
                        </div>
                        {isLoading && importStatus && (
                            <div className="status-message" style={{ marginTop: '10px', width: '100%', color: '#8D8D8D', fontSize: '14px' }}>
                                {getProgressDetails(importStatus.status) ? (
                                    <div>{getProgressDetails(importStatus.status).message}</div>
                                ) : (
                                    <div>{getProgressMessage(importStatus.status)}</div>
                                )}
                            </div>
                        )}
                    </div>
                    {(errorMessage || warningMessage) && (
                        <div className="groupt-audit-message-container" style={{ marginTop: "10px" }}>
                            {errorMessage && (
                                <Message severity="error" text={errorMessage} style={{ marginLeft: "5px" }} />
                            )}
                            {warningMessage && (
                                <Message severity="warn" text={warningMessage} style={{ marginLeft: "5px" }} />
                            )}
                        </div>
                    )}
                    {isLoading && !ShowCredentialsModal && showMessage && (
                        <div className='audit-status-container'>
                            <div className="audit-status-message" style={{color: '#8D8D8D', fontSize: '16px', marginTop: '5px'}}>Your audit started. This might take a few minutes depending on the size of the repo...</div>
                        </div>
                    )}
                </div>
            )}
            
        </div>
    );
}

export default InviteeView;