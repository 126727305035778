import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';
import ReactMarkdown from 'react-markdown';
import { blogPosts } from './blogPosts';
import './Blog.css';

// Function to generate URL-friendly slug from title (same as in BlogList)
const generateSlug = (title) => {
  return title
    .toLowerCase()
    .replace(/[^\w\s-]/g, '')
    .replace(/\s+/g, '-')
    .replace(/--+/g, '-')
    .trim();
};

const BlogDetail = () => {
  const { slug } = useParams();
  const navigate = useNavigate();
  const [blogData, setBlogData] = useState(null);

  useEffect(() => {
    const loadBlogPost = () => {
      try {
        // Find the blog post that matches the slug
        const post = Object.values(blogPosts).find(post => {
          const lines = post.content.split('\n');
          const titleMatch = lines.find(line => line.startsWith('# '));
          const title = titleMatch ? titleMatch.replace('# ', '').replace(/\*\*/g, '') : '';
          return generateSlug(title) === slug;
        });

        if (!post) {
          navigate('/blog');
          return;
        }

        const lines = post.content.split('\n');
        
        // Extract title and subtitle
        const titleMatch = lines.find(line => line.startsWith('# '));
        const title = titleMatch ? titleMatch.replace('# ', '').replace(/\*\*/g, '') : '';
        
        const subtitleMatch = lines.find(line => line.startsWith('## '));
        const subtitle = subtitleMatch ? subtitleMatch.replace('## ', '').replace(/\*\*/g, '') : '';

        setBlogData({
          title,
          subtitle,
          content: post.content,
          date: new Date().toLocaleDateString('en-US', {
            year: 'numeric',
            month: 'long',
            day: 'numeric'
          })
        });
      } catch (error) {
        console.error('Error loading blog post:', error);
        navigate('/blog');
      }
    };

    loadBlogPost();
  }, [slug, navigate]);

  if (!blogData) {
    return (
      <div className="blog-detail-loading">
        <div className="loading-spinner"></div>
      </div>
    );
  }

  return (
    <div className="blog-detail-page">
      <Helmet>
        <title>{`${blogData.title} | CodeDD Blog`}</title>
        <meta 
          name="description" 
          content={blogData.subtitle} 
        />
      </Helmet>

      <div className="blog-detail-hero">
        <div className="blog-detail-header">
          <button 
            className="back-to-blog" 
            onClick={() => navigate('/blog')}
          >
            ← Back to Blog
          </button>
          <h1 className="blog-detail-title">{blogData.title}</h1>
          <h2 className="blog-detail-subtitle">{blogData.subtitle}</h2>
          <div className="blog-detail-meta">
            <span className="blog-detail-date" style={{ color: 'rgba(255, 255, 255, 0.7)' }}>{blogData.date}</span>
          </div>
        </div>
      </div>

      <article className="blog-detail-content">
        <div className="markdown-content">
          <ReactMarkdown
            components={{
              h1: ({node, ...props}) => <h1 className="md-h1" {...props} />,
              h2: ({node, ...props}) => <h2 className="md-h2" {...props} />,
              h3: ({node, ...props}) => <h3 className="md-h3" {...props} />,
              p: ({node, ...props}) => <p className="md-p" {...props} />,
              a: ({node, ...props}) => (
                <a className="md-link" target="_blank" rel="noopener noreferrer" {...props} />
              ),
              img: ({node, ...props}) => (
                <div className="md-image-container">
                  <img className="md-image" {...props} alt={props.alt || ''} />
                </div>
              ),
              ul: ({node, ...props}) => <ul className="md-ul" {...props} />,
              ol: ({node, ...props}) => <ol className="md-ol" {...props} />,
              li: ({node, ...props}) => <li className="md-li" {...props} />,
              blockquote: ({node, ...props}) => (
                <blockquote className="md-blockquote" {...props} />
              ),
              code: ({node, inline, ...props}) => (
                inline ? 
                <code className="md-inline-code" {...props} /> :
                <div className="md-code-block">
                  <code {...props} />
                </div>
              ),
            }}
          >
            {blogData.content}
          </ReactMarkdown>
        </div>
      </article>
    </div>
  );
};

export default BlogDetail; 