import React, { useEffect, useState, useRef } from 'react';
import { Dialog } from 'primereact/dialog';
import Redis from '../../images/audits/redis_logo.png';
import FastAPI from '../../images/audits/fastapi_logo.png';
import Axios from '../../images/audits/axios_logo.png';
import CheckoutStepper from './CheckoutStepper';
import './CheckoutModal.css';
import axiosInstance from '../../axiosConfig';
import { Toast } from 'primereact/toast';

/**
 * CheckoutModal component for handling payments through Stripe
 * Implementation of organization selection/creation and Stripe Checkout
 */
const CheckoutModal = ({ 
    visible, 
    onHide, 
    account_uuid, 
    audit_uuid, 
    orderDetails: initialOrderDetails, 
    successHandler,
    initialSessionId,
    initialPaymentSuccess,
    initialPaymentCancelled,
    initialActiveIndex,
    isGroupAudit = false,
    groupAuditUuid
}) => {
    const toast = useRef(null);

    // State for storing order details (allows us to modify it locally if needed)
    const [orderDetails, setOrderDetails] = useState(() => {
        // Use initialOrderDetails if provided, otherwise use default values
        if (initialOrderDetails) {
            return initialOrderDetails;
        }

        return {
            linesOfCode: 0,
            payableLines: 0,
            freeLines: 0,
            audit_uuid: audit_uuid,
            account_uuid: account_uuid,
            isGroupAudit: false
        };
    });
    
    // Fetch account_uuid from localStorage if not provided in props
    useEffect(() => {
        if (!account_uuid) {
            try {
                const persistRoot = JSON.parse(localStorage.getItem('persist:root'));
                if (persistRoot) {
                    const user = JSON.parse(persistRoot.user || '{}');
                    if (user.accountUuid) {
                        // Store in state to use later
                        setAccountUuidFromStorage(user.accountUuid);
                    }
                }
            } catch (error) {
                console.error("Error fetching account UUID from localStorage:", error);
            }
        }
    }, [account_uuid]);
    
    // State to store accountUuid from localStorage if props is empty
    const [accountUuidFromStorage, setAccountUuidFromStorage] = useState(null);
    
    // Get the effective account UUID (from props or localStorage)
    const effectiveAccountUuid = account_uuid || accountUuidFromStorage;
    
    // Update orderDetails when initialOrderDetails changes
    useEffect(() => {
        if (initialOrderDetails && 
            (initialOrderDetails.linesOfCode > 0 || 
             initialOrderDetails.payableLines > 0)) {
            setOrderDetails(initialOrderDetails);
        }
    }, [initialOrderDetails]);
    
    // State for storing basic pricing information for display only
    const [pricingDetails, setPricingDetails] = useState({
        total: 0,
        subtotal: 0,
        totalWithTax: 0,
        vatRate: 0,
        currency: 'EUR',
        breakdown: []
    });

    // State for payment processing
    const [paymentStatus, setPaymentStatus] = useState('initial'); // initial, processing, succeeded, failed, cancelled

    // Flag to prevent double-processing of Stripe callbacks
    const [stripeParamsProcessed, setStripeParamsProcessed] = useState(false);

    // State for return from Stripe
    const [isReturnFromStripe, setIsReturnFromStripe] = useState(false);

    // Flag to prevent duplicate manual payment processing across all verification methods
    const [manualPaymentProcessed, setManualPaymentProcessed] = useState(false);
    
    // Track processed payment intents to prevent duplicate processing
    const [processedPaymentIntents, setProcessedPaymentIntents] = useState(new Set());

    // State for active index
    const [activeIndex, setActiveIndex] = useState(() => {
        // First check explicit initialActiveIndex prop
        if (typeof initialActiveIndex === 'number') {
            return initialActiveIndex;
        }
        
        // Otherwise, check URL for Stripe parameters
        const params = new URLSearchParams(window.location.search);
        const hasStripeParams = params.get('session_id') || 
                               params.get('success') === 'true' || 
                               params.get('cancelled') === 'true' || 
                               initialSessionId || 
                               initialPaymentSuccess || 
                               initialPaymentCancelled;
        
        // Start on payment tab (index 1) if we have Stripe parameters
        return hasStripeParams ? 1 : 0;
    });

    // Effect to calculate initial pricing from orderDetails
    useEffect(() => {
        if (orderDetails && orderDetails.payableLines) {
            // Simple initial calculation (will be refined by backend later)
            const baseRate = 0.0035; // €0.0035 per line as default rate
            const estimatedTotal = Math.max(250, orderDetails.payableLines * baseRate); // Minimum €250
            
            setPricingDetails(prev => ({
                ...prev,
                total: estimatedTotal,
                subtotal: estimatedTotal,
                totalWithTax: estimatedTotal // Will be updated with proper tax by backend
            }));
        }
    }, [orderDetails]);

    // Effect to handle Stripe redirect on component mount
    useEffect(() => {
        const checkStripeRedirect = () => {
            const shouldShowNotification = isReturnFromStripe || initialSessionId || 
                                          initialPaymentSuccess || initialPaymentCancelled;
            
            if (shouldShowNotification) {
                // Set the active tab to payment (index 1) to show the result
                setActiveIndex(1);
                
                // Show appropriate message based on payment result
                if (initialPaymentSuccess) {
                    if (toast.current) {
                        toast.current.show({
                            severity: 'success',
                            summary: 'Payment Successful',
                            detail: 'Your payment has been successfully processed.',
                            life: 5000
                        });
                    }
                } else if (initialPaymentCancelled) {
                    if (toast.current) {
                        toast.current.show({
                            severity: 'info',
                            summary: 'Payment Cancelled',
                            detail: 'You have cancelled the payment process.',
                            life: 5000
                        });
                    }
                } else {
                    // Session ID exists but neither success nor cancelled specified
                    verifyPaymentSession(initialSessionId);
                }
            }
        };
        
        checkStripeRedirect();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isReturnFromStripe, initialSessionId, initialPaymentSuccess, initialPaymentCancelled, toast]);

    // Sample audits to show at the bottom
    const [sampleAudits] = useState([
        { 
            name: 'Redis', 
            logo: Redis, 
            repoUrl: 'https://github.com/redis/redis',
            linesOfCode: 310716,
            url: '/9ed79825-92eb-41a5-be92-d47f978d28d2/audit-summary'
        },
        { 
            name: 'FastAPI', 
            logo: FastAPI, 
            repoUrl: 'https://github.com/tiangolo/fastapi',
            linesOfCode: 81016,
            url: '/f910c1c1-40a6-488e-a2a7-b7200383bc1c/audit-summary'
        },
        { 
            name: 'Axios', 
            logo: Axios, 
            repoUrl: 'https://github.com/axios/axios',
            linesOfCode: 27743,
            url: '/223a1ddd-5f13-486d-8dd3-79e1c5c53d9d/audit-summary'
        }
    ]);

    // Make the URL params effect more robust
    useEffect(() => {
        // When URL contains session_id and success/cancelled parameters, 
        // handle Stripe redirect and set payment tab active
        const queryParams = new URLSearchParams(window.location.search);
        const sessionId = queryParams.get('session_id');
        const success = queryParams.get('success');
        const cancelled = queryParams.get('cancelled');
        
        if ((sessionId || success || cancelled || initialSessionId || initialPaymentSuccess || initialPaymentCancelled) && !stripeParamsProcessed) {
            
            setStripeParamsProcessed(true);
            
            // If modal is not already visible, make it visible
            if (!visible) {
                console.log("Forcing modal to be visible because of Stripe params");
                onHide(true);
            }
            
            // Ensure order details are prepared if they're not available
            if (!orderDetails && audit_uuid) {
                fetchAuditCost();
            }
            
            // Track that we're returning from Stripe to avoid redirection loops
            setIsReturnFromStripe(true);
            
            // Set payment status based on parameters for immediate feedback
            if (success === 'true' || initialPaymentSuccess) {
                setPaymentStatus('verifying');
                
                // Verify the payment with sessionId
                verifyPaymentSession(sessionId || initialSessionId);
            } else if (cancelled === 'true' || initialPaymentCancelled) {
                setPaymentStatus('cancelled');
            }
            
            // Force payment tab to be active - this is crucial
            setActiveIndex(1);
            
            // Clean URL to prevent re-processing
            setTimeout(() => {
                if (window.history.replaceState) {
                    const cleanUrl = window.location.pathname;
                    window.history.replaceState({}, document.title, cleanUrl);
                }
            }, 100);
        }
    }, [visible, stripeParamsProcessed, initialSessionId, initialPaymentSuccess, initialPaymentCancelled, audit_uuid, orderDetails]);

    // Function to verify the payment session status
    const verifyPaymentSession = async (sessionId) => {
        if (!sessionId) {
            console.error('No session ID provided for payment verification');
            return;
        }
                
        try {
            const response = await axiosInstance.get(`/api/checkout-session/${sessionId}/`);
            const sessionData = response.data;
            
            // Extract account_uuid and audit_uuid from session metadata if available
            const sessionAccountUuid = sessionData.metadata && sessionData.metadata.account_uuid;
            const sessionAuditUuid = sessionData.metadata && sessionData.metadata.audit_uuid;
            const sessionGroupAuditUuid = sessionData.metadata && sessionData.metadata.group_audit_uuid;
            
            // Use session metadata or fallback to props
            const effectiveAccUuid = sessionAccountUuid || effectiveAccountUuid;
            const effectiveAudUuid = sessionAuditUuid || audit_uuid;
            const effectiveGroupAudUuid = sessionGroupAuditUuid || groupAuditUuid;

            // Verify payment intent status
            if (sessionData.payment_intent) {
                const paymentIntentId = sessionData.payment_intent;
                
                // Check if we've already processed this payment intent
                if (processedPaymentIntents.has(paymentIntentId)) {
                    console.log(`Payment intent ${paymentIntentId} already processed, skipping verification`);
                    return;
                }
                
                try {
                    const verificationResponse = await axiosInstance.get(`api/verify_payment_status/${paymentIntentId}/?account_uuid=${encodeURIComponent(effectiveAccUuid)}&audit_uuid=${encodeURIComponent(effectiveAudUuid)}&group_audit_uuid=${encodeURIComponent(effectiveGroupAudUuid)}&is_group_audit=${isGroupAudit}`);
                                        
                    // If the payment is successful, update the payment status
                    if (verificationResponse.data.stripe_status === 'succeeded') {
                        if (verificationResponse.data.is_paid) {
                            // Add to processed payment intents set
                            setProcessedPaymentIntents(prev => new Set([...prev, paymentIntentId]));
                            
                            // Set payment status to succeeded to update the UI
                            setPaymentStatus('succeeded');
                                                        
                            // Create payment data object
                            const paymentDetails = {
                                session_id: sessionId,
                                payment_intent_id: paymentIntentId,
                                account_uuid: effectiveAccUuid,
                                audit_uuid: effectiveAudUuid,
                                group_audit_uuid: effectiveGroupAudUuid,
                                is_group_audit: isGroupAudit,
                                transactionId: paymentIntentId // Add the transactionId property
                            };
                            
                            // Call success handler with the payment data
                            if (successHandler) {
                                successHandler(paymentDetails);
                            }
  
                        } else if (!manualPaymentProcessed && !processedPaymentIntents.has(paymentIntentId)) {
                            // Only process manual payment if we haven't already done it and we haven't processed this intent
                            setManualPaymentProcessed(true);
                            
                            // Add to processed payment intents set right away to prevent duplicate calls
                            setProcessedPaymentIntents(prev => new Set([...prev, paymentIntentId]));
                            
                            // Prepare payment data for manual recording
                            const paymentData = {
                                payment_intent_id: paymentIntentId,
                                account_uuid: effectiveAccUuid,
                                audit_uuid: effectiveAudUuid,
                                group_audit_uuid: effectiveGroupAudUuid,
                                is_group_audit: isGroupAudit,
                                amount: sessionData.amount_total / 100, // Convert from cents
                                currency: sessionData.currency,
                                send_receipt: true
                            };
                                                        
                            try {
                                const manualPaymentResponse = await axiosInstance.post('/api/manual_payment_success/', paymentData);                                
                                if (manualPaymentResponse.data.success) {
                                    // Set payment status to succeeded to update the UI
                                    setPaymentStatus('succeeded');
                                                                        
                                    // Add transactionId property for the success handler
                                    const formattedPaymentData = {
                                        ...paymentData,
                                        transactionId: paymentData.payment_intent_id
                                    };
                                    
                                    // Call success handler with the appropriate payment data
                                    if (successHandler) {
                                        successHandler(formattedPaymentData);
                                    }
                                } else if (manualPaymentResponse.data.already_existed) {
                                    // Set payment status to succeeded even though recording wasn't needed
                                    setPaymentStatus('succeeded');
                                    
                                    // Add transactionId property for the success handler
                                    const formattedPaymentData = {
                                        ...paymentData,
                                        transactionId: paymentData.payment_intent_id
                                    };
                                    
                                    // Call success handler with the appropriate payment data
                                    if (successHandler) {
                                        successHandler(formattedPaymentData);
                                    }
                                } else {
                                    console.error('CheckoutModal: Manual payment recording failed:', manualPaymentResponse.data);
                                    if (toast.current) {
                                        toast.current.show({
                                            severity: 'error',
                                            summary: 'Payment Error',
                                            detail: 'There was an error processing your payment. Please try again or contact support.',
                                            life: 5000
                                        });
                                    }
                                }
                            } catch (manualError) {
                                console.error('CheckoutModal: Error recording manual payment:', manualError);
                                if (toast.current) {
                                    toast.current.show({
                                        severity: 'error',
                                        summary: 'Payment Error',
                                        detail: 'There was an error processing your payment. Please try again or contact support.',
                                        life: 5000
                                    });
                                }
                            }
                        } else {
                            console.log('CheckoutModal: Manual payment already processed or intent already handled, skipping duplicate call');
                        }
                    } else if (verificationResponse.data.stripe_status === 'canceled') {
                        console.log('CheckoutModal: Payment was canceled');
                        if (toast.current) {
                            toast.current.show({
                                severity: 'info',
                                summary: 'Payment Cancelled',
                                detail: 'The payment process was cancelled.',
                                life: 5000
                            });
                        }
                    } else {
                        console.log('CheckoutModal: Payment is still pending or failed:', verificationResponse.data.stripe_status);
                        if (toast.current) {
                            toast.current.show({
                                severity: 'warning',
                                summary: 'Payment Pending',
                                detail: 'Your payment is still being processed. Please check back later.',
                                life: 5000
                            });
                        }
                    }
                } catch (verificationError) {
                    console.error('CheckoutModal: Error verifying payment:', verificationError);
                    if (toast.current) {
                        toast.current.show({
                            severity: 'error',
                            summary: 'Verification Error',
                            detail: 'There was an error verifying your payment. Please contact support.',
                            life: 5000
                        });
                    }
                }
            } else {
                console.error('CheckoutModal: No payment intent found in session data');
                if (toast.current) {
                    toast.current.show({
                        severity: 'error',
                        summary: 'Payment Error',
                        detail: 'No payment information found. Please try again or contact support.',
                        life: 5000
                    });
                }
            }
        } catch (error) {
            console.error('CheckoutModal: Error fetching session data:', error);
            if (toast.current) {
                toast.current.show({
                    severity: 'error',
                    summary: 'Session Error',
                    detail: 'There was an error retrieving your payment session. Please try again or contact support.',
                    life: 5000
                });
            }
        }
    };

    // Handle successful payment
    const handlePaymentSuccess = (paymentData) => {
        // Add the payment intent to our processed set to prevent future duplicate calls
        if (paymentData && paymentData.payment_intent_id) {
            setProcessedPaymentIntents(prev => new Set([...prev, paymentData.payment_intent_id]));
        }
        
        // Set payment status to succeeded so the user sees success state
        setPaymentStatus('succeeded');
        
        // Call the parent success handler
        if (successHandler && paymentData) {
            // Ensure we're passing a properly formatted object with transactionId property
            const formattedPaymentData = {
                ...paymentData,
                transactionId: paymentData.payment_intent_id // Ensure transactionId is set
            };
            successHandler(formattedPaymentData);
        }
        // Note: We don't show toast here because it will be shown by the calling function
    };

    // Reset state when modal is closed
    const handleHide = (forceShow = false, forceClose = false) => {
        // If forceShow is true, ensure the modal is shown
        if (forceShow === true) {
            console.log("Force showing modal");
            return false; // Prevent default close behavior
        }
        
        // If forceClose is true, close regardless of payment status
        if (forceClose) {
            console.log("Force closing modal");
            onHide(); // Call onHide to close the modal
            return;
        }
        
        // Block closing during payment processing or verification
        if (paymentStatus === 'processing' || paymentStatus === 'verifying') {
            console.log(`CheckoutModal: Preventing close during ${paymentStatus} state`);
            return false; // Prevent closing during processing
        }
        
        // If payment was successful, let user manually close the modal to review the success state
        if (paymentStatus === 'succeeded') {
            console.log("CheckoutModal: Manual close of successful payment dialog");
            
            // Reset payment status for future payments
            setPaymentStatus('initial');
            onHide(); // Allow closing when user manually closes
            return;
        }
        
        setPaymentStatus('initial');
        onHide();
        return;
    };
    
    // Open audit example in new tab
    const handleAuditClick = (url) => {
        window.open(url, '_blank');
    };

    // Function to fetch audit cost details
    const fetchAuditCost = async () => {
        try {
            if (!audit_uuid) {
                console.error("No audit UUID provided");
                return;
            }
            
            // First get the basic audit info
            const auditResponse = await axiosInstance.get(`/api/audit_scope_summary/${audit_uuid}/`);
            
            if (!auditResponse.data) {
                console.error("No audit data returned");
                return;
            }
            
            // Get LOC budget information
            const budgetResponse = await axiosInstance.get(`/api/get_loc_buget/?account_uuid=${effectiveAccountUuid}`);
            const locBudget = budgetResponse.data?.loc_budget || 0;
            
            // Calculate payable lines based on LOC and budget
            const linesOfCode = auditResponse.data.lines_of_code || 0;
            const payableLines = Math.max(0, linesOfCode - locBudget);
            const freeLines = Math.min(locBudget, linesOfCode);
            
            // Set basic order details
            const newOrderDetails = {
                linesOfCode: linesOfCode,
                payableLines: payableLines,
                freeLines: freeLines,
                audit_uuid: audit_uuid,
                account_uuid: effectiveAccountUuid,
                isGroupAudit: auditResponse.data.is_group_audit || false
            };
            
            setOrderDetails(newOrderDetails);
            
            return newOrderDetails;
        } catch (error) {
            console.error("Error fetching audit cost:", error);
            return null;
        }
    };

    // Force the modal open when Stripe parameters are detected
    useEffect(() => {
        const queryParams = new URLSearchParams(window.location.search);
        const sessionId = queryParams.get('session_id');
        const success = queryParams.get('success');
        const cancelled = queryParams.get('cancelled');
        
        const hasStripeParams = sessionId || success || cancelled || 
                               initialSessionId || initialPaymentSuccess || initialPaymentCancelled;
                               
        if (hasStripeParams && !visible && !stripeParamsProcessed) {
            console.log("Stripe parameters detected but modal not visible - forcing open:", {
                sessionId, success, cancelled, initialSessionId, initialPaymentSuccess, initialPaymentCancelled
            });
            
            // Force the modal to be visible by calling onHide with true
            // This is the conventional way to force visibility in the codebase
            onHide(true);
            
            // Ensure payment tab is active
            setActiveIndex(1);
            
            // Mark the return from Stripe
            setIsReturnFromStripe(true);
        }
    }, [
        visible, 
        stripeParamsProcessed, 
        initialSessionId, 
        initialPaymentSuccess, 
        initialPaymentCancelled, 
        onHide
    ]);

    // Override active tab when returning from Stripe to ensure payment tab is shown
    useEffect(() => {
        if (isReturnFromStripe && activeIndex !== 1) {
            console.log("Force setting active index to payment tab (1) because returning from Stripe");
            setActiveIndex(1);
        }
    }, [isReturnFromStripe, activeIndex]);

    return (
        <div className="checkout-modal">
            <Toast ref={toast} />
            <Dialog
                header="Checkout - Payment Details"
                visible={visible}
                style={{ width: '80vw', minWidth: '400px', maxWidth: '800px' }}
                onHide={handleHide}
                modal
                closeOnEscape={paymentStatus !== 'processing' && paymentStatus !== 'verifying'}
                closable={paymentStatus !== 'processing' && paymentStatus !== 'verifying'}
            >
                <div className="checkout-content">
                    <div className="cs-checkout-stepper-container">
                        <CheckoutStepper 
                            account_uuid={effectiveAccountUuid}
                            audit_uuid={audit_uuid}
                            orderDetails={orderDetails}
                            pricingDetails={pricingDetails}
                            setPricingDetails={setPricingDetails}
                            successHandler={handlePaymentSuccess}
                            onCancel={handleHide}
                            paymentStatus={paymentStatus}
                            setPaymentStatus={setPaymentStatus}
                            initialSessionId={initialSessionId}
                            initialPaymentSuccess={initialPaymentSuccess}
                            initialPaymentCancelled={initialPaymentCancelled}
                            isReturnFromStripe={isReturnFromStripe}
                            activeIndex={activeIndex}
                            setActiveIndex={setActiveIndex}
                            onClose={handleHide}
                            isGroupAudit={isGroupAudit}
                            groupAuditUuid={groupAuditUuid}
                            processedPaymentIntents={processedPaymentIntents}
                            setProcessedPaymentIntents={setProcessedPaymentIntents}
                        />
                    </div>
                    
                    {/* Sample audits section - hide when payment is successful */}
                    {paymentStatus !== 'succeeded' && (
                        <div className="sample-audits-section">
                            <div style={{ textAlign: 'center', marginBottom: '10px' }}>
                                <h2 style={{ margin: '0 0 8px 0' }}>Feeling undecided?</h2>
                                <p style={{ margin: '0', fontSize: '14px' }}>Explore other audits to see what you get</p>
                            </div>
                            <div className="audit-cards-checkout">
                                {sampleAudits.map((audit, index) => (
                                    <div 
                                        key={index}
                                        className="audit-example-card" 
                                        style={{ 
                                            flex: '1 1 33%', 
                                            minWidth: '150px',
                                            backgroundColor: 'white',
                                            border: '1px solid #32AFC3',
                                            borderRadius: '8px',
                                            padding: '16px',
                                            cursor: 'pointer',
                                            boxShadow: '0 2px 4px rgba(0, 0, 0, 0.05)',
                                            margin: '3px'
                                        }}
                                        onClick={() => handleAuditClick(audit.url)}
                                    >
                                        <div style={{ display: 'flex', flexDirection: 'column', gap: '10px' }}>
                                            <img 
                                                src={audit.logo} 
                                                alt={audit.name} 
                                                style={{ 
                                                    height: '20px', 
                                                    maxWidth: '100%',
                                                    objectFit: 'contain',
                                                    marginBottom: '6px'
                                                }}
                                            />
                                            <div
                                                style={{ 
                                                    fontSize: '0.8rem',
                                                    color: '#666',
                                                    whiteSpace: 'nowrap',
                                                    overflow: 'hidden',
                                                    textOverflow: 'ellipsis'
                                                }}
                                            >
                                                {audit.repoUrl}
                                            </div>
                                            <div style={{ fontSize: '0.8rem', color: '#666' }}>
                                                {audit.linesOfCode.toLocaleString()} lines of code analyzed
                                            </div>
                                        </div>
                                    </div>
                                ))}
                            </div>
                            <div style={{ textAlign: 'center', marginTop: '10px' }}>
                                <a 
                                    href="/discover" 
                                    target="_blank"
                                    rel="noopener noreferrer" 
                                    style={{ 
                                        color: '#32AFC3', 
                                        textDecoration: 'underline',
                                        fontSize: '14px',
                                        display: 'inline-block',
                                        padding: '5px' 
                                    }}
                                    onClick={(e) => {
                                        e.preventDefault();
                                        window.open('/discover', '_blank');
                                    }}
                                >
                                    Explore more
                                </a>
                            </div>
                        </div>
                    )}
                    
                    <p className="cs-payment-note">
                        <i className="pi pi-info-circle"></i> 
                        Your payment will be processed securely through Stripe. No payment information is stored on our servers.
                    </p>
                </div>
            </Dialog>
        </div>
    );
};

export default CheckoutModal; 