// audit_invitation_components/inviter_view.js
import React from 'react';
const InviterView = ({ auditName, inviteeEmail, userRole, userName, auditFinished, isPaid }) => (
    <div>
        <div className='audit-invitation-header' style={{paddingLeft: "5%", paddingTop: "30px"}}>
            <h1 style={{ fontSize: "24px" }}>
                Invitation to audit: <span style={{ color: "#C049DE", fontSize: "24px" }}>{auditName}</span>
            </h1>
            <p className="sub-text" style={{color: "#8D8D8D", marginTop: "10px"}}>
                You successfully invited <strong>{inviteeEmail}</strong> to take part in the auditing process.
            </p>
            <p className="sub-text" style={{color: "#8D8D8D", marginTop: "10px"}}>
                It's now their turn to add relevant repositories. You will be notified by email as soon as the audit was marked as ready.
            </p>
            
            {isPaid && (
                <div style={{ 
                    backgroundColor: '#f0f9eb', 
                    border: '1px solid #e1f3d8', 
                    padding: '10px 15px', 
                    borderRadius: '4px',
                    marginTop: '15px',
                    display: 'flex',
                    alignItems: 'center'
                }}>
                    <i className="pi pi-check-circle" style={{ color: '#2CB392', marginRight: '10px', fontSize: '18px' }}></i>
                    <span style={{ color: '#67c23a' }}>Payment completed. Your audit is ready to start.</span>
                </div>
            )}
            <hr style={{ border: "1px solid #32AFC3", marginBottom: "45px", marginTop: "15px", maxWidth: '900px'}} />
        </div>
    </div>
);

export default InviterView;