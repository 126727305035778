import React, { useState, useEffect, useCallback, useRef } from 'react';
import axiosInstance from '../axiosConfig';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { DataScroller } from 'primereact/datascroller';
import { Button } from 'primereact/button';
import { Message } from 'primereact/message';
import { InputText } from 'primereact/inputtext';
import { ProgressSpinner } from 'primereact/progressspinner';
import { selectAuth } from '../features/user/userSlice';
import EnterRepoCredentials from '../components/EnterRepoCredentials';
import InviteToAudit from '../components/InviteToAudit';

import GroupAuditCard from './components/GroupAuditCard';
import RepoCard from './components/RepoCard';
import './UserDashboard.css';
import AuditSearch from './components/AuditSearch';

function UserDashboard() {
    const [gitUrl, setGitUrl] = useState('');
    const [audits, setAudits] = useState([]);
    const [groupAudits, setGroupAudits] = useState([]);
    const [totalItems, setTotalItems] = useState(0);
    const limit = 10; // Number of audits to list per page
    const [offset, setOffset] = useState(0);
    const [hasLoadedMore, setHasLoadedMore] = useState(false);
    const userTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    const [isLoading, setIsLoading] = useState(false);
    const [isCheckingUrl, setIsCheckingUrl] = useState(false);
    const [isValidUrl, setIsValidUrl] = useState(true);
    const [errorMessage, setErrorMessage] = useState('');
    const [errorType, setErrorType] = useState('');
    const [showCredentialsModal, setShowCredentialsModal] = useState(false);
    const dispatch = useDispatch();
    const { accountUuid } = useSelector(selectAuth);
    const navigate = useNavigate();
    const [auditUuid, setAuditUuid] = useState(''); 
    const [auditStarted, setAuditStarted] = useState(false);
    const [initialLoadComplete, setInitialLoadComplete] = useState(false);
    const [hasSearched, setHasSearched] = useState(false);
    const [currentSearchQuery, setCurrentSearchQuery] = useState('');
    const searchHandlerRef = useRef(null);
    const [importStatus, setImportStatus] = useState({
        status: '',
        progress: 0,
        message: '',
        details: null
    });
    const [isRedirecting, setIsRedirecting] = useState(false);

    const fetchAuditDetails = async (auditUuid) => {
        try {
            const response = await axiosInstance.get(`audit_details/${auditUuid}/`);
            return {
                file_count: response.data.file_count,
                overall_quality_score: response.data.overall_quality_score,
                overall_functionality_score: response.data.overall_functionality_score,
                overall_security_score: response.data.overall_security_score,
                overall_architecture_score: response.data.overall_architecture_score,
                overall_documentation_score: response.data.overall_documentation_score,
                overall_standards_score: response.data.overall_standards_score
            };
        } catch (error) {
            console.error('Error fetching audit details:', error);
            return {
                file_count: 0,
                overall_quality_score: 0,
                overall_functionality_score: 0,
                overall_security_score: 0,
                overall_architecture_score: 0,
                overall_documentation_score: 0,
                overall_standards_score: 0
            };
        }
    };

    const getProgressFromStatus = (status) => {
        if (!status) return 0;

        // Handle detailed progress information for repository processing
        if (status.startsWith('Repository imported|')) {
            const [_, filesProgress, statusText] = status.split('|');
            const [processed, total] = filesProgress.split('/');
            
            // Calculate progress: 20% base + up to 80% based on file processing
            const fileProgress = (parseInt(processed) / parseInt(total));
            return 20 + (fileProgress * 80);
        }

        // Base progress states
        const progressMap = {
            'Audit session created': 20,
            'Processing file selection...': 100,
            'File information imported': 100
        };
        return progressMap[status] || 0;
    };

    const getProgressMessage = (status) => {
        if (!status) return 'Processing repository...';

        // Handle detailed progress information
        if (status.startsWith('Repository imported|')) {
            const [_, filesProgress, statusText] = status.split('|');
            
            if (statusText.startsWith('Processing repository data:')) {
                // Extract the full status message which already includes all the information
                return statusText;
            }
            
            // Fallback for any other format
            return `Processing repository... (${filesProgress} files)`;
        }

        // Fallback for simple status messages
        const messageMap = {
            'Audit session created': 'Creating audit session and downloading repository...',
            'Processing file selection...': 'Processing file selection...',
            'File information imported': 'Import completed! Redirecting...'
        };
        return messageMap[status] || 'Processing repository...';
    };

    // Add a function to get additional progress details
    const getProgressDetails = (status) => {
        if (status.startsWith('Repository imported|')) {
            const [_, filesProgress, statusText] = status.split('|');
            const [processed, total] = filesProgress.split('/');
            
            // Calculate single percentage based on both processing and importing
            let percentage = Math.round((parseInt(processed) / parseInt(total)) * 100);
            
            // Extract processing rate if available
            const rateMatch = statusText.match(/at ([0-9.]+) files\/s/);
            const rate = rateMatch ? rateMatch[1] : 0;
            
            return {
                message: `Processing data: ${processed}/${total} files (${percentage}%)${rate ? ` at ${rate} files/s` : ''}`
            };
        }
        return null;
    };

    const pollAuditStatus = useCallback(async (uuid) => {
        try {
          const response = await axiosInstance.get('audit_status/', {
            params: { audit_uuid: uuid }
          });
      
          if (response.data.audit_status) {
            const status = response.data.audit_status;
            const progress = getProgressFromStatus(status);
            const message = getProgressMessage(status);
            const details = getProgressDetails(status);
      
            setImportStatus({
              status,
              progress,
              message,
              details
            });
      
            // Stop polling if the process is complete
            if (status === 'File information imported') {
              setAuditStarted(false);
              setIsRedirecting(true);
              setTimeout(() => {
                setIsRedirecting(false);
                navigate(`/${uuid}/audit-scope-selection`);
              }, 1000);
              return true;
            }
      
            // Stop polling if an error is encountered
            if (status.startsWith("Error:")) {
              setAuditStarted(false);
              // Optionally, display an error message to the user here
              return true;
            }
          }
          return false; // Continue polling if no complete/error status is detected
        } catch (error) {
          console.error('Error polling audit status:', error);
          return true; // Stop polling on network or unexpected errors
        }
      }, [navigate]);

    const fetchAudits = useCallback(async (loadMore = false) => {
        if (isLoading) {
            return;
        }
        
        setIsLoading(true);
        
        try {
            const accessToken = localStorage.getItem('accessToken');
            
            if (!accountUuid || !accessToken) {
                setIsLoading(false);
                navigate('/login');
                return;
            }

            const newOffset = loadMore ? offset + limit : 0;
            const response = await axiosInstance.get('user_audits/', {
                params: {
                    offset: newOffset,
                    limit: limit,
                    account_uuid: accountUuid
                }
            });

            const fetchedAudits = response.data.audits_data || [];
            const fetchedGroupAudits = response.data.group_audits_data || [];

            const auditsWithDetails = await Promise.all(fetchedAudits.map(async audit => {
                const details = await fetchAuditDetails(audit.audit_uuid);
                return { 
                    ...audit, 
                    ...details
                };
            }));

            if (loadMore) {
                setAudits(prev => [...prev, ...auditsWithDetails]);
                setGroupAudits(prev => [...prev, ...fetchedGroupAudits]);
                setOffset(newOffset);
                setHasLoadedMore(true);
            } else {
                setAudits(auditsWithDetails);
                setGroupAudits(fetchedGroupAudits);
                setOffset(0);
            }
            
            setTotalItems(response.data.total_audits);
            
            if (!loadMore) {
                setInitialLoadComplete(true);
            }

        } catch (error) {
            console.error('Error fetching audits:', error);
            if (error.response?.status === 401) {
                return;
            }
        } finally {
            setIsLoading(false);
        }
    }, [accountUuid, navigate]);

    useEffect(() => {
        let pollInterval;
      
        const startPolling = async () => {
          if (auditStarted && auditUuid) {
            try {
              // First poll immediately
              const shouldStop = await pollAuditStatus(auditUuid);
              if (shouldStop) return;
      
              // Set up polling every 3 seconds
              pollInterval = setInterval(async () => {
                try {
                  const shouldStop = await pollAuditStatus(auditUuid);
                  if (shouldStop) {
                    clearInterval(pollInterval);
                  }
                } catch (error) {
                  console.error("Error during polling:", error);
                  clearInterval(pollInterval);
                }
              }, 5000);
            } catch (error) {
              console.error("Error initiating polling:", error);
            }
          }
        };
      
        startPolling();
      
        return () => {
          if (pollInterval) clearInterval(pollInterval);
        };
      }, [auditStarted, auditUuid, pollAuditStatus]);
      

    // Replace the previous useEffect with this one for initial load
    useEffect(() => {
        let mounted = true;

        const loadInitialAudits = async () => {
            if (!accountUuid || !mounted) return;
            
            // Only fetch if we haven't loaded anything yet
            if (!initialLoadComplete && !isLoading) {
                await fetchAudits(false);
            }
        };

        loadInitialAudits();

        return () => {
            mounted = false;
        };
    }, []); // Empty dependency array as this should only run once on mount

    const handleSubmit = async () => {
        let mounted = true;
        setIsLoading(true);
        setErrorMessage('');
        setIsCheckingUrl(true);
        setErrorType('');

        try {
            // Step 1: Validate URL with timeout
            const validationResponse = await axiosInstance.post(
                'validate_git_url',
                { 
                    git_repo_url: gitUrl, 
                    account_uuid: accountUuid 
                }
            );
            
            setIsCheckingUrl(false);

            if (!validationResponse.data.isValid) {
                handleValidationError(validationResponse.data);
                if (validationResponse.data.message === 'SSH access denied.') {
                    setShowCredentialsModal(true);
                }
                setIsLoading(false);
                return;
            }

            // Step 2: Create audit and get UUID
            const auditScopeResponse = await axiosInstance.post(
                'post_audit_scope_selector/',
                { 
                    git_repo_url: gitUrl, 
                    account_uuid: accountUuid 
                }
            );
            
            if (!auditScopeResponse.data.audit_uuid) {
                throw new Error('No audit UUID received');
            }

            // Set audit UUID and start polling immediately
            const receivedAuditUuid = auditScopeResponse.data.audit_uuid;
            setAuditUuid(receivedAuditUuid);
            
            // Set initial status
            setImportStatus({
                status: 'Audit session created',
                progress: 20,
                message: 'Creating audit session and downloading repository...',
                details: {
                    message: 'Creating audit session and downloading repository...'
                }
            });
            
            dispatch({
                type: 'SET_AUDIT_STATUS',
                payload: {
                    uuid: receivedAuditUuid,
                    hasAccess: true,
                    isPublic: false,
                    auditStatus: 'created'
                }
            });

            // Start polling by setting auditStarted to true
            setAuditStarted(true);
            setIsLoading(false);

            // Fetch audits to show the new audit in the list
            await fetchAudits(false);

        } catch (error) {
            if (mounted) {
                setIsCheckingUrl(false);
                setIsLoading(false);
                setAuditStarted(false);
            }
            
            if (error.message === 'Request timeout') {
                setErrorMessage('Request timed out. Please try again.');
                setErrorType('timeout');
            } else if (error.response?.data?.message === "SSH access denied.") {
                setShowCredentialsModal(true);
            } else {
                setErrorMessage(error.response?.data?.message || 'An error occurred while processing the URL.');
                setErrorType(error.response?.data?.errorType || 'unknown');
                setIsValidUrl(false);
            }
        } 
    };

    // Helper function to handle validation errors
    const handleValidationError = (data) => {
        const errorMessages = {
            invalidUrl: "This is not a valid URL.",
            invalidGitUrl: "This is not a valid git root URL to clone.",
            accessError: "SSH access denied.",
            default: "An unknown error occurred."
        };

        setErrorMessage(errorMessages[data.errorType] || errorMessages.default);
        setErrorType(data.errorType || 'unknown');
        setIsValidUrl(false);
    };

    const renderAuditItem = (audit) => {
        if (audit.isGroupAudit) {
            return <GroupAuditCard 
                groupAudit={audit} 
                userTimeZone={userTimeZone} 
                accountUuid={accountUuid} 
            />;
        }
        return <RepoCard 
            audit={audit} 
            userTimeZone={userTimeZone} 
            accountUuid={accountUuid} 
        />;
    };

    const getSortedAudits = useCallback(() => {
        const combinedAudits = [...audits, ...groupAudits.map(audit => ({...audit, isGroupAudit: true}))];
        return combinedAudits.sort((a, b) => new Date(b.ai_synthesis) - new Date(a.ai_synthesis));
    }, [audits, groupAudits]);

    const canLoadMore = totalItems > audits.length + groupAudits.length;

    const handleSearchResults = useCallback((auditsWithDetails, fetchedGroupAudits, loadMore) => {
        if (loadMore) {
            setAudits(prev => [...prev, ...auditsWithDetails]);
            setGroupAudits(prev => [...prev, ...fetchedGroupAudits]);
        } else {
            setAudits(auditsWithDetails);
            setGroupAudits(fetchedGroupAudits);
        }
    }, []);

    const handleLoadMore = useCallback(() => {
        if (canLoadMore && !isLoading) {
            if (currentSearchQuery && typeof searchHandlerRef.current === 'function') {
                searchHandlerRef.current(currentSearchQuery, true);
            } else {
                fetchAudits(true);
            }
        }
    }, [canLoadMore, isLoading, fetchAudits, currentSearchQuery]);

    const handleKeyDown = (e) => {
        if (e.key === 'Enter') {
            handleSubmit();
        }
    }

    const isButtonDisabled = !gitUrl.trim() || isLoading || isCheckingUrl || auditStarted || isRedirecting;

    useEffect(() => {
        let mounted = true;

        return () => {
            mounted = false;
            // Reset all states on unmount
            setIsLoading(false);
            setIsCheckingUrl(false);
            setAuditStarted(false);
            setErrorMessage('');
            setErrorType('');
        };
    }, []);

    const handleSearchReference = useCallback((handler) => {
        searchHandlerRef.current = handler;
    }, []);

    return (
        <div className="user-dashboard-container">
            <InviteToAudit accountUuid={accountUuid}/>
            <EnterRepoCredentials
                isVisible={showCredentialsModal}
                onHide={() => setShowCredentialsModal(false)}
                gitUrl={gitUrl}
                onAuthenticate={handleSubmit}
                accountUuid={accountUuid}
            />

            <div className="divider-container">
                <hr className="line" />
                <span className="divider-text">or</span>
                <hr className="line" />
            </div>

            <div className="direct-audit-container">
                <div className="audit-repo-title">Audit a git repository directly</div>
                <div className="audit-repo-inputcontainer-dashboard">
                    <span className="audit-repo-inputfield-dashboard">
                        <div className="input-progress-container">
                        <InputText
                            id="gitUrl"
                            value={gitUrl}
                            onChange={(e) => {
                                setGitUrl(e.target.value);
                                setIsValidUrl(true);
                            }}
                            onKeyDown={handleKeyDown}
                            placeholder="e.g. https://github.com/tensorflow/tensorflow"
                            className={`${isValidUrl ? 'p-inputtext' : 'p-inputtext p-invalid'}`}
                            disabled={auditStarted}
                            style={{ width: '100%' }}
                        />
                        {auditStarted && (
                        <>
                            <div
                            className="progress-overlay"
                            style={{
                                width: `${importStatus.progress || 20}%`,
                            }}
                            />
                        </>
                        )}
                        </div>
                        {(auditStarted) && (
                            <div className="dashboard-status-message">
                                {importStatus.details?.message || importStatus.message || 'Creating audit session...'}
                            </div>
                        )}
                    </span>
                    <Button
                        className='audit-repo-button-dashboard'
                        label="Analyze" 
                        onClick={handleSubmit} 
                        disabled={isButtonDisabled} 
                        icon={(isLoading || isCheckingUrl || auditStarted) ? 'pi pi-spin pi-spinner' : 'pi pi-bolt'}
                        iconPos="left"
                    />
                </div>
            </div>

            <div className="error-message-container">
                {errorType && <Message severity="error" text={errorMessage} />}
            </div>

            {(initialLoadComplete && (hasSearched || audits.length > 0 || groupAudits.length > 0)) && (
                <div className="repository-list-section">
                    <AuditSearch 
                        accountUuid={accountUuid}
                        onSearchResults={handleSearchResults}
                        fetchAudits={fetchAudits}
                        limit={limit}
                        setOffset={setOffset}
                        setTotalItems={setTotalItems}
                        setHasLoadedMore={setHasLoadedMore}
                        fetchAuditDetails={fetchAuditDetails}
                        setHasSearched={setHasSearched}
                        setCurrentSearchQuery={setCurrentSearchQuery}
                        onSearchReference={handleSearchReference}
                    />
                    {audits.length === 0 && groupAudits.length === 0 && hasSearched ? (
                        <div className="no-results-message">
                            <i className="pi pi-info-circle" style={{ fontSize: '2rem', color: 'var(--text-color-secondary)' }} />
                            <p>No audits found matching your search criteria</p>
                        </div>
                    ) : (
                        <DataScroller 
                            value={getSortedAudits()}
                            itemTemplate={renderAuditItem}
                            rows={limit}
                            loader={isLoading}
                            footer={
                                <div className="p-d-flex p-jc-center">
                                    {isLoading ? (
                                        <ProgressSpinner style={{width: '30px', height: '30px'}} />
                                    ) : (
                                        canLoadMore ? (
                                            <Button 
                                                label="Load More"
                                                onClick={handleLoadMore}
                                                disabled={isLoading}
                                                style={{height: '40px'}}
                                            />
                                        ) : (
                                            hasLoadedMore && (
                                                <Button 
                                                    label="No More Items"
                                                    disabled={true}
                                                />
                                            )
                                        )
                                    )}
                                </div>
                            }
                        />
                    )}
                </div>
            )}
            
            {!initialLoadComplete && isLoading && (
                <ProgressSpinner 
                    style={{marginTop: '3rem', width: '50px', height: '50px'}}
                />
            )}
        </div>
    );
}

export default UserDashboard;