import React, { useState, useEffect } from 'react';
import { Card } from 'primereact/card';
import { Button } from 'primereact/button';
import { ProgressSpinner } from 'primereact/progressspinner';
import { Tooltip } from 'primereact/tooltip';
import axiosInstance from '../axiosConfig';
import { useSelector } from 'react-redux';
import { selectAuth } from '../features/user/userSlice';
import AdminTabMenu from './components/TabMenu';
import UserStatisticsGraph from './components/graphs/UserStatisticsGraph';
import { useNavigate } from 'react-router-dom';

import './AdminDashboard.css';

const AdminDashboard = () => {
    const navigate = useNavigate();
    const [activeTabIndex, setActiveTabIndex] = useState(0);
    const [userStatistics, setUserStatistics] = useState(null);
    const [timePeriod, setTimePeriod] = useState('1M');
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const { accountUuid } = useSelector(selectAuth);

    useEffect(() => {
        fetchUserStatistics();
    }, [timePeriod, accountUuid]);

    const handleTabChange = (e) => {
        setActiveTabIndex(e.index);
        switch (e.index) {
            case 0:
                navigate('/admin/dashboard');
                break;
            case 1:
                navigate('/admin/audits');
                break;
            case 2:
                navigate('/admin/users');
                break;
            case 3:
                navigate('/admin/settings');
                break;
            default:
                break;
        }
    };

    const fetchUserStatistics = async () => {
        setLoading(true);
        setError(null);
        try {
            const accountUuid = localStorage.getItem('accountUuid');
            const response = await axiosInstance.get('get_users_statistics', {
                params: { 
                    time_period: timePeriod,
                    account_uuid: accountUuid
                },
            });
            setUserStatistics(response.data);
        } catch (error) {
            console.error('Error fetching user statistics:', error);
            setError('Failed to load user statistics. Please try again later.');
        } finally {
            setLoading(false);
        }
    };

    const formatUserChartData = () => {
        if (!userStatistics || !userStatistics.new_users || !userStatistics.active_users) {
            return [];
        }

        const allDates = new Set([
            ...userStatistics.new_users.map(item => item.date),
            ...userStatistics.active_users.map(item => item.date)
        ]);

        const newUsersMap = new Map(
            userStatistics.new_users.map(item => [item.date, item])
        );
        
        const activeUsersMap = new Map(
            userStatistics.active_users.map(item => [item.date, item])
        );

        return Array.from(allDates).sort().map(date => ({
            date: new Date(date),
            new_users: newUsersMap.get(date)?.count || 0,
            users: newUsersMap.get(date)?.cumulative_count || 0,
            active_users: activeUsersMap.get(date)?.cumulative_count || 0
        }));
    };

    const renderTimePeriodButtons = () => (
        <div className="time-period-buttons">
            <Button 
                label="1W" 
                onClick={() => setTimePeriod('1W')} 
                className={timePeriod === '1W' ? 'p-button-outlined' : 'p-button-text'} 
            />
            <Button 
                label="1M" 
                onClick={() => setTimePeriod('1M')} 
                className={timePeriod === '1M' ? 'p-button-outlined' : 'p-button-text'} 
            />
            <Button 
                label="3M" 
                onClick={() => setTimePeriod('3M')} 
                className={timePeriod === '3M' ? 'p-button-outlined' : 'p-button-text'} 
            />
            <Button 
                label="6M" 
                onClick={() => setTimePeriod('6M')} 
                className={timePeriod === '6M' ? 'p-button-outlined' : 'p-button-text'} 
            />
            <Button 
                label="1Y" 
                onClick={() => setTimePeriod('1Y')} 
                className={timePeriod === '1Y' ? 'p-button-outlined' : 'p-button-text'} 
            />
            <Button 
                label="All" 
                onClick={() => setTimePeriod('ALL')} 
                className={timePeriod === 'ALL' ? 'p-button-outlined' : 'p-button-text'} 
            />
        </div>
    );

    const renderContent = () => {
        if (loading) {
            return (
                <div className="admin-dashboard-loader">
                    <ProgressSpinner />
                </div>
            );
        }

        if (error) {
            return (
                <div className="admin-dashboard-error">
                    <i className="pi pi-exclamation-circle" style={{ marginRight: '0.5rem' }} />
                    {error}
                </div>
            );
        }

        const chartData = formatUserChartData();
        if (chartData.length === 0) {
            return (
                <div className="no-data-message">
                    No data available for the selected time period
                </div>
            );
        }

        return (
            <div className="admin-dashboard-graph-container">
                <UserStatisticsGraph 
                    data={chartData} 
                    timePeriod={timePeriod} 
                    aggregation={userStatistics?.aggregation} 
                />
            </div>
        );
    };

    return (
        <div className="admin-dashboard-page-layout">
            <AdminTabMenu activeIndex={activeTabIndex} onTabChange={handleTabChange} />
            <div className="admin-dashboard-container">
                <div className="admin-dashboard-header">
                    <h1>Admin Dashboard</h1>
                    <p>Overview of system metrics and performance</p>
                </div>
                
                <div className="admin-dashboard-section">
                    <div className="admin-dashboard-cards">
                        <Card className="admin-dashboard-card">
                            <div className="card-header">
                                <h2>User Statistics</h2>
                                {renderTimePeriodButtons()}
                            </div>
                            {renderContent()}
                        </Card>
                    </div>
                </div>
            </div>
            <Tooltip target=".admin-tooltip-icon" className="admin-dashboard-tooltip" />
        </div>
    );
};

export default AdminDashboard;