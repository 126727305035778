import React, { useState, useEffect, useRef, useCallback, useMemo } from 'react';
import { useLocation } from 'react-router-dom';
import axiosInstance from '../axiosConfig';
import { Toast } from 'primereact/toast';
import InviterView from './audit_invitation_components/InviterView';
import InviteeView from './audit_invitation_components/InviteeView';
import RepositoryCard from '../components/RepositoryCard';
import AuditInvitationSummaryTab from './audit_invitation_components/AuditInvitationSummaryTab';
import EnterRepoCredentials from '../components/EnterRepoCredentials';
import useRepositories from './hooks/UseRepositories';

import './AuditInvitation.css';

const AuditInvitation = () => {
    const [gitUrl, setGitUrl] = useState('');
    const [isValidUrl, setIsValidUrl] = useState(true);
    const [isLoading, setIsLoading] = useState(false);
    const [isCheckingUrl, setIsCheckingUrl] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');
    const [errorType, setErrorType] = useState('');
    const [userRole, setUserRole] = useState(null);
    const [userName, setUserName] = useState('');
    const [auditName, setAuditName] = useState('');
    const [email, setInviteeEmail] = useState('');

    const persistRoot = JSON.parse(localStorage.getItem('persist:root'));
    const user = JSON.parse(persistRoot?.user || '{}');
    const accountUuid = user.accountUuid;

    const location = useLocation();
    const groupAuditUuid = location.pathname.split('/')[1];

    const { repositoriesData, isLoadingRepositories, error: repositoriesError, fetchRepositories } = useRepositories(groupAuditUuid);

    const [submittedUrls, setSubmittedUrls] = useState([]);
    const [showCredentialsModal, setShowCredentialsModal] = useState(false);

    const [auditStarted, setAuditStarted] = useState(false);
    const [auditFinished, setAuditFinished] = useState(false);
    const [isPaid, setIsPaid] = useState(false);
    const [readyForAudit, setReadyForAudit] = useState(false);

    // Add state for Stripe redirect handling
    const [stripeParams, setStripeParams] = useState({
        sessionId: null,
        success: false,
        cancelled: false
    });
    const [stripeParamsProcessed, setStripeParamsProcessed] = useState(false);

    const toast = useRef(null);

    const [isDuplicateRepo, setIsDuplicateRepo] = useState(false);
    const [warningMessage, setWarningMessage] = useState('');

    const [importStatus, setImportStatus] = useState({
        status: '',
        progress: 0,
        message: ''
    });
    const [currentImportUuid, setCurrentImportUuid] = useState(null);

    const intervalRef = useRef(null);

    // Create a stable reference to the polling interval and UUID
    const pollIntervalRef = useRef(null);
    const currentUuidRef = useRef(null);

    // Parse URL parameters for Stripe redirect
    useEffect(() => {
        const searchParams = new URLSearchParams(location.search);
        const sessionId = searchParams.get('session_id');
        const success = searchParams.get('success') === 'true';
        const cancelled = searchParams.get('cancelled') === 'true';

        if (sessionId || success || cancelled) {
            console.log('Detected Stripe redirect parameters:', { sessionId, success, cancelled });
            
            setStripeParams({
                sessionId,
                success,
                cancelled
            });

            // Clean URL to prevent re-processing on refresh
            if (window.history.replaceState) {
                const cleanUrl = window.location.pathname;
                window.history.replaceState({}, document.title, cleanUrl);
            }
        }
    }, [location.search]);

    // Verify payment when Stripe session ID is detected
    useEffect(() => {
        if (stripeParams.sessionId && !stripeParamsProcessed) {
            // Forcefully verify payment with the backend once right after redirect
            const verifyPaymentStatus = async () => {
                try {
                    console.log('Verifying payment status for session:', stripeParams.sessionId);
                    // First fetch the checkout session to get the payment intent
                    const sessionResponse = await axiosInstance.get(`api/checkout-session/${stripeParams.sessionId}/`);
                    const sessionData = sessionResponse.data;
                    
                    if (sessionData?.payment_intent) {
                        // Then verify the payment intent status
                        const verifyResponse = await axiosInstance.get(`api/verify_payment_status/${sessionData.payment_intent}/?account_uuid=${accountUuid}&audit_uuid=${groupAuditUuid}`);
                        
                        console.log('Payment verification response:', verifyResponse.data);
                        
                        // If verification was successful or payment is already recorded, update our state
                        if (verifyResponse.data.is_paid || verifyResponse.data.audit_already_paid) {
                            console.log('Payment is recorded in the system');
                            setIsPaid(true);
                        } 
                        // If Stripe shows success but payment is not recorded in our system, attempt manual recording
                        else if (verifyResponse.data.stripe_status === 'succeeded' || sessionData.payment_status === 'paid') {
                            console.log('Payment succeeded in Stripe but not recorded in our system. Attempting manual recording...');
                            
                            // Extract metadata from session
                            const metadataAccountUuid = sessionData.metadata?.account_uuid || accountUuid;
                            const metadataAuditUuid = sessionData.metadata?.audit_uuid || groupAuditUuid;
                            const isGroupAudit = sessionData.metadata?.is_group_audit === 'true' || true;
                            
                            // Prepare payment data
                            const paymentData = {
                                payment_intent_id: sessionData.payment_intent,
                                account_uuid: metadataAccountUuid,
                                audit_uuid: metadataAuditUuid,
                                group_audit_uuid: groupAuditUuid,
                                is_group_audit: isGroupAudit,
                                send_receipt: true,
                                amount: sessionData.amount_total / 100, // Convert from cents if needed
                                currency: sessionData.currency || 'eur'
                            };
                            
                            // Add customer details if available
                            if (sessionData.customer_email) {
                                paymentData.customer_email = sessionData.customer_email;
                            }
                            
                            if (sessionData.customer_details?.address?.country) {
                                paymentData.country_code = sessionData.customer_details.address.country;
                            }
                                                        
                            try {
                                // Call manual payment success endpoint
                                const manualResponse = await axiosInstance.post('/api/manual_payment_success/', paymentData);
                                                                
                                if (manualResponse.data.success || manualResponse.data.already_existed) {
                                    setIsPaid(true);
                                    
                                    // Show success toast if available
                                    if (toast.current) {
                                        toast.current.show({
                                            severity: 'success',
                                            summary: 'Payment Confirmed',
                                            detail: `Transaction ID: ${sessionData.payment_intent}`,
                                            life: 3000
                                        });
                                    }
                                } else {
                                    if (manualResponse.data.details) {
                                        console.warn('Details:', manualResponse.data.details);
                                    }
                                    
                                    // Still set paid to true if stripe payment succeeded, even if our recording failed
                                    setIsPaid(true);
                                    
                                    // Show info toast if available
                                    if (toast.current) {
                                        toast.current.show({
                                            severity: 'info',
                                            summary: 'Payment Processing',
                                            detail: 'Your payment was successful but is still being processed. You can proceed with the audit.',
                                            life: 5000
                                        });
                                    }
                                }
                            } catch (manualError) {
                                console.error('Error during manual payment recording:', manualError);
                                
                                // Still set isPaid to true since the payment did succeed in Stripe
                                setIsPaid(true);
                            }
                        }
                    }
                } catch (error) {
                    console.error('Error verifying payment status:', error);
                }
                
                // Mark as processed to avoid re-processing
                setStripeParamsProcessed(true);
            };
            
            verifyPaymentStatus();
        }
    }, [stripeParams.sessionId, stripeParamsProcessed, accountUuid, groupAuditUuid, toast]);

    // Reset stripe params function to be passed to child component
    const resetStripeParams = useCallback(() => {
        setStripeParams({
            sessionId: null,
            success: false,
            cancelled: false
        });
        setStripeParamsProcessed(true);
    }, []);

    useEffect(() => {
        const identifyUserRole = async () => {
            try {
                const response = await axiosInstance.get('identify_audit_role/', {
                    params: {
                        audit_uuid: groupAuditUuid,
                        account_uuid: accountUuid
                    }
                });
                setUserRole(response.data.role);
                setUserName(response.data.inviterName);
                setAuditName(response.data.auditName);
                setInviteeEmail(response.data.email);
            } catch (error) {
                setErrorMessage("Error identifying user role.");
                setErrorType('roleIdentificationError');
                console.error(error);
            }
        };

        identifyUserRole();
    }, [groupAuditUuid, accountUuid]);

    const isValidGitUrl = (url) => {
        const pattern = /^(https?:\/\/)?(www\.)?(github\.com|gitlab\.com|bitbucket\.org|sourceforge\.net|code.google.com|codeplex\.com|launchpad\.net|savannah\.gnu\.org|freecode\.com|gitkraken\.com|beanstalkapp\.com|assembla\.com|phabricator\.com|gogs\.io|gitea\.com|gitbucket\.github\.io|codeberg\.org|azure\.com|dev\.azure\.com|bitbucket\.com|sr\.ht|dags\.hub\.com|chiselapp\.com|repo\.or\.cz|notabug\.org|kallithea-scm\.org|git\.savannah\.gnu\.org|repo\.openpandora\.org|git\.kernel\.org)\/[\w\-\.\/]+$/;
        return pattern.test(url);
    };

    const handleInputChange = (event) => {
        const newUrl = event.target.value;
        setGitUrl(newUrl);
        setIsValidUrl(true);
        setErrorMessage('');
        setErrorType('');
        setWarningMessage('');
        
        if (newUrl && repositoriesData?.repositories?.length > 0) {
            const normalizedNewUrl = normalizeGitUrl(newUrl);
            const isDuplicate = repositoriesData.repositories.some(repo => {
                const normalizedRepoUrl = normalizeGitUrl(repo.url);
                return normalizedRepoUrl === normalizedNewUrl;
            });
            
            if (isDuplicate) {
                setWarningMessage('This repository has already been added to the audit list.');
                setIsDuplicateRepo(true);
            } else {
                setIsDuplicateRepo(false);
            }
        } else {
            setIsDuplicateRepo(false);
        }
    };

    const normalizeGitUrl = (url) => {
        let normalized = url.replace(/\.git$/, '');
        normalized = normalized.replace(/\/$/, '');
        normalized = normalized.replace(/^https?:\/\//, '');
        normalized = normalized.replace(/^www\./, '');
        return normalized.toLowerCase();
    };

    const getProgressFromStatus = (status) => {
        const progressMap = {
            'Audit session created': 20,
            'Repository imported': 40,
            'Repository structure imported': 60,
            'File information imported': 100
        };
        return progressMap[status] || 0;
    };

    const getProgressMessage = (status) => {
        const messageMap = {
            'Audit session created': 'Creating audit session...',
            'Repository imported': 'Importing repository files...',
            'Repository structure imported': 'Analyzing repository structure...',
            'File information imported': 'Import completed!'
        };
        return messageMap[status] || 'Processing repository...';
    };

    // Create a stable ref for fetchRepositories
    const fetchRepositoriesRef = useRef(fetchRepositories);
    
    // Update the ref when fetchRepositories changes
    useEffect(() => {
        fetchRepositoriesRef.current = fetchRepositories;
    }, [fetchRepositories]);

    const pollAuditStatus = useCallback(async (uuid) => {
        if (!uuid) {
            return true;
        }
        
        try {
            const response = await axiosInstance.get('audit_status/', {
                params: { audit_uuid: uuid }
            });
            
            if (response.data.audit_status) {
                const status = response.data.audit_status;
                
                // Stop polling if the import is complete or has failed
                if (status === 'File information imported' || status === 'Import failed') {
                    setIsLoading(false);
                    // Use the ref instead of the direct function
                    Promise.resolve().then(() => {
                        if (fetchRepositoriesRef.current) {
                            fetchRepositoriesRef.current();
                        }
                    });
                    return true;
                }

                const progress = getProgressFromStatus(status);
                const message = getProgressMessage(status);
                
                setImportStatus(prevState => {
                    // Only update if status has changed
                    if (prevState.status !== status) {
                        return {
                            status,
                            progress,
                            message
                        };
                    }
                    return prevState;
                });
            }
            return false;
        } catch (error) {
            console.error('[pollAuditStatus] Error polling status:', error);
            setIsLoading(false);
            return true;
        }
    }, []); // Remove fetchRepositories from dependencies since we're using ref

    useEffect(() => {
        let isMounted = true;

        // Update the ref
        currentUuidRef.current = currentImportUuid;

        const stopPolling = () => {
            if (pollIntervalRef.current) {
                clearInterval(pollIntervalRef.current);
                pollIntervalRef.current = null;
            }
            if (isMounted && currentUuidRef.current === currentImportUuid) {
                setCurrentImportUuid(null);
            }
        };

        const startPolling = async () => {
            if (!currentImportUuid) {
                return;
            }
            if (!isMounted) {
                return;
            }

            // Clear existing interval if any
            if (pollIntervalRef.current) {
                clearInterval(pollIntervalRef.current);
                pollIntervalRef.current = null;
            }

            // Initial poll
            const shouldStop = await pollAuditStatus(currentImportUuid);
            
            if (shouldStop || currentUuidRef.current !== currentImportUuid) {
                stopPolling();
                return;
            }

            if (!isMounted) {
                return;
            }

            // Start interval polling only if not already polling
            if (!pollIntervalRef.current && currentUuidRef.current === currentImportUuid) {
                pollIntervalRef.current = setInterval(async () => {
                    if (!isMounted || currentUuidRef.current !== currentImportUuid) {
                        stopPolling();
                        return;
                    }

                    const shouldStop = await pollAuditStatus(currentImportUuid);
                    if (shouldStop) {
                        stopPolling();
                    }
                }, 5000);
            }
        };

        startPolling();

        return () => {
            isMounted = false;
            if (pollIntervalRef.current) {
                clearInterval(pollIntervalRef.current);
                pollIntervalRef.current = null;
            }
        };
    }, [currentImportUuid, pollAuditStatus]);

    const showSuccess = (url) => {
        toast.current.show({severity: 'success', summary: 'URL Submitted', detail: url, life: 3000});
    };

    const handleValidationError = (errorType) => {
        switch (errorType) {
            case 'invalidUrl':
                setErrorMessage("This is not a valid URL.");
                setErrorType('invalidUrl');
                setIsValidUrl(false);
                break;
            case 'invalidGitUrl':
                setErrorMessage("This is not a valid git root URL to clone.");
                setErrorType('invalidGitUrl');
                setIsValidUrl(false);
                break;
            case 'accessError':
                setErrorMessage("The git URL is protected and could not be accessed.");
                setErrorType('accessError');
                setIsValidUrl(false);
                break;
            default:
                setErrorMessage("An unknown error occurred.");
                setErrorType('unknown');
                setIsValidUrl(false);
                break;
        }
    };

    const handleError = (error) => {
        setIsCheckingUrl(false);
        setIsLoading(false);
    
        if (error.response && error.response.data) {
            const { message, errorType } = error.response.data;
            
            if (message === "SSH access denied.") {
                setShowCredentialsModal(true);
            } else {
                setErrorMessage(message);
                setErrorType(errorType);
                setIsValidUrl(false);
            }
        } else {
            console.error('Error:', error);
            setErrorMessage('An error occurred while processing the URL.');
            setErrorType('unknown');
            setIsValidUrl(false);
        }
    };

    const handleSubmit = useCallback(async () => {
        if (isLoading) {
            console.log('[handleSubmit] Already loading, returning');
            return;
        }

        // Reset all states at the beginning
        setIsLoading(true);
        setErrorMessage('');
        setErrorType('');
        setIsCheckingUrl(true);
        setIsValidUrl(true);
        
        if (!isValidGitUrl(gitUrl)) {
            console.log('[handleSubmit] Invalid git URL format');
            setErrorMessage("Invalid Git URL format.");
            setErrorType('invalidUrlFormat');
            setIsValidUrl(false);
            setIsCheckingUrl(false);
            setIsLoading(false);
            return;
        }
        
        try {
            const validationResponse = await axiosInstance.post(
                'validate_git_url', 
                { 
                    git_repo_url: gitUrl, 
                    account_uuid: accountUuid 
                }
            );
            
            setIsCheckingUrl(false);

            if (validationResponse.data.isValid) {
                setImportStatus({
                    status: 'Audit session created',
                    progress: 20,
                    message: 'Creating audit session...'
                });

                const auditScopeResponse = await axiosInstance.post(
                    'post_audit_scope_selector/',
                    { 
                        git_repo_url: gitUrl, 
                        account_uuid: accountUuid, 
                        g_audit_uuid: groupAuditUuid 
                    }
                );

                if (auditScopeResponse.data.success) {
                    setSubmittedUrls(prev => [...prev, gitUrl]);
                    setGitUrl('');
                    setIsDuplicateRepo(false);
                    setWarningMessage('');
                    
                    const newAuditUuid = auditScopeResponse.data.audit_uuid;
                    await fetchRepositories();
                    showSuccess(gitUrl);
                    
                    setCurrentImportUuid(newAuditUuid);
                } else {
                    handleValidationError(validationResponse.data.errorType);
                    setIsLoading(false);
                }
            } else {
                handleValidationError(validationResponse.data.errorType);
                setIsLoading(false);
            }
        } catch (error) {
            console.error('[handleSubmit] Error in submission:', error);
            handleError(error);
        }
    }, [
        isLoading, gitUrl, accountUuid, groupAuditUuid, 
        setIsLoading, setErrorMessage, setErrorType, setIsCheckingUrl, 
        setIsValidUrl, setImportStatus, setSubmittedUrls, setGitUrl, 
        setIsDuplicateRepo, setWarningMessage, setCurrentImportUuid,
        fetchRepositories, handleValidationError, handleError, showSuccess,
        isValidGitUrl
    ]);

    // Wrap fetchRepositories in useCallback to maintain stable reference
    const memoizedFetchRepositories = useCallback(() => {
        fetchRepositories();
    }, [fetchRepositories]);

    // Memoize the repository card props
    const repositoryCardProps = useMemo(() => ({
        fetchRepositories: memoizedFetchRepositories,
        className: "repository-card",
        style: {alignItems: "self-start"},
        repositories: repositoriesData.repositories,
        groupAuditUuid,
        userRole,
        auditStarted,
        auditFinished,
        isLoading: isLoadingRepositories,
        error: repositoriesError,
        isPaid
    }), [
        memoizedFetchRepositories,
        repositoriesData.repositories,
        groupAuditUuid,
        userRole,
        auditStarted,
        auditFinished,
        isLoadingRepositories,
        repositoriesError,
        isPaid
    ]);

    // Memoize the invitee view props
    const inviteeViewProps = useMemo(() => ({
        auditName,
        gitUrl,
        isCheckingUrl,
        isValidUrl,
        isLoading,
        errorMessage,
        handleInputChange,
        handleSubmit,
        userName,
        showCredentialsModal,
        auditFinished,
        auditStarted,
        isDuplicateRepo,
        warningMessage,
        importStatus,
        readyForAudit
    }), [
        auditName,
        gitUrl,
        isCheckingUrl,
        isValidUrl,
        isLoading,
        errorMessage,
        handleInputChange,
        handleSubmit,
        userName,
        showCredentialsModal,
        auditFinished,
        auditStarted,
        isDuplicateRepo,
        warningMessage,
        importStatus,
        readyForAudit
    ]);

    // Check for payment status when component loads
    useEffect(() => {
        const checkPaymentStatus = async () => {
            try {
                const response = await axiosInstance.get(`get_audit_ready_status/?groupAuditUuid=${encodeURIComponent(groupAuditUuid)}`);

                if (response.data && response.data.is_paid === true) {
                    setIsPaid(true);
                }
            } catch (error) {
                console.error('Error checking payment status:', error);
            }
        };
        
        if (groupAuditUuid) {
            checkPaymentStatus();
        }
    }, [groupAuditUuid, stripeParamsProcessed]); // Add stripeParamsProcessed as dependency to recheck after Stripe redirect

    // Effect to check status of audit ready
    useEffect(() => {
        const checkAuditReadyStatus = async () => {
            try {
                const response = await axiosInstance.get(`get_audit_ready_status/?groupAuditUuid=${encodeURIComponent(groupAuditUuid)}`);
                const status = response.data.status;
                
                // Check if status is "Group audit ready"
                const isReady = status === 'Group audit ready';
                setReadyForAudit(isReady);
                
                // Also update other statuses while we're at it
                const isStarted = status === 'Group audit started';
                const isCompleted = status.toLowerCase().includes('group audit completed');
                
                setAuditStarted(isStarted);
                setAuditFinished(isCompleted);
            } catch (error) {
                console.error('Error checking audit ready status:', error);
            }
        };
        
        if (groupAuditUuid) {
            checkAuditReadyStatus();
            // Poll every 30 seconds
            const intervalId = setInterval(checkAuditReadyStatus, 30000);
            return () => clearInterval(intervalId);
        }
    }, [groupAuditUuid]);

    return (
        <div className="audit-invitation-container">
            <EnterRepoCredentials
                isVisible={showCredentialsModal}
                onHide={() => setShowCredentialsModal(false)}
                gitUrl={gitUrl}
                style={{ width: '900px' }}
                onAuthenticate={handleSubmit}
                isLoading={isLoading}
                accountUuid={accountUuid}
            />
            <Toast ref={toast} />
            <AuditInvitationSummaryTab
                visible={true} 
                onHide={() => {}}
                userRole={userRole}
                repositoriesData={repositoriesData}
                groupAuditUuid={groupAuditUuid}
                userName={userName}
                fetchRepositories={fetchRepositories}
                auditStarted={auditStarted}
                setAuditStarted={setAuditStarted}
                setAuditFinished={setAuditFinished}
                auditFinished={auditFinished}
                isLoading={isLoading}
                setIsPaidForParent={setIsPaid}
                readyForAudit={readyForAudit}
                setReadyForAudit={setReadyForAudit}
                // Pass stripe parameters to summary tab
                shouldOpenCheckoutModal={!stripeParamsProcessed && (stripeParams.sessionId != null)}
                stripeSessionId={stripeParams.sessionId}
                stripeSuccess={stripeParams.success}
                stripeCancelled={stripeParams.cancelled}
                resetStripeParams={resetStripeParams}
            />
            <div className="audit-invitation-content">
                {userRole === 'inviter' && (
                    <InviterView 
                        auditName={auditName}
                        inviteeEmail={email}
                        auditFinished={auditFinished}
                        isPaid={isPaid}
                    />
                )}
                {userRole === 'invitee' && <InviteeView {...inviteeViewProps} />}
            </div>
            <div className="repo-list-container">
                <RepositoryCard {...repositoryCardProps} />
            </div>
        </div>
    );
};

export default AuditInvitation;