import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';
import { blogPosts } from './blogPosts';
import './Blog.css';

const BlogHero = () => {
  return (
    <div className="blog-hero">
      <div className="blog-hero-content">
        <div className="blog-hero-text">
          <h3>Our Blog</h3>
          <div className="hero-title">
            <div className="gradient-wrapper">
              <span className="hero-gradient-text">Insights</span>
            </div>
            <h1>& Updates</h1>
          </div>
          <p className="hero-description-blog">
            Stay up to date with the latest insights in code due diligence, 
            software M&A, and AI-powered code analysis.
          </p>
        </div>
      </div>
    </div>
  );
};

// Function to generate URL-friendly slug from title
const generateSlug = (title) => {
  return title
    .toLowerCase() // Convert to lowercase
    .replace(/[^\w\s-]/g, '') // Remove special characters
    .replace(/\s+/g, '-') // Replace spaces with hyphens
    .replace(/--+/g, '-') // Replace multiple hyphens with single hyphen
    .trim(); // Trim spaces from start and end
};

const BlogCard = ({ blogData }) => {
  const navigate = useNavigate();

  const handleClick = () => {
    navigate(`/blog/${blogData.slug}`);
  };

  return (
    <div className="blog-card" onClick={handleClick}>
      {blogData.coverImage && (
        <div className="blog-card-image">
          <img src={blogData.coverImage} alt={blogData.title} />
        </div>
      )}
      <div className="blog-card-content">
        <h2 className="blog-card-title">{blogData.title}</h2>
        <p className="blog-card-subtitle">{blogData.subtitle}</p>
        <div className="blog-card-footer">
          <span className="blog-card-date">{blogData.date}</span>
          <span className="blog-card-read-more">Read More →</span>
        </div>
      </div>
    </div>
  );
};

const BlogList = () => {
  const [blogs, setBlogs] = useState([]);

  useEffect(() => {
    const fetchBlogPosts = () => {
      try {
        const processedBlogs = Object.entries(blogPosts).map(([id, post]) => {
          const lines = post.content.split('\n');
          
          // Extract title (first # heading)
          const titleMatch = lines.find(line => line.startsWith('# '));
          const title = titleMatch ? titleMatch.replace('# ', '').replace(/\*\*/g, '') : '';
          
          // Extract subtitle (first ## heading)
          const subtitleMatch = lines.find(line => line.startsWith('## '));
          const subtitle = subtitleMatch ? subtitleMatch.replace('## ', '').replace(/\*\*/g, '') : '';

          // Generate slug from title
          const slug = generateSlug(title);

          return {
            id,
            title,
            subtitle,
            slug,
            content: post.content,
            date: new Date().toLocaleDateString('en-US', {
              year: 'numeric',
              month: 'long',
              day: 'numeric'
            })
          };
        });

        setBlogs(processedBlogs);
      } catch (error) {
        console.error('Error loading blog posts:', error);
      }
    };

    fetchBlogPosts();
  }, []);

  return (
    <div className="blog-page">
      <Helmet>
        <title>Blog | CodeDD</title>
        <meta 
          name="description" 
          content="Read the latest insights about code due diligence, software M&A, and AI-powered code analysis." 
        />
      </Helmet>

      <BlogHero />

      <section className="blog-section">
        <div className="blog-content">
          <div className="blog-grid">
            {blogs.map((blog) => (
              <BlogCard 
                key={blog.slug}
                blogData={blog}
              />
            ))}
          </div>
        </div>
      </section>
    </div>
  );
};

export default BlogList; 