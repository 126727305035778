import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

// Component version - automatically scrolls to top on route change
export const ScrollToTop = () => {
  const { pathname } = useLocation();

  useEffect(() => {
    // More aggressive approach to ensure scrolling works
    const scrollToTopImmediately = () => {
      window.scrollTo(0, 0);
    };

    // Apply scroll immediately
    scrollToTopImmediately();
    
    // And also after a small delay to ensure it works after any dynamic content loads
    const timeoutId = setTimeout(scrollToTopImmediately, 50);
    
    // Clean up timeout
    return () => clearTimeout(timeoutId);
  }, [pathname]);

  return null;
};

// Hook version - can be used in any component
export const useScrollToTop = (dependencies = []) => {
  useEffect(() => {
    const scrollToTopImmediately = () => {
      window.scrollTo(0, 0);
    };
    
    // Apply scroll immediately
    scrollToTopImmediately();
    
    // And also after a small delay to ensure it works after any dynamic content loads
    const timeoutId = setTimeout(scrollToTopImmediately, 50);
    
    // Clean up timeout
    return () => clearTimeout(timeoutId);
  }, dependencies);
};

// Function version - can be called programmatically
export const scrollToTop = (behavior = 'auto') => {
  window.scrollTo({
    top: 0,
    left: 0,
    behavior
  });
};

export default ScrollToTop; 