import React, { useState, useEffect, useCallback } from 'react';
import { useParams, Navigate, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import axiosInstance from '../axiosConfig';
import { checkAuditStatus } from '../utils/Actions';
import DevelopmentActivityGraph from './development_component/DevelopmentActivityGraph';
import useAuditDetails from '../utils/useAuditDetails';
import PageLayout from '../components/PageLayout';
import FormattedText from '../components/FormattedText';
import HotspotsBubbleChart from './development_component/HotspotsBubbleChart';
import { ProgressSpinner } from 'primereact/progressspinner';

import './Development.css';

const getTooltipDescription = (statType) => {
    const tooltipMap = {
        totalCommits: "Total number of code changes submitted to the repository, indicating development activity level",
        activeDays: "Number of unique days with development activity, showing consistent engagement",
        avgCommitsDay: "Average commits per active day, reflecting daily development intensity",
        totalAdditions: "Total lines of code added, representing new features and enhancements",
        totalDeletions: "Total lines of code removed, showing code optimization and refactoring",
        modifiedFiles: "Number of files changed, indicating scope of codebase modifications"
    };
    return tooltipMap[statType] || "";
};

const formatNumber = (number) => {
    return new Intl.NumberFormat('en-US').format(number);
};

const StatValue = ({ loading, value, className = '' }) => {
    if (loading) {
        return (
            <span className={`development-stat-value ${className}`}>
                <ProgressSpinner style={{width: '20px', height: '20px'}} strokeWidth="4" />
            </span>
        );
    }
    return (
        <span className={`development-stat-value ${className}`}>
            {value}
        </span>
    );
};

export default function DevelopmentView() {
    const dispatch = useDispatch();
    const [loading, setLoading] = useState(true);
    const [shouldRedirect, setShouldRedirect] = useState(false);
    const { auditUuid } = useParams();
    const auditAccessDetails = useSelector(state => state.audits[auditUuid]);
    const navigate = useNavigate();

    // Remove Redux selectors
    // Add state for previously Redux-stored data
    const [developmentData, setDevelopmentData] = useState(null);
    const [additionalData, setAdditionalData] = useState(null);
    const [lastFetched, setLastFetched] = useState({
        developmentAnalysis: null,
        additionalStats: null
    });

    // Use the new hook to get audit details
    const { 
        auditDetails, 
        headerProps, 
        loading: auditLoading, 
        isDataLoaded 
    } = useAuditDetails(auditUuid);

    // Split loading states for different data sections
    const [loadingCoreStats, setLoadingCoreStats] = useState(true);
    const [loadingAdditionalStats, setLoadingAdditionalStats] = useState(true);
    const [loadingAnalysis, setLoadingAnalysis] = useState(true);
    const [dataFetched, setDataFetched] = useState(false);
    
    // Derived loading state for PageLayout (initial page load only)
    const pageLoading = loading || auditLoading;
    
    // Derived loading state for individual sections
    const coreStatsLoading = loadingCoreStats;
    const additionalStatsLoading = loadingAdditionalStats;
    const analysisLoading = loadingAnalysis;
    
    // State for git statistics - core stats are stored only in component state, not in Redux
    const [gitStats, setGitStats] = useState({
        timeBasedStats: null,
        authorStats: null,
        codeChurnStats: null,
        collaborationStats: null,
        releaseStats: null,
        branchStats: null,
        mergeStats: null,
        metaInfo: null,
        repositoryInfo: null,
        developmentAnalysis: null,
        developerExpertise: null
    });

    // State for selected file
    const [selectedFile, setSelectedFile] = useState(null);

    // Check if the user has access to the audit
    useEffect(() => {
        if (!auditAccessDetails) {
            console.log("Audit details are not available yet.");
        } else if (auditAccessDetails.error) {
            console.error('Audit access error:', auditAccessDetails.error);
            setShouldRedirect(true);
        } else if (!auditAccessDetails.isPublic && !auditAccessDetails.hasAccess) {
            setShouldRedirect(true);
        } else {
            setShouldRedirect(false);
        }
    }, [auditAccessDetails]);

    // Check audit status
    useEffect(() => {
        const checkStatus = async () => {
            if (!auditUuid) return;
            try {
                await dispatch(checkAuditStatus(auditUuid));
            } catch (error) {
                console.error('Error checking audit status:', error);
            }
        };

        checkStatus();
    }, [auditUuid, dispatch]); 

    // Check if we have valid cached data
    const hasCachedData = useCallback(() => {
        if (!lastFetched.developmentAnalysis || !lastFetched.additionalStats) return false;
        
        // Check if we have both development analysis and additional stats
        const hasAnalysis = developmentData && lastFetched.developmentAnalysis;
        const hasAdditionalStats = additionalData && lastFetched.additionalStats;
        
        if (!hasAnalysis || !hasAdditionalStats) return false;
        
        // Check if cache is still valid (30 minutes)
        const now = new Date().getTime();
        const analysisAge = now - new Date(lastFetched.developmentAnalysis).getTime();
        const additionalStatsAge = now - new Date(lastFetched.additionalStats).getTime();
        const maxAge = 30 * 60 * 1000; // 30 minutes
        
        return analysisAge < maxAge && additionalStatsAge < maxAge;
    }, [lastFetched, developmentData, additionalData]);

    // Fetch development analysis and additional stats - no Redux
    useEffect(() => {
        const fetchData = async () => {
            if (!auditUuid || !auditAccessDetails) return;
            if (!auditAccessDetails.isPublic && !auditAccessDetails.hasAccess) return;
            if (dataFetched) return;

            // Check if we have valid cached data
            if (hasCachedData()) {
                console.log('Using cached data from local state');
                setGitStats(prevStats => ({
                    ...prevStats,
                    developmentAnalysis: developmentData,
                    ...additionalData
                }));
                setLoadingAnalysis(false);
                setLoadingAdditionalStats(false);
                setDataFetched(true);
                setLoading(false);
                return;
            }

            setLoading(true);
            setLoadingAdditionalStats(true);
            setLoadingAnalysis(true);

            try {
                // Otherwise fetch from API
                const [developmentAnalysisResponse, additionalStatsResponse] = await Promise.all([
                    axiosInstance.get(`get_git_development_analysis/${auditUuid}/`),
                    axiosInstance.get(`get_git_additional_stats/${auditUuid}/`)
                ]);

                let newGitStats = {};

                // Process development analysis
                if (developmentAnalysisResponse.data.status === 'success') {
                    const analysisData = developmentAnalysisResponse.data.data.development_analysis;
                    newGitStats.developmentAnalysis = analysisData;
                    
                    // Store in local state instead of Redux
                    setDevelopmentData(analysisData);
                    setLastFetched(prev => ({
                        ...prev,
                        developmentAnalysis: new Date().toISOString()
                    }));
                    
                    setLoadingAnalysis(false);
                }

                // Process additional stats
                if (additionalStatsResponse.data.status === 'success') {
                    const additionalResponseData = additionalStatsResponse.data.data;
                    const additionalStats = {
                        releaseStats: additionalResponseData.release_stats || null,
                        branchStats: additionalResponseData.branch_stats || null,
                        mergeStats: additionalResponseData.merge_stats || null,
                        metaInfo: additionalResponseData.meta_info || null,
                        repositoryInfo: additionalResponseData.repository_info || null
                    };
                    
                    newGitStats = {
                        ...newGitStats,
                        ...additionalStats
                    };
                    
                    // Store in local state instead of Redux
                    setAdditionalData(additionalStats);
                    setLastFetched(prev => ({
                        ...prev,
                        additionalStats: new Date().toISOString()
                    }));
                    
                    setLoadingAdditionalStats(false);
                }
                setGitStats(prevStats => ({
                    ...prevStats,
                    ...newGitStats
                }));
                setDataFetched(true);
            } catch (error) {
                console.error('Error fetching data:', error);
            } finally {
                setLoading(false);
            }
        };

        fetchData();
    }, [auditUuid, auditAccessDetails, hasCachedData, developmentData, additionalData]);

    // Always fetch core stats directly from API - never use Redux for this
    useEffect(() => {
        const fetchCoreStats = async () => {
            if (!auditUuid || !auditAccessDetails || !dataFetched) return;
            if (!auditAccessDetails.isPublic && !auditAccessDetails.hasAccess) return;

            setLoadingCoreStats(true);

            try {
                // Always fetch core stats from API, never from Redux
                const coreStatsResponse = await axiosInstance.get(`get_git_core_stats/${auditUuid}/`);
                
                // Process core stats
                if (coreStatsResponse.data.status === 'success') {
                    const coreData = coreStatsResponse.data.data;
                    const coreStats = {
                        timeBasedStats: coreData.time_based_stats || null,
                        authorStats: coreData.author_stats || null,
                        codeChurnStats: coreData.code_churn_stats || null,
                        collaborationStats: coreData.collaboration_stats || null,
                        developerExpertise: coreData.developer_expertise || null
                    };

                    
                    // Only update component state, not Redux
                    setGitStats(prevStats => ({
                        ...prevStats,
                        ...coreStats
                    }));
                }
            } catch (error) {
                console.error('Error fetching core stats:', error);
            } finally {
                setLoadingCoreStats(false);
            }
        };

        fetchCoreStats();
    }, [auditUuid, auditAccessDetails, dataFetched]);

    // Add a handler for file clicks
    const handleFileClick = (filename) => {
        const params = new URLSearchParams();
        params.append('search', filename);
        navigate(`/${auditUuid}/files?${params.toString()}`);
    };

    if (shouldRedirect) {
        return <Navigate to="/" />;
    }

    const LoadingSpinner = () => (
        <div className="loading-spinner-container">
            <ProgressSpinner style={{width: '30px', height: '30px'}} strokeWidth="3" />
        </div>
    );

    return (
        <PageLayout 
            headerProps={headerProps}
            loading={pageLoading}
            className="development-layout"
        >
            <div className="development-content">
                {!pageLoading && (
                    <>
                        {/* Development Summary Section */}
                        <div className="development-summary-card">
                            {analysisLoading ? <LoadingSpinner /> : (
                                <div className="development-summary-header">
                                    <h2 className="development-section-title">Development Overview</h2>
                                    <p className="development-section-subtitle">
                                        Analysis of repository development patterns and team collaboration metrics.
                                    </p>
                                </div>
                            )}
                            {gitStats.developmentAnalysis && (
                                <div className="development-summary-content">
                                    <div className="development-summary-analysis">
                                        <h3>Development Analysis</h3>
                                        <FormattedText text={gitStats.developmentAnalysis} />
                                    </div>
                                </div>
                            )}
                        </div>
                        
                        {/* Time-based Analytics Section */}
                        <div className="development-card">
                            <h3>Development Activity</h3>
                            <div className="development-stats-grid">
                                <div className="development-stat-item">
                                    <div className="development-stat-label-container">
                                        <span className="development-stat-label">Total Commits</span>
                                        <i className="pi pi-question-circle tooltip-icon" 
                                           data-pr-position="right"
                                           data-pr-tooltip={getTooltipDescription('totalCommits')} />
                                    </div>
                                    <StatValue 
                                        loading={coreStatsLoading}
                                        value={formatNumber(gitStats?.timeBasedStats?.commit_frequency?.total_commits || 0)}
                                    />
                                </div>
                                <div className="development-stat-item">
                                    <div className="development-stat-label-container">
                                        <span className="development-stat-label">Active Days</span>
                                        <i className="pi pi-question-circle tooltip-icon" 
                                           data-pr-position="right"
                                           data-pr-tooltip={getTooltipDescription('activeDays')} />
                                    </div>
                                    <StatValue 
                                        loading={coreStatsLoading}
                                        value={formatNumber(gitStats?.timeBasedStats?.commit_frequency?.days_active || 0)}
                                    />
                                </div>
                                <div className="development-stat-item">
                                    <div className="development-stat-label-container">
                                        <span className="development-stat-label">Avg. Commits/Day</span>
                                        <i className="pi pi-question-circle tooltip-icon" 
                                           data-pr-position="right"
                                           data-pr-tooltip={getTooltipDescription('avgCommitsDay')} />
                                    </div>
                                    <StatValue 
                                        loading={coreStatsLoading}
                                        value={formatNumber(parseFloat(gitStats?.timeBasedStats?.commit_frequency?.avg_commits_per_day?.toFixed(2) || 0))}
                                    />
                                </div>
                            </div>
                            {gitStats?.timeBasedStats && (
                                <div className="development-activity-section">
                                    <h4 className="development-activity-title">Developer Contributions Over Time</h4>
                                    <p className="development-activity-subtitle">
                                        Track the development activity of top contributors and see how their contributions have evolved over time.
                                    </p>
                                    <DevelopmentActivityGraph gitStats={gitStats} auditUuid={auditUuid} />
                                </div>
                            )}
                        </div>

                        {/* Code Churn Section */}
                        <div className="development-card">
                            <h3>Code Changes</h3>
                            <div className="code-changes-container">
                                <div className="code-changes-metrics">
                                    <div className="development-stats-grid">
                                        <div className="development-stat-item">
                                            <div className="development-stat-label-container">
                                                <span className="development-stat-label">Total Additions</span>
                                                <i className="pi pi-question-circle tooltip-icon" 
                                                   data-pr-position="right"
                                                   data-pr-tooltip={getTooltipDescription('totalAdditions')} />
                                            </div>
                                            <StatValue 
                                                loading={coreStatsLoading}
                                                value={formatNumber(gitStats?.codeChurnStats?.overall_churn?.total_additions || 0)}
                                                className="additions"
                                            />
                                        </div>
                                        <div className="development-stat-item">
                                            <div className="development-stat-label-container">
                                                <span className="development-stat-label">Total Deletions</span>
                                                <i className="pi pi-question-circle tooltip-icon" 
                                                   data-pr-position="right"
                                                   data-pr-tooltip={getTooltipDescription('totalDeletions')} />
                                            </div>
                                            <StatValue 
                                                loading={coreStatsLoading}
                                                value={formatNumber(gitStats?.codeChurnStats?.overall_churn?.total_deletions || 0)}
                                                className="deletions"
                                            />
                                        </div>
                                        <div className="development-stat-item">
                                            <div className="development-stat-label-container">
                                                <span className="development-stat-label">Modified Files</span>
                                                <i className="pi pi-question-circle tooltip-icon" 
                                                   data-pr-position="right"
                                                   data-pr-tooltip={getTooltipDescription('modifiedFiles')} />
                                            </div>
                                            <StatValue 
                                                loading={coreStatsLoading}
                                                value={formatNumber(gitStats?.codeChurnStats?.file_stability?.total_files_modified || 0)}
                                            />
                                        </div>
                                    </div>
                                    
                                    {selectedFile && (
                                        <div className="file-details-container">
                                            <div className="file-details">
                                                <h4>
                                                    <span 
                                                        className="file-name-link"
                                                        onClick={() => handleFileClick(selectedFile.name)}
                                                    >
                                                        {selectedFile.name}
                                                    </span>
                                                </h4>
                                                <div className="file-stats">
                                                    <div className="stat-item">
                                                        <span className="stat-label">Domain</span>
                                                        <span className="stat-value">{selectedFile.domain}</span>
                                                    </div>
                                                    <div className="stat-item">
                                                        <span className="stat-label">Changes</span>
                                                        <span className="stat-value">{selectedFile.stats.total_changes}</span>
                                                    </div>
                                                    <div className="stat-item">
                                                        <span className="stat-label">Modified</span>
                                                        <span className="stat-value">{selectedFile.stats.times_modified} times</span>
                                                    </div>
                                                    <div className="stat-bar">
                                                        <div className="additions" style={{
                                                            width: `${(selectedFile.stats.total_additions / selectedFile.stats.total_changes) * 100}%`
                                                        }}/>
                                                        <div className="deletions" style={{
                                                            width: `${(selectedFile.stats.total_deletions / selectedFile.stats.total_changes) * 100}%`
                                                        }}/>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    )}
                                </div>
                                <div className="code-changes-visualization">
                                    <div className="visualization-description">
                                        <h4>Code Hotspots</h4>
                                        <p>
                                            This visualization shows the most actively modified files in your codebase. 
                                            The size of each bubble represents the file's hotspot score, calculated from 
                                            both the number of changes and frequency of modifications. Colors indicate 
                                            different domains. <strong style={{fontSize: '0.875rem'}}>Click on any bubble to see detailed statistics.</strong>
                                        </p>
                                    </div>
                                    <HotspotsBubbleChart 
                                        hotspots={gitStats?.codeChurnStats?.hotspots?.most_modified_files || []} 
                                        onBubbleSelect={setSelectedFile}
                                    />
                                </div>
                            </div>
                        </div>

                        {/* Additional Stats Sections */}
                        <div className="additional-stats-section">
                            {additionalStatsLoading ? <LoadingSpinner /> : (
                                <>
                                    {/* ... existing additional stats sections ... */}
                                </>
                            )}
                        </div>
                    </>
                )}
            </div>
        </PageLayout>
    );
}