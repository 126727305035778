import { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useLocation } from 'react-router-dom';
import { checkAuditStatus, selectAuditAccess } from '../features/audits/auditsSlice';
import { selectAuth } from '../features/user/userSlice';

export const useAuditAccess = (auditUuid) => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const location = useLocation();
    const { isLoggedIn } = useSelector(selectAuth);
    const auditAccess = useSelector(state => selectAuditAccess(state, auditUuid));
    const [isChecking, setIsChecking] = useState(true);

    const accessState = useMemo(() => {
        const state = {
            ...auditAccess,
            canAccess: auditAccess.isPublic || (isLoggedIn && auditAccess.hasAccess) || auditAccess.isAdmin
        };
        return state;
    }, [auditAccess, isLoggedIn]);

    useEffect(() => {
        let isMounted = true;

        const checkAccess = async () => {
            if (!auditUuid) {
                setIsChecking(false);
                return;
            }

            try {
                const resultAction = await dispatch(checkAuditStatus(auditUuid)).unwrap();
                
                if (!isMounted) return;

                // Don't redirect for public audits
                if (resultAction.isPublic) {
                    setIsChecking(false);
                    return;
                }

                // Only handle redirects for private audits
                const hasValidAccess = isLoggedIn && resultAction.hasAccess;
                if (!hasValidAccess) {
                    if (!isLoggedIn) {
                        navigate('/login', { state: { from: location.pathname } });
                    } else {
                        navigate('/dashboard');
                    }
                }
            } catch (error) {
                console.error('useAuditAccess: Error checking access', error);
                if (error?.response?.status !== 401) {
                    navigate('/error');
                }
            } finally {
                if (isMounted) {
                    setIsChecking(false);
                }
            }
        };

        checkAccess();

        return () => {
            isMounted = false;
        };
    }, [auditUuid, isLoggedIn, dispatch, navigate, location?.pathname]);

    return { ...accessState, isChecking };
}; 