// files.js
import React, { useState, useEffect, useRef } from 'react';
import axiosInstance from '../axiosConfig';
import { TreeTable } from 'primereact/treetable';
import { Column } from 'primereact/column';
import { useParams, Navigate } from 'react-router-dom';
import { MultiSelect } from 'primereact/multiselect';
import { Editor } from 'primereact/editor';
import { useDispatch, useSelector } from 'react-redux'; // Import Redux hooks
import { checkAuditStatus } from '../utils/Actions'; // Correct import path
import FileDetailSidebar from './fileDetailSidebar';
import FilesStatistics from './files_component/FilesStatistics';
import PageLayout from '../components/PageLayout';
import useAuditDetails from '../utils/useAuditDetails';

import './Files.css';

export default function FilesView() {

    const dispatch = useDispatch();
    const isMounted = useRef(true);
    const [shouldRedirect, setShouldRedirect] = useState(false);

    const [treeData, setTreeData] = useState([]);
    const [loading, setLoading] = useState(false);
    const { auditUuid } = useParams();
    const auditAccessDetails = useSelector(state => state.audits[auditUuid]); // Redux state for access control

    const [isDataLoaded, setIsDataLoaded] = useState(false);
    const [selectedNodeKey, setSelectedNodeKey] = useState(null);
    const [editorContent, setEditorContent] = useState('');

    const [sidebarVisible, setSidebarVisible] = useState(false);
    const [selectedFileDetails, setSelectedFileDetails] = useState({});
    const [expandedRows, setExpandedRows] = useState({});
    const [isAttributeSelectionVisible, setIsAttributeSelectionVisible] = useState(false);
    const [treeTableLoading, setTreeTableLoading] = useState(false);
  

    const fileDetailAttributes = {
    "Code Quality": ["readability", "consistency", "modularity", "maintainability", "reusability", "redundancy", "technical_debt", "code_smells"],
    "Functionality": ["completeness", "edge_cases", "error_handling"],
    "Performance & Scalability": ["efficiency", "scalability", "resource_utilization", "load_handling", "parallel_processing", "database_interaction_efficiency", "concurrency_management", "state_management_efficiency", "modularity_decoupling", "configuration_customization_ease"],
    "Security": ["input_validation", "data_handling", "authentication", "package_dependencies", "flag_color"],
    "Compatibility": ["independence", "integration"],
    "Documentation": ["inline_comments"],
    "Coding Standards": ["standards", "design_patterns", "code_complexity", "refactoring_opportunities"]
    };
  
    const initialSelectedAttributes = {
    "Code Quality": [],
    "Functionality": [],
    "Performance & Scalability": [],
    "Security": [],
    "Compatibility": [],
    "Documentation": [],
    "Coding Standards": []
    };

    const [selectedAttributes, setSelectedAttributes] = useState(initialSelectedAttributes);

    // Use the auditDetails hook
    const { 
        auditDetails, 
        headerProps, 
        loading: auditLoading, 
        isDataLoaded: isAuditDataLoaded 
    } = useAuditDetails(auditUuid);

    // Check access and redirect if needed
    useEffect(() => {
        if (!auditAccessDetails) {
            console.log("Audit details are not available yet.");
        } else if (auditAccessDetails.error) {
            console.error('Audit access error:', auditAccessDetails.error);
            setShouldRedirect(true);
        } else if (!auditAccessDetails.isPublic && !auditAccessDetails.hasAccess) {
            setShouldRedirect(true);
        } else {
            setShouldRedirect(false);
        }
    }, [auditAccessDetails]);

    // Check audit status
    useEffect(() => {
        const checkStatus = async () => {
            if (!auditUuid) return;
            try {
                await dispatch(checkAuditStatus(auditUuid));
            } catch (error) {
                console.error('Error checking audit status:', error);
            }
        };
        checkStatus();
    }, [auditUuid, dispatch]);

    // Fetch files data
    useEffect(() => {
        let isActive = true;

        const fetchFilesData = async () => {
            if (!auditUuid || !isAuditDataLoaded || !auditDetails) return;

            try {
                setTreeTableLoading(true);
                const filesResponse = await axiosInstance.get(`api/get_initial_treetable/${auditUuid}/`);

                if (!isActive) return;

                const files = filesResponse.data;
                const formattedData = formatAndSortData(files);

                const filesWithDetails = await Promise.all(formattedData.map(file => {
                    if (!file.children || file.children.length === 0) {
                        const filePath = encodeURIComponent(file.data.file_path);
                        return axiosInstance.get(`api/file_detail/${auditUuid}/${filePath}/`)
                            .then(res => ({
                                ...file,
                                data: {
                                    ...file.data,
                                    size: res.data.lines_of_code + res.data.lines_of_doc,
                                    type: res.data.file_type,
                                    ...res.data
                                }
                            }))
                            .catch(error => {
                                console.error('Error fetching file details:', error);
                                return file;
                            });
                    }
                    return Promise.resolve(file);
                }));

                setTreeData(filesWithDetails);
                setIsDataLoaded(true);
            } catch (error) {
                console.error('Error fetching files data:', error);
            } finally {
                if (isActive) {
                    setTreeTableLoading(false);
                }
            }
        };

        fetchFilesData();

        return () => {
            isActive = false;
        };
    }, [auditUuid, isAuditDataLoaded, auditDetails]);

    useEffect(() => {
        let isActive = true;

        const fetchComments = async () => {
            if (!auditUuid || !auditAccessDetails) return;
            
            if (!auditAccessDetails.isPublic && !auditAccessDetails.hasAccess) {
                return;
            }

            try {
                const response = await axiosInstance.get(`api/get_file_selection_comments/${auditUuid}/`);
                
                if (!isActive) return;
                
                if (response.data && response.data.comments.length > 0) {
                    setEditorContent(response.data.comments[0]);
                    console.log("File comments:", response.data.comments[0]);
                } else {
                    setEditorContent('');
                }
            } catch (error) {
                console.error('Error fetching file comments:', error);
            }
        };

        fetchComments();

        return () => {
            isActive = false;
        };
    }, [auditUuid, auditAccessDetails]);

    if (shouldRedirect) {
        return <Navigate to="/" />;
    }

    const formatAndSortData = (data, basePath = '') => {
        // Input validation
        if (!Array.isArray(data)) {
            console.error('Invalid data format received:', data);
            return [];
        }

        // Special files that should appear at the top (similar to GitHub)
        const specialFiles = [
            'README.md',
            'LICENSE',
            'package.json',
            'tsconfig.json',
            '.gitignore',
            '.npmignore'
        ];

        const getFileWeight = (name) => {
            const index = specialFiles.indexOf(name);
            return index === -1 ? specialFiles.length : index;
        };

        const sortNodes = (nodes) => {
            return nodes.sort((a, b) => {
                try {
                    const aName = (a.data.name || '').toLowerCase();
                    const bName = (b.data.name || '').toLowerCase();
                    const aIsFolder = a.data.type === 'Folder';
                    const bIsFolder = b.data.type === 'Folder';

                    // If both are folders or both are files, sort by name
                    if (aIsFolder === bIsFolder) {
                        // If they're files, check if they're special files
                        if (!aIsFolder) {
                            const aWeight = getFileWeight(a.data.name);
                            const bWeight = getFileWeight(b.data.name);
                            if (aWeight !== bWeight) {
                                return aWeight - bWeight;
                            }
                        }
                        return aName.localeCompare(bName);
                    }

                    // If one is a folder and one is a file, folders come first
                    return aIsFolder ? -1 : 1;
                } catch (error) {
                    console.error('Error sorting nodes:', error, { a, b });
                    return 0;
                }
            });
        };

        return sortNodes(data.map(node => {
            if (!node || typeof node !== 'object') {
                console.warn('Invalid node format:', node);
                return null;
            }

            try {
                // Determine if it's a folder based on type or children
                const isFolder = node.data.type === 'Folder' || 
                               (Array.isArray(node.children) && node.children.length > 0);

                const processedNode = {
                    ...node,
                    key: node.key || encodeURIComponent(node.data.fullPath.replace(/^\//, '')),
                    data: {
                        ...node.data,
                        isFolder: isFolder,
                        // Ensure required properties exist
                        name: node.data.name || '',
                        fullPath: node.data.fullPath || '',
                        file_path: node.data.file_path || null,
                        // Make sure type is preserved for folders
                        type: isFolder ? 'Folder' : (node.data.type || 'file'),
                        // Add default values for size if they don't exist yet
                        size: node.data.size || 0
                    }
                };

                // Recursively format and sort children if they exist
                if (node.children) {
                    processedNode.children = formatAndSortData(node.children, node.data.fullPath);
                }

                return processedNode;
            } catch (error) {
                console.error('Error processing node:', error, node);
                return null;
            }
        })
        .filter(Boolean)); // Remove any null entries from invalid nodes
    };

    const renderAttributeSelection = () => {
        return (
            <div className="attribute-selection-container">
                <div 
                    className="attribute-selection-header" 
                    onClick={() => setIsAttributeSelectionVisible(!isAttributeSelectionVisible)}
                >
                    <i className={`pi ${isAttributeSelectionVisible ? 'pi-minus' : 'pi-plus'}`}></i>
                    <span className="adjust-table-title">Add data columns</span>
                </div>
                <div className={`attribute-selection-content ${isAttributeSelectionVisible ? 'expanded' : ''}`}>
                    <div className="adjust-table-text">You can adjust the data shown in your file table. Simply select additional fields.</div>
                    <div className="adjust-table-container">
                        <div className="adjust-table-content">
                            {Object.entries(fileDetailAttributes).map(([category, attributes]) => (
                                <div key={category} className="attribute-dropdown">
                                    <span className="p-float-label w-full">
                                        <MultiSelect 
                                            value={selectedAttributes[category]} 
                                            options={attributes.map(attr => ({ label: attr, value: attr }))}
                                            onChange={(e) => handleAttributeSelectionChange(e, category)}
                                            optionLabel="label"
                                            maxSelectedLabels={3}
                                            className="w-full"
                                            id={`ms-${category.replace(/\s+/g, '')}`}
                                        />
                                        <label htmlFor={`ms-${category.replace(/\s+/g, '')}`} className="custom-multiselect-label">
                                            {category}
                                        </label>
                                    </span>
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
            </div>
        );
    };

    const handleAttributeSelectionChange = (e, category) => {
        setSelectedAttributes({
            ...selectedAttributes,
            [category]: e.value
        });
    };

    const renderReadOnlyEditor = () => {
        if (!editorContent) return null;
    
        return (
            <div className="file_comment_card">
                <span className="file_selection_title">Comments on file selection</span>
                <div 
                    className="editor-readonly-content" 
                    dangerouslySetInnerHTML={{ __html: editorContent }}
                    style={{ minHeight: '320px', border: '1px solid #ccc', padding: '1rem', borderRadius: '4px' }}
                />
            </div>
        );
    };

    // Recursive utility function to update node children by key
    const updateNodeChildren = (nodes, key, newChildren) => {
        return nodes.map(node => {
            if (node.key === key) {
                // Preserve existing children that are already expanded
                const existingExpandedChildren = node.children
                    ? node.children.filter(child => 
                        child.data.type === 'folder' && expandedRows[child.key]
                    )
                    : [];

                // Merge existing expanded children with new children
                const mergedChildren = [...newChildren];
                existingExpandedChildren.forEach(existingChild => {
                    const index = mergedChildren.findIndex(
                        newChild => newChild.key === existingChild.key
                    );
                    if (index !== -1) {
                        mergedChildren[index] = {
                            ...existingChild,
                            ...mergedChildren[index],
                            children: existingChild.children // Preserve existing children
                        };
                    }
                });

                return { ...node, children: mergedChildren };
            }
            if (node.children) {
                return {
                    ...node,
                    children: updateNodeChildren(node.children, key, newChildren)
                };
            }
            return node;
        });
    };

    const renderColumns = () => {
        const columns = [
            <Column key="audited" header="Audited" body={node => (
                node.data.selected_for_audit ? 
                <i className="pi pi-eye" style={{ color: '#2CB392' }}></i> : 
                null
            )} style={{ width: '90px', minWidth: '90px' }} />,
            <Column key="name" field="name" header="Name" expander body={node => (
                node.data.type === 'folder' ? <span>{node.data.name}</span> : <span className="file-name">{node.data.name}</span>
            )} style={{ minWidth: '200px' }} />,
            <Column key="size" field="size" header="Lines of Code" body={node => (
                <span>{node.data.size || 0}</span>
            )} style={{ minWidth: '100px' }} />,
            <Column key="type" field="type" header="Type" body={(node) => 
                node.data.type === 'folder' ? 'Folder' : node.data.type
            } style={{ minWidth: '100px' }}/>
        ];
    
        // Add dynamic columns based on selected attributes
        const allSelectedAttributes = Object.values(selectedAttributes).flat();
        allSelectedAttributes.forEach(attribute => {
            columns.push(<Column key={attribute} field={attribute} header={attribute} style={{ minWidth: '150px' }} />);
        });
    
        return columns;
    };

    const onSelect = (event) => {
        const node = event.node;
        if (node.data.isFolder) {
            // If it's a folder, update its expanded state in the expandedRows
            const newExpandedRows = { ...expandedRows };
            if (newExpandedRows[node.key]) {
                delete newExpandedRows[node.key]; // Collapse if it was expanded
            } else {
                newExpandedRows[node.key] = true; // Expand if it was collapsed
            }
            setExpandedRows(newExpandedRows);
        } else {
            // If it's a file, fetch its details and open the sidebar
            fetchFileDetails(node);
            setSelectedFileDetails(node.data);
            setSidebarVisible(true);
        }
    };

    const onUnselect = () => {
        setSidebarVisible(false);;
    };

    const fetchFileDetails = async (node) => {
        if (!node || !node.data || !node.data.file_path) {
            console.warn('Invalid node or missing file path:', node);
            return node;
        }

        try {
            const filePath = encodeURIComponent(node.data.file_path);
            const response = await axiosInstance.get(`api/file_detail/${auditUuid}/${filePath}/`);
            
            // Return updated node with fetched details
            return {
                ...node,
                data: {
                    ...node.data,
                    size: response.data.lines_of_code + response.data.lines_of_doc,
                    type: response.data.file_type,
                    detailsFetched: true, // Mark that we've fetched details
                    ...response.data
                }
            };
        } catch (error) {
            console.error('Error fetching file details:', error);
            return node; // Return original node if fetch fails
        }
    };

    const onExpand = async (event) => {
        const node = event.node;
        if (!node.data.type === 'folder') return;

        try {
            setTreeTableLoading(true);
            
            // Use the file_path instead of fullPath for the folder
            const folderPath = encodeURIComponent(node.data.file_path || node.data.fullPath);
            console.log('Expanding folder with path:', folderPath); // Debug log
            
            const response = await axiosInstance.get(`api/folder_files/${auditUuid}/`, {
                params: {
                    folder_path: folderPath
                }
            });
            
            // If we get an empty array, we should keep the existing children
            const responseData = response.data;
            if (!responseData || !Array.isArray(responseData)) {
                console.error('Invalid response data:', responseData);
                return;
            }

            // If the response is empty, use the existing children
            const childrenToUse = responseData.length === 0 ? (node.children || []) : responseData;

            // Fetch details for immediate child files
            const childrenWithDetails = await Promise.all(
                childrenToUse.map(async (child) => {
                    if (child.data.type !== 'folder' && child.data.file_path) {
                        try {
                            const filePath = encodeURIComponent(child.data.file_path);
                            const detailsResponse = await axiosInstance.get(`api/file_detail/${auditUuid}/${filePath}/`);
                            return {
                                ...child,
                                data: {
                                    ...child.data,
                                    ...detailsResponse.data,
                                    detailsFetched: true
                                }
                            };
                        } catch (error) {
                            console.error('Error fetching file details:', error);
                            return child;
                        }
                    }
                    return child;
                })
            );

            // Format the received data
            const formattedData = formatAndSortData(childrenWithDetails);

            // Update the tree data with the new children while maintaining the tree structure
            const updatedNodes = updateNodeChildren(treeData, node.key, formattedData);
            setTreeData(updatedNodes);

            // Update expanded rows state
            const newExpandedRows = { ...expandedRows };
            newExpandedRows[node.key] = true;  // Always set to true when expanding
            setExpandedRows(newExpandedRows);

        } catch (error) {
            console.error('Error expanding folder:', error);
        } finally {
            setTreeTableLoading(false);
        }
    };

    return (
        <PageLayout 
            headerProps={headerProps}
            loading={loading || auditLoading}
            className="files-layout"
        >
            <div className="files-container">
                {!(loading || auditLoading) && (
                    <>
                        <div className="files-header">
                            <div className="files-statistics-section">
                                <FilesStatistics />
                            </div>
                        </div>
                        <div className="files-content">
                            <FileDetailSidebar 
                                visible={sidebarVisible} 
                                onClose={() => setSidebarVisible(false)} 
                                fileDetails={selectedFileDetails}
                            />
                            
                            <div className="attribute-selection-wrapper">
                                <h2 className="score-distribution-title">File directory</h2>
                                <p className="score-distribution-subtitle">Here you can review the files as they were in the repository.</p>
                                <p className="score-distribution-subtitle">You can adjust the data shown in your file table. Simply select additional fields.</p>
                                {renderAttributeSelection()}
                                {treeData.length === 0 && (
                                    <div>No files available</div>
                                )}
                                <div className="treetable-container">
                                <TreeTable 
                                    value={treeData} 
                                    lazy={true}
                                    onExpand={onExpand} 
                                    loading={loading} 
                                    selectionMode="single" 
                                    selectionKeys={selectedNodeKey} 
                                    expandedKeys={expandedRows}
                                    onSelectionChange={(e) => setSelectedNodeKey(e.value)} 
                                    metaKeySelection={false} 
                                    onSelect={onSelect} 
                                    onUnselect={onUnselect} 
                                >
                                {renderColumns()}
                                </TreeTable>
                                {treeTableLoading && (
                                    <div className="treetable-loading-overlay"></div>
                                )}
                                </div>
                            </div>
                        </div>
                        {renderReadOnlyEditor()}
                    </>
                )}
            </div>
        </PageLayout>
    );
}

