import React, { useEffect, useRef, useCallback, useMemo } from 'react';
import * as d3 from 'd3';
import { Button } from 'primereact/button';
import './DependenciesGraph.css';

const DependenciesGraph = ({ dependencyData }) => {
  // Debug props only once on initial mount
  const hasLoggedRef = useRef(false);
  useEffect(() => {
    if (!hasLoggedRef.current) {
      hasLoggedRef.current = true;
    }
  }, [dependencyData]);
  
  // Add a ref to track resize events
  const resizeKeyRef = useRef(Date.now().toString());
  
  // Add a ref to track if any node is being dragged
  const isDraggingRef = useRef(false);
  
  // Add a ref to track the last click time for cooldown
  const lastClickTimeRef = useRef(0);
  
  // Determine which data structure to use
  const graphData = useMemo(() => {
    // Check if we have the dependencyData format with dependency_graph property
    if (dependencyData && dependencyData.dependency_graph) {
      return dependencyData.dependency_graph;
    }
    
    // Check if the dependencyData itself is the graph structure
    if (dependencyData && (dependencyData.nodes || dependencyData.edges)) {
      return dependencyData;
    }
    
    // Default empty structure
    return { nodes: [], edges: [] };
  }, [dependencyData]);

  const graphRef = useRef(null);
  const svgRef = useRef(null);
  const simulationRef = useRef(null);
  const nodesRef = useRef([]);
  const linksRef = useRef([]);
  const linkedByIndexRef = useRef({});
  const nodeGroupsRef = useRef(null); // New ref to store node groups
  const linkElementsRef = useRef(null); // New ref to store link elements
  
  // Flag to track if simulation has been stopped
  const simulationStoppedRef = useRef(false);
  
  // Use ref for selected node instead of state to avoid re-renders
  const selectedNodeRef = useRef(null);
  const detailsPanelRef = useRef(null);
  
  // Define domain colors
  const domainColors = useMemo(() => ({
    frontend: '#6a89cc',
    backend: '#eb4d4b',
    database: '#f6b93b',
    infrastructure: '#4a69bd',
    testing: '#78e08f',
    utility: '#aaa69d',
    unknown: '#b8b5ff'
  }), []);
  
  // Categorize file extensions by domain
  const fileExtensions = useMemo(() => ({
    frontend: ['js', 'jsx', 'ts', 'tsx', 'html', 'css', 'scss', 'vue', 'svelte'],
    backend: ['py', 'java', 'rb', 'php', 'go', 'cs', 'rs', 'ex', 'exs', 'erl', 'scala', 'm'],
    database: ['sql', 'prisma', 'gql', 'graphql'],
    infrastructure: ['yml', 'yaml', 'tf', 'dockerfile', 'docker-compose', 'sh', 'bash'],
    testing: ['spec.js', 'test.js', 'spec.ts', 'test.ts', 'spec.jsx', 'test.jsx']
  }), []);

  // Helper function to determine file domain based on extension
  const getFileDomain = useCallback((filename) => {
    if (!filename) return 'unknown';
    
    const extension = filename.split('.').pop().toLowerCase();
    
    // Check domain by extension
    for (const [domain, extensions] of Object.entries(fileExtensions)) {
      if (extensions.includes(extension)) {
        return domain;
      }
    }
    
    // Check for testing files by name pattern
    if (filename.includes('test') || filename.includes('spec')) {
      return 'testing';
    }
    
    return 'utility';
  }, [fileExtensions]);
  
  // Helper function to determine node size based on importance/complexity
  const getNodeSize = useCallback((node) => {
    if (node.type === 'package') {
      // Use times_used for package size
      return Math.max(30, Math.min(80, 30 + (node.times_used || 1) * 2));
    } else {
      // For files, use a fixed size or calculate based on available data (lines of code, etc.)
      return 25; // Default file size
    }
  }, []);
  
  // Helper function to determine node color based on type or status
  const getNodeColor = useCallback((node) => {
    if (node.type === 'package') {
      // Color packages based on status
      switch (node.status) {
        case 'active':
          return '#4caf50';
        case 'deprecated':
          return '#f44336';
        case 'maintained':
          return '#2196f3';
        case 'unmaintained':
          return '#ff9800';
        case 'built-in':
          return '#9c27b0'; // Purple for built-in modules
        default:
          return '#9e9e9e';
      }
    } else {
      // Color files based on domain
      const domain = getFileDomain(node.name || node.file_path);
      return domainColors[domain] || domainColors.unknown;
    }
  }, [getFileDomain, domainColors]);
  
  // Helper function to clean package names (remove registry prefixes)
  const cleanPackageName = useCallback((name) => {
    if (!name) return 'Unknown';
    return name.replace(/@.*\//, '').replace(/\$\![a-z]+\$!\_/i, '');
  }, []);
  
  // Helper function to extract clean filename from paths
  const getCleanFilename = useCallback((path) => {
    if (!path) return 'Unknown';
    
    // Extract the filename from the path
    const parts = path.split(/[\/\\]/); // Split by both forward and backward slashes
    const filename = parts[parts.length - 1];
    
    // Handle special compound formats like .test.js, .spec.ts, etc.
    // This preserves the full filename rather than just the extension
    return filename;
  }, []);
  
  // Calculate dependency count for edge thickness
  const getDependencyCount = useCallback((source, target) => {
    return 1; // Default value, can be calculated based on actual data
  }, []);
  
  // Helper function to stop simulation
  const stopSimulation = useCallback(() => {
    if (simulationRef.current && !simulationStoppedRef.current) {
      simulationRef.current.stop();
      simulationStoppedRef.current = true;
      
      // Fix node positions to prevent movement
      if (nodesRef.current.length > 0) {
        nodesRef.current.forEach(node => {
          // Fix all nodes in their current positions
          node.fx = node.x;
          node.fy = node.y;
        });
      }
    }
  }, []);
  
  // Helper function to update the positions of nodes and links in the DOM
  // without restarting the simulation
  const updateNodePositions = useCallback(() => {
    if (!nodeGroupsRef.current || !linkElementsRef.current) return;
    
    try {
      // Update links - handle both object and primitive source/target formats
      linkElementsRef.current
        .attr('x1', d => {
          if (!d.source) return 0;
          return typeof d.source === 'object' ? d.source.x || 0 : 0;
        })
        .attr('y1', d => {
          if (!d.source) return 0;
          return typeof d.source === 'object' ? d.source.y || 0 : 0;
        })
        .attr('x2', d => {
          if (!d.target) return 0;
          return typeof d.target === 'object' ? d.target.x || 0 : 0;
        })
        .attr('y2', d => {
          if (!d.target) return 0;
          return typeof d.target === 'object' ? d.target.y || 0 : 0;
        });
      
      // Update nodes
      nodeGroupsRef.current
        .attr('transform', d => {
          const x = d.x || 0;
          const y = d.y || 0;
          return `translate(${x},${y})`;
        });
    } catch (error) {
      console.error('Error updating node positions:', error);
    }
  }, []);
  
  // Helper function to reset highlighted nodes
  const resetHighlight = useCallback(() => {
    if (!svgRef.current) return;
    
    // Reset all node styles
    d3.selectAll('.node')
      .attr('fill-opacity', 0.5)
      .style('filter', 'none')
      .classed('selected', false);
    
    // Reset all link styles
    d3.selectAll('.link')
      .style('stroke-opacity', 0.3)
      .style('stroke-width', 1)
      .classed('highlighted', false);
      
    // Hide all text backgrounds
    d3.selectAll('.text-background')
      .style('opacity', 0);
  }, []);
  
  // Function to update the details panel when a node is selected
  // This avoids using React state updates which would trigger re-renders
  const updateDetailsPanel = useCallback((nodeData) => {
    selectedNodeRef.current = nodeData;
    
    if (!detailsPanelRef.current) {
      return;
    }
    
    try {
      if (!nodeData) {
        // Display a placeholder message when no node is selected
        detailsPanelRef.current.innerHTML = `
          <div class="details-placeholder">
            <div class="details-placeholder-icon">
              <i class="pi pi-info-circle"></i>
            </div>
            <div class="details-placeholder-text">
              Select a node in the graph to reveal more details
            </div>
          </div>
        `;
        return;
      }
      
      const isPackage = nodeData.type === 'package';
      const name = isPackage 
        ? cleanPackageName(nodeData.name || nodeData.display_name) 
        : getCleanFilename(nodeData.name || nodeData.file_path);
        
      let html = `
        <h3>
          ${isPackage ? 'Package' : 'File'}: 
          <span class="details-name ${isPackage ? '' : (nodeData.selected_for_audit ? 'file-selected' : 'file-not-selected')}">
            ${isPackage ? name : (
              nodeData.selected_for_audit 
              ? `<a href="#" class="file-link" data-path="${nodeData.file_path || ''}">${name}</a>` 
              : name
            )}
          </span>
        </h3>
        <div class="details-content">
      `;
      
      if (isPackage) {
        html += `
          <div class="details-row">
            <div class="details-label">Status:</div>
            <div class="details-value status-${nodeData.status || 'unknown'}">
              ${nodeData.status || 'Unknown'}
            </div>
          </div>
          <div class="details-row">
            <div class="details-label">Used:</div>
            <div class="details-value">${nodeData.times_used || 0} times</div>
          </div>
        `;
        
        if (nodeData.version) {
          html += `
            <div class="details-row">
              <div class="details-label">Version:</div>
              <div class="details-value">${nodeData.version || 'N/A'}</div>
            </div>
          `;
        }
        
        if (nodeData.description) {
          html += `
            <div class="details-row">
              <div class="details-label">Description:</div>
              <div class="details-value" style="white-space: normal; max-width: 100%">
                ${(nodeData.description || '').replace(/</g, '&lt;').replace(/>/g, '&gt;')}
              </div>
            </div>
          `;
        }
      } else {
        // If it's a file, show the full path
        html += `
          <div class="details-row">
            <div class="details-label">Domain:</div>
            <div class="details-value">
              ${getFileDomain(nodeData.name || nodeData.file_path)}
            </div>
          </div>
        `;
        
        if (nodeData.script_purpose) {
          html += `
            <div class="details-row">
              <div class="details-label">Purpose:</div>
              <div class="details-value" style="white-space: normal; max-width: 100%">
                ${(nodeData.script_purpose || '').replace(/</g, '&lt;').replace(/>/g, '&gt;')}
              </div>
            </div>
          `;
        }
        
        // Add selected_for_audit status
        html += `
          <div class="details-row">
            <div class="details-label">Selected:</div>
            <div class="details-value">
              ${nodeData.selected_for_audit ? 'Yes' : 'No'}
            </div>
          </div>
        `;
      }
      
      // Count dependencies
      const dependenciesCount = linksRef.current.filter(link => {
        const sourceId = typeof link.source === 'object' ? link.source.id : link.source;
        const nodeId = nodeData.id || nodeData.index;
        return sourceId === nodeId;
      }).length;
      
      // Count dependents
      const dependentsCount = linksRef.current.filter(link => {
        const targetId = typeof link.target === 'object' ? link.target.id : link.target;
        const nodeId = nodeData.id || nodeData.index;
        return targetId === nodeId;
      }).length;
      
      html += `
        <div class="details-row">
          <div class="details-label">Dependencies:</div>
          <div class="details-value">${dependenciesCount}</div>
        </div>
        <div class="details-row">
          <div class="details-label">Dependents:</div>
          <div class="details-value">${dependentsCount}</div>
        </div>
      `;
      
      html += '</div>'; // Close details-content
      
      // Update HTML content safely
      if (detailsPanelRef.current) {
        detailsPanelRef.current.innerHTML = html;
        
        // Add event listener for file links after DOM is updated
        setTimeout(() => {
          if (detailsPanelRef.current) {
            const fileLinks = detailsPanelRef.current.querySelectorAll('.file-link');
            fileLinks.forEach(link => {
              link.addEventListener('click', (e) => {
                e.preventDefault();
                const filePath = link.getAttribute('data-path');
                // Here you could add code to navigate to the file or show it in a modal
              });
            });
          }
        }, 0);
      }
    } catch (error) {
      console.error("Error updating details panel:", error);
      
      // Provide fallback content in case of error
      if (detailsPanelRef.current) {
        detailsPanelRef.current.innerHTML = `
          <div class="details-placeholder">
            <div class="details-placeholder-icon">
              <i class="pi pi-exclamation-triangle" style="color: #e24c4c"></i>
            </div>
            <div class="details-placeholder-text">
              Error displaying node details
            </div>
          </div>
        `;
      }
    }
  }, [cleanPackageName, getFileDomain, getCleanFilename]);
  
  // Highlight connected nodes and links
  const highlightConnectedNodes = useCallback((nodeData) => {
    if (!nodeData) {
      resetHighlight();
      updateDetailsPanel(null);
      return;
    }
    
    // Make sure simulation is stopped before highlighting
    stopSimulation();
    
    // Update the details panel directly, avoiding state updates
    updateDetailsPanel(nodeData);
    
    // Set up linked nodes tracking if not already done
    if (Object.keys(linkedByIndexRef.current).length === 0) {
      linksRef.current.forEach(link => {
        linkedByIndexRef.current[`${link.source.id || link.source},${link.target.id || link.target}`] = 1;
      });
    }
    
    const isConnected = (a, b) => {
      const aId = a.id || a.index;
      const bId = b.id || b.index;
      return linkedByIndexRef.current[`${aId},${bId}`] || 
             linkedByIndexRef.current[`${bId},${aId}`] || 
             aId === bId;
    };
    
    // Update node styling for connections
    d3.selectAll('.node-group')
      .select('.node')
      .attr('fill-opacity', d => isConnected(d, nodeData) ? 0.8 : 0.2) // Increased opacity difference
      .style('filter', d => isConnected(d, nodeData) ? 'drop-shadow(0 0 3px rgba(0,0,0,0.3))' : 'none')
      // Add or remove the 'selected' class based on whether this is the selected node
      .classed('selected', d => (d.id === nodeData.id));
    
    // Update link styling for connections
    d3.selectAll('.link')
      .style('stroke-opacity', l => {
        const sourceId = l.source.id || l.source;
        const targetId = l.target.id || l.target;
        const nodeId = nodeData.id || nodeData.index;
        return sourceId === nodeId || targetId === nodeId ? 0.9 : 0.1;
      })
      .style('stroke-width', l => {
        const sourceId = l.source.id || l.source;
        const targetId = l.target.id || l.target;
        const nodeId = nodeData.id || nodeData.index;
        return sourceId === nodeId || targetId === nodeId 
          ? 2 + getDependencyCount(l.source, l.target) 
          : 1;
      })
      // Add 'highlighted' class to connected links
      .classed('highlighted', l => {
        const sourceId = l.source.id || l.source;
        const targetId = l.target.id || l.target;
        const nodeId = nodeData.id || nodeData.index;
        return sourceId === nodeId || targetId === nodeId;
      });
  }, [getDependencyCount, resetHighlight, stopSimulation, updateDetailsPanel]);
  
  // Improved drag handlers to properly manage fixed positions and prevent simulation restart
  const dragStarted = useCallback((event, d) => {
    // Prevent default behavior
    event.sourceEvent.stopPropagation();
    
    // Remember original position for this node
    d.originalX = d.x;
    d.originalY = d.y;
    
    // Temporarily unfix the node being dragged
    d.fx = null;
    d.fy = null;
    
    // Set dragging state to track which node is being dragged
    d.isDragging = true;
    
    // Set global dragging state to prevent mouseover effects 
    isDraggingRef.current = true;
  }, []);

  const dragged = useCallback((event, d) => {
    try {
      // Update node position
      d.x = event.x;
      d.y = event.y;
      
      // Update just this node position in the DOM
      d3.select(event.sourceEvent.target.parentNode)
        .attr('transform', `translate(${d.x},${d.y})`);
      
      // Update connected links' positions manually without affecting other nodes
      d3.selectAll('.link')
        .filter(link => {
          try {
            const sourceId = typeof link.source === 'object' ? link.source.id : link.source;
            const targetId = typeof link.target === 'object' ? link.target.id : link.target;
            const nodeId = d.id;
            return sourceId === nodeId || targetId === nodeId;
          } catch (e) {
            console.warn('Error filtering links during drag:', e);
            return false;
          }
        })
        .attr('x1', link => {
          try {
            if (typeof link.source === 'object') {
              return (link.source.id === d.id) ? d.x : link.source.x;
            }
            return (link.source === d.id) ? d.x : (typeof link.source === 'object' ? link.source.x : 0);
          } catch (e) {
            return 0;
          }
        })
        .attr('y1', link => {
          try {
            if (typeof link.source === 'object') {
              return (link.source.id === d.id) ? d.y : link.source.y;
            }
            return (link.source === d.id) ? d.y : (typeof link.source === 'object' ? link.source.y : 0);
          } catch (e) {
            return 0;
          }
        })
        .attr('x2', link => {
          try {
            if (typeof link.target === 'object') {
              return (link.target.id === d.id) ? d.x : link.target.x;
            }
            return (link.target === d.id) ? d.x : (typeof link.target === 'object' ? link.target.x : 0);
          } catch (e) {
            return 0;
          }
        })
        .attr('y2', link => {
          try {
            if (typeof link.target === 'object') {
              return (link.target.id === d.id) ? d.y : link.target.y;
            }
            return (link.target === d.id) ? d.y : (typeof link.target === 'object' ? link.target.y : 0);
          } catch (e) {
            return 0;
          }
        });
    } catch (error) {
      console.error("Error during drag operation:", error);
    }
  }, []);

  const dragEnded = useCallback((event, d) => {
    // Clear dragging state
    d.isDragging = false;
    
    // Fix the node at its final position
    d.fx = d.x;
    d.fy = d.y;
    
    // Make sure all nodes remain fixed
    nodesRef.current.forEach(node => {
      if (!node.fx || !node.fy) {
        node.fx = node.x;
        node.fy = node.y;
      }
    });
    
    // Reset global dragging state
    isDraggingRef.current = false;
  }, []);

  // Create a reusable function to clear the graph container
  const clearGraph = useCallback(() => {
    if (graphRef.current) {
      // Use D3's remove method instead of direct DOM manipulation
      d3.select(graphRef.current).selectAll('svg').remove();
    }
    
    // Also clear any references
    if (simulationRef.current) {
      simulationRef.current.stop();
      simulationRef.current = null;
    }
    svgRef.current = null;
    nodeGroupsRef.current = null;
    linkElementsRef.current = null;
    
    // Reset simulation stopped flag
    simulationStoppedRef.current = false;
    
    // Clear linkedByIndex reference
    linkedByIndexRef.current = {};
    
    // Clear selection
    selectedNodeRef.current = null;
    
    // Clear details panel without direct DOM manipulation
    if (detailsPanelRef.current) {
      detailsPanelRef.current.innerHTML = '';
    }
  }, []);
  
  // Function to handle refresh button click
  const handleRefresh = useCallback(() => {
    // Clear the graph and recreate it
    const currentResizeKey = Date.now().toString();
    resizeKeyRef.current = currentResizeKey;
    
    // Reset the graph to its original state
    clearGraph();
    
    // Re-render the graph with the same data
    renderGraph(graphData);
  }, [clearGraph, graphData]);
  
  // Create the renderGraph function outside of useEffect to be able to call it from handleRefresh
  const renderGraph = useCallback((graphDataSnapshot) => {
    if (!graphRef.current) {
      console.error("Graph container ref is not available");
      return;
    }
    
    if (!graphDataSnapshot || (!graphDataSnapshot.nodes && !graphDataSnapshot.edges)) {
      console.log("Missing dependency data, cannot render graph");
      return;
    }
    
    // Clean up any previous SVG elements first
    d3.select(graphRef.current).selectAll('svg').remove();
    
    const containerWidth = graphRef.current ? graphRef.current.offsetWidth : 800;
    const width = Math.max(800, containerWidth);
    const height = 700;
    
    // Create SVG
    const svg = d3.select(graphRef.current)
      .append('svg')
      .attr('width', '100%')
      .attr('height', '100%')
      .attr('viewBox', [0, 0, width, height])
      .attr('preserveAspectRatio', 'xMidYMid meet')
      .attr('class', 'dependencies-graph');
    
    svgRef.current = svg.node();
    
    // Verify nodes and edges exist and have the expected structure
    if (!graphDataSnapshot.nodes || !graphDataSnapshot.edges || 
        !Array.isArray(graphDataSnapshot.nodes) || 
        !Array.isArray(graphDataSnapshot.edges)) {
      console.error("Invalid graph data structure:", graphDataSnapshot);
      
      // Add a message in the SVG if data is invalid
      svg.append("text")
        .attr("x", width / 2)
        .attr("y", height / 2)
        .attr("text-anchor", "middle")
        .style("font-size", "16px")
        .style("fill", "#666")
        .text("Could not render graph: Invalid data structure");
      return;
    }
    
    // Process nodes and links
    // Create deep copies to avoid modifying source data
    const nodes = graphDataSnapshot.nodes.map(node => ({
      ...node,
      id: node.id || node.name,
      file_path: node.file_path || node.name, // Support both properties
      name: node.name || node.file_path, // Support both properties
      size: getNodeSize(node),
      selected_for_audit: node.selected_for_audit || false // Make sure we have this property
    }));
    
    const links = graphDataSnapshot.edges.map(edge => ({
      ...edge,
      source: edge.source,
      target: edge.target,
      value: getDependencyCount(edge.source, edge.target)
    }));
    
    // Check if we have enough data to render the graph
    if (nodes.length === 0) {
      svg.append("text")
        .attr("x", width / 2)
        .attr("y", height / 2)
        .attr("text-anchor", "middle")
        .style("font-size", "16px")
        .style("fill", "#666")
        .text("No nodes to display in the graph");
      return;
    }
    
    nodesRef.current = nodes;
    linksRef.current = links;
    
    // Create a container for all elements
    const container = svg.append('g').attr('class', 'container');
    
    // Create links
    const link = container.append('g')
      .attr('class', 'links')
      .selectAll('line')
      .data(links)
      .enter()
      .append('line')
      .attr('class', 'link')
      .style('stroke-width', d => 1 + (d.value || 1) * 0.5);
    
    linkElementsRef.current = link;
    
    // Create node groups
    const nodeGroup = container.append('g')
      .attr('class', 'nodes')
      .selectAll('.node-group')
      .data(nodes)
      .enter()
      .append('g')
      .attr('class', 'node-group');
      
    // Apply drag behavior after creation to avoid manipulation conflicts
    nodeGroup.call(d3.drag()
      .on('start', dragStarted)
      .on('drag', dragged)
      .on('end', dragEnded));
    
    nodeGroupsRef.current = nodeGroup;
    
    // Add node shapes (rectangle for files, ellipse for packages)
    nodeGroup.append('path')
      .attr('class', 'node')
      .attr('d', d => {
        if (d.type === 'file') {
          // File icon (rounded rectangle)
          const size = d.size || 25;
          const cornerRadius = 4;
          return `
            M${-size/2 + cornerRadius},${-size/2}
            h${size - 2*cornerRadius}
            q${cornerRadius},0 ${cornerRadius},${cornerRadius}
            v${size - 2*cornerRadius}
            q0,${cornerRadius} ${-cornerRadius},${cornerRadius}
            h${-size + 2*cornerRadius}
            q${-cornerRadius},0 ${-cornerRadius},${-cornerRadius}
            v${-size + 2*cornerRadius}
            q0,${-cornerRadius} ${cornerRadius},${-cornerRadius}
          `;
        } else {
          // Package icon (ellipse)
          const rx = d.size / 2 || 15;
          const ry = d.size / 3 || 10;
          return `M0,${-ry} a${rx},${ry} 0 1,0 0,${ry*2} a${rx},${ry} 0 1,0 0,${-ry*2}`;
        }
      })
      .attr('fill', d => getNodeColor(d))
      .attr('stroke', d => d3.color(getNodeColor(d)).darker(0.5))
      .attr('stroke-width', 2)
      .attr('fill-opacity', 0.5); // Increased base opacity for better visibility
    
    // Add text background for better readability
    nodeGroup.append('rect')
      .attr('class', 'text-background')
      .attr('x', d => d.type === 'file' ? -d.size/2 - 2 : -d.size/1.5)
      .attr('y', d => d.type === 'file' ? d.size/2 + 5 : d.size/3 + 5)
      .attr('width', d => d.type === 'file' ? d.size + 4 : d.size * 1.3)
      .attr('height', 18)
      .attr('rx', 4)
      .attr('fill', '#fff')
      .attr('fill-opacity', 0.7)
      .style('opacity', 0);
    
    // Add labels with truncated text
    nodeGroup.append('text')
      .attr('class', 'graph-label')
      .attr('x', 0)
      .attr('y', d => d.type === 'file' ? d.size/2 + 15 : d.size/3 + 15)
      .attr('text-anchor', 'middle')
      .text(d => {
        let label = 'Unknown';
        if (d.type === 'package') {
          label = cleanPackageName(d.name || d.display_name);
        } else {
          const path = d.name || d.file_path;
          label = path ? getCleanFilename(path) : 'Unknown File';
        }
        // Truncate text to fit within node size
        const maxLength = d.type === 'file' ? 10 : Math.floor(d.size / 5);
        return label.length > maxLength ? label.substring(0, maxLength) + '...' : label;
      });
    
    // Use D3 event binding carefully to avoid conflicts
    // Add separate handlers after node creation is complete
    nodeGroup.each(function(d) {
      const node = d3.select(this);
      
      // Add mouseover handler
      node.on('mouseover', function(event) {
        // Skip the hover effect if we're currently dragging any node
        if (isDraggingRef.current) return;
        
        // Prevent event propagation
        if (event.sourceEvent) event.sourceEvent.stopPropagation();
        
        // Ensure simulation is stopped
        stopSimulation();
        
        // Highlight this node
        d3.select(this).select('.node')
          .transition()
          .duration(200)
          .attr('stroke-width', 3)
          .attr('fill-opacity', 0.8)
          .style('filter', 'drop-shadow(0 0 5px rgba(0,0,0,0.3))');
          
        d3.select(this).select('.text-background')
          .transition()
          .duration(200)
          .style('opacity', 1);
      });
      
      // Add mouseout handler
      node.on('mouseout', function(event) {
        // Skip the hover effect if we're currently dragging any node
        if (isDraggingRef.current) return;
          
        // Prevent event propagation
        if (event.sourceEvent) event.sourceEvent.stopPropagation();
        
        // Reset node styling but keep connections highlighted if this node is selected
        const selectedNode = selectedNodeRef.current;
        const isThisNodeSelected = selectedNode && (selectedNode.id === d.id);
        
        if (!isThisNodeSelected) {
          d3.select(this).select('.node')
            .transition()
            .duration(200)
            .attr('stroke-width', 2);
            
          // Hide text background
          d3.select(this).select('.text-background')
            .transition()
            .duration(200)
            .style('opacity', 0);
        }
      });
      
      // Add click handler
      node.on('click', function(event) {
        // Prevent default behavior
        event.preventDefault();
        
        // Prevent event propagation
        event.stopPropagation();
        
        // Check for cooldown period (200ms)
        const now = Date.now();
        if (now - lastClickTimeRef.current < 200) {
          return; // Skip if clicked too soon after last click
        }
        lastClickTimeRef.current = now;
        
        // Check if this is the same node that was already selected
        const previouslySelected = selectedNodeRef.current;
        const isDeselect = previouslySelected && previouslySelected.id === d.id;
        
        if (isDeselect) {
          // If clicking the same node, deselect it
          selectedNodeRef.current = null;
          resetHighlight();
          updateDetailsPanel(null);
        } else {
          // Select this node and update details
          selectedNodeRef.current = d;
          highlightConnectedNodes(d);
        }
      });
      
      // Add double-click handler
      node.on('dblclick', function(event) {
        d.userPinned = !d.userPinned;
        
        if (d.userPinned) {
          // Pin at current position
          d.fx = d.x;
          d.fy = d.y;
          d3.select(this).select('.node')
            .attr('stroke-width', 3)
            .attr('stroke', '#000');
        } else {
          // Keep fixed position (no movement) but mark as not user-pinned
          d3.select(this).select('.node')
            .attr('stroke-width', 2)
            .attr('stroke', d3.color(getNodeColor(d)).darker(0.5));
        }
      });
    });
    
    // Add background click handler to clear selection
    svg.on('click', function(event) {
      // Only handle clicks directly on the SVG background, not bubbled events
      if (event.target === this) {
        selectedNodeRef.current = null;
        resetHighlight();
        updateDetailsPanel(null);
      }
    });
    
    // Add zoom functionality
    const zoom = d3.zoom()
      .scaleExtent([0.1, 10])
      .on('zoom', (event) => {
        container.attr('transform', event.transform);
      });
    
    svg.call(zoom);
    
    // Create the force simulation with slower cooling
    const simulation = d3.forceSimulation(nodes)
      .force('link', d3.forceLink(links).id(d => d.id || d.index).distance(d => {
        // Adjust link distance based on connected node sizes
        try {
          // Safely access source and target nodes
          const sourceId = typeof d.source === 'object' ? d.source.id || d.source.index : d.source;
          const targetId = typeof d.target === 'object' ? d.target.id || d.target.index : d.target;
          
          const sourceNode = nodes.find(n => (n.id || n.index) === sourceId);
          const targetNode = nodes.find(n => (n.id || n.index) === targetId);
          
          return (sourceNode?.size || 30) + (targetNode?.size || 30) + 20;
        } catch (e) {
          console.warn('Error calculating link distance:', e);
          return 100; // Default distance if calculation fails
        }
      }))
      .force('charge', d3.forceManyBody().strength(d => 
        d.type === 'package' ? -200 : -100
      ))
      .force('center', d3.forceCenter(width / 2, height / 2))
      .force('collision', d3.forceCollide().radius(d => d.size + 10))
      .alphaTarget(0)
      .alphaDecay(0.02); // Slower decay for better initial layout
    
    simulationRef.current = simulation;
    
    // Update positions on tick (during the initial layout only)
    simulation.on('tick', () => {
      // Prevent nodes from going out of bounds
      nodes.forEach(node => {
        node.x = Math.max(node.size || 25, Math.min(width - (node.size || 25), node.x));
        node.y = Math.max(node.size || 25, Math.min(height - (node.size || 25), node.y));
      });
      
      // Update positions
      updateNodePositions();
    });
    
    // Let the simulation run for a bit to get a good initial layout
    // then stop it and fix all node positions
    const timeoutId = setTimeout(() => {
      // Make sure the simulation ref is still valid
      if (simulationRef.current === simulation) {
        // Stop the simulation and fix all nodes in place
        simulation.stop();
        simulationStoppedRef.current = true;
        
        // Fix all node positions - this is crucial to prevent unwanted movement
        nodes.forEach(node => {
          node.fx = node.x;
          node.fy = node.y;
        });
        
        // Final position update for all elements
        updateNodePositions();
      }
    }, 5000); // Reduced timeout for better performance
    
    // Store the timeout ID for cleanup
    return timeoutId;
  }, [
    dragStarted, 
    dragged, 
    dragEnded, 
    stopSimulation, 
    updateNodePositions, 
    getNodeSize, 
    getNodeColor, 
    getDependencyCount, 
    cleanPackageName, 
    getCleanFilename, 
    highlightConnectedNodes,
    resetHighlight
  ]);

  // Render the graph when dependency data changes
  useEffect(() => {
    const graphDataSnapshot = graphData;
    
    if (!graphDataSnapshot || (!graphDataSnapshot.nodes && !graphDataSnapshot.edges)) {
      console.log("Missing dependency data, cannot render graph");
      return;
    }
    
    // Get the current resize key to track resize events
    const currentResizeKey = resizeKeyRef.current;
    
    // First, make sure to clear existing SVG to avoid DOM conflicts
    clearGraph();
    
    // Initialize details panel with placeholder
    if (detailsPanelRef.current) {
      detailsPanelRef.current.innerHTML = `
        <div class="details-placeholder">
          <div class="details-placeholder-icon">
            <i class="pi pi-info-circle"></i>
          </div>
          <div class="details-placeholder-text">
            Select a node in the graph to reveal more details
          </div>
        </div>
      `;
    }
    
    // Render the graph with current data - delay slightly to ensure DOM is ready
    let renderTimeoutId;
    renderTimeoutId = setTimeout(() => {
      if (graphRef.current) { // Make sure ref is still valid
        const simulationTimeoutId = renderGraph(graphDataSnapshot);
        
        // Store the timeout ID so we can clear it during cleanup
        return () => {
          if (simulationTimeoutId) {
            clearTimeout(simulationTimeoutId);
          }
        };
      }
    }, 0);
    
    // Cleanup function - vital for preventing DOM node errors
    return () => {
      // Clear the render timeout if component unmounts before rendering starts
      if (renderTimeoutId) {
        clearTimeout(renderTimeoutId);
      }
      
      // Make sure to properly clean up D3 elements before React removes the container
      clearGraph();
    };
  }, [graphData, clearGraph, renderGraph]);
  
  // Create and memoize the legend
  const legendComponent = useMemo(() => (
    <div className="graph-legend">
      {/* Package Status section first */}
      <div className="legend-section">
        <h4>Package Status</h4>
        <div className="legend-items">
          <div className="legend-item">
            <div className="legend-color status-active" style={{ border: '2px solid #348c39', opacity: 0.5, borderRadius: '50%' }}></div>
            <div className="legend-label">Active</div>
          </div>
          <div className="legend-item">
            <div className="legend-color status-deprecated" style={{ border: '2px solid #c62828', opacity: 0.5, borderRadius: '50%' }}></div>
            <div className="legend-label">Deprecated</div>
          </div>
          <div className="legend-item">
            <div className="legend-color status-maintained" style={{ border: '2px solid #1976d2', opacity: 0.5, borderRadius: '50%' }}></div>
            <div className="legend-label">Maintained</div>
          </div>
          <div className="legend-item">
            <div className="legend-color status-unmaintained" style={{ border: '2px solid #ef6c00', opacity: 0.5, borderRadius: '50%' }}></div>
            <div className="legend-label">Unmaintained</div>
          </div>
          <div className="legend-item">
            <div className="legend-color" style={{ backgroundColor: '#9c27b0', border: '2px solid #7b1fa2', opacity: 0.5, borderRadius: '50%' }}></div>
            <div className="legend-label">Built-in</div>
          </div>
        </div>
      </div>
      
      {/* File Types section second */}
      <div className="legend-section">
        <h4>File Types</h4>
        <div className="legend-items">
          {Object.entries(domainColors).map(([domain, color]) => (
            <div className="legend-item" key={domain}>
              <div className="legend-color" style={{ 
                backgroundColor: color, 
                border: `2px solid ${d3.color(color).darker(0.5)}`,
                opacity: 0.5,
                borderRadius: '4px' // Change back to square with rounded corners
              }}></div>
              <div className="legend-label">{domain}</div>
            </div>
          ))}
        </div>
      </div>
    </div>
  ), [domainColors]);

  return (
    <div className="dependencies-graph-container">
      <div className="graph-content">
        <div className="graph-visualization" ref={graphRef}>
          {/* Add refresh button */}
          <Button 
            icon="pi pi-refresh" 
            onClick={handleRefresh}
            className="refresh-button"
            tooltip="Reset graph to original state"
            tooltipOptions={{ position: 'top' }}
            aria-label="Refresh graph"
          />
        </div>
        <div className="graph-sidebar">
          {legendComponent}
          <div className="details-container" ref={detailsPanelRef}>
            {/* The details panel content will be managed by direct DOM manipulation */}
          </div>
        </div>
      </div>
    </div>
  );
};

export default React.memo(DependenciesGraph);