import React, { useEffect, useRef, useState } from 'react';
import * as d3 from 'd3';
import { Tooltip } from 'primereact/tooltip';
import './TimeToFixGraph.css';

const TimeToFixDomain = ({ data, colorData, colors = { Red: "#E83636", Orange: "#DE7539" } }) => {
    const ref = useRef(null);
    const [hoveredInfo, setHoveredInfo] = useState(null);

    useEffect(() => {
        
        if (!data || Object.keys(data).length === 0) {
            console.log("No domain data to visualize");
            return;
        }

        // Clear any existing SVG content
        d3.select(ref.current).selectAll("*").remove();

        // Data validation - ensure all values are numeric
        const validatedData = {};
        Object.keys(data).forEach(domain => {
            // Parse value and default to 0 if NaN
            const value = parseFloat(data[domain]);
            validatedData[domain] = isNaN(value) ? 0 : value;
        });

        // Set up dimensions - make it wider for horizontal bars
        const margin = { top: 10, right: 30, bottom: 30, left: 120 }; // Increased left margin for domain labels
        const width = 500;
        const height = Math.max(140, Object.keys(validatedData).length * 35); // Dynamic height based on number of domains
        const innerWidth = width - margin.left - margin.right;
        const innerHeight = height - margin.top - margin.bottom;

        // Create SVG
        const svg = d3.select(ref.current)
            .attr("width", width)
            .attr("height", height)
            .attr("viewBox", [0, 0, width, height])
            .attr("style", "max-width: 100%; height: auto;");

        // Process the data
        const domains = Object.keys(validatedData);
        
        // Filter domains with 0 values
        const nonZeroDomains = domains.filter(domain => validatedData[domain] > 0);
        
        // If no valid domains, don't render anything
        if (nonZeroDomains.length === 0) {
            console.log("No non-zero domains found");
            svg.append("text")
                .attr("x", width / 2)
                .attr("y", height / 2)
                .attr("text-anchor", "middle")
                .attr("fill", "#6B7280")
                .text("No data available");
            return;
        }
        
        // Sort domains by hours (descending)
        nonZeroDomains.sort((a, b) => validatedData[b] - validatedData[a]);
        
        // Prepare stacked data for each domain
        const stackedData = [];
        nonZeroDomains.forEach(domain => {
            if (colorData && colorData[domain]) {
                const domainColorData = colorData[domain];
                
                // Add red segment if exists and > 0
                if (domainColorData.Red && parseFloat(domainColorData.Red) > 0) {
                    stackedData.push({
                        domain,
                        color: "Red",
                        value: parseFloat(domainColorData.Red),
                        displayColor: colors.Red
                    });
                }
                
                // Add orange segment if exists and > 0
                if (domainColorData.Orange && parseFloat(domainColorData.Orange) > 0) {
                    stackedData.push({
                        domain,
                        color: "Orange",
                        value: parseFloat(domainColorData.Orange),
                        displayColor: colors.Orange
                    });
                }
            }
        });

        // Create y scale (for domains)
        const y = d3.scaleBand()
            .domain(nonZeroDomains)
            .range([margin.top, height - margin.bottom])
            .padding(0.3);

        // Create x scale (for hours)
        const maxHours = d3.max(Object.values(validatedData));
        const x = d3.scaleLinear()
            .domain([0, maxHours * 1.1]) // Add 10% padding
            .range([margin.left, width - margin.right]);

        // Create y axis (domains)
        svg.append("g")
            .attr("transform", `translate(${margin.left},0)`)
            .call(d3.axisLeft(y))
            .call(g => g.select(".domain").remove())
            .call(g => g.selectAll(".tick line").remove())
            .call(g => g.selectAll(".tick text")
                .attr("font-size", "12px")
                .attr("fill", "#6B7280")
                .attr("font-weight", "500")
                .attr("text-anchor", "end")
                .each(function(d) {
                    // Truncate domain names if they're too long
                    const text = d3.select(this);
                    if (d.length > 15) {
                        text.text(d.substring(0, 15) + '...');
                    }
                }));

        // Create x axis (hours)
        svg.append("g")
            .attr("transform", `translate(0,${height - margin.bottom})`)
            .call(d3.axisBottom(x).ticks(5).tickFormat(d => `${d}h`))
            .call(g => g.select(".domain").remove())
            .call(g => g.selectAll(".tick text")
                .attr("font-size", "12px")
                .attr("fill", "#6B7280"));

        // Add grid lines
        svg.append("g")
            .attr("class", "grid-lines")
            .selectAll("line")
            .data(x.ticks(5))
            .join("line")
            .attr("x1", d => x(d))
            .attr("x2", d => x(d))
            .attr("y1", margin.top)
            .attr("y2", height - margin.bottom)
            .attr("stroke", "#E5E7EB")
            .attr("stroke-dasharray", "4,4");

        // Create domain groups
        const domainGroups = svg.selectAll(".domain-group")
            .data(nonZeroDomains)
            .join("g")
            .attr("class", "domain-group")
            .attr("data-domain", d => d);

        // Function to calculate x position for each segment
        const getSegmentX = (domain, color) => {
            if (color === "Red") {
                return margin.left; // Red always starts at the beginning
            } else {
                // Orange starts after Red
                const redValue = colorData[domain].Red ? parseFloat(colorData[domain].Red) : 0;
                return x(redValue); 
            }
        };

        // Create stacked bar segments
        svg.selectAll(".bar-segment")
            .data(stackedData)
            .join("rect")
            .attr("class", "time-fix-bar bar-segment")
            .attr("x", d => {
                // Position based on segment type
                return getSegmentX(d.domain, d.color);
            })
            .attr("y", d => y(d.domain))
            .attr("width", d => {
                // Calculate segment width
                const barWidth = x(d.value) - margin.left;
                // For Orange, we need to adjust to just show the segment size
                if (d.color === "Orange") {
                    return barWidth;
                }
                return isNaN(barWidth) || barWidth <= 0 ? 0 : barWidth;
            })
            .attr("height", y.bandwidth())
            .attr("fill", d => d.displayColor)
            .attr("rx", 4)
            // Add a small white gap between segments
            .attr("stroke", "white")
            .attr("stroke-width", 1)
            .on("mouseover", function(event, d) {
                setHoveredInfo({
                    domain: d.domain,
                    color: d.color,
                    hours: d.value,
                    position: {
                        x: event.clientX,
                        y: event.clientY
                    }
                });
                
                d3.select(this)
                    .transition()
                    .duration(200)
                    .attr("opacity", 0.8)
                    .attr("stroke", "white")
                    .attr("stroke-width", 2);
            })
            .on("mouseout", function() {
                setHoveredInfo(null);
                
                d3.select(this)
                    .transition()
                    .duration(200)
                    .attr("opacity", 1)
                    .attr("stroke", "white")
                    .attr("stroke-width", 1);
            });

        // Add total hour labels at the end of each bar
        domainGroups.each(function(domain) {
            const totalHours = validatedData[domain];
            if (totalHours > 0) {
                d3.select(this).append("text")
                    .attr("class", "bar-value")
                    .attr("x", x(totalHours) + 5)
                    .attr("y", y(domain) + y.bandwidth() / 2 + 5)
                    .attr("text-anchor", "start")
                    .attr("font-size", "14px")
                    .attr("font-weight", "600")
                    .attr("fill", "#4B5563")
                    .text(`${Math.round(totalHours)}h`);
            }
        });

    }, [data, colorData, colors]);

    return (
        <div className="time-fix-graph-container">
            <svg 
                ref={ref} 
                className="time-fix-graph"
            />
            {hoveredInfo && (
                <div 
                    id="fix-time-domain-tooltip" 
                    className="fix-time-tooltip" 
                    style={{ 
                        left: `${hoveredInfo.position.x}px`, 
                        top: `${hoveredInfo.position.y}px`,
                        visibility: 'hidden' 
                    }}
                    data-pr-tooltip={`${hoveredInfo.domain} - ${hoveredInfo.color} flags: ${Math.round(hoveredInfo.hours)} hours to fix`}
                />
            )}
            <Tooltip target="#fix-time-domain-tooltip" position="top" />
        </div>
    );
};

export default TimeToFixDomain;
